import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConectorJsonService } from "../../../services/conector-json.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../../services/authentication.service";
import { ResponseHandler } from "../../../interfaces/ResponseHandler";
import Swal from "sweetalert2";

@Component({
  selector: "app-request",
  templateUrl: "./request.component.html",
  styleUrls: ["./request.component.css"],
})
export class RequestComponent implements OnInit {
  makeHttp = false;
  resetForm: FormGroup;
  isTrue = false;
  languageNavigator = "";

  constructor(
    private conector: ConectorJsonService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private auth: AuthenticationService
  ) {
    this.resetForm = this.fb.group(
      {
        hash: [{ value: "", disabled: true }, Validators.required],
        password: [null, Validators.required],
        repeat: [null, Validators.required],
      },
      {
        validator: this.checkPasswords,
      }
    );

    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get("password").value;
    let confirmPass = group.get("repeat").value;
    return pass === confirmPass ? null : { notSame: true };
  }

  async ngOnInit() {
    this.activatedRoute.paramMap.subscribe((p: any) => {
      if (p.params.id != null) {
        this.resetForm.get("hash").setValue(p.params.id);
        this.resetForm.updateValueAndValidity();
      }
    });
    this.getLanguageNavigator();
  }

  setPassword(value) {
    value.hash = this.resetForm.get("hash").value;

    this.conector
      .renewComplaint(value)
      .subscribe(async (k: ResponseHandler) => {
        const { ok, msg } = k;
        const translationFile = this.languageNavigator.startsWith("es")
          ? "es"
          : "en";

        await this.translate.use(translationFile).toPromise();

        const messages = {
          "Password Changed": "changedPasswordMessage",
          "Token invalid": "tokenInvalid",
          "User not found": "userNotFound",
        };

        const translationKey = messages[msg] || "";
        const msgAlert = await this.translate.get(translationKey).toPromise();

        const op = ok === true ? "success" : "error";
        const tt = await this.translate.get(op).toPromise();

        Swal.fire({ title: tt, text: msgAlert, icon: op });

        this.isTrue = ok;
      });
  }
  getLanguageNavigator() {
    if (navigator && navigator.language) {
      this.languageNavigator = navigator.language;
    } else {
      this.languageNavigator = "es";
    }
  }
}

//https://www.udemy.com/course/master-django-by-building-complete-restful-api-project/?couponCode=OCTOBERSALE&ranMID=39197&ranEAID=NuZiHLoAApo&ranSiteID=NuZiHLoAApo-IPHbjAI4LJDCX8M9A9QTwA&LSNPUBID=NuZiHLoAApo&utm_source=aff-campaign&utm_medium=udemyads

//7CDD6EDB31631313E9B74E05DE31AA4C
