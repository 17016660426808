import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ConectorKnowService } from '../../../services/conector-know.service';
import { SharedCommunicationService } from '../../../services/shared-communication.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-comunica-evaluaciones-resultados',
  templateUrl: './comunica-evaluaciones-resultados.component.html',
  styleUrls: ['./comunica-evaluaciones-resultados.component.css']
})

export class ComunicaEvaluacionesResultadosComponent implements OnInit {

  data = { total: 0 };

  users = [];

  results = [];

  evaluation = { total: 0, area_name: null, category_name: null, message: null };

  public chartHandler: any = {
    chart: {
      type: 'bar'
    },
    title: {
      text: 'Total'
    },
    xAxis: {
      categories: [],
      title: {
          text: null
      }
    },
    yAxis:{
      tickInterval: 1
    },
    plotOptions: {
      bar: {
          dataLabels: {
              enabled: true
          }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: '',
      data: [1]
    }]
  };

  constructor(private know: ConectorKnowService, private widgets: SharedCommunicationService, private snap: ActivatedRoute) {

    console.info('prev', this.chartHandler);

  }

  ngOnInit() {

    this.snap.params.subscribe( (ks: Params) => {

      let bar_data = [];
      let categories = [];

      this.know.evaluations_results(ks.id).subscribe( (ap: any) => {

        this.data = ap.results.data;
        this.evaluation = ap.question;
        this.users = ap.results.users;
        this.results = ap.results.data.results;

        this.results.forEach( x => {
          bar_data.push(Number(x.cuenta));
          categories.push(x.option_name);
        })

        this.chartHandler.series[0].data = bar_data;
        this.chartHandler.xAxis.categories = categories;

        Highcharts.chart('chartzilla', this.chartHandler);

      });

    });

  }

  getPercentaje(total) {
    let ps = Math.floor(total / this.data.total * 100);
    return ps;
  }

}
