import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import EvaluationInformation from '../types/db-evaluations-info.type';
import DbResponseHandler from '../types/common/db-response-handler';

@Injectable({
	providedIn: 'root',
})
export class IntegrityReportService {
	constructor(private http: HttpClient) {}

	evaluations_get(formdata, init = 0, limit = 10): Observable<DbResponseHandler<EvaluationInformation[]>> {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.post<DbResponseHandler<EvaluationInformation[]>>(
			`${environment.server}/integrityreport?limit=${limit}&offset=${init}`,
			formdata,
			{
				headers,
			}
		);
	}

	comment_post(formdata, init = 0, limit = 10) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.post(`${environment.server}/integrityreport/updateComment`, formdata, { headers });
	}

	downloadselection_post(formdata) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.post(`${environment.server}/integrityreport/downloadselection`, formdata, { headers });
	}

	reportConfiguration(idEvaluation: number, formData: any) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});
		return this.http.post(`${environment.server}/integrityreport/reportConfiguration/${idEvaluation}`, formData, {
			headers,
		});
	}

	generateMassiveReports(evaluateds) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.post(`${environment.server}/integrityreport/generateMassiveReports`, evaluateds, { headers });
	}
}
