import { Component, OnInit } from "@angular/core";
import { OwnQuestionsServices } from "../../../services/own-questions.service";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { Pagination } from "../../../models/pagination";
import { CreateAndUpdateOwnQuestionComponent } from "../modals/create-and-update-own-questions/create-and-update-own-questions.component";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../../services/authentication.service";
import { AnswersOwnQuestionComponent } from "../modals/answers-own-questions/answers-own-questions.component";

@Component({
  selector: "app-own-questions",
  templateUrl: "./own-questions.component.html",
  styleUrls: ["./own-questions.component.css"],
})
export class OwnQuestionsComponent implements OnInit {
  ownQuestions: any[];
  pagination = new Pagination();
  displayedColumns: string[] = [
    "question",
    "creationDate",
    "userCreated",
    "numberOfAssignments",
    "actions",
  ];
  dataSource: MatTableDataSource<Questions>;
  tableMessage = "";
  hiddenMessageTable: boolean = false;

  constructor(
    public ownQuestionsServices: OwnQuestionsServices,
    public dialog: MatDialog,
    public translate: TranslateService,
    public auth: AuthenticationService
  ) {
    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
    this.dataSource = new MatTableDataSource([]);
    this.pagination.length = 10;
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;
  }
  ngOnInit() {
    this.getAllOwnQuestions();
  }

  getAllOwnQuestions() {
    this.ownQuestionsServices
      .getAllOwnQuestions(this.pagination.init, this.pagination.limit)
      .subscribe((response: any) => {
        const { ok, data, msg } = response;
        if (ok) {
          this.ownQuestions = data;
          this.pagination.length = msg;
          this.dataSource.data = data;
          this.pagination.pageSize = 10;
          this.pagination.limit = 10;
          this.hiddenMessageTable = data.length > 0;
          this.tableMessage = data.length === 0 ? "No existen registros." : "";
        } else {
          console.log("Error get all own questions");
        }
      });
  }
  changePage($event) {
    this.pagination.length = 0;
    this.dataSource.data = [];
    this.pagination.changePage($event);
    this.ownQuestionsServices
      .getAllOwnQuestions(this.pagination.init, this.pagination.limit)
      .subscribe((response: any) => {
        const { ok, data, msg } = response;
        if (ok) {
          this.ownQuestions = data;
          this.pagination.length = msg;
          this.pagination.pageSize = 10;
          this.pagination.limit = 10;
          this.dataSource.data = data;
          this.hiddenMessageTable = data.length > 0;
          this.tableMessage = data.length === 0 ? "No existen registros." : "";
        } else {
          console.log("Error get all own questions");
        }
      });
  }
  createAndUpdateQuestion(element: Questions) {
    const dialogRef = this.dialog.open(CreateAndUpdateOwnQuestionComponent, {
      data: element,
      width: "700px",
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getAllOwnQuestions();
    });
  }
  createAndUpdateAnswers(element: Questions) {
    const dialogRef = this.dialog.open(AnswersOwnQuestionComponent, {
      data: element.questionId,
      width: "700px",
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }
  async deleteQuestions(element: Questions) {
    const title = await this.translate.get("advertencia").toPromise();
    const text = await this.translate.get("accionIrreversible").toPromise();
    Swal.fire({
      title: title,
      icon: "warning",
      text: text,
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "No",
    }).then((d: any) => {
      if (d.value) {
        this.ownQuestionsServices
          .deleteQuestion(element.questionId)
          .subscribe((response: any) => {
            const { ok } = response;
            if (ok) {
              Swal.fire({
                title: "Success",
                text: "Eliminado correctamente.",
                icon: "success",
              });
              this.getAllOwnQuestions();
            } else {
              Swal.fire("Error", "error");
            }
          });
      }
    });
  }
}

export interface Questions {
  questionId: number;
  question: string;
  userCreated: string;
  creationDate: string;
  numberOfAssignments: number;
}
