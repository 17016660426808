import { answerQuestionsSurveyConfig, ColorScaleSettings, configModelType1, configModelType2, ownQuestionsSurveyConfig, questionsSurveyConfig, questionsTypeSurveyConfig, seriesSurveyConfig, topicsSurvey, UsersSurvey } from "../../../ViewModel/config-model/config-model.component";
import { FormArray } from "@angular/forms";
import { Injectable } from "@angular/core";
@Injectable({
    providedIn: 'root'
})
export class Submit {

    submitPsot(data : any) {
        data.stateSubmit = false;
        
        // NOM035 
        if(data.firstFormGroup.get('stepFirstType').value == '2' ){

            if(data.submitNom035Valid == false){

                const EntityConfig = new configModelType2();
                EntityConfig.id	= 0,
                
                EntityConfig.nameProyect	= data.secondFormGroup.get('nameProyect').value;
                EntityConfig.nameCompany	= data.secondFormGroup.get('nameCompany').value;
                EntityConfig.idModule	= 1,
                EntityConfig.idUser	= "oscar",
                EntityConfig.idAccess	= "1",
                EntityConfig.startDate	= data.secondFormGroup.get('startDate').value;
                EntityConfig.endDate	= data.secondFormGroup.get('endDate').value;
        
                var f = new Date();
        
                EntityConfig.createDate =  data.datepipe.transform(f, 'yyyy-MM-dd');
        
                EntityConfig.idAccount	= "oscar";
        
                EntityConfig.type	 = data.firstFormGroup.get('stepFirstType').value;
                EntityConfig.subType= "0";
                EntityConfig.engagement = "0";
        
                EntityConfig.description	= data.secondFormGroup.get('description').value;
                EntityConfig.predetermined	= "1";
                EntityConfig.idLanguaje	= "1";
                EntityConfig.status	= "1";
                // USUARIOS 
                let UsersSurveyArrayModel =  [new UsersSurvey()];

                for (var i = 0; i < data.listUsersForm.length; i++) {
                let item = data.listUsersForm[i];

                if (item.status == true) {
                    const UsersSurvey_ = new UsersSurvey();
                    UsersSurvey_.id = item.id;
                    UsersSurvey_.idGroup = item.idGroup;
                    UsersSurveyArrayModel.push(UsersSurvey_);
                } 

                }
                UsersSurveyArrayModel.splice(0,1);

                EntityConfig.ListUsersSurvey = UsersSurveyArrayModel;
                //return false;
        
                if(data.secondFormGroup.get('random').value == "True"){
                EntityConfig.random	= "True";
                }else{
                EntityConfig.random	= "False";
                }
                return EntityConfig
                // data.conector.postNom035(EntityConfig).subscribe( 
                // (response: any) => {
                //     const {ok, data, msg} = response;
                //     if (ok === false) {
                //     var MsgError  = "";
                //     for(var k in data) {
                //         MsgError = MsgError +  "Error en " +  data[k].cam + " ( " + data[k].msg + " ) \n";
                //     }
                //     return Swal.fire('Error', MsgError, 'error')
                //     } else {
                    
                //     data.submitNom035Valid = true;
                //     data.stateSubmit = true;

                //     Swal.fire('Buen trabajo!', 'Configuración Guardada', 'success');

                //     data.route.navigate(['manager/Survey']);

                //     }
                // },
                // (err) => {
                //     data.stateSubmit = true;

                //     return Swal.fire('Error', err.status + ' ' + err.statusText, 'error')
                // }
                // );
        

            }else{
                return null
                // data.stateSubmit = true;
                // return Swal.fire('Error', "Esta Configuración ya se Guardo ", 'error')
            };
        
            
        }else{
            //CLIMA
            if(data.submitValid == false) {

                const EntityConfig = new configModelType1();

                // -----------------------
                // DATOS BASICOS
                // -----------------------

                EntityConfig.id	= 0,
                
                EntityConfig.nameProyect	= data.secondFormGroup.get('nameProyect').value;
                EntityConfig.nameCompany	= data.secondFormGroup.get('nameCompany').value;
                EntityConfig.idModule	= 1,
                EntityConfig.idUser	= "",
                EntityConfig.idAccess	= "1",
                EntityConfig.startDate	= data.secondFormGroup.get('startDate').value;
                EntityConfig.endDate	= data.secondFormGroup.get('endDate').value;

                var f = new Date();
                EntityConfig.createDate =  data.datepipe.transform(f, 'yyyy-MM-dd');

                EntityConfig.idAccount	= "";

                EntityConfig.type	 = data.firstFormGroup.get('stepFirstType').value;
                EntityConfig.subType= data.firstFormGroup.get('stepSecondEngagement').value;
                EntityConfig.engagement = data.firstFormGroup.get('stepSecondEngagement').value;

                EntityConfig.description	= data.secondFormGroup.get('description').value;
                EntityConfig.predetermined	= "1";
                EntityConfig.idLanguaje	= "1";
                EntityConfig.status	= "1";

                if(data.secondFormGroup.get('random').value == "True"){
                EntityConfig.random	= "True"; 
                }else{
                EntityConfig.random	= "False";
                }

                // -----------------------
                // Configuracion de escala de colores 
                // -----------------------

                const colorScaleSettings = new ColorScaleSettings()
                colorScaleSettings.id = "0";
                colorScaleSettings.idSurveyConfig = "0";
                colorScaleSettings.Correct = data.ceroFormGroup.get('level1').value
                colorScaleSettings.Check = data.ceroFormGroup.get('level2').value
                colorScaleSettings.Beef_up = data.ceroFormGroup.get('level3').value
                colorScaleSettings.Hold = data.ceroFormGroup.get('level4').value


                EntityConfig.colorScaleSettings = colorScaleSettings;
                // -----------------------
                // USUARIOS 
                // -----------------------

                let UsersSurveyArrayModel =  [new UsersSurvey()];
                for (var i = 0; i < data.listUsersForm.length; i++) {
                let item = data.listUsersForm[i];
                
                if (item.status == true) {
                    const UsersSurvey_ = new UsersSurvey();
                    UsersSurvey_.id = item.id;
                    UsersSurvey_.idGroup = item.idGroup;
                    UsersSurveyArrayModel.push(UsersSurvey_);

                } 
            
                }
                EntityConfig.ListUsersSurvey = UsersSurveyArrayModel;

                // -----------------------
                // Categorias
                // -----------------------
                let categoryModel   =  data.fifthFormGroup.get('categorys') as FormArray;
                let categoryArrayModel =  [new topicsSurvey()];
                for (var i = 0; i < categoryModel.value.length; i++) {
                
                let item = categoryModel.value[i];
                if(item.id != ""){
                    const categorSuvery = new topicsSurvey();
                    categorSuvery.id = item.id;
                    categorSuvery.idSurveyConfig = 1;
                    categorSuvery.idCategory = 1;
                    categorSuvery.name = item.name;
                    categorSuvery.description = item.name;
                    categorSuvery.active = item.status;
                    categorSuvery.orderlist = i;
                    categorSuvery.view = item.view;
                    categoryArrayModel.push(categorSuvery);
                }
                }
                categoryArrayModel.splice(0,1);
                EntityConfig.category = categoryArrayModel;
                
                // -----------------------
                // Series
                // -----------------------
                let seriesModel   =  data.fifthFormGroup.get('series') as FormArray;
                let seriesArrayModel =  [new seriesSurveyConfig()];
                for (var i = 0; i < seriesModel.value.length; i++) {
                let item = seriesModel.value[i];
                if(item.id != ""){
                    const seriesSurvey = new seriesSurveyConfig();
                    if(!seriesSurvey.idSeries) seriesSurvey.idSeries =  item.id;  else seriesSurvey.idSeries = item.idSeries;
                    seriesSurvey.id = item.id;
                    seriesSurvey.idSurveyConfig = 1;
                    seriesSurvey.name = item.name;
                    seriesSurvey.description = item.name;
                    seriesSurvey.idTopic = item.idCategory;
                    seriesSurvey.active = 1;
                    seriesSurvey.orderList = i;
                    seriesSurvey.change = 0;
                    

                    seriesArrayModel.push(seriesSurvey);
                }
                }
                seriesArrayModel.splice(0,1);
                EntityConfig.series = seriesArrayModel;
                
                // -----------------------
                // preguntas
                // -----------------------
                let questionsModel   =  data.fifthFormGroup.get('questions') as FormArray;
                let questionsArrayModel =  [new questionsSurveyConfig()];
                for (var i = 0; i < questionsModel.value.length; i++) {
                let item = questionsModel.value[i];
                if(item.id != ""){
                    const questionsSurvey = new questionsSurveyConfig();

                    questionsSurvey.idquestion =  item.id;
                    questionsSurvey.idserie =  item.idSeries;
                    questionsSurvey.idtype =  1; // consultar
                    questionsSurvey.name =  item.name;
                    questionsSurvey.active =  1;
                    questionsSurvey.orderlist =  i;
                    questionsSurvey.idFactors =  item.idFactors;
                    questionsSurvey.GroupFactor =  item.GroupFactor;
                    questionsSurvey.change =  1;
                    questionsSurvey.CodigoPreg = item.CodigoPreg;
                    questionsSurvey.pertEngagement = item.pertEngagement;

                    questionsArrayModel.push(questionsSurvey);
                }
                }
                questionsArrayModel.splice(0,1);
                EntityConfig.questions = questionsArrayModel;
                
                // -----------------------
                // tipo de respuesta
                // -----------------------
                let MaxValue: number = data.ceroFormGroup.get("level4").value / 4;

                let questionsTypesModel   =  data.fifthFormGroup.get('questionsTypes') as FormArray;
                let questionsTypesArrayModel = [new questionsTypeSurveyConfig()];
                for (var i = 0; i < questionsTypesModel.value.length; i++) {
                let item = questionsTypesModel.value[i];
                debugger
                if(item.id != ""){
                    const questionsTypeSurvey = new questionsTypeSurveyConfig();
                    questionsTypeSurvey.id = item.id;
                    questionsTypeSurvey.typeid = item.typeid;
                    questionsTypeSurvey.section1 = (MaxValue * item.section1).toString();
                    questionsTypeSurvey.section2 = item.section2 ;
                    questionsTypeSurvey.section3 = item.section3 ;
                    questionsTypeSurvey.description = item.description ;
                    questionsTypeSurvey.orderlist = item.orderlist ;
                    questionsTypeSurvey.active = item.active ;
                    questionsTypesArrayModel.push(questionsTypeSurvey);
                }
                }
                questionsTypesArrayModel.splice(0,1);
                EntityConfig.typeAnswer = questionsTypesArrayModel;
                
                // -----------------------
                //respuestas  engagement
                // -----------------------
                
                let answerQuestionsModel   =  data.sixFormGroup.get('questions') as FormArray;
                let answerQuestionsArrayModel =  [new answerQuestionsSurveyConfig()];
                
                if (EntityConfig.subType != "3") {

                for (var i = 0; i < answerQuestionsModel.value.length; i++) {
                
                    let item = answerQuestionsModel.value[i];
                    if(item.id != ""){
                    const  answerQuestionsSurvey = new answerQuestionsSurveyConfig();
                    if(item.id == "-1"){
                        answerQuestionsSurvey.id =  item.id;
                        answerQuestionsSurvey.answer = item.question+"||"+item.answer;
                    }else{
                        answerQuestionsSurvey.id =  item.id;
                        answerQuestionsSurvey.answer =  item.answer.toString();
                    }
                    answerQuestionsArrayModel.push(answerQuestionsSurvey);
                    }
                }

                } else {
                const  answerQuestionsSurvey = new answerQuestionsSurveyConfig();
                answerQuestionsSurvey.id = 0;
                answerQuestionsSurvey.answer = "||";
                answerQuestionsArrayModel.push(answerQuestionsSurvey);

                }

                answerQuestionsArrayModel.splice(0,1);
                EntityConfig.answer = answerQuestionsArrayModel;

                // -----------------------
                // preguntas propias
                // -----------------------
                let ownQuestionsModel   =  data.quarterFormGroup.get('questions') as FormArray;
                let ownQuestionsArrayModel =  [new ownQuestionsSurveyConfig()];
                
                if (EntityConfig.subType != "3") {
                
                for (var i = 0; i < ownQuestionsModel.value.length; i++) {
                    let item = ownQuestionsModel.value[i];
                    //if(item.id != "" && item.id > 0){
                    const ownQuestionsSurvey = new ownQuestionsSurveyConfig();
                    ownQuestionsSurvey.id  =  i;
                    ownQuestionsSurvey.question =  item.question;
                    ownQuestionsSurvey.answer =  item.answer;
                    ownQuestionsArrayModel.push(ownQuestionsSurvey);
                // }
                }

                }else{
                const ownQuestionsSurvey = new ownQuestionsSurveyConfig();
                ownQuestionsSurvey.id  =  0;
                ownQuestionsSurvey.question = "0";
                ownQuestionsSurvey.answer =  "0";
                ownQuestionsArrayModel.push(ownQuestionsSurvey);
                }
                ownQuestionsArrayModel.splice(0,1);
                EntityConfig.ownQuestions = ownQuestionsArrayModel;

                // -----------------------
                // ENVIO FORMULARIO
                // -----------------------
                return EntityConfig
                // data.conector.post(EntityConfig).subscribe( 
                //     (response: any) => {
                //         const {ok, data, msg} = response;
                //         if (ok === false) {
                //             // var MsgError  = "";
                //             // for(var k in data) {
                //             //     MsgError = MsgError +  "Error en " +  data[k].cam + " ( " + data[k].msg + " ) \n";
                //             // }
                //             // return Swal.fire('Error', MsgError, 'error')
                //             alert('ok');
                            
                //             return true 

                //         } else {
                //             return true 
                //         }
                //     },
                //     (err) => {
                //         data.stateSubmit = true;
                //         return Swal.fire('Error', err.status + ' ' + err.statusText, 'error')
                //     }
                // );

            } else{
                // data.stateSubmit = true;
                // return Swal.fire('Error', "Esta Configuración ya se Guardo ", 'error')
                return null
            };

        }
    }

}
