import { Component, OnDestroy, OnInit } from "@angular/core";
import { Chart } from "angular-highcharts";
import { SeriesOptionsType } from "highcharts";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { HomeSurveyServices } from "../../../../services/home-survey.service";
import Project from "../../../../types/survey-home/db-survey-home-project";
import SurveyHomeStatistics from "../../../../types/survey-home/db-survey-home-statistics";
import StatiticsGroupsAndSubgroups from "../../../../types/survey-home/db-survey-home-statitics-groups-subgroups";

@Component({
  selector: "app-survey-home",
  templateUrl: "./survey-home.component.html",
  styleUrls: ["./survey-home.component.css"],
})
export class SurveyHomeComponent implements OnDestroy, OnInit {
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public projectSurveys: Chart;
  public groupSurveys: any;
  public surveyHomeStatistics: SurveyHomeStatistics;
  public allProjects: Project[] = [];
  public statiticsByProject: Chart;
  public groupsAndSubgroups: StatiticsGroupsAndSubgroups[] = [];
  public showDetails: boolean = false;
  public displayedColumns: string[] = [
    "groupOrSubgroup",
    "totalAssigneds",
    "totalAnswereds",
  ];
  public textDetails: string =
    "Si desea visualizar más detalles de un proyecto seleccione uno.";

  constructor(private survey: HomeSurveyServices) {}
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  ngAfterViewInit(): void {}
  ngOnInit() {
    this.initSurveyHomeStatistics();
    this.getAllProjects();
  }

  private initSurveyHomeStatistics(): void {
    this.survey
      .getSurveyHomeStatistics()
      .pipe(
        takeUntil(this.destroy$),
        map((response) => response.data)
      )
      .subscribe((response) => {
        this.surveyHomeStatistics = response;
      });
  }

  private getAllProjects(): void {
    this.survey
      .getAllProjects()
      .pipe(
        takeUntil(this.destroy$),
        map((response: any) => response.data)
      )
      .subscribe((projects: Project[]) => {
        this.projectSurveys = this.createChartProjectSurveys(projects);
        this.allProjects = projects;
      });
  }

  private createChartProjectSurveys(projects: Project[]): Chart {
    const categories: string[] = projects.map((project) => project.nameProyect);
    const series = [
      {
        name: "Asignadas",
        data: projects.map((project) => project.totalAssigneds),
        color: "#05aff2",
      },
      {
        name: "Respondidas",
        data: projects.map((project) => project.totalAnswereds),
        color: "#04d98b",
      },
    ];
    return new Chart({
      chart: {
        type: "column",
      },
      title: { text: "" },
      xAxis: { type: "category", categories: categories },
      yAxis: { min: 0, title: { text: "# Encuestas" } },
      credits: { enabled: false },
      legend: { enabled: false },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      series: series as SeriesOptionsType[],
    });
  }
  selectProject(item: Project) {
    if (item.totalAssigneds > 0) {
      this.getStatiticsGroupsAndSubgroupsByProject(item.id);
      this.statiticsByProject = this.createChartStatiticsByProject(item);
      this.showDetails = true;
    } else {
      this.textDetails =
        "El proyecto seleccionado no contiene asignaciones, por lo tanto, no es posible visualizar más detalles.";
      this.showDetails = false;
    }
  }

  private getStatiticsGroupsAndSubgroupsByProject(idProject: number): void {
    this.survey
      .getStatiticsGroupsAndSubgroups(idProject)
      .pipe(
        takeUntil(this.destroy$),
        map((response: any) => response.data)
      )
      .subscribe((result: StatiticsGroupsAndSubgroups[]) => {
        this.groupsAndSubgroups = result;
      });
  }

  private createChartStatiticsByProject(item: Project) {
    const percentageAnswereds =
      (item.totalAnswereds * 100) / item.totalAssigneds;
    const percentageMissing = 100 - percentageAnswereds;
    const series = [
      { name: "Realizadas", y: percentageAnswereds, color: "#04d98b" },
      { name: "Sin realizar", y: percentageMissing, color: "#970ff2" },
    ];
    return new Chart({
      chart: {
        type: "pie",
      },
      title: {
        text: " ",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "{series.name}: {point.percentage:.1f}%",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: "Porcentaje",
          colorByPoint: true,
          type: undefined,
          data: series,
        },
      ],
    });
  }
}
