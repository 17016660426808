import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ConnectorJobProfileService } from '../../../../services/job-profile.service';
import { QuestionsSelectionComponent } from '../questions-selection/questions-selection.component';

@Component({
	selector: 'app-configurate-jobprofile',
	templateUrl: './configurate-jobprofile.component.html',
	styleUrls: ['./configurate-jobprofile.component.scss'],
})
export class ConfigurateJobProfileComponent implements OnInit {
	@Input() IdProfile = '';
	@Output() nextClassicalMethodAndRiskAnalysis: EventEmitter<any> = new EventEmitter();

	id = 0;
	isLinear = false;
	configureJobProfileGroup: FormGroup;
	displayedColumns: string[] = ['seleccionado', 'dimension', 'peso', 'limites'];
	//weigthList = [0, 1, 2, 3, 4, 5, 6];
	weigthList = [6, 5, 4, 3, 2, 1];
	dataSource: Dimension[];
	puesto: string;
	descripcion: string;
	idJobProfile: number;
	jobQuestions = [];
	trueIdentity = false;
	ti_requerido = false;
	true_Identity_requerido = 0;
	evaluationResults = [];
	lInferior = 0;
	lSuperior = 0;
	lMedio = 0;

	value: number = 40;
	highValue: number = 60;
	minValueRGI: number;
	maxValueRGI: number;

	chartOptions: Highcharts.Options = {
		chart: {
			inverted: true,
			margin: [0, 0, 0, 0],
			marginLeft: 0,
			type: 'bullet',
		},
		title: { text: null },
		legend: { enabled: false },
	};

	showBaseName: boolean = true;

	constructor(
		private conector: ConnectorJobProfileService,
		private form: FormBuilder,
		private snap: ActivatedRoute,
		public dialog: MatDialog
	) { }

	createFormArray(): FormArray {
		console.log('createFormArray');
		console.log(this.dataSource);
		return new FormArray(
			this.dataSource.map(
				(item) =>
					new FormGroup({
						seleccionado: new FormControl(item.seleccionado),
						peso: new FormControl(item.peso),
						linferior: new FormControl(item.linferior),
						lsuperior: new FormControl(item.lsuperior),
					})
			)
		);
	}

	ngOnInit(): void {
		if (this.IdProfile) {
			this.id = parseInt(this.IdProfile);
		} else {
			this.snap.paramMap.subscribe(async (p: Params) => {
				this.id = p.params.id;
			});
		}

		this.conector.jobprofileById(this.id).subscribe((response: any) => {
			const { data, msg } = response;

			this.puesto = data.puesto;
			this.descripcion = data.descripcion;
			this.idJobProfile = data.id;
			this.configureJobProfileGroup = this.form.group({
				dimentions: this.dataSource,
				idJobProfile: this.idJobProfile,
			});
			this.trueIdentity = data.true_Identity;
			this.ti_requerido = data.tI_Requerido;

			if (this.ti_requerido == true) {
				this.true_Identity_requerido == 1;
			} else {
				if (this.trueIdentity == true) {
					this.true_Identity_requerido == 2;
				}
			}

			this.conector.dimentions_get(this.idJobProfile).subscribe((response: any) => {
				const { data, msg } = response;
				// this.configureJobProfileGroup = data;
				this.dataSource = data as Dimension[];

				this.dataSource.forEach(function (dim) {
					dim.options = {
						floor: 0,
						ceil: 100,
						disabled: dim.dimensionH == 2 ? false : true,
						showTicks: true,
						showSelectionBar: false,
					};
				});

				const dimentionRGI = this.dataSource.filter((item) => item.dimensionH == 2);
				this.minValueRGI = dimentionRGI[0].linferior;
				this.maxValueRGI = dimentionRGI[0].lsuperior;
				this.getPercentageLimits();
				// this.conector.GetPercentageApprovalRejection(this.id).subscribe((response: any) => {
				// 	const { data, msg } = response;
				// 	let dimension = this.dataSource.filter((x) => x.dimensionH == 2);

				// 	if (data.length > 0) {
				// 		this.lInferior =
				// 			(data.filter((x) => x.result < dimension[0].linferior).length * 100) / data.length;

				// 		this.lMedio =
				// 			(data.filter((x) => x.result > dimension[0].linferior && x.result < dimension[0].lsuperior)
				// 				.length *
				// 				100) /
				// 			data.length;

				// 		this.lSuperior =
				// 			(data.filter((x) => x.result > dimension[0].lsuperior).length * 100) / data.length;
				// 	} else {
				// 		this.lInferior = 0;
				// 		this.lMedio = 0;
				// 		this.lSuperior = 0;
				// 	}
				// });
			});
		});
	}

	getPercentageLimits() {
		this.conector.GetPercentageApprovalRejection(this.id).subscribe((response: any) => {
			const { data } = response;
			this.evaluationResults = data;
			if (data.length > 0) {
				this.lInferior = (data.filter((x) => x.result <= this.minValueRGI).length * 100) / data.length;
				this.lMedio = (data.filter((x) => x.result > this.minValueRGI && x.result <= this.maxValueRGI).length * 100) / data.length;
				this.lSuperior = (data.filter((x) => x.result > this.maxValueRGI).length * 100) / data.length;
			} else {
				this.lInferior = this.lMedio = this.lSuperior = 0;
			}
		});
	}


	NumSeleccionados(row?: Dimension, evento?: any) {
		var dimensionesHonestidad = 5;
		var dimensionesIncidencia = 4;

		var maxSelected = row.dimensionH === 1 ? dimensionesHonestidad : dimensionesIncidencia;

		var seleccionados = this.dataSource.filter(
			(dimention) => dimention.dimensionH === row.dimensionH && dimention.seleccionado
		).length;

		if (seleccionados < maxSelected) row.seleccionado = evento.checked;
		else {
			row.seleccionado = false;
			evento.source.checked = false;
		}
	}

	openDialog(): void {
		this.conector
			.get_by_company(this.id)
			.pipe(
				switchMap((e: any) => {
					this.jobQuestions = e.data;
					return this.dialog
						.open(QuestionsSelectionComponent, {
							width: '600px',
							data: { id: this.id, questions: this.jobQuestions },
						})
						.afterClosed();
				})
			)
			.subscribe(() => {
				console.log('The dialog was closed');
			});
	}

	submit() {
		let formdata = { idJobProfile: this.idJobProfile, dimentions: [] };
		formdata.dimentions = this.dataSource
			.filter((f) => f.seleccionado)
			.map((item) => {
				if (item.dimensionH == 2) {
					return {
						...item,
						linferior: this.minValueRGI,
						lsuperior: this.maxValueRGI,
					};
				} else {
					return item;
				}
			});

		this.conector.dimentions_post(formdata).subscribe((d: any) => {
			const { ok, msg, data } = d;
			if (ok) {
				this.nextClassicalMethodAndRiskAnalysis.emit(formdata);
			} else {
				Swal.fire('Error', msg, 'error');
			}
		});
		this.showBaseName = false;
	}
	onMinChange(newMinRGI: number) {
		if (this.minValueRGI != newMinRGI) {
			this.calculatePercentageLimitsRGI(newMinRGI, this.maxValueRGI);
		}
		this.minValueRGI = newMinRGI;
	}

	onMaxChange(newMaxRGI: number) {
		if (this.maxValueRGI != newMaxRGI) {
			this.calculatePercentageLimitsRGI(this.minValueRGI, newMaxRGI);
		}
		this.maxValueRGI = newMaxRGI;
	}

	calculatePercentageLimitsRGI(minValue, maxValue) {
		if (this.evaluationResults.length > 0) {
			this.lInferior = (this.evaluationResults.filter((x) => x.result <= minValue).length * 100) / this.evaluationResults.length;
			this.lMedio = (this.evaluationResults.filter((x) => x.result > minValue && x.result <= maxValue).length * 100) / this.evaluationResults.length;
			this.lSuperior = (this.evaluationResults.filter((x) => x.result > maxValue).length * 100) / this.evaluationResults.length;
		} else {
			this.lInferior = this.lMedio = this.lSuperior = 0;
		}
	}
}

export interface Dimension {
	id: number;
	dimension: string;
	orden: number;
	editable: boolean;
	dimensionH: number;
	seleccionado: boolean;
	peso: number;
	linferior: number;
	lsuperior: number;
	lowRange: number;
	midRangeMin: number;
	midRangeMed: number;
	midRangeMax: number;
	highRange: number;
	options: Options;
}
