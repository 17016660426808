import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import Swal from "sweetalert2";
import { ResponseHandler } from "../../../../interfaces/ResponseHandler";
import { ConnectorGroupService } from "../../../../services/Admin/conector-group.service";
import { ConnectorCollaboratorService } from "../../../../services/Collaborator/conector-collaborator.service";
import { ConectorJsonService } from "../../../../services/conector-json.service";
import { ConectorModuleService } from "../../../../services/conector-module.service";
import { GenericCatalogsService } from "../../../../services/GenericCatalogs.service";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";
import { tap } from 'rxjs/operators';

@Component({
	selector: 'app-create-many-collaborator',
	templateUrl: './create-many-collaborator.component.html',
	styleUrls: ['./create-many-collaborator.component.css'],
})
export class CreateManyCollaboratorComponent implements OnInit {
	evaluations = [];
	displayedColumns: string[] = ['selected', 'name'];
	manyCollaboratorForm: FormGroup;
	jobProfileList = [];
	lentTestList = [];
	countriesList = [];
	groupInfo = { visible: false, description: null };
	groupsList = [];
	tree_selected: FormControl;
	groups: any = [];
	moduleSurvey = true;
	idCountry = 0;
	userAuthenticated: string = '';
	constructor(
		private jsonconector: ConectorJsonService,
		private generic: GenericCatalogsService,
		private job: ConnectorJobProfileService,
		private groupConnector: ConnectorGroupService,
		private conectorModule: ConectorModuleService,
		private form: FormBuilder,
		private conector: ConnectorCollaboratorService,
		private dialog: MatDialog
	) {}
	async loadDataGroups() {
		try {
			const data = await this.groupConnector.group_tree().toPromise();
			this.groups = data;
		} catch (error) {
			console.error('Error al cargar los datos:', error);
		}
	}
	ngOnInit(): void {
		if (localStorage.getItem('module') == 'Survey') this.moduleSurvey = false;

		this.jsonconector
			.getNamesOfAuthentication()
			.pipe(tap((response: any) => (this.userAuthenticated = response.data.names)))
			.subscribe();

		this.tree_selected = new FormControl();

		this.tree_selected.valueChanges.subscribe((e: any) => {
			this.saveGroup(e);
		});

		if (localStorage.getItem('idCountry') != null) {
			this.idCountry = +localStorage.getItem('idCountry');
		}

		this.manyCollaboratorForm = this.form.group({
			numberIds: [null, Validators.required],
			lengtest: localStorage.getItem('module') == 'Honestidad' ? [null, Validators.required] : [0],
			country: [this.idCountry, Validators.required],
			namesRegisteredBy: [null],
			group: [0],
			jobProfile: localStorage.getItem('module') == 'Honestidad' ? [null, Validators.required] : [0],
		});

		this.generic.ct_LengTest().subscribe((response: any) => {
			const { ok, data, msg } = response;
			this.lentTestList = data;
		});

		this.generic.ct_Countries().subscribe((response: any) => {
			const { ok, data, msg } = response;
			this.countriesList = data;
		});

		this.job.jobprofile_all().subscribe((response: any) => {
			const { ok, data, msg } = response;
			this.jobProfileList = data;
		});

		this.loadDataGroups();

		this.conectorModule.submodule_get(1, 0).subscribe((response: any) => {
			const { data, msg } = response;
			this.evaluations = data.filter((f) => f.id != 2);
		});
	}

	NumSeleccionados(row?: Submodule, evento?: any) {
		row.selected = evento.checked;
	}

	submit(formdata) {
		if (formdata.group == null) {
			formdata.group = 0;
		}

		this.conector.collaborator_post_multiple(formdata).subscribe((d: any) => {
			const { ok, msg, data } = d;
			if (ok == true) {
				Swal.fire({
					title: 'Alta múltiple generada',
					icon: 'success',
					text: data,
					showCancelButton: false,
					confirmButtonText: ' Aceptar ',
				}).then((result) => this.dialog.closeAll());
			} else {
				Swal.fire('Error', msg, 'error');
			}
		});
	}

	saveGroup(action) {
		//this.manyCollaboratorForm.get('group').setValue(action.id);
		this.manyCollaboratorForm.value.group = action.id;

		// this.jsonconector.reporte_category_description(this.manyCollaboratorForm.value).subscribe((opts: ResponseHandler) => {
		//   console.info(opts.data);
		//   this.groupInfo = opts.data;
		// });
	}
}

export interface Submodule {
  id: number;
  name: string;
  selected: boolean;
}
