import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { Component, Input, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class AppBreadcrumbComponent implements OnInit {

  @Input() layout;

  pageInfo;

  items;

  isEnabled = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private socket: SharedCommunicationService,
    private titleService: Title
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.titleService.setTitle(event['title']);
        this.pageInfo = event;
      });
  }

  ngOnInit() {

    // Suscribe al objeto y si hay cambios los guarda en localstorage
    this.socket.menubar.subscribe( (d: any) => {

      const todo = [];

      d.forEach(e => {

        if ( e.node === 'Integriline' ) {

          const route_name = localStorage.getItem('mainLayout');

          const node_items = e.items.find( x => x.node.toLowerCase() === route_name );

          const model = {
            node : e.node,
            items : node_items.items
          };

          todo.push(model);

        } else {

          todo.push(e);

        }

      });

      this.items = todo;

    });

    this.socket.enableNav.subscribe( (d: any) => {
      localStorage.setItem('enableNav', 'true');
      this.isEnabled = d;
    });

    // Valida si hay valores en memoria para pintarlos en caso de refresh
    if ( localStorage.getItem('enableNav') === 'true' ) {
      this.isEnabled = true;
    }

    if ( localStorage.getItem('validItems') !== null ) {

      let mi = localStorage.getItem('validItems');

      mi = JSON.parse(mi);

      this.items = mi;

    }

  }

  openRoute(route) {
    this.socket.openRoute.next(route);
  }

  ngAfterViewInit(): void {

  }

}
