import { environment } from "./../../../../environments/environment";
import { Pagination } from "./../../../models/pagination";
import { AuthenticationService } from "./../../../services/authentication.service";
import { ConectorJsonService } from "./../../../services/conector-json.service";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { ConectorModuleService } from "../../../services/conector-module.service";

@Component({
  selector: "app-relations",
  templateUrl: "./relations.component.html",
  styleUrls: ["./relations.component.css"],
})
export class RelationsComponent implements OnInit {
  @ViewChild("firstField", null) firstField: ElementRef;

  pagination = new Pagination();
  isEditable = false;
  relationsForm: FormGroup;
  relations = [];
  length = 0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 15];
  selected = null;

  akey = environment.tiny;
  permissionCreateRol: boolean = true;
  permissionUpdateRol: boolean = true;
  permissionDeleteRol: boolean = true;

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private service: AuthenticationService,
    private conector: ConectorJsonService,
    private route: Router,
    private conectorModuleService: ConectorModuleService,
    public dialog: MatDialog
  ) {
    const idModuleLibrary = this.getIdModuleLibrary();
    this.getFunctionalityPermissions(idModuleLibrary);
    this.pagination.length = 10;
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;

    this.relationsForm = this.fb.group({
      relation: [null, Validators.required],
      tycos: null,
      description: [null],
      active: [true],
      enableTycos: [false],
    });

    this.service.setLanguage().then((k) => this.translate.setDefaultLang(k));
  }

  async ngOnInit() {
    this.conector
      .relations_get(0, this.pagination.pageSize)
      .subscribe((res: any) => {
        const { data, msg } = res;
        this.length = msg;
        this.relations = data;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  updateRows($events) {
    const { pageIndex, pageSize } = $events;

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.relations = [];

    this.conector
      .relations_get(this.pageIndex, this.pageSize)
      .subscribe((x: any) => {
        this.relations = x.data;
        this.pagination.length = x.msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  onClick(formdata) {
    formdata.active = true;

    if (this.isEditable === false) {
      this.conector
        .relations_post(formdata)
        .subscribe(async ({ ok, msg }: any) => {
          if (ok === true) {
            this.conector.relations_get().subscribe((x: any) => {
              this.relations = x.data;
              this.pagination.length = x.msg;
              this.pagination.pageSize = 10;
              this.pagination.limit = 10;
            });

            this.relationsForm.reset();

            const msg = await this.translate.get("success").toPromise();

            Swal.fire({
              position: "center",
              icon: "success",
              title: msg,
              showConfirmButton: false,
              timer: 1000,
            });
          } else {
            Swal.fire("Error", msg, "error");
          }
        });
    } else {
      Object.keys(formdata).forEach((key) => {
        this.selected[key] = formdata[key];
      });

      this.conector.relations_put(this.selected).subscribe(async () => {
        this.relationsForm.reset();
        this.isEditable = false;

        const msg = await this.translate.get("success").toPromise();
        Swal.fire({
          position: "center",
          icon: "success",
          title: msg,
          showConfirmButton: false,
          timer: 1000,
        });
      });
    }
  }

  editRow(row) {
    this.permissionCreateRol = true;
    this.isEditable = true;
    this.selected = row;
    Object.keys(row).forEach((key) => {
      if (this.relationsForm.get(key)) {
        this.relationsForm.controls[key].setValue(row[key]);
      }
    });

    this.firstField.nativeElement.focus();
  }

  deleteRow(row) {
    this.conector.relations_delete(row.id).subscribe(() => {
      this.conector
        .relations_get(this.pageIndex, this.pageSize)
        .subscribe((x: any) => {
          this.relations = x.data;
          this.pagination.length = x.msg;
          this.pagination.pageSize = 10;
          this.pagination.limit = 10;
        });
    });
  }
  getIdModuleLibrary() {
    const currentRoute = this.route.url;
    const routesString = localStorage.getItem("menu-sidebar");
    const routesArray = JSON.parse(routesString);
    const routeMatch = routesArray.find(
      (route) => route.route === currentRoute
    );
    return routeMatch.id;
  }
  getFunctionalityPermissions(idModuleLibrary: number) {
    this.conectorModuleService
      .getFunctionalityPermissionByModuleAndUser(idModuleLibrary)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          const result = JSON.parse(data);
          result.forEach((item) => {
            if (item.code === "createRol") {
              this.permissionCreateRol = item.havePermission;
            } else if (item.code === "updateRol") {
              this.permissionUpdateRol = item.havePermission;
            } else if (item.code === "deleteRol") {
              this.permissionDeleteRol = item.havePermission;
            }
          });
        } else {
          console.log("Error get functionality permissions");
        }
      });
  }
}
