import { AdminItems } from './admin-items';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

/** @title Responsive sidenav */
@Component({
  selector: 'app-admin-layout',
  templateUrl: 'admin.component.html',
  styleUrls: []
})

export class AdminComponent implements OnDestroy {

  mobileQuery: MediaQueryList;
  dir = 'ltr';

  green: boolean;
  blue: boolean;

  dark: boolean;
  minisidebar: boolean;

  boxed: boolean;
  danger: boolean;

  showHide: boolean;
  url: string;

  sidebarOpened;
  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    public menuItems: AdminItems,
    media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  // Mini sidebar
}
