import { ConectorJsonService } from './../../../../services/conector-json.service';
import { Component, OnInit, Inject } from '@angular/core';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-group-questions',
  templateUrl: './group-questions.component.html',
  styleUrls: ['./group-questions.component.css']
})
export class GroupQuestionsComponent implements OnInit {

  category = null;
  questions = [];
  records = [];
  option = 0;
  question = {};

  constructor(private conector: ConectorJsonService, @Inject(MAT_DIALOG_DATA) public data) {

    if ( data.row == null) {

    } else {
      localStorage.setItem('questions', JSON.stringify(data.row));
      this.category = data.row;
    }

  }
  ngOnInit() {

    this.category = JSON.parse(localStorage.getItem('questions'));

    this.conector.questions_get().subscribe( (d: any) => {
      const {ok, msg, data} = d;
      this.questions = data;
    });

    this.reset();

  }

  select(row) {
    this.option = row;
  }

  onClick() {

    const model = {
      group: this.category.id,
      question: this.option
    };

    this.conector.group_questions_post(model).subscribe( (d: any) => {
      const { ok, msg, data} = d;

      if (ok === false) {
        Swal.fire('Error', msg, 'error');
      } else {
        this.option = null;
        this.option = 0;
        this.reset();
      }

    });

  }

  reset() {
    this.conector.groups_questions_get(this.category.id).subscribe( (d: any) => {
      const {ok, msg, data} = d;
      this.records = data;
    });
  }

  delRow(row) {

    Swal.fire({
      title: 'Confirmar Eliminación',
      text: 'Esta acción es irreversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceder',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.conector.group_questions_del(row.id).subscribe( (d: any) => {
          console.info('Del&', d);
          this.reset();
        });
      }
    });
    console.info('op&', row);
  }

}
