import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ConnectorExportDataService {
    constructor(private http: HttpClient) { }

    exportDataGet(formdata) {
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json'
      });
      let varformdata = JSON.stringify(formdata);
      return this.http.post(`${environment.server}/ExportData`, varformdata, {headers}) ;
    }

    getGroupbyIdDad(id, idSubModule) {
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/getGroupbyIdDad/${id}/${idSubModule}`,  {headers}) ;
    }

    getSubModule(data) {
      return this.http.post(`${environment.server}/submodules`, data);
    } 
    
    ethicsSurveyGet(subModule = 0) {
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/getProyect?subModule=${subModule}`, { headers });
    }
}
