import { Identifiers } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ConnectorEthicsSurveyService } from '../../../../services/ethics-survey/conector-ethics-survey.service';

export interface PeriodicElement {
  nickname: string;
  name: string;
  email: string;
  namegroup: string;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
//   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
//   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
//   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
//   {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
//   {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
//   {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
//   {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
//   {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
//   {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
// ];

@Component({
  selector: 'app-send-email-unanwers',
  templateUrl: './send-email-unanwers.component.html',
  styleUrls: ['./send-email-unanwers.component.css']
})
export class SendEmailUnanwersComponent implements OnInit {

  displayedColumns: string[] = ['nickname', 'name', 'email', 'namegroup'];
  dataSourceAnswers = [];
  dataSourceUnAnswers = [];
  id = this.router.snapshot.paramMap.get('id');

  
  constructor(
    private conector: ConnectorEthicsSurveyService,
    private router: ActivatedRoute,

  ) { }

  ngOnInit() {
    this.GetUsers(this.id);
  }

  sendEmail() {
    this.conector.GetEvaluatedAll(parseInt(this.id),1).subscribe( (response: any) => {
      const {data, msg, ok} = response;
      if (ok == true) {
        return Swal.fire('Buen trabajo !', msg, 'success') 
        
      } else {
        return Swal.fire('Error', msg, 'error')

      }
    });

  }
  GetUsers(id) {

    this.conector.GetEvaluatedAll(id, 0).subscribe( (response: any) => {
      const {data, msg} = response;

      let Answers: PeriodicElement[] = [];
      let UnAnswers: PeriodicElement[] = [];

      data.evaluated.map(function(item) {
        
        let data =  {
          nickname : item.userProfile.nickname ,
          name : item.userProfile.name + " " + item.userProfile.firstname + " " + item.userProfile.lastname ,
          email : item.userProfile.email ,
          namegroup : item.group.name ,
        };

        if (item.answer == null ) {
          UnAnswers.push(data);
        } else {
          Answers.push(data);
        }
      
      });

      this.dataSourceAnswers = Answers;
      this.dataSourceUnAnswers = UnAnswers;
    });
  }
}