import { ConectorJsonService } from './../../services/conector-json.service';
import { environment } from './../../../environments/environment';
import { SharedCommunicationService } from './../../services/shared-communication.service';
import { ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { SupervisorItems } from './supervisor-items';
import { Router } from '@angular/router';

/** @title Responsive sidenav */
@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.css']
})

export class SupervisorComponent implements OnDestroy {

  mobileQuery: MediaQueryList;
  dir = 'ltr';

  green: boolean;
  blue: boolean;

  dark: boolean;
  minisidebar: boolean;

  boxed: boolean;
  danger: boolean;

  showHide: boolean;
  url: string;

  sidebarOpened;
  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  menuitems = [];
  moduleName: any = [];
  makeHttp = false;
  userIcon =  environment.public;

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private shared: SharedCommunicationService,
    private conector: ConectorJsonService,
    private main: SupervisorItems) {

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.shared.httpCall.subscribe( k => this.makeHttp = k);

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getMessage() { }

  ngOnInit() {

    this.conector.user_permission().subscribe( (x: any) => {

      const { data, msg } = x;

      this.userIcon = `${this.userIcon}/logos/${msg}`;

      const permissions =  JSON.parse(data.application);

      const currentModule = permissions.find( (cm: any) => cm.node === 'Integriline');

      const { items } = currentModule.items.find( fx => fx.node === 'Handler');

      this.main.getMenuitem().forEach( mit => {

        console.info(mit.state);

        if ( items.find(me => me.node === mit.state && me.checked === true) ) {
          this.menuitems.push(mit);
        }

      });

      localStorage.setItem('routerValidItems', JSON.stringify(items));

      console.info(this.userIcon);

      console.info( this.main.getMenuitem() );

    });

  }

}
