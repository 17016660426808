import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { MatCheckboxChange } from "@angular/material";
import Swal from "sweetalert2";

@Component({
  selector: "app-eva-instructions-general",
  templateUrl: "./eva-instructions-general.component.html",
  styleUrls: ["./eva-instructions-general.component.css"],
})
export class EvaInstructionsGeneralComponent implements OnInit {
  @Input() data: any = null;
  @Input() openModal: boolean = false;
  @Output() confirmation: EventEmitter<any> = new EventEmitter();

  public webcam: boolean = false;
  public readlen: any = 0;
  public camera_check = false;
  public permissionCamera: boolean = false;
  public localStream: any = null;
  trueIdentity: boolean = false;
  trueIdentityRequired: boolean = false;
  showModalCamera: boolean = false;
  permissionCameraBrowser: boolean = false;
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {}

  startLen(trueIdentity, trueIdentityRequired) {
    this.trueIdentity = trueIdentity;
    this.trueIdentityRequired = trueIdentityRequired;
    this.openModal = true;
    this.camera_check = this.trueIdentityRequired;
    let newEntryEvaluation =
      localStorage.getItem("newEntryEvaluation") === null ? "yes" : "no";
    localStorage.setItem("newEntryEvaluation", newEntryEvaluation);
    this.showModalCamera = newEntryEvaluation === "yes";
  }

  continue() {
    if (this.trueIdentityRequired) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(() => {
            localStorage.setItem("newEntryEvaluation", "no");
            if (
              this.data.instructions !== null &&
              this.data.instructions !== ""
            ) {
              const timer = moment
                .duration(moment().diff(this.readlen))
                .asSeconds();
              const words = this.data.instructions.match(/(\w+)/g).length;
              const factor = (words / timer) * 1000;
              this.confirmation.next({
                webcam: this.webcam,
                timer,
                words,
                factor,
                allow: this.camera_check,
              });
            } else {
              this.confirmation.next({
                webcam: this.webcam,
                timer: 0,
                words: 0,
                factor: 1,
                allow: this.camera_check,
              });
            }

            this.webcam = false;
            this.readlen = null;
            this.openModal = false;
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: "Requiere otorgar permisos al navegador para acceder a su cámara.",
            });
          });
      }
    } else {
      localStorage.setItem("newEntryEvaluation", "no");
      if (this.data.instructions !== null && this.data.instructions !== "") {
        const timer = moment.duration(moment().diff(this.readlen)).asSeconds();
        const words = this.data.instructions.match(/(\w+)/g).length;
        const factor = (words / timer) * 1000;
        this.confirmation.next({
          webcam: this.webcam,
          timer,
          words,
          factor,
          allow: this.camera_check,
        });
      } else {
        this.confirmation.next({
          webcam: this.webcam,
          timer: 0,
          words: 0,
          factor: 1,
          allow: this.camera_check,
        });
      }

      this.webcam = false;
      this.readlen = null;
      this.openModal = false;
    }
  }

  enableWebCam($event: MatCheckboxChange) {
    if ($event.checked == true) {
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        navigator.mediaDevices.getUserMedia({ video: true }).then(
          async (stream: any) => {
            this.localStream = stream;

            this.permissionCamera = true;
          },
          () => {
            this.permissionCamera = false;
          }
        );
      } else {
        alert(
          "Your browser do not support webcam, please use firefox or chrome"
        );
      }
    } else {
      this.localStream.getVideoTracks()[0].stop();
    }
  }
}
