import { SharedCommunicationService } from './../../../../services/shared-communication.service';
import Swal from 'sweetalert2';
import { ResponseKnow } from './../../../../interfaces/ResponseHandler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConectorKnowService } from '../../../../services/conector-know.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cc-crear',
  templateUrl: './cc-crear.component.html',
  styleUrls: ['./cc-crear.component.css']
})
export class CcCrearComponent implements OnInit {

  @Input('update') update: boolean = false;

  @Input('fieldParams') selecetdRow = { id: 0 };

  @Output() close: EventEmitter<any> = new EventEmitter();

  cuestionario: FormGroup;

  idEditable = 0;

  areas = [];

  startAt = new Date();

  endAt = new Date();

  constructor(private know: ConectorKnowService, private fb: FormBuilder, private widgets: SharedCommunicationService ) {

    this.cuestionario = fb.group({
      area_id: [null, Validators.required],
      title: [null, Validators.required],
      select_media: 1,
      image: null,
      video_link: null,
      start_date: [ new Date(), Validators.required],
      end_date: [null, Validators.required],
      everyday: [0, Validators.required],
      enable_message: [0, Validators.required],
      message: null,
      push_notifications: [0, Validators.required],
      active: [0, Validators.required],
      daily_notifications: 0,
      push_text: [null, Validators.required],
      feedback_info: [0, Validators.required]
    });

  }

  async ngOnInit() {

    const { areas } = await this.know.surveys_list().toPromise();

    this.areas = areas;

    if( this.update == true) {

      this.know.survey_single(this.selecetdRow.id).subscribe( (e: ResponseKnow) => {

        this.idEditable = e.survey.id;

        Object.keys(this.cuestionario.value).forEach( item => {
          this.cuestionario.get(item).setValue( e.survey[item]);
        });

        this.cuestionario.get('select_media').setValue( e.survey['select-media']);

        const init = moment(e.survey.start_date);
        const ends = moment(e.survey.end_date);

        this.cuestionario.get("start_date").setValue( new Date( init.get('year'), init.get('month'), init.get('day'), init.get('hours'), init.get('minutes'), init.get('seconds') ));
        this.cuestionario.get("end_date").setValue( new Date( ends.get('year'), ends.get('month'), ends.get('day') ));

      });

    }

  }

  sendForm(formdata) {

    formdata.start_date = moment(formdata.start_date).format("YYYY-MM-DD HH:mm:ss a").toString();

    formdata.end_date = moment(formdata.end_date).format("YYYY-MM-DD").toString();

    const model = new FormData();

    model.set('company_id', localStorage.getItem('know_session'));

    Object.keys(this.cuestionario.value).forEach( item => {
      model.append(item, formdata[item]);
    });

    if(formdata.image) {
      model.append('image', formdata.files[0]);
    }

    if( this.update == true) {

      model.append('_method', 'PATCH');

      model.append('id', this.idEditable.toString() );

      this.know.surveys_put(model, this.idEditable).subscribe( (k: ResponseKnow) => {

        const message = "<code>"+JSON.stringify(k.message)+"</code>";

        if( k.success === true) {
          return Swal.fire({ icon: 'success', title: 'Error', html: message });
        }

        Swal.fire({ icon: 'success', title: 'Completado', html: message });

      });

    }

    if( this.update == false) {

      this.know.surveys_post(model).subscribe( (k: ResponseKnow) => {

        const title = (k.success == true) ? 'Completado': 'Error';

        const state = (k.success) ? 'success' : 'error';

        const message = "<code>"+JSON.stringify(k.message)+"</code>";

        Swal.fire({
          icon: state,
          title: title,
          html: message
        }).then( () => {
          if(k.success == true) {
            this.close.emit(true);
          }
        });

      });

    }

  }

}
