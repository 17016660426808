export const environment = {

  production: true,
  base: 'https://api.amitaisystem.com',
  server: 'https://api.amitaisystem.com/api/v1',

  public: 'https://api.amitaisystem.com/public',
  private: 'https://api.amitaisystem.com/private',
  tiny: '5iboyhuj1h86j1nz1avwhfiknu3qs31rwditmn4x2bnd4nb8',

  know_server: 'http://apicmsknowapp.cinnco.co/api',
  know_email: 'rduran@amitai.com',
  know_password: 'Amitai2020',

  hiringroom_server: 'http://api.hiringroom.com/v0',
  hiringroom_name: 'amitaistaging',
  hiringroom_redirect_uri: 'https://api.amitaisystem.com/oauth/hiringroom',
  hiringroom_client_id: 'amitai_stg',
  hiringroom_client_secret: 'Ry1S0mzQfsVicfq8algCIdkjlmqszPZshTUAeCKPEGg'

};