import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ConnectorEthicsSurveyService } from '../../../../services/ethics-survey/conector-ethics-survey.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';
import { answerQuestionsSurveyConfig, answerUsers } from '../../ViewModel/config-model/config-model.component';
import { EvaluatedAnswerComponent } from '../evaluated-answer.component';
import {NavigationStart, Router, ActivatedRoute, Params} from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { animate, style, transition, trigger } from '@angular/animations';
import { ethics_survey_type_dialog } from './ethics-survey-type-dialog.component';

@Component({
  selector: 'app-ethics-survey-type-two',
  templateUrl: './ethics-survey-type-two.component.html',
  styleUrls: ['./ethics-survey-type-two.component.css'],
  animations: [
    trigger('slideDownUp', [
      transition(':enter', [style({ height: 0 }), animate(500)]),
      transition(':leave', [animate(500, style({ height: 0 }))]),
    ]),
  ],
})
export class EthicsSurveyTypeTwoComponent implements OnInit {
  [x: string]: any;
  isShownEngagement: boolean = true ; // var control view div hiden
  numberItem: number = 1 ; // var control view div hiden
  tableShow: boolean = false ; // var control view div hiden
  questionsForm : FormGroup;
  answersForm : FormGroup;

  numberQuestions: number = 0 ;

  pageOfItems: Array<any>;

  itemsQuestions= [];
  itemsQuestionsControl = [];

  countSrs1 : number = 0 ;
  statusSrs1 : boolean = true ;


  // ================================

  // topicDescription = item.TPCS.description,
  // topicName = item.TPCS.name,
  // SrsDescription = item.SRS.description,
  // SrsName = item.SRS.name,

  columnas: string[] = ['id', 'idquestion', 'idFactors', 'name', 'answers', 'topicDescription', 'topicName', 'SrsDescription', 'SrsName'  ];
  datos: QuestionsArray[] = [];
  columnsToDisplay: string[] = this.columnas.slice();

  dataSource = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  itemsAnswers = [];
  // ================================
  

  constructor( 
    private rutaActiva: ActivatedRoute, 
    private conector: ConnectorEthicsSurveyService, 
    private _formBuilder: FormBuilder,
    private router:Router,
    public dialog: MatDialog) {
    this.questionsForm = this._formBuilder.group({
      questions: this._formBuilder.array(
        [this._formBuilder.group({
          id : [''], 
          question : [''],
          type : [''], 
          answerQuestions : ['',Validators.required]})]),
    });
    this.answersForm = this._formBuilder.group({
      answersData: this._formBuilder.array(
        [this._formBuilder.group({
          id : [''], 
          idquestion : [''],
          name : [''], 
          active : [''], 
          orderlist : [''], 
          valueAnwers : ['',Validators.required],
          valueAnwersIn : ['']
        })]),
    });
  }

  ngOnInit() {

    this.showDialog(
      'Riesgo psicosocial' , 
      `Por favor, dedique un momento para completar esta pequeña encuesta de riesgos psicosocial. Sus respuestas serán tratadas de forma confidencial, con el único fin de identificar a trabajadores que presentaron acontecimientos traumáticos severos.`
    );
    this. get_ethics_suvey();
  }

  
  get_ethics_suvey() {
    var idSurvey = this.rutaActiva.snapshot.params.idSurvey  ;
    var idUser = this.rutaActiva.snapshot.params.idUser  ;
    this.conector.evaluatedAnswerSurveyTypeTwoGet(idSurvey,idUser).subscribe( (response: any) => {
      const {ok, data, msg} = response;
      
        let q = 0;
        let a = 0;
        for (var key in response.data) {
            q ++;
            var QuestionKey = response.data[key];

            let itemsAnswersControlFor = [];

            for (var item in QuestionKey.anwers) {

              var AnwersKey = QuestionKey.anwers[item];
              a++;
              //this.itemsAnswersControl.push({ id : a, idAnwers :  AnwersKey.id , idquestion :  AnwersKey.idquestion , name :  AnwersKey.name , active :  AnwersKey.active , orderlist : AnwersKey.orderlist , valueAnwersIn : AnwersKey.value, valueAnwers : ''});
              itemsAnswersControlFor.push(
              { id : a,
                idAnwers :  AnwersKey.id , 
                idquestion :  AnwersKey.idquestion , 
                name :  AnwersKey.name , 
                active :  AnwersKey.active , 
                orderlist : AnwersKey.orderlist , 
                valueAnwersIn : AnwersKey.value, 
                valueAnwers : ''
              });
              this.itemsAnswers.push(
                { id : a,
                  idAnwers :  AnwersKey.id , 
                  idquestion :  AnwersKey.idquestion , 
                  name :  AnwersKey.name , 
                  active :  AnwersKey.active , 
                  orderlist : AnwersKey.orderlist , 
                  valueAnwersIn : AnwersKey.value, 
                  valueAnwers : ''
                });
              

              //controlanswersForm.push(this._formBuilder.group({id : [ AnwersKey.id ], idquestion : [ AnwersKey.idquestion ], name : [ AnwersKey.name ], active : [ AnwersKey.active ], orderlist : [AnwersKey.orderlist ], valueAnwersIn : [AnwersKey.value ], valueAnwers : ['']}));
            }
            this.itemsQuestionsControl.push(
            { id : q, 
              idquestion : QuestionKey.idquestion, 
              idFactors  : QuestionKey.idFactors , 
              name :QuestionKey.name, 
              answers : "A", 
              itemsAnswersControl : itemsAnswersControlFor 
            });

            this.datos.push(new QuestionsArray(q, QuestionKey.idquestion, QuestionKey.idFactors,QuestionKey.name, '.', QuestionKey.topicDescription, QuestionKey.topicName, QuestionKey.srsDescription, QuestionKey.srsName   ));
            //controlquestionsForm.push(this._formBuilder.group({id : [ QuestionKey.idquestion ], type : [ QuestionKey.idFactors ], question : [ QuestionKey.name ], answerQuestions : ['']}));
        }

       

        this.dataSource = new MatTableDataSource<QuestionsArray>(this.datos); // llena con datos paso anterior 

         this.dataSource.filterPredicate =
        (data: QuestionsArray, filter: string) => !filter || (data.SrsName.indexOf(filter) != -1) ; // prepara filtro para la tabla 

 
        this.dataSource.paginator = this.paginator;
       
        // ============= hide columns
       
       
        
        this.columnsToDisplay.length = 2 ;
        this.columnsToDisplay.pop();

        

        this.itemsQuestions = this.itemsQuestionsControl;
        this.numberQuestions = this.itemsQuestions.length; 
        this.isShownEngagement = false;
        this.tableShow = true;

    });

  }

  // get getQuestionsArrayControls(){
  //   let questions   =  this.questionsForm.get('questions') as FormArray;
  //   return questions;
  // }

  // getQuestionsControls(Index : string, Type : string ){
  //   let Question = "";

  //   if(Index != "0"){
  //     let array   =  this.questionsForm.get('questions') as FormArray;
  //     let item = array.value[Index];

  //     if(item.question != "" && Type == "1"){
  //       Question = item.question;
  //     }else if(item.question != "" && Type == "2"){
  //       Question = item.id;
  //     }
  // }
  //   return Question;
  // }

  // get getAnwersArrayControls(){
  //   let Anwers_   =  this.answersForm.get('answersData') as FormArray;
  //   return Anwers_;
  // }

  // getAnwersControls(Index : string , Type : string){
  //   let Anwers = "";

  //   if(Index != "0"){
  //     let array   =  this.answersForm.get('answersData') as FormArray;
  //     let item = array.value[Index];

  //     if(item.idquestion != "" && Type == "1" ){
  //       Anwers = item.name;
  //     }
  //     else if(item.idquestion != "" && Type == "2" ){
  //       Anwers = item.idquestion;
  //     }
  //     else if(item.idquestion != "" && Type == "3" ){
  //       Anwers = item.valueAnwersIn;
  //     }
  //   }
  //   return Anwers;
  // }

  submit(){

    const ValidAnwers = this.itemsQuestionsControl.find( item => item.answers === 'A' );
    if(ValidAnwers != undefined ){
      Swal.fire('Error', 'Tiene Preguntas sin respuestas ', 'error')
    }else{
      const answersUsers = new answerUsers();

      let answerQuestionsModel   =  this.questionsForm.get('questions') as FormArray;
      let answerQuestionsArrayModel =  [new answerQuestionsSurveyConfig()];
  
      for (var i = 0; i < this.itemsQuestions.length; i++) {
        let item = this.itemsQuestions[i];
        if(item.id != ""){
          const  answerQuestionsSurvey = new answerQuestionsSurveyConfig();
          answerQuestionsSurvey.id =  item.idquestion;
          answerQuestionsSurvey.answer =  item.answers;
          answerQuestionsSurvey.type =  item.type;
          answerQuestionsArrayModel.push(answerQuestionsSurvey);
        }
      }
      answerQuestionsArrayModel.splice(0,1);
      answersUsers.answer = answerQuestionsArrayModel;
      answersUsers.typeSurvey = "2";
      var f = new Date();
      answersUsers.dateAnswers =  new Date(f.getTime() - (f.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];

      answersUsers.host = '192.168.1.0'; 
      this.conector.postAnswerUser(answersUsers).subscribe( 
        (response: any) => {
          const {ok, data, msg} = response;
          if (ok === false) {
            var MsgError  = "";
            for(var k in data) {
              MsgError = MsgError +  "Error en " +  data[k].cam + " ( " + data[k].msg + " ) \n";
            }
            return Swal.fire('Error', MsgError, 'error')
          } else {
            return Swal.fire('Buen trabajo!', 'Respuestas Guardadas', 'success')
          }
        },
        (err) => {
          return Swal.fire('Error', err.status + ' ' + err.statusText, 'error')
        }
      );
    
    }
  }

  // onChangePage(pageOfItems: Array<any>) {
  //   // update current page of items
  //   console.log(pageOfItems);
  //   this.pageOfItems = pageOfItems;
  // }

  // getValueAnswers(id:number , answer:string){
  //   id =id-1;
  //   this.itemsQuestions[id].answers = answer;
  // }

  getValueAnswersNew(id:number , answer:string, name:string){

    id =id-1;
    var srs1 = this.datos.filter(datos => datos.SrsName == "Serie 1");
    this.datos[id].answers = answer;

    const found = srs1.find(element => element.answers == '.');
    
    if (found) { // si faltan preguntas por responder de las Srs1 entra 

      if (name == "No ") { // si la respuesta es no se suma 
        this.countSrs1 =  this.countSrs1 + 1 ;
      } 
      this.paginator.nextPage();

    } else { // si todas las preguntas estan con respuesta entra 
      if(this.countSrs1 == 5 && this.statusSrs1 == true ){
        if (name == "No ") { // si el total de las respuesta negativas es 5 valida la 6 si es no entra 
        
          alert( this.countSrs1);
          this.statusSrs1 = false;

          this.dataSource.filter = "   ";

//           20190930-02
// 20190930-03
// 5755239
// INT1001



          this.showDialog(
            'Riesgo psicosocial' , 
            `Por favor, dedique un momento para completar esta pequeña encuesta de riesgos psicosocial. Sus respuestas serán tratadas de forma confidencial, con el único fin de identificar a trabajadores que presentaron acontecimientos traumáticos severos.`
          );

          this.paginator.firstPage();

          console.log('this.dataSource');

          console.log(this.dataSource);

          this.numberQuestions = this.dataSource.filteredData.length; 
        } 
      }else {
        this.paginator.nextPage();
      }
      
    }
  }


  showDialog(header_ , body_   ){
    const dialogRef = this.dialog.open(ethics_survey_type_dialog, {
      width: '500px',
      data: {
        header: header_,
        body: body_,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == '1') {
      }
    });
  }
  // selectedCheck(answers:string, value :string){
  //     const question = this.itemsQuestionsControl.find( item => item.id === answers );
  //     if(question.answers == "A" ){
  //       return false;
  //     } else {
  //       if(question.answers == value){
  //         return true;
  //       }else{
  //         return false;
  //       }
  //     }
  // }

  selectedCheckNew(answers, value){
    const question = this.datos.find( item => item.id === answers );
    if(question.answers == "." ){
      return false;
    } else {
      if(question.answers == value){
        return true;
      }else{
        return false;
      }
    }
}

  filterItemsAnswers(idquestion){

    let resultAnswers = [];
    resultAnswers = this.itemsAnswers.filter(x => x.idquestion == idquestion);
    
    return resultAnswers

  }
  
  // isDisabled(){
  //   const resultado = this.itemsQuestionsControl.find( item => item.answers === 'A' );
  //   if(resultado == 'A' || resultado == undefined ){
  //     return false;
  //   }else{
  //     return true;
  //   }
  // }

  isDisabledNew(){

    if (this.datos.length > 0) {
    

      const resultado = this.datos.find( item => item.answers === '.' );
      if(resultado.answers == '.' || resultado == undefined ){
        return false;
      }else{
        return true;
      }  
    }
    
  }
  

  

}

export class QuestionsArray {
  constructor(
    public id: number, 
    public idquestion: string, 
    public idFactors: string, 
    public name: string, 
    public answers: string,
    public topicDescription: string,
    public topicName: string,
    public SrsDescription: string,
    public SrsName: string,
    ) 
    {
  }
}


