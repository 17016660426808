import { HomeSupervisorComponent } from './../../views/integriline/home-supervisor/home-supervisor.component';
import { SupervisorComponent } from './supervisor.component';
import { Routes } from '@angular/router';

export const routes: Routes = [
{
  path: 'supervisor',
  component: SupervisorComponent,
  children: [
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    {
      path: 'home',
      component: HomeSupervisorComponent
    }
  ]
}];
