import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';

export interface DialogData {
  link: string
}

@Component({
  selector: 'app-link-jobprofile',
  templateUrl: './link-jobprofile.component.html',
  styleUrls: ['./link-jobprofile.component.css']
})
export class LinkJobprofileComponent implements OnInit {

  elementType = 'url';
  constructor(
    public dialogRef: MatDialogRef<LinkJobprofileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private clipboardService: ClipboardService
  ) { }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close(); 
  }
  copyContent() {
    this.clipboardService.copy(this.data.link)
  }
  
}
