import { AuthenticationService } from '../../../services/authentication.service';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2';
import { MatStepper } from '@angular/material';
import {Md5} from 'md5-typescript';
import { TranslateService } from '@ngx-translate/core';
import { UserIdleService } from 'angular-user-idle';
import * as jwt from 'jwt-decode';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public authForm: FormGroup;

  company: any = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private conector: ConectorJsonService,
    private translate: TranslateService,
    private service: AuthenticationService,
    private userIdle: UserIdleService
  ) {
    this.service.setLanguage().then( k => this.translate.setDefaultLang(k));
  }

  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @ViewChild('boxModule', {static: true}) boxModule: ElementRef;

  isLogged = false;
  isLoading = false;
  require2FA = false;
  isCompleted = false;
  kindOF = 'password';
  contador = 0;
  isFired = false;

  ngOnInit() {

    this.authForm = this.fb.group({
      reporting: [null, Validators.required],
      username: [null, Validators.required],
      password: [null, Validators.required],
      verification: [null]
    });

    this.userIdle.startWatching();

    this.userIdle.onTimerStart().subscribe( async (count: any) => {

      if (count && this.isFired === false) {

        let timerInterval;

        Swal.fire({
          title: await this.translate.get('session-alert').toPromise(),
          html: await this.translate.get('session-alert-msg').toPromise(),
          icon: 'warning',
          timer: 10000,
          showConfirmButton: true,
          confirmButtonText: await this.translate.get('refresh').toPromise(),
          onBeforeOpen: () => {
            timerInterval = setInterval(() => {
              Swal.getContent().querySelector('strong').textContent = moment(Swal.getTimerLeft()).format('s').toString();
            }, 1000);
          },
          onClose: () => {
            clearInterval(timerInterval);
          }
        }).then( (result: any) => {
          if (result.value) {
            console.info('session reset');
            this.isFired = false;
            this.userIdle.resetTimer();
          }
        });

        this.isFired = true;

      }

    });

    this.userIdle.onTimeout().subscribe(() => {

      const token = localStorage.getItem('token');

      const decodes = jwt(token);

      this.conector.log_out(decodes.unique_name).subscribe( () => {

        localStorage.clear();

        this.router.navigateByUrl('selection');

      });

    });

  }

  ngAfterViewInit(): void {
    /*
      particleground(document.getElementById('boxModule'), {
        dotColor: '#a4a9b6',
        lineColor: '#c1c4cd'
      });
    */
  }

  toogleKind() {
    this.kindOF = ( this.kindOF == 'password') ? 'text' : 'password';
  }

  verificarCompania(formdata) {

    this.conector.validarAcceso(formdata).subscribe( (data: any) => {

      this.isLoading = false;

      const { ok, msg} = data;

      if (ok === false) {

        return Swal.fire('Error', msg, 'error');

      } else {

        this.company = data;

        this.company.reporting = formdata.reporting;

        this.isLogged = true;

        this.stepper.next();

      }

    });

  }

  onSubmit(formdata) {

    const model = {
      username: formdata.username,
      password: Md5.init(formdata.password),
      reporting: this.company.reporting
    };

    this.conector.validarAcceso(model).subscribe( (res: any) => {

      const { ok, data, msg } = res;

      if ( ok === true ) {

        const decodes = jwt(data);

        console.info('token', decodes);

        localStorage.setItem('token', data);

        switch ( parseInt(decodes.role) ) {

          // Super admin
          case 1:
          case 2: {
            localStorage.setItem('roleName', 'Super Administrator');
            this.router.navigateByUrl('/admin');
            break;
          }

          // Manager & handler
          case 3:
          case 4:
          case 5:{
            localStorage.setItem('roleName', 'Account manager');
            this.router.navigateByUrl('/manager');
            break;
          }
          /*
          // handler
          case 4: {
            localStorage.setItem('roleName', 'Handler');
            this.router.navigateByUrl('/handler');
            break;
          }

          // supervisor
          case 5: {
            localStorage.setItem('roleName', 'Supervisor');
            this.router.navigateByUrl('/supervisor');
            break;
          } */

          // Ticket
          case 6: {
            localStorage.setItem('roleName', 'Ticket');
            this.router.navigateByUrl('/ticket');
            break;
          }

          // Evaluado
          case 7: {
            localStorage.setItem('roleName', 'Evaluated');
            this.router.navigateByUrl('/evaluated');
            break;
          }

          default: {
            Swal.fire('Integriline', 'User does not have a valid permission', 'error');
            break;
          }
        }

      } else {
        Swal.fire('Error', msg, 'error');
      }

    });

  }

}
