import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import Swal from 'sweetalert2';
import { ConnectorJobProfileService } from '../../../../services/job-profile.service';
import { OwnQuestionsServices } from '../../../../services/own-questions.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
	id: string;
	questions: any;
}

@Component({
	selector: 'app-quiestionsselection',
	templateUrl: './questions-selection.component.html',
	styleUrls: ['./questions-selection.component.css'],
})
export class QuestionsSelectionComponent implements OnInit {
	@Input() questions = [];
	@Input() id: number = 0;
	openModal: boolean = false;
	displayedColumns: string[] = ['select', 'question'];

	constructor(
		public ownQuestionsServices: OwnQuestionsServices,
		private conector: ConnectorJobProfileService,
		@Optional() public dialogRef: MatDialogRef<QuestionsSelectionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) {
		console.log('data', data);
	}
	ngOnInit() {}

	selectQuestion(questionId: number) {
		this.questions = this.questions.map((question) => {
			if (question.id === questionId) {
				return { ...question, selected: !question.selected };
			} else {
				return question;
			}
		});
	}
	save() {
		const idsSelectedQuestions = this.questions.filter((item) => item.selected).map((item) => item.id);
		const information = {
			jobId: this.id,
			questionsIds: idsSelectedQuestions,
		};
		this.ownQuestionsServices.addQuestionsToJob(information).subscribe((response: any) => {
			const { ok, data } = response;
			if (ok) {
				Swal.fire({
					title: 'Actualización correcta.',
					icon: 'success',
					showCancelButton: false,
					confirmButtonText: 'Aceptar',
					reverseButtons: true,
				}).then((result) => {
					this.conector.get_by_company(this.id).subscribe((e: any) => {
						this.questions = e.data;
					});
				});
			} else {
				Swal.fire('Error', 'error');
			}
		});
	}
}
export interface QuestionElement {
	id: number;
	label: string;
	selected: boolean;
}
