import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { of, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConectorModuleService {
  constructor(private http: HttpClient) {}

  submodule_get(idModule, idUsuario) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.get(
      `${environment.server}/submodules?idm=${idModule}&idu=${idUsuario}`,
      { headers }
    );
  }
  getFunctionalityPermissionByModuleAndUser(idModuleLibrary: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.get(
      `${environment.server}/permission/GetFunctionalityPermissionsByModule/${idModuleLibrary}`,
      { headers }
    );
  }
}
