import { ResponseHandler } from './../../../interfaces/ResponseHandler';
import { SharedCommunicationService } from '../../../services/shared-communication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { UserIdleService } from 'angular-user-idle';

import Swal from 'sweetalert2';
import * as jwt from 'jwt-decode';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent implements OnInit {

  showForm = false;
  selectedForm = 1;

  authForm: FormGroup;
  loginForm: FormGroup;

  isLogged = false;
  company: any = {};

  isLoading = false;
  require2FA = false;

  isCompleted = false;
  kindOF = 'password';

  contador = 0;
  isFired = false;

  surveyFrm: FormGroup;
  userIp = null;

  year : number = new Date().getFullYear();
  kindAdmin: boolean = false;
  kindRole:number = 0;
  invalidRoles = [0,7];

  constructor(
    private trans: TranslateService,
    private service: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private shared: SharedCommunicationService,
    private conector: ConectorJsonService,
    private translate: TranslateService,
    private userIdle: UserIdleService) {

    this.service.setLanguage().then(k => this.trans.setDefaultLang(k));

    this.authForm = this.fb.group({
      reporting: [null, Validators.required],
      username: [null, Validators.required],
      password: [null, Validators.required],
      verification: [null]
    });

    this.loginForm = this.fb.group({
      reportingId: [null, Validators.required],
      password: [null, Validators.required]
    });

    this.surveyFrm = this.fb.group({
      company: [null, Validators.required],
      username: [null, Validators.required]
    });

  }

  ngOnInit() {

    this.shared.httpCall.subscribe(k => this.isLoading = k);

    const { data }: any = this.conector.get_user_ip().toPromise();

    this.userIp = data;

    this.shared.setJsonObject('userIp', { userIp: data });

  }

  async askRoutine() {

    Swal.fire({
      icon: 'question',
      title: await this.translate.get('que_realizar').toPromise(),
      confirmButtonText: await this.translate.get('nuevo_reporte').toPromise(),
      showConfirmButton: true,
      cancelButtonText: await this.translate.get('status_reporte').toPromise(),
      showCancelButton: true,
      cancelButtonColor: '#9F5CC0'
    }).then((response: any) => {

      const { value } = response;

      if (value === true) {

        this.askCompany();

      } else {

        this.showForm = true;

        this.selectedForm = 3;

      }

    });

  }

  setForm(event) {
    this.showForm = true;
    this.selectedForm = event;
  }

  async askCompany() {

    this.translate.get('Company ID').subscribe(async (ts) => {

      Swal.fire({
        imageUrl: '/assets/images/suitcase.png',
        imageWidth: 128,
        html: ts,
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        cancelButtonColor: '#9F5CC0',
        showCancelButton: true,
        cancelButtonText: await this.trans.get('x-cancel').toPromise(),
      }).then(async (response: any) => {

        console.info(response);

        const { value } = response;

        if (value) {

          const { ok, data, msg }: any = await this.conector.reporte_validarCompany(value).toPromise();

          if (ok === true) {

            localStorage.setItem('company', value);

            this.router.navigateByUrl('/license');

          } else if (ok === false) {
            Swal.fire('Error', await this.translate.get('checkyoutlogindata').toPromise(), 'error');
          }

          console.info(ok, data, msg);

        }

      });

    });

  }
  startWatch() {

    this.userIdle.startWatching();

    this.userIdle.onTimerStart().subscribe(async (count: any) => {

      if (count && this.isFired === false) {

        let timerInterval;

        Swal.fire({
          title: await this.translate.get('session-alert').toPromise(),
          html: await this.translate.get('session-alert-msg').toPromise(),
          icon: 'warning',
          timer: 10000,
          showConfirmButton: true,
          cancelButtonColor: '#9F5CC0',
          confirmButtonText: await this.translate.get('refresh').toPromise(),
          cancelButtonText: await this.trans.get('x-cancel').toPromise(),
          onBeforeOpen: () => {
            timerInterval = setInterval(() => {
              Swal.getContent().querySelector('strong').textContent = moment(Swal.getTimerLeft()).format('s').toString();
            }, 1000);
          },
          onClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result: any) => {
          if (result.value) {
            console.info('session reset');
            this.isFired = false;
            this.userIdle.resetTimer();
          }
        });

        this.isFired = true;

      }

    });

    this.userIdle.onTimeout().subscribe(() => {

      const token = localStorage.getItem('token');

      const decodes = jwt(token);

      this.conector.log_out(decodes.unique_name).subscribe(() => {

        localStorage.clear();

        this.router.navigateByUrl('selection');

      });

    });

  }

  validateTicket(e) {

    e.IpHostName = this.userIp;

    this.conector.report_auth(e).subscribe((k: any) => {

      const { ok, data } = k;

      if (ok === true && data !== null) {

        localStorage.setItem('token', data);

        this.router.navigateByUrl('/tracking/information');

      } else {
        Swal.fire('Error', 'Ticket or password not found', 'error');
      }

    });

  }

  onSubmit(formdata) {

    localStorage.clear();

    const model = {
      username: formdata.username,
      password: formdata.password,
      reporting: formdata.reporting,
      IpHostName: this.userIp
    };

    this.conector.validarAcceso(model).subscribe((res: any) => {

      const { ok, data, msg } = res;

      if (ok === true) {

        if (data.factor == true) {

          Swal.fire({
            title: 'Ingresar código 2FA',
            html: 'Se ha enviado un codigo de autenticación a su correo.',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: false,
            confirmButtonText: 'Continuar',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result) => {

            if (result.value == res.data.challenge) {

              this.initializeSystem(data);

            }

          });

        } else {

          this.initializeSystem(data);

        }

      } else {
        Swal.fire('Error', msg, 'error');
      }

    });

  }
  initializeSystem(data: any) {

    this.startWatch();

    const decodes = jwt(data.token);

    localStorage.setItem('token', data.token);

    switch (parseInt(decodes.role)) {

      // Super admin
      case 1:
      case 2: {
        localStorage.setItem('roleName', 'Super Administrator');
        this.router.navigateByUrl('/admin');
        break;
      }

      /* Manager, handler, supervisor en orden estricto, se homologan los templates y la navegación se limita a la asignacion desde dbo */

      case 3:
      case 4:
      case 5: {
        localStorage.setItem('roleName', 'Account manager');
        this.router.navigateByUrl('/manager');
        break;
      }

      // Ticket
      case 6: {
        localStorage.setItem('roleName', 'Ticket');
        this.router.navigateByUrl('/ticket');
        break;
      }

      // Evaluado
      case 7: {
        localStorage.setItem('roleName', 'Evaluated');
        this.router.navigateByUrl('/evaluated');
        break;
      }

      default: {
        Swal.fire('Integriline', 'User does not have a valid permission', 'error');
        break;
      }

    }
  }

  toogleKind() {
    this.kindOF = (this.kindOF == 'text') ? 'password' : 'text';
  }

  async requestMail() {

    const ts = await this.translate.get('username').toPromise();
    const cm = await this.translate.get('company_code').toPromise();

    Swal.fire({
      html:
        '<div class="resetForm"><h2>Recuperar contraseña </h2> ' +
        '<p>Para restablecer su contraseña introduzca sus datos y posteriormente vaya a su bandeja de correo ya que el sistema le enviará un correo electrónico para continuar con el proceso.</p>' +
        '<label>' + ts + '</label>' +
        '<input id="swal-input1" class="swal2-input">' +
        '<label>' + cm + '</label>' +
        '<input id="swal-input2" class="swal2-input"></div>',
      cancelButtonColor: '#9F5CC0',
      showCancelButton: true,
      cancelButtonText: await this.trans.get('x-cancel').toPromise(),
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve({
            reporting: $('#swal-input2').val(),
            username: $('#swal-input1').val()
          })
        })
      }
    }).then(async (response: any) => {

      const { value } = response;

      if (value) {

        const { ok, msg }: any = await this.conector.resetPassword(value).toPromise();

        const handler = (ok == true) ? 'success' : 'error';

        const tt = await this.translate.get(handler).toPromise();

        Swal.fire(tt, await this.translate.get(msg).toPromise(), handler);

      }

    });
  }

  validateSurvey(formdata) {

    formdata.IpHostName = this.userIp;
    this.conector.postEvaluation_login(formdata).subscribe((res: ResponseHandler) => {

      console.info('e', res);

      if (res.ok == true) {

        localStorage.setItem('token_evaluaciones', res.data);

        this.router.navigateByUrl('evaluations');

      } else {
        Swal.fire('Error', res.msg, 'error');
      }

    });

  }

  validateKinda(data) {

    data.password = "ND";

    console.info(data);

    if(data.reporting != null && data.username != null) {
      this.conector.get_simpleAuth(data).subscribe( (d:any) => {
        if(d.ok == true) {

          this.kindRole == d.data.roleID;

          this.kindAdmin = this.invalidRoles.indexOf(d.data.roleID) == -1;

          if(d.data.roleID == 7) {
            this.authForm.value.company = this.authForm.value.reporting;
            this.validateSurvey(data);
          }

        }else {
          Swal.fire('Sucedió un error', d.msg, 'error');
        }
      })
    }
  }

  navTo(location: number): void {

    const destinations:string[] = [ null, "/administration", "/complaint", "/evaluation"];

    console.info(location, destinations[location]);

    this.router.navigateByUrl(destinations[location]);

  }

}
