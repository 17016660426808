import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute, Params } from "@angular/router";
import Swal from "sweetalert2";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";
import { tap } from 'rxjs/operators';

@Component({
	selector: 'app-jobprofile-analisisriesgo',
	templateUrl: './jobprofile-analisisriesgo.component.html',
	styleUrls: ['./jobprofile-analisisriesgo.component.css'],
})
export class JobProfileAnalisisRiesgoComponent implements OnInit {
	@Input() IdJobRisk = '';
	@Input() evaluationConfig: any;
	@Input() evaluationConfigMsg: any;
	@Output() nextClassicalMethodAndRiskAnalysis: EventEmitter<any> = new EventEmitter();

	id = 0;
	isLinear = false;
	jobProfileAnalisisRiesgo: FormGroup;
	displayedColumns: string[] = ['seleccionado', 'riesgo'];
	puesto: string;
	descripcion: string;
	idJobProfile: number;
	dataSource: Dimension[];
	panelOpenState = false;
	enabledViewTitleJob = true;
	showBaseNav: boolean = true;
	allRiskAnalysis: any = [];

	constructor(
		private form: FormBuilder,
		private conector: ConnectorJobProfileService,
		private snap: ActivatedRoute
	) {}

	ngOnInit(): void {
		if (this.evaluationConfig == 1 && this.evaluationConfigMsg == 1) {
			this.enabledViewTitleJob = false;
		}
		//
		if (this.IdJobRisk) {
			this.id = parseInt(this.IdJobRisk);
		} else {
			this.snap.paramMap.subscribe(async (p: Params) => {
				this.id = p.params.id;
			});
		}

		this.conector.jobprofileById(this.id).subscribe((response: any) => {
			const { data, msg } = response;

			this.puesto = data.puesto;
			this.descripcion = data.descripcion;
			this.idJobProfile = data.id;

			this.jobProfileAnalisisRiesgo = this.form.group({
				dimentions: this.dataSource,
				idJobProfile: this.idJobProfile,
			});

			this.conector.dimentions_get(this.idJobProfile).subscribe((response: any) => {
				const { data, msg } = response;
				// this.configureJobProfileGroup = data;
				this.dataSource = data as Dimension[];
			});
		});

		this.conector
			.getAllRiskAnalysis()
			.pipe(
				tap((response: any) => {
					this.allRiskAnalysis = response.data;
				})
			)
			.subscribe();
	}

	probabilidadLabel(value: number): string {
		switch (value) {
			case 1:
				return 'Muy baja';
			case 2:
				return 'Baja';
			case 3:
				return 'Media';
			case 4:
				return 'Alta';
			case 5:
				return 'Muy alta';
		}
	}

	impactoLabel(value: number): string {
		switch (value) {
			case 1:
				return 'Muy bajo';
			case 2:
				return 'Bajo';
			case 3:
				return 'Medio';
			case 4:
				return 'Alto';
			case 5:
				return 'Muy alto';
		}
	}

	ProSeleccionado(row?: Dimension, evento?: any) {
		row.probabilidad = evento.value;
	}

	ImpSeleccionado(row?: Dimension, evento?: any) {
		row.impacto = evento.value;
	}

	NumSeleccionados(row?: Dimension, evento?: any) {
		var dimensionesHonestidad = 5;
		var dimensionesIncidencia = 4;

		var maxSelected = row.dimensionH === 1 ? dimensionesHonestidad : dimensionesIncidencia;

		var seleccionados = this.dataSource.filter(
			(dimention) => dimention.dimensionH === row.dimensionH && dimention.seleccionado
		).length;

		if (seleccionados < maxSelected) row.seleccionado = evento.checked;
		else {
			row.seleccionado = false;
			evento.source.checked = false;
		}
	}

	submit(formdata) {
		formdata.dimentions = this.dataSource.filter((f) => f.seleccionado);
		formdata.dimentions = formdata.dimentions.map((dimension) => {
			const { probabilidad, impacto } = dimension;
			const risk = this.allRiskAnalysis.find(
				(risk: any) => risk.probability === probabilidad && risk.impact === impacto
			) || { weight: 0 };

			dimension.peso = risk.weight;
			return dimension;
		});

		this.nextClassicalMethodAndRiskAnalysis.emit(formdata.dimentions);
		this.conector.dimentions_post(formdata).subscribe((d: any) => {
			const { ok, msg, data } = d;
			if (ok === false) {
				Swal.fire('Error', msg, 'error');
			}
			this.showBaseNav = false;
		});
	}
}

export interface Dimension {
  id: number;
  dimension: string;
  orden: number;
  editable: boolean;
  dimensionH: number;
  seleccionado: boolean;
  peso: number;
  linferior: number;
  lsuperior: number;
  probabilidad: number;
  impacto: number;
}