import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ConectorJsonService } from "./../../../services/conector-json.service";
import Swal from "sweetalert2";

@Component({
  selector: "privacy-policy-background",
  templateUrl: "./privacy-policy-background.component.html",
  styleUrls: ["./privacy-policy-background.component.css"],
})
export class PrivacyPolicyBackgroundComponent implements OnInit {
  profileForm: FormGroup;
  tycos = { es: null, en: null };
  isLinear = true;
  idEvaluated = null;
  evaluated: any = {};
  grant: any = 0;
  showConfiguration: boolean = false;
  registerStatus: number = 0;
  company: string = "";
  nameEvaluated: string = "";
  hasEvaluation: boolean = false;
  hasNotExpiredEvaluation: boolean = false;

  constructor(
    private router: Router,
    private snap: ActivatedRoute,
    private services: ConectorJsonService,
    private formBuilder: FormBuilder
  ) {
    this.profileForm = this.formBuilder.group({
      name: [null, Validators.required],
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      curp: [
        null,
        [
          Validators.required,
          Validators.pattern(
            "^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$"
          ),
        ],
      ],
      idEvaluated: [null],
      nss: [null],
    });
  }
  ngOnInit() {
    this.snap.paramMap.subscribe((result: any) => {
      const { params } = result;
      this.idEvaluated = params.id;
      this.services
        .reporte_valida_usuario({ token: result.params.id })
        .subscribe((e: any) => {
          if (e.ok) {
            localStorage.setItem("token_evaluaciones", e.data);
            this.grant = "Correcto, accediendo...";
            this.getEvaluatedBackgroundCheck();
            //console.log("llegamos aqui");
            setTimeout(() => {
              this.showConfiguration = true;
            }, 300);
            //console.log("llegamos aqui2");
          } else {
            Swal.fire("Error", "Código no coindice", "error");
            this.grant = "El código no es valido, validar nuevamente.";
          }
        });
    });
  }

  async getEvaluatedBackgroundCheck() {
    this.services
      .get_evaluated_backgroundcheck(this.idEvaluated)
      .subscribe((r: any) => {
        const { ok, data, msg } = r;
        
        if (ok) {
          this.evaluated = data[0];
          this.registerStatus = this.evaluated.registerStatus;
          this.nameEvaluated = this.evaluated.name;
          this.company = this.evaluated.company;
          this.hasEvaluation =
            this.evaluated.companySubmodule.hasEvaluationAccount;
          this.hasNotExpiredEvaluation =
            this.evaluated.companySubmodule.expiredEvaluationAccount;
          if (
            (!this.hasEvaluation || !this.hasNotExpiredEvaluation) &&
            this.registerStatus == 1
          ) {
            Swal.fire({
              position: "center",
              icon: "warning",
              title:
                "Favor de contactar al administrador que le asignó la evaluación.",
              showConfirmButton: true,
            });
          }
          
          Object.keys(this.profileForm.value).forEach((item) => {
            if (this.evaluated[item]) {
              this.profileForm.get(item).setValue(this.evaluated[item]);
              const nss = this.evaluated["nss"];
              
              if (nss !== "" && nss !== null) {
                this.profileForm.get("nss").setValidators(Validators.required);
              } else {
                this.profileForm.get("nss").clearValidators();
              }
              this.profileForm.get("nss").updateValueAndValidity();
            }
          });
          
        }
      });
  }
  declineGetInformation() {
    Swal.fire({
      title:
        "<span style='font-size: 14px;'>¿Está seguro de NO otorgar su consentimiento? <br/> En caso de confirmar, se enviará un mensaje a la empresa indicando que NO otorgó su consentimiento  y se cerrará la aplicación.</span>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Regresar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Cancelando el acceso a su información",
          text: "Espere un momento...",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        this.services
          .decline_privacy_policy_backgroundcheck(this.profileForm.value)
          .subscribe((response: any) => {
            const { ok, data, msg } = response;
            Swal.close();
            if (ok) {
              Swal.fire(
                "¡Listo!",
                "Se canceló el acceso a su información. Se notificará a la empresa de su decisión.",
                "success"
              );
              this.router.navigateByUrl("/selection");
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Error al cancelar el acceso a su información.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
      }
    });
  }

  async acceptGetInformation() {
    Swal.fire({
      title: "Accediendo a su información",
      text: "Espere un momento...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    this.services
      .accept_privacy_policy_backgroundcheck(this.profileForm.value)
      .subscribe((response: any) => {
        const { ok, data, msg } = response;
        Swal.close();
        if (ok) {
          Swal.fire(
            "Gracias",
            "La consulta se realizó exitosamente. El reporte se enviará a la empresa que solicitó la búsqueda.",
            "success"
          );
          this.router.navigateByUrl("/selection");
        } else {
          Swal.fire({
				position: 'center',
				icon: 'error',
				title: msg,
				showConfirmButton: false,
				timer: 4000,
			});
        }
      });
  }
}
