import { Pagination } from './../../shared/pagination';
import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { barModel } from '../../../interfaces/Barchart';
import { ResponseKnow } from '../../../interfaces/ResponseHandler';
import { ConectorKnowService } from '../../../services/conector-know.service';

@Component({
  selector: 'app-estadisticas-eventos',
  templateUrl: './estadisticas-eventos.component.html',
  styleUrls: ['./estadisticas-eventos.component.css']
})
export class EstadisticasEventosComponent implements OnInit {

  pagination = new Pagination();

  constructor(private know :ConectorKnowService) { }

  ini = moment().format("YYYY-MM-DD").toString();
  end = moment().add(1, 'month').format("YYYY-MM-DD").toString();

  chart: any = barModel;
  datas: any = [];

  ngOnInit() {

    this.getDates();

  }

  async getDates() {

    const in_ = moment(this.ini).format("YYYY-MM-DD hh:mm:ss a");
    const ou_ = moment(this.end).format("YYYY-MM-DD hh:mm:ss a");

    this.know.chart_events(in_, ou_).subscribe( (res: ResponseKnow) => {

      this.datas = res.logs;

      let bar_data = [];
      let categories = [];

      res.logs.forEach( x => {
        bar_data.push(Number(x.cuenta));
        categories.push(x.article_name);
      })

      this.chart.series[0].data = bar_data;
      this.chart.xAxis.categories = categories;

      Highcharts.chart('chartzilla', this.chart);

    })

  }

  changePage(ev) {}

}
