import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: 'root',
})
export class ConnectorGroupService {
	constructor(private http: HttpClient) {}

	group_tree(): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/groups/tree`, { headers }).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	import_users(data) {
		return this.http.post(`${environment.server}/users/import`, data);
	}

	getAllGroups(): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/groups/all`, { headers }).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getAllSubGroupsByGroupId(groupId: number): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/groups/subgroups/${groupId}`, { headers }).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}
}
