import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";

@Component({
  selector: 'app-catquestions-selection',
  templateUrl: './catquestions-selection.component.html',
  styleUrls: ['./catquestions-selection.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CatquestionsSelectionComponent implements OnInit {

    step = 1;
    step2 = 3;
    questionForm: FormGroup;
    answerForm: FormGroup;
    relQuestionForm: FormGroup;
    openModal:boolean = false;
    displayedColumns: string[] = [ 'select', 'question', 'delete' ];
    displayedColumns2: string[] = [ 'answer', 'delete' ];
    expandedElement: QuestionElement | undefined;
    questions = [];
    id: number = 0;

  constructor(private fb: FormBuilder, private conector: ConnectorJobProfileService) {
    this.questionForm = this.fb.group({
      question: [null, Validators.required]
    });
    
    this.answerForm = this.fb.group({
      idquestion: [null, Validators.required],
      answer: [null, Validators.required]
    });

    this.relQuestionForm = this.fb.group({
      IdQuestion: [0, Validators.required],
      IdJob: [0, Validators.required]
    });

   }

  ngOnInit() {
    this.conector.get_all_company().subscribe ( (e:any) => {
      this.questions = e.data;
    });
  }


  setStep(index: number) {
    this.step = index;
  }

  setStep2(index: number) {
    this.step2 = index;
  }

  AddQuestion(formdata) {
    this.conector.add_question(formdata).subscribe ( (e: any) => {
      if (e.ok === true) {
        this.setStep(1);

        Swal.fire({
          title: 'Pregunta agregada correctamente.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
          reverseButtons: true
        }).then((result) => {
          this.conector.get_all_company().subscribe ( (e:any) => {
            this.questions = e.data;
          });
        });
      }
      else {
        Swal.fire('Error', e.msg, 'error');
      }
    });
  }

  SelectedQuestion(row: any) {
    this.answerForm.patchValue({
      idquestion: row.id
    });
  }

  // RelQuestion(question : number, accion : string) {

  //   this.relQuestionForm.setValue({
  //     IdQuestion: question,
  //     IdJob: parseInt(this.id.toString()) 
  //   });

  //   this.conector.rel_question(this.relQuestionForm.value).subscribe ( (e: any) => {
  //     if (e.ok === true) {
  //       Swal.fire({
  //         title: 'Se ' + accion + ' la pregunta correctamente.',
  //         icon: 'success',
  //         showCancelButton: false,
  //         confirmButtonText: 'Aceptar',
  //         reverseButtons: true
  //       });

  //       this.conector.get_by_company(this.id).subscribe((e: any) => {
  //         this.questions = e.data;
  //       });
  //     }
  //     else {
  //       Swal.fire('Error', e.msg, 'error');
  //     }
  //   });

  // }

  AddAnswer(formdata) {
    this.conector.add_answer(formdata).subscribe ( (e: any) => {
      if (e.ok === true) {
        this.setStep2(3);

        Swal.fire({
          title: 'Respuesta agregada correctamente.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
          reverseButtons: true
        }).then((result) => {
          this.conector.get_all_company().subscribe ( (e:any) => {
            this.questions = e.data;
          });
        });
      }
      else {
        Swal.fire('Error', e.msg, 'error');
      }
    });
  }

  DeleteQuestion(id : number) {
    this.conector.delete_question(id).subscribe ( (e: any) => {
      if (e.ok === true) {
        Swal.fire({
          title: 'Pregunta eliminada correctamente.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
          reverseButtons: true
        }).then((result) => {
          this.conector.get_all_company().subscribe ( (e:any) => {
            this.questions = e.data;
          });
        });
      }
      else {
        Swal.fire('Error', e.msg, 'error');
      }
    });
  }

  DeleteAnswer(id: number) {
    this.conector.delete_answer(id).subscribe ( (e: any) => {
      if (e.ok === true) {
        Swal.fire({
          title: 'Respuesta eliminada correctamente.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
          reverseButtons: true
        }).then((result) => {
          this.conector.get_all_company().subscribe ( (e:any) => {
            this.questions = e.data;
          });
        });
      }
      else {
        Swal.fire('Error', e.msg, 'error');
      }
    });
  }

}

export interface QuestionElement {
  id: number,
  label: string,
  selected: boolean
}
