import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import Swal from "sweetalert2";
import { ConnectorCollaboratorService } from "../../../../services/Collaborator/conector-collaborator.service";
import { ConectorModuleService } from "../../../../services/conector-module.service";

@Component({
    selector: 'app-submodulecollaborator',
    templateUrl: './submodule-collaborator.component.html',
    styleUrls: ['./submodule-collaborator.component.css']
  })
  export class SubmoduleCollaboratorComponent implements OnInit {

    evaluations = [];
    nickname = null;
    displayedColumns: string[] = ['selected', 'name'];
    mailForm: FormGroup;

    constructor(
      private conector: ConectorModuleService, 
      private collaboratorService: ConnectorCollaboratorService,
      private form: FormBuilder, 
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any) { }
      
    ngOnInit(): void {

      this.nickname = this.data.nickname;
        
      this.mailForm = this.form.group({
        id: [this.data.id, [Validators.required]],
        email: [this.data.email, [Validators.email]]
      });
    }

    save(formdata): void {

      this.collaboratorService.collaborator_sendmail(formdata).subscribe( (response: any) => {

        const {ok, msg, data} = response;

        if (ok === true) {
          Swal.fire({
            title: 'Evaluaciones asignadas correctamente.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            reverseButtons: true
          }).then((result) => {
            this.dialog.closeAll();
          });
        } else {
          Swal.fire('Error', msg, 'error');
        }
      });
    }

  }