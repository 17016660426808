import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class OwnCompetencyService {
    constructor(private http: HttpClient) { }

    getOwnComptencies() {
        return this.http.get(`${environment.server}/MHCJobProfile/getOwnCompetencies`);
    }

    getOwnCompetencyById(ownCompetencyId: number) {
        return this.http.get(`${environment.server}/MHCJobProfile/getOwnCompetencyById/${ownCompetencyId}`);
    }

    getCompetencyCategories() {
        return this.http.get(`${environment.server}/MHCJobProfile/getCompetencyCategories`);
    }

    postOwnCompetency(body) {
        return this.http.post(`${environment.server}/MHCJobProfile/postOwnCompetency`, body);
    }

    putOwnCompetency(body) {
        return this.http.put(`${environment.server}/MHCJobProfile/putOwnCompetency`, body);
    }

    deleteOwnCompetency(ownCompetencyId) {
        return this.http.delete(`${environment.server}/MHCJobProfile/deleteOwnCompetency/${ownCompetencyId}`);
    }
}
