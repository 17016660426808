import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import DbJobResponse from '../types/db-job-response';
import DbResponseHandler from '../types/common/db-response-handler';
import { JobPosition } from '../types/db-job-positions.type';

type JobResponse = DbResponseHandler<DbJobResponse[]>;
@Injectable({
	providedIn: 'root',
})
export class ConnectorJobProfileService {
	constructor(private http: HttpClient) { }

	jobprofileById(id) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/jobprofile/${id}`, { headers });
	}

	predetermnated_get() {
		return this.http.get(`${environment.server}/jobprofile/predesigned`);
	}

	trueIdentityOptions() {
		return this.http.get(`${environment.server}/jobprofile/TrueIdentityOptions`);
	}

	jobprofile_all() {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/jobprofile/getall`, {
			headers,
		});
	}

	jobprofile_get_evaluation_type(idEvaluationType) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		let uri;

		// if (idEvaluationType === 3) {
		//   uri = `${environment.server}/MHCJobProfile/getJobsWithProfiles`;
		// } else {
		uri = `${environment.server}/jobprofile/getByEvaluationType/${idEvaluationType}`;
		// }

		return this.http.get(uri, { headers });
	}

	jobprofile_post(formdata) {
		console.log(formdata);
		return this.http.post(`${environment.server}/jobprofile`, formdata);
	}

	jobprofile_put(formdata) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.put(`${environment.server}/jobprofile/${formdata.id}`, formdata, { headers });
	}

	jobprofile_delete(rowId) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.delete(`${environment.server}/MHCJobProfile/${rowId}`, {
			headers,
		});
	}

	dimentions_get(id) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/dimentions?id=${id}`, {
			headers,
		});
	}

	dimentionsNewNorm_get(id) {
		return this.http.get(`${environment.server}/Dimentions/GetNewNorma/${id}`);
	}

	dimentionsUpdateNorm_get(id, data) {
		return this.http.put(`${environment.server}/Dimentions/ActualizarNormaPuesto/${id}`, data);
	}

	dimentions_post(formdata) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.post(`${environment.server}/dimentions`, formdata, {
			headers,
		});
	}

	getAllRiskAnalysis() {
		return this.http.get(`${environment.server}/dimentions/all-risk-analysis`);
	}

	GetPercentageApprovalRejection(formdata) {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/dimentions/GetPercentageApprovalRejection/${formdata}`, {
			headers,
		});
	}

	get_job_surveys(data) {
		return this.http.post(`${environment.server}/jobprofile/submodules`, data);
	}

	put_job_surveys(data) {
		return this.http.put(`${environment.server}/jobprofile/submodules`, data);
	}

	put_job_terceros(idPuesto, data) {
		return this.http.put(`${environment.server}/jobprofile/sendJobToExternal/${idPuesto}`, data);
	}

	get_by_company(id) {
		return this.http.get(`${environment.server}/JobQuestions/GetByCompany/${id}`);
	}

	get_all_company() {
		return this.http.get(`${environment.server}/JobQuestions/GetAllQuestionsCompany`);
	}

	add_question(data) {
		return this.http.post(`${environment.server}/JobQuestions`, data);
	}

	add_answer(data) {
		return this.http.post(`${environment.server}/JobQuestions/AddAnswer`, data);
	}

	rel_question(data) {
		return this.http.post(`${environment.server}/JobQuestions/RelQuestion`, data);
	}

	delete_question(id) {
		return this.http.delete(`${environment.server}/JobQuestions/${id}`);
	}

	delete_answer(id) {
		return this.http.delete(`${environment.server}/JobQuestions/DeleteAnswer/${id}`);
	}

	delete_rel_question(data) {
		return this.http.delete(`${environment.server}/JobQuestions/DeleteRelQuestion`, data);
	}

	get_competencies(jobId = 0, page = 0, pageSize = 10) {
		return this.http.get(`${environment.server}/MHCJobProfile/getCompetencias/${jobId}?page=0&size=300`);
	}

	get_predesigned_competencies(jobId = 0) {
		return this.http.get(`${environment.server}/jobprofile/predesigned/competences/${jobId}`);
	}

	put_job_competencies(data) {
		return this.http.post(`${environment.server}/MHCJobProfile/updatecompetencias`, data);
	}

	get_mapaconductual(data) {
		return this.http.post(`${environment.server}/MHCJobProfile/getmapaconductual`, data);
	}

	post_profile(data) {
		return this.http.post(`${environment.server}/MHCJobProfile/profile`, data);
	}

	get_profile(id) {
		return this.http.get(`${environment.server}/MHCJobProfile/profile/${id}`);
	}

	assign_evals(id, data) {
		return this.http.post(`${environment.server}/MHCJobProfile/assignevals/${id}`, data);
	}

	create_profileMHC(id, data) {
		return this.http.post(`${environment.server}/MHCJobProfile/createProfileMHC/${id}`, data);
	}

	update_profileMHC(id, data) {
		return this.http.post(`${environment.server}/MHCJobProfile/updateProfileMHC/${id}`, data);
	}

	hiring_vacancy(hId) {
		return this.http.get(`${environment.server}/hiring/vacancies/${hId}`);
	}

	stepper_hiring(vid) {
		return this.http.get(`${environment.server}/hiring/stepper/${vid}`);
	}

	config_hiring(data) {
		return this.http.post(`${environment.server}/hiring/assessment`, data);
	}

	config_hiringUpdate(data) {
		return this.http.post(`${environment.server}/hiring/assessmentUpdate`, data);
	}

	config_hiringDelete(rowId) {
		return this.http.delete(`${environment.server}/hiring/assessmentDelete/${rowId}`);
	}

	post_com_chart(data) {
		return this.http.post(`${environment.server}/MHCJobProfile/profiles/chart`, data);
	}

	config_battery(data) {
		return this.http.post(`${environment.server}/hiring/batteryTest`, data);
	}

	config_batteryDelete(rowId) {
		return this.http.delete(`${environment.server}/hiring/batteryDelete/${rowId}`);
	}

	public getJobProfilesAll(): Observable<JobResponse> {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});
		return this.http.get<JobResponse>(`${environment.server}/jobprofile/getall`, {
			headers,
		});
	}

	getJobs(
		pase: number,
		size: number,
		sortDescending: boolean,
		search: string
	): Observable<{
		ok: boolean;
		data: {
			totalJobsPositions: number;
			jobsPositions: JobPosition[];
			totalPages: number;
			currentPage: number;
		};
	}> {
		return this.http.get<{
			ok: boolean;
			data: {
				totalJobsPositions: number;
				jobsPositions: JobPosition[];
				totalPages: number;
				currentPage: number;
			};
		}>(
			`${environment.server}/jobprofile/jobs-positions/?pageNumber=${pase}&pageSize=${size}&sortDescending=${sortDescending}&search=${search}`
		);
	}

}
