import Swal from 'sweetalert2';
import { ResponseHandler } from './../../../interfaces/ResponseHandler';
import { AuthenticationService } from './../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { param } from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  makeHttp = false;
  resetForm: FormGroup;
  isTrue = false;

  constructor(private conector: ConectorJsonService, private fb:FormBuilder, private activatedRoute: ActivatedRoute, private translate: TranslateService, private auth: AuthenticationService) {

    this.resetForm = this.fb.group({
      hash: [{value: '', disabled: true}, Validators.required],
      password: [null, Validators.required],
      repeat: [null, Validators.required]
    },
    {
      validator:
      this.checkPasswords
    });

    this.auth.setLanguage().then( k =>  this.translate.setDefaultLang(k) );

  }

  checkPasswords(group: FormGroup) { 
    let pass = group.get('password').value;
    let confirmPass = group.get('repeat').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  async ngOnInit() {

    this.activatedRoute.paramMap.subscribe( (p: any) => {

      console.info(p.params.id);

      if(p.params.id != null) {
        this.resetForm.get('hash').setValue(p.params.id);
        this.resetForm.updateValueAndValidity();
      }

    });

  }

  setPassword(value) {

    value.hash = this.resetForm.get('hash').value;

    this.conector.renewPassword(value).subscribe( async (k: ResponseHandler) => {

      const { ok, msg }  = k;

      const op = (ok === true) ? 'success' : 'error';

      const tt = await this.translate.get(op).toPromise();

      Swal.fire(tt, msg, op);

      this.isTrue = ok;

    });

  }

}


//https://www.udemy.com/course/master-django-by-building-complete-restful-api-project/?couponCode=OCTOBERSALE&ranMID=39197&ranEAID=NuZiHLoAApo&ranSiteID=NuZiHLoAApo-IPHbjAI4LJDCX8M9A9QTwA&LSNPUBID=NuZiHLoAApo&utm_source=aff-campaign&utm_medium=udemyads

//7CDD6EDB31631313E9B74E05DE31AA4C