import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import DbResponseHandler from "../types/common/db-response-handler";
import ChartSeries from "../types/common/db-chart-series-response";
import SurveyHomeStatistics from "../types/survey-home/db-survey-home-statistics";
import Project from "../types/survey-home/db-survey-home-project";
import StatiticsGroupsAndSubgroups from "../types/survey-home/db-survey-home-statitics-groups-subgroups";

@Injectable({
  providedIn: "root",
})
export class HomeSurveyServices {
  constructor(private http: HttpClient) {}

  public getSurveyHomeStatistics(): Observable<
    DbResponseHandler<SurveyHomeStatistics>
  > {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get<DbResponseHandler<SurveyHomeStatistics>>(
      `${environment.server}/survey/statistics`,
      {
        headers,
      }
    );
  }
  public getAllProjects(): Observable<DbResponseHandler<Project>> {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get<DbResponseHandler<Project>>(
      `${environment.server}/survey/getAllProjects`,
      {
        headers,
      }
    );
  }
  public getStatiticsGroupsAndSubgroups(
    idProject: number
  ): Observable<DbResponseHandler<StatiticsGroupsAndSubgroups>> {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get<DbResponseHandler<StatiticsGroupsAndSubgroups>>(
      `${environment.server}/survey/getStatiticsGroupsAndSubgroupsByProject/${idProject}`,
      {
        headers,
      }
    );
  }
}
