import { SelectionModel } from "@angular/cdk/collections";
import { ViewChild } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { MatSort } from "@angular/material";
import { MatPaginator } from "@angular/material";
import { MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { RankingService } from "../../../../services/ranking.service";
import { Pagination } from "../../../../models/pagination";

export interface Evaluated {
  id: number;
  name: string;
  lastname: string;
  email: string;
  lastEvaluationDate: Date;
}

@Component({
  selector: "app-ranking",
  templateUrl: "./ranking.component.html",
  styleUrls: ["./ranking.component.css"],
})
export class RankingComponent implements OnInit {
  selection;
  rankingForm: FormGroup;
  displayedColumns: string[] = [
    "select",
    "username",
    "name",
    "lastname",
    "email",
  ];
  dataSource: MatTableDataSource<Evaluated>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  jobs: object[];
  startDate: Date;
  endDate: Date;
  datesValid = false;
  pagination = new Pagination();
  selectedRows: any[] = [];
  tableMessage = "Por favor, realiza una búsqueda para mostrar información.";
  hiddenMessageTable: boolean = false;
  maxDate = new Date();

  constructor(
    private form: FormBuilder,
    private rankingService: RankingService,
    private router: Router
  ) {
    this.rankingForm = this.form.group({
      jobId: [null],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
    });
    this.dataSource = new MatTableDataSource([]);
    this.selection = new SelectionModel<Evaluated>(true, []);
    this.pagination.length = 10;
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;
  }

  ngOnInit() {
    this.rankingService
      .getJobsWithEvaluations()
      .subscribe((response: object[]) => {
        this.jobs = response;
      });
  }

  search() {
    const body = {
      jobId: this.rankingForm.controls.jobId.value,
      startDate: this.rankingForm.controls.startDate.value,
      endDate: this.rankingForm.controls.endDate.value,
    };

    this.rankingService
      .getRankingEvaluateds(body, this.pagination.init, this.pagination.limit)
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.selection.clear();
        this.dataSource.data = data;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
        this.hiddenMessageTable = data.length > 0;
        if (data.length === 0) {
          this.tableMessage =
            "La consulta realizada no emitio ningún resultado, verifica los filtros ingresados.";
        }
      });
  }

  onDateInput() {
    const startDate = this.rankingForm.controls.startDate.value;
    const endDate = this.rankingForm.controls.endDate.value;

    if (!!!startDate || !!!endDate) {
      this.datesValid = false;
    } else if (startDate.getTime() > endDate.getTime()) {
      this.datesValid = false;
      Swal.fire({
        text: "La fecha final no puede ser menor a la inicial",
        icon: "error",
        showConfirmButton: true,
      });
    } else {
      this.datesValid = true;
    }
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      const first25Rows = this.dataSource.data.slice(0, 25);
      first25Rows.forEach((row) => {
        this.selectedRows.push(row);
        this.selection.select(row);
      });
    }
  }
  toggle(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
      const index = this.selectedRows.findIndex(
        (selectedRow) => selectedRow === row
      );
      if (index !== -1) {
        this.selectedRows.splice(index, 1);
      }
    } else {
      if (this.selection.selected.length < 25) {
        this.selection.select(row);
        this.selectedRows.push(row);
      } else {
        const ultimateSelected =
          this.selectedRows[this.selectedRows.length - 1];

        this.selection.deselect(ultimateSelected);
        this.selection.select(row);

        const index = this.selectedRows.indexOf(ultimateSelected);
        if (index !== -1) {
          this.selectedRows.splice(index, 1);
        }
        this.selectedRows.push(row);
      }
    }
  }

  isSelected(row: Evaluated): boolean {
    return this.selection.isSelected(row);
  }
  openRanking() {
    if (this.selection.selected.length < 2) {
      return;
    }
    const evaluatedsId = this.selection.selected.map((e) => e.id);

    this.router.navigateByUrl(`/manager/Ranking/Content`, {
      state: { evaluateds: evaluatedsId },
    });
  }
  changePage($event) {
    this.pagination.length = 0;
    this.dataSource.data = [];
    this.selection.clear();
    this.pagination.changePage($event);
    const body = {
      jobId: this.rankingForm.controls.jobId.value,
      startDate: this.rankingForm.controls.startDate.value,
      endDate: this.rankingForm.controls.endDate.value,
    };
    this.rankingService
      .getRankingEvaluateds(body, this.pagination.init, this.pagination.limit)
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.dataSource.data = data;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
        if (data.length === 0) {
          this.tableMessage =
            "La consulta realizada no emitio ningún resultado, verifica los filtros ingresados.";
        }
      });
  }
}
