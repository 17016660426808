import { DemoMaterialModule } from './../../material-module';
import { CommonModule } from '@angular/common';
import { UserLayoutRoutes } from './user.routing';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports: [
        RouterModule.forChild(UserLayoutRoutes),
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        DemoMaterialModule,
        CommonModule
    ],
    declarations: [

    ],
    entryComponents: [

    ]
})

export class UserLayoutModule {}
