import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { OwnQuestionsServices } from "../../../../services/own-questions.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-create-update-own-questions",
  templateUrl: "./create-and-update-own-questions.component.html",
  styleUrls: ["./create-and-update-own-questions.component.css"],
})
export class CreateAndUpdateOwnQuestionComponent implements OnInit {
  questionForm: FormGroup;
  newRegister: boolean = true;

  constructor(
    public dialog: MatDialog,
    private form: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ownQuestionsServices: OwnQuestionsServices
  ) {}
  ngOnInit(): void {
    this.newRegister = this.data === null;
    const id = this.newRegister ? null : this.data.questionId;
    const ownQuestion = this.newRegister ? null : this.data.question;
    this.questionForm = this.form.group({
      id: [id],
      Question: [ownQuestion, [Validators.required]],
    });
  }
  save(questionForm) {
    if (this.newRegister) {
      this.ownQuestionsServices
        .createQuestion(questionForm.value)
        .subscribe((response: any) => {
          const { ok, msg } = response;
          if (ok) {
            Swal.fire({
              title: "Guardado correctamente.",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Aceptar",
              reverseButtons: true,
            }).then((result) => {
              this.dialog.closeAll();
            });
          } else {
            Swal.fire("Error", "error");
          }
        });
    } else {
      this.ownQuestionsServices
        .updateQuestion(questionForm.value)
        .subscribe((response: any) => {
          const { ok, msg } = response;
          if (ok) {
            Swal.fire({
              title: "Guardado correctamente.",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Aceptar",
              reverseButtons: true,
            }).then((result) => {
              this.dialog.closeAll();
            });
          } else {
            Swal.fire("Error", "error");
          }
        });
    }
  }
}
