import { AuthGuardService } from './../../services/auth-guard.service';
import { EvaluationsComponent } from './evaluations.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TycosComponent } from '../../views/evaluations/tycos/tycos.component';
import { ProfileComponent } from '../../views/evaluations/profile/profile.component';
import { SurveyComponent } from '../../views/evaluations/survey/survey.component';
import { AuthEvaluationsService } from '../../services/auth-evaluations.service';


const routes: Routes = [
  {
    path: 'evaluations',
    component: EvaluationsComponent,
    canActivate: [AuthEvaluationsService],
    children: [
      { path: '', redirectTo:'tycos', pathMatch: 'full'},
      { path: 'tycos', component: TycosComponent },
      { path: 'profile', component: ProfileComponent},
      { path: 'survey', component: SurveyComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class EvaluationsRoutingModule { }
