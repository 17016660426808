import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConectorKnowService } from '../../../../services/conector-know.service';

@Component({
  selector: 'app-cc-retro',
  templateUrl: './cc-retro.component.html',
  styleUrls: ['./cc-retro.component.css']
})
export class CcRetroComponent implements OnInit {

  @Input() retros: any[] = [];

  @Input() preguntas = [];

  @Output() saveRetros: EventEmitter<any>  = new EventEmitter();

  respuestas = [];

  constructor(private know: ConectorKnowService, private fb: FormBuilder) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {

    if( changes.retros.currentValue != undefined) {

      const rs: any = changes.retros.currentValue;

      rs.forEach( e => {

        if( Array.isArray( e.conditions) ) {

          e.conditions.forEach( i => {

            this.veRespuestas(i);

          })

        }

      });

    }

  }

  addCondicion(row) {
    row.conditions.push({
      condition_id: 0,
      condition_option_id: null,
      condition_question_id: null
    });
  }

  veRespuestas(cc) {
    console.info('veRespuestas', cc);
    this.respuestas = this.preguntas[this.preguntas.findIndex( x => x.id == cc.condition_question_id)].options;
  }

  eliminaCondicion(row, con) {
    row.conditions.slice( row.conditions.findIndex( x => x == row), 1);
  }

  addRetro() {
    this.retros.push({
      id:0,
      condition_type:0,
      feedback: null,
      feedback_id:0,
      precedence:0,
      conditions: [],
    });
  }

  eliminaRetro(row) {
    this.retros.slice( this.retros.findIndex(x => x == row), 1);
  }

  finalizar() {
    this.saveRetros.emit(this.retros);
  }

}
