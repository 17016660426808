import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CollaboratorExitSurveysComponent } from "../collaborator-exit-surveys/collaborator-exit-surveys.component";

@Component({
  selector: 'app-collaborator-surveys',
  templateUrl: './collaborator-surveys.component.html',
  styleUrls: ['./collaborator-surveys.component.css']
})
export class CollaboratorSurveysComponent implements OnInit {

  @Input() modules = [];
  @Input() evaluations = [];

  @Output() update: EventEmitter<any> = new EventEmitter();

  openModal: boolean = false;

  @ViewChild(CollaboratorExitSurveysComponent, { static: true })
  csc: CollaboratorExitSurveysComponent;


  constructor() { }

  ngOnInit() {
  }

  saveAction() {
    this.update.emit(this.modules);
  }

  update_row(foo, bar) {
    console.info('selection', foo, bar);
  }

  exitSurveyEvaluations(info) {
    //this.csc.openModalExitSurvey = info.selected;
  }

}