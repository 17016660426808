import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import Swal from "sweetalert2";
import { ConectorJsonService } from "../../../../services/conector-json.service";
import { GenericCatalogsService } from "./../../../../services/GenericCatalogs.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-exit-survey-form",
  templateUrl: "./exit-survey-form.component.html",
  styleUrls: ["./exit-survey-form.component.css"],
})
export class ExitSurveyFormComponent implements OnInit {
  exitSurveys: any = [];
  contractTypes: any = [];
  exitSurveyForm: FormGroup;
  maxDate = new Date();
  civilStatus: any = [];
  showForm: boolean = false;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private conectorJsonServices: ConectorJsonService,
    private genericCatalogsService: GenericCatalogsService,
    private form: FormBuilder
  ) {
    this.getContractTypes();
    this.getExitSurveys();
    this.getCivilStatus();
  }
  ngOnInit() {
    this.getExitSurveyByEvaluated();
  }

  getContractTypes() {
    this.genericCatalogsService.ct_TypeContract().subscribe((response: any) => {
      const { ok, data } = response;
      if (ok) {
        this.contractTypes = data;
      } else {
        console.log("Error get contract types");
      }
    });
  }
  getExitSurveys() {
    this.conectorJsonServices.getAllSurveys().subscribe((response: any) => {
      const { ok, data } = response;
      if (ok) {
        this.exitSurveys = data;
      } else {
        console.log("Error get all exit surveys");
      }
    });
  }
  getCivilStatus() {
    this.genericCatalogsService.getCivilStatus().subscribe((response: any) => {
      const { ok, data } = response;
      if (ok) {
        this.civilStatus = data;
      } else {
        console.log("Error get civil status");
      }
    });
  }
  getExitSurveyByEvaluated() {
    var surveyId = null;
    var dateOfAdmission = null;
    var typeOfContract = null;
    var workStartTime = null;
    var workEndTime = null;
    var civilStatus = null;
    this.conectorJsonServices
      .getExitSurveyByEvaluated(this.data.evaluatedId)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          if (data != null) {
            surveyId = data["exitSurvey"];
            dateOfAdmission = data["dateOfAdmission"];
            typeOfContract = data["typeOfContract"];
            workStartTime = data["workStartTime"];
            workEndTime = data["workEndTime"];
            civilStatus = data["civilStatus"];
          }
          this.exitSurveyForm = this.form.group({
            surveyId: [surveyId, [Validators.required]],
            dateOfAdmission: [dateOfAdmission, [Validators.required]],
            evaluated: [
              { value: this.data.evaluatedName, disabled: true },
              [Validators.required],
            ],
            typeOfContract: [typeOfContract, [Validators.required]],
            workStartTime: [workStartTime, [Validators.required]],
            workEndTime: [workEndTime, [Validators.required]],
            civilStatus: [civilStatus, [Validators.required]],
          });
        } else {
          console.log("Error get exit survey by evaluated");
        }
        this.showForm = true;
      });
  }
  save(form: any) {
    this.conectorJsonServices
      .asignExitSurvey(this.data.evaluatedId, form.value)
      .subscribe((response: any) => {
        const { ok } = response;
        if (ok) {
          Swal.fire({
            title: "Guardado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Aceptar",
            reverseButtons: true,
          }).then((result) => {
            this.dialog.closeAll();
          });
        } else {
          Swal.fire("Error", "error");
        }
      });
  }
}
