import { AdminComponent } from './admin.component';
import { Routes } from '@angular/router';

import { CompaniesComponent } from '../../views/admin/companies/companies.component';

export const AdminLayoutRoutes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        children: [
            {
                path: 'companies',
                component: CompaniesComponent
            },
            {
                path: '**',
                redirectTo: 'companies'
            }
        ]
    }
];
