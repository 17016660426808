import { ConnectorEthicsSurveyService } from '../../../services/ethics-survey/conector-ethics-survey.service';
import { CreateConfigComponent } from '../modal/create-config/create-config.component';
import { CreateCollaboratorComponent } from '../../general/create-collaborator/create-collaborator.component';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatTabChangeEvent, MatDialog, MatPaginator } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {NavigationStart, Router, ActivatedRoute, Params} from '@angular/router';
import { from } from 'rxjs';

@Component({
  selector: 'app-evaluated-answer',
  templateUrl: './evaluated-answer.component.html',
  styleUrls: ['./evaluated-answer.component.css']
})
export class EvaluatedAnswerComponent implements OnInit {
  [x: string]: any;

  proyecto = {  
    id : '', 
    nameProyect : '', 
    nameCompany : '', 
    startDate : '',
    endDate : '', 
    createDate : '',
    description : '',
    type : '',
  };
  listaProyecto =null ; // lista Entity
  constructor( private router:Router,public datepipe: DatePipe, private conector: ConnectorEthicsSurveyService, private fb: FormBuilder, public dialog: MatDialog) { 
    
  }

  ngOnInit() {
    // this.conector.collaborator_get().subscribe( (response: any) => {
    //   const {ok, data, msg} = response;
    //   this.collaborators = data;
    // });
    this.get_ethics_suvey();

  }

   // ge list proyect
  get_ethics_suvey() {

    this.conector.evaluatedAnswerSurveyGet().subscribe( (response: any) => {

      const {ok, data, msg} = response;

      if (ok === false) {
        return Swal.fire('Error', msg, 'error')

      } else {
        let lista = [this.proyecto] ; // lista Entity

        if(msg == '1'){
          for (var key in response.data) {
            if(response.data[key].type == '2'){
              this.router.navigateByUrl('manager/SurveyAnswersTypeTwo;idSurvey='+response.data[key].id+';idUser='+response.data[key].idUser);

            }else
            if(response.data[key].type == '1'){
              this.router.navigateByUrl('manager/SurveyAnswersTypeOne;idSurvey='+response.data[key].id+';idUser='+response.data[key].idUser);
            }
          }

        }else{
          for (var key in response.data) {
            let proyecto = {  

            id : '', 
            nameProyect : '', 
            nameCompany : '', 
            startDate : '',
            endDate : '', 
            createDate : '',
            description : '',
            type : '',
            idUser : '',
          };
            proyecto.id =  response.data[key].id;
            proyecto.nameProyect =  response.data[key].nameProyect;
            proyecto.nameCompany =  response.data[key].nameCompany;
            proyecto.startDate =  this.datepipe.transform(response.data[key].startDate, 'yyyy-MM-dd');
            proyecto.endDate = this.datepipe.transform(response.data[key].endDate, 'yyyy-MM-dd');
            proyecto.createDate = this.datepipe.transform( response.data[key].createDate, 'yyyy-MM-dd');
            proyecto.description =  response.data[key].description;
            proyecto.type =  response.data[key].type;
            proyecto.idUser =  response.data[key].idUser;
  
            lista.push(proyecto);
  
            debugger;
            proyecto = null;
          }
          this.listaProyecto = lista;

        }

        

      //console.log(this.firstFormGroup);
      //console.log(this.secondFormGroup);
      //console.log(this.thirdFormGroup);
      //console.log(this.quarterFormGroup);
      }

    });

  }
  AddConfig(row) {

    const dialogRef = this.dialog.open(CreateConfigComponent, { data : row, width : '70%' });

    // dialogRef.afterClosed().subscribe(res => {
    //   this.conector.users_get().subscribe( (response: any) => {
    //     const {ok, data, msg} = response;
    //     this.users = data;
    //   });
    // });

  }

  // AddConfig2(row) {

  //   this.conector.PdfGet("85").subscribe( (response: any) => {
      
  //     const {ok, data, msg} = response;
  //     debugger;
  //     const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
  //     const downloadLink = document.createElement("a");
  //     const fileName = "sample.pdf";

  //     downloadLink.href = linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();
  //     debugger;

  //   });

  // }
  
}