import Swal from 'sweetalert2';
import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class CustomErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector) { }
  handleError(data: any) {

    const router = this.injector.get(Router);

    if (Error instanceof HttpErrorResponse) {

      console.log(data.status);

    } else {

      console.info('Error &', data);

      // const getError = this.extractError(data);

      // Swal.fire( 'Wops!', getError + '', 'error');

    }

  }

  extractError(data) {

    let errorMsg = data;

    if (data.hasOwnProperty('error') ) {

      const { error, status, statusText } = data;

      if (error.hasOwnProperty('msg')) {

        errorMsg = error.msg;

      } else {

        errorMsg = data.error;

      }

    }

    console.info('Extracted msg', errorMsg);

    return errorMsg;

  }

}
