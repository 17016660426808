import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-apply",
  templateUrl: "./apply.component.html",
  styleUrls: ["./apply.component.css"],
})
export class ApplyComponent implements OnInit {
  @Input() survey: any = {};
  @Input() numQuestion: string = null;
  @Input() section;
  @Input() question;
  @Output() solve: EventEmitter<any> = new EventEmitter();
  @Output() photo: EventEmitter<any> = new EventEmitter();
  @Output() finishSection: EventEmitter<any> = new EventEmitter();
  @Output() timerSetting: EventEmitter<any> = new EventEmitter();

  current = {
    name: null,
    sections: [],
    instructions: null,
    questions: [],
    visible: false,
  };

  sections = [];

  dateSection = null;
  dateSectionOrig = null;
  //addTimerHabilidades = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    var sec = this.section;
    if (
      changes.hasOwnProperty("section") &&
      changes.section.currentValue != null
    ) {
      if (changes.section.currentValue.addTimer) {
        this.dateSection = new Date(
          moment()
            .add(changes.section.currentValue.timeLimit, "seconds")
            .toDate()
        );
        this.onTimerSetting();
        console.log(1038, this.dateSection);
      }
    } else {
      var nameEval = this.survey.name;
      var posDiag = this.numQuestion.split("/");
      var numPreg = parseInt(posDiag[0]); // this.numQuestion.substring(0,2);
      if (
        this.section != null &&
        nameEval == "Habilidades Cognitivas" &&
        numPreg == 1
      ) {
        if (this.section.addTimer) {
          this.dateSection = new Date(
            moment().add(this.section.timeLimit, "seconds").toDate()
          );
          this.onTimerSetting();
          console.log(1038, this.dateSection);
        }
      } else {
        if (
          this.section != null &&
          nameEval == "Habilidades Cognitivas" &&
          numPreg > 1
        ) {
          this.section.addTimer = true;
          var difSeg = moment().diff(this.dateSectionOrig, "seconds") + 1;
          this.dateSection = new Date(
            moment(this.dateSection).add(difSeg, "seconds").toDate()
          );
          this.onTimerSetting();
          console.log(1038, this.dateSection);
        }
      }
    }
  }
  ngOnInit() {}

  siguiente(ev) {
    var nameEval = this.survey.name;
    if (this.section != null && nameEval == "Habilidades Cognitivas") {
      this.section.addTimer = false;
    }

    this.dateSectionOrig = new Date(moment().toDate());
    this.solve.emit(ev);
  }

  savePhoto(ev) {
    this.photo.emit(ev);
  }

  triggerFunction() {
    this.finishSection.emit(true);
  }

  onTimerSetting() {
    this.timerSetting.emit(this.dateSection);
  }
}
