import { Component, OnInit } from "@angular/core";
import { Chart } from "angular-highcharts";
import { HomeIntegrityServices } from "../../../../services/home-integrity.service";
import { AuthenticationService } from "../../../../services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-integrity-home",
  templateUrl: "./integrity-home.component.html",
  styleUrls: ["./integrity-home.component.css"],
})
export class IntegrityHomeComponent implements OnInit {
  generalInformation: [];
  evaluationsCarriedOutSeries: [];
  evaluationsCarriedOutCategories: [];
  evaluationsPercentage: [];
  chartEvaluaciones: Chart;
  chartRGI: Chart;
  quantityUsers: number = 0;
  quantityJobs: number = 0;
  quantityEvaluateds: number = 0;
  quantityEvaluations: number = 0;
  language: string = "";
  dataEvaluations: { name: string; color: string }[] = [];
  translations: { [key: string]: string } = {
    "home-nom035-survey": "",
    "home-survey-engagement": "",
    "home-integriline": "",
    "home-artificial-intelligence-report": "",
    "home-ethics-survey": "",
    "home-exit-survey": "",
    "home-brain-dominance": "",
    "home-honesty-follow-up": "",
    "home-cognitive-abilities": "",
    "home-honesty-selection": "",
    "home-profiles": "",
    "home-permanence-survey": "",
    "home-back-ground-check": "",
    "home-limited-license": "",
    "home-unlimited-license": "",
    "home-undefined-license": "",
    "home-by-volume": "",
  };
  evaluationsNamesAndColors = [
    { evaluationCode: 'home-honesty-selection', color: '#970ff2' },
    { evaluationCode: 'home-honesty-follow-up', color: '#0597f2' },
    { evaluationCode: 'home-permanence-survey', color: '#49d907' },
    { evaluationCode: 'home-exit-survey', color: '#eaf205' },
    { evaluationCode: 'home-profiles', color: '#f24607' },
    { evaluationCode: 'home-cognitive-abilities', color: '#ff3399' },
    { evaluationCode: 'home-brain-dominance', color: '#ffcccc' },
    { evaluationCode: 'home-back-ground-check', color: '#99ccff' }
  ];
  inventory: [];

  constructor(private integrity: HomeIntegrityServices, private auth: AuthenticationService, private translate: TranslateService) {
    this.setLanguageAndGetTranslation();
  }
  async setLanguageAndGetTranslation(): Promise<void> {
    const language = await this.auth.setLanguage();
    this.language = language;
    this.translate.setDefaultLang(language);
    const translationPromises = Object.keys(this.translations).map(key => {
      return new Promise<void>((resolve, reject) => {
        this.translate.get(key).subscribe((res: string) => {
          this.translations[key] = res;
          resolve();
        });
      });
    });

    await Promise.all(translationPromises);

    this.evaluationsNamesAndColors.forEach(item => {
      const translatedName = this.translations[item.evaluationCode];
      if (translatedName) {
        this.dataEvaluations.push({ name: translatedName, color: item.color });
      } else {
        console.error(`La traducción para ${item.evaluationCode} no se encontró.`);
      }
    });
  }

  ngOnInit(): void {
    this.integrity
      .getInformationModuleIntegrity(this.language)
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.generalInformation = data.generalModuleInformation;
        this.quantityUsers = this.generalInformation["quantityUsers"];
        this.quantityJobs = this.generalInformation["quantityJobs"];
        this.quantityEvaluateds = this.generalInformation["quantityEvaluateds"];
        this.quantityEvaluations =
          this.generalInformation["quantityEvaluations"];
        this.evaluationsCarriedOutSeries = data.evaluationsCarriedOut.series;
        this.evaluationsCarriedOutCategories =
          data.evaluationsCarriedOut.categories;
        this.evaluationsPercentage = data.percentageOfEvaluations.data;
        this.generateChartRGI(this.evaluationsPercentage);
        this.generateChartCarriedOutSeries(
          this.evaluationsCarriedOutCategories,
          this.evaluationsCarriedOutSeries
        );
      });

    this.integrity.getInformationInventory().subscribe((response: any) => {
      const { data, msg } = response;

      const licenseMapping: { [key: string]: string } = {
        'Licencia Limitada': 'home-limited-license',
        'Licencia Ilimitada': 'home-unlimited-license',
        'Licencia no definida': 'home-undefined-license'
      };

      const submoduleMapping: { [key: string]: string } = {
        'NOM035 Survey': 'home-nom035-survey',
        'Engagement Survey': 'home-survey-engagement',
        'Integri-Line': 'home-integriline',
        'Reporte de Inteligencia Artificial': 'home-artificial-intelligence-report',
        'E-thics Survey': 'home-ethics-survey',
        'Encuesta de Salida': 'home-exit-survey',
        'Dominancia Cerebral': 'home-brain-dominance',
        'Honestidad Seguimiento': 'home-honesty-follow-up',
        'Habilidades Cognitivas': 'home-cognitive-abilities',
        'Honestidad Selección': 'home-honesty-selection',
        'Perfiles': 'home-profiles',
        'Encuesta de Permanencia': 'home-permanence-survey',
        'Back Ground Check': 'home-back-ground-check'
      };

      data.forEach(element => {
        if (licenseMapping[element["licensing"]]) {
          element["licensing"] = this.translations[licenseMapping[element["licensing"]]];
        }
        if (submoduleMapping[element["submodule"]]) {
          element["submodule"] = this.translations[submoduleMapping[element["submodule"]]];
        }
      });
      this.inventory = data;
    });

  }
  generateChartRGI(evaluationsPercentage) {
    this.chartRGI = new Chart({
      chart: {
        type: "pie",
      },
      title: {
        text: " ",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "{series.name}: {point.percentage:.1f}%",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: "Porcentaje",
          colorByPoint: true,
          type: undefined,
          data: evaluationsPercentage,
        },
      ],
    });
  }
  generateChartCarriedOutSeries(
    evaluationsCarriedOutCategories,
    evaluationsCarriedOutSeries
  ) {
    this.chartEvaluaciones = new Chart({
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: evaluationsCarriedOutCategories,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: evaluationsCarriedOutSeries,
    });
  }
}
