import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { IChartService } from '../interfaces/IChartService';
import { Options } from 'highcharts';
import { IBehavioralMapDistributionData } from '../interfaces/IBehavioralMapDistributionData';

enum BehavioralMapDistributionSeriesColor {
	Relationship = '#FFD400',
	Pause = '#42AE76',
	NormsAdaptability = '#34ACE2',
	FightSpirit = '#E84D48',
}

enum BehavioralMapDistributionSeriesName {
	Relationship = 'Relación',
	Pause = 'Pausa',
	NormsAdaptability = 'Adaptación a normas',
	FightSpirit = 'Espíritu de lucha',
}

@Injectable({
	providedIn: 'root',
})
export class BehavioralMapDistributionService implements IChartService<IBehavioralMapDistributionData> {
	constructor() {}
	public generateChart({
		data,
		width,
		height,
	}: {
		data: IBehavioralMapDistributionData;
		width?: number;
		height?: number;
	}): Chart {
		const chartOptions: Options = {
			boost: { enabled: true, useGPUTranslations: true, seriesThreshold: 5 },
			chart: {
				polar: true,
				type: 'area',
				backgroundColor: 'rgba(0,0,0,0)',
				width,
				height,
			},
			credits: { enabled: false },
			exporting: { enabled: false },
			pane: { startAngle: 0, endAngle: 360 },
			title: { text: '' },
			legend: { enabled: false },
			xAxis: {
				min: 0,
				max: 360,
				tickInterval: 1,
				labels: { enabled: false },
				gridLineColor: 'transparent',
				lineColor: '#F4F4F4',
				lineWidth: 1,
			},
			yAxis: { min: 0, max: 10, tickInterval: 1, gridLineColor: '#F4F4F4' },
			plotOptions: {
				series: { pointStart: 0, pointInterval: 90 },
				column: { pointPadding: 0, groupPadding: 0 },
			},
			series: [
				{
					name: 'Valor',
					type: 'column',
					data: [
						{
							y: data.relationship,
							color: BehavioralMapDistributionSeriesColor.Relationship,
							name: BehavioralMapDistributionSeriesName.Relationship,
						},
						{
							y: data.pause,
							color: BehavioralMapDistributionSeriesColor.Pause,
							name: BehavioralMapDistributionSeriesName.Pause,
						},
						{
							y: data.normsAdaptability,
							color: BehavioralMapDistributionSeriesColor.NormsAdaptability,
							name: BehavioralMapDistributionSeriesName.NormsAdaptability,
						},
						{
							y: data.fightSpirit,
							color: BehavioralMapDistributionSeriesColor.FightSpirit,
							name: BehavioralMapDistributionSeriesName.FightSpirit,
						},
					],
					pointPlacement: 'between',
				},
			],
		};
		return new Chart(chartOptions);
	}
}
