import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material";
import Swal from "sweetalert2";
import { Pagination } from "../../../models/pagination";
import { GroupSurveyReportComponent } from "../modals/group-survey-report/group-survey-report.component";
import { ConectorJsonService } from "./../../../services/conector-json.service";

@Component({
  selector: "app-surveys",
  templateUrl: "./surveys.component.html",
  styleUrls: ["./surveys.component.css"],
})
export class SurveysComponent implements OnInit {
  constructor(
    private conector: ConectorJsonService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.formTypeSurvey = this._formBuilder.group({
      typeSurvey: [""],
    });

    this.formCalendar = this._formBuilder.group({
      initialDate: [null],
      endlDate: [null],
    });
    this.pagination.length = 10;
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;
  }
  surveysData = [];
  showGeneralInformation: boolean = true;
  showCreateSurvey: boolean = false;
  surveySelected: any = null;
  displayedColumns: string[] = [
    "name",
    "state",
    "assignedSurveys",
    "numberAnswered",
    "actions",
  ];
  formTypeSurvey: FormGroup;
  formCalendar: FormGroup;
  pagination = new Pagination();

  ngOnInit() {
    this.loadSurveys();
  }
  loadSurveys() {
    this.conector
      .get_eConfig(this.pagination.init, this.pagination.pageSize)
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.surveysData = data;
        this.pagination.length = msg;
      });
  }
  AddEvaluation() {
    this.surveySelected = null;
    this.showGeneralInformation = false;
    this.showCreateSurvey = true;
  }
  DownloadReport() {
    Swal.fire({
      title: "Descargando reporte",
      text: "Espere un momento...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let formdata = {
      idSurvey: this.formTypeSurvey.controls["typeSurvey"].value,
      initialDate: this.formCalendar.controls["initialDate"].value,
      endlDate: this.formCalendar.controls["endlDate"].value,
    };
  }
  saveSurvey() {
    this.loadSurveys();
    this.showGeneralInformation = true;
    this.showCreateSurvey = false;
  }

  changePage($event) {
    this.pagination.length = 0;
    this.surveysData = [];
    this.pagination.changePage($event);
    this.conector
      .get_eConfig($event.pageIndex, $event.pageSize)
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.surveysData = data;
        this.pagination.length = msg;
      });
  }
  setCurrentSurvey(row) {
    this.showGeneralInformation = false;
    this.showCreateSurvey = true;
    this.surveySelected = row;
  }
  downloadReport(row) {
    const numberAnswered = row["numberAnswered"];
    if (numberAnswered > 0) {
      const dialogRef = this.dialog.open(GroupSurveyReportComponent, {
        data: row["id"],
      });
    } else {
      Swal.fire({
        text: "No es posible descargar un reporte grupal, ya que no tiene encuestas finalizadas",
        icon: "error",
        showConfirmButton: true,
      });
    }
  }
}
