import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

import { environment } from './../../../../environments/environment';
import { AuthenticationService } from './../../../services/authentication.service';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { SharedCommunicationService } from './../../../services/shared-communication.service';

@Component({
	selector: 'app-license',
	templateUrl: './license.component.html',
	styleUrls: ['./license.component.css'],
})
export class LicenseComponent implements OnInit {
	isLinear: boolean = true;
	makeHttp: boolean = false;
	newReport: boolean = false;
	isLoading: boolean = false;
	newComplaint: boolean = false;
	isEnableTyCos: boolean = false;
	isEnableWelcome: boolean = false;
	isEnableProcess: boolean = false;

	data: any;
	snav: any;
	tycos: any;
	userIp: any;
	company: any;
    process: any;
	welcome: any;
    languaje: any;

	privacy = null;

	firstFormGroup: FormGroup;
	secondFormGroup: FormGroup;
	authForm: FormGroup;

	publicFolder: string = environment.public;
	mainLogo: string = 'assets/images/logo_without_slogan.png';

	constructor(
		private router: Router,
		private translate: TranslateService,
		private auth: AuthenticationService,
		private conector: ConectorJsonService,
		private fb: FormBuilder,
		private _sanitizer: DomSanitizer,
		private shared: SharedCommunicationService
	) {
		this.auth.setLanguage().then((k: any) => {
			this.translate.setDefaultLang(k);
			this.languaje = k;
		});

		this.authForm = this.fb.group({
			reportingId: [null, Validators.required],
			password: [null, Validators.required],
		});
	}

	ngOnInit() {
		this.shared.httpCall.subscribe((k) => (this.isLoading = k));

		const { data }: any = this.conector.get_user_ip().toPromise();

		this.userIp = data;
		this.shared.setJsonObject('userIp', { userIp: data });

		this.company = localStorage.getItem('company');
		this.conector.company_tycos(this.company).subscribe((d: any) => {
			console.log(1090, d);
			const { ok, data, msg } = d;

			this.data = data;
			this.welcome = this._sanitizer.bypassSecurityTrustHtml(data.welcome_es); // ( this.languaje == 'es') ? data.welcome_es : data.welcome_en;
			this.tycos = this._sanitizer.bypassSecurityTrustHtml(data.tycos_es); // ( this.languaje == 'es') ? data.tycos_es : data.tycos_en;
            this.process = this._sanitizer.bypassSecurityTrustHtml(data.process_es); // ( this.languaje == 'es') ? data.process_es : data.process_en;
            
			this.isEnableWelcome = data.enableWelcome;
			this.isEnableProcess = data.enableProcess;
			this.isEnableTyCos = data.enableTyCos;

			if (data != null) {
				this.data = data;
				fetch(this.publicFolder + '/logos/' + data.logo).then((d: any) => {
					if (d.status === 200) {
						this.mainLogo = this.publicFolder + '/logos/' + data.logo;
					}
				});
			}
		});
	}

	openReport() {
		this.router.navigateByUrl('/report');
	}

	validateTicket(e) {
		e.IpHostName = this.userIp;

		this.conector.report_auth(e).subscribe((k: any) => {
			const { ok, data } = k;

			if (ok === true && data !== null) {
				localStorage.setItem('token', data);
				this.router.navigateByUrl('/tracking/information');
			} else {
				Swal.fire('Error', 'Ticket or password not found', 'error');
			}
		});
	}

	async requestMail() {
		const ts = await this.translate.get('Ticket ID').toPromise();
		const cm = await this.translate.get('email address').toPromise();

		Swal.fire({
			html:
				'<div class="resetForm"><h2>' +
				(await this.translate.get('Reset password').toPromise()) +
				'</h2> ' +
				'<p>' +
				(await this.translate.get('reset_context').toPromise()) +
				'</p>' +
				'<label>' +
				ts +
				'</label>' +
				'<input id="swal-input1" class="swal2-input">' +
				'<label>' +
				cm +
				'</label>' +
				'<input id="swal-input2" class="swal2-input"></div>',
			cancelButtonColor: '#9F5CC0',
			showCancelButton: true,
			cancelButtonText: await this.translate.get('x-cancel').toPromise(),
			preConfirm: function () {
				return new Promise(function (resolve) {
					resolve({
						username: $('#swal-input2').val(),
						reporting: $('#swal-input1').val(),
					});
				});
			},
		}).then(async (response: any) => {
			const { value } = response;

			if (value) {
				const { ok, msg }: any = await this.conector.resetComplaint(value).toPromise();

				const handler = ok == true ? 'success' : 'error';

				const tt = await this.translate.get(handler).toPromise();

				Swal.fire(tt, await this.translate.get(msg).toPromise(), handler);
			}
		});
	}
}
