import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HomeIntegrityServices {
  constructor(private http: HttpClient) { }

  getInformationModuleIntegrity(language: string) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(`${environment.server}/integrity/${language}`, {
      headers,
    });
  }
  getInformationInventory() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(`${environment.server}/company/inventory`, {
      headers,
    });
  }
}
