import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment, CanLoad } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Route } from '@angular/compiler/src/core';

@Injectable({
  providedIn: 'root'
})
export class ChildGuardService implements CanActivate {

  constructor(private auth: AuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    return true;

    const validItems: any = JSON.parse(localStorage.getItem('routerValidItems'));

    const verification = validItems.some( x => state.url.includes(x.node) === true && x.checked === true);

    const mainRoute = localStorage.getItem('mainRoute');

    if (verification === true) {

      return true;

    } else {

      this.router.navigateByUrl(`${mainRoute}/home`);

      return false;
    }

  }

}
