import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { NgxCsvParser } from "ngx-csv-parser";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import Swal from "sweetalert2";

import { ResponseHandler } from "../../../../interfaces/ResponseHandler";
import { ConnectorGroupService } from "../../../../services/Admin/conector-group.service";
import { ConectorJsonService } from "../../../../services/conector-json.service";
import DbCountryResponse from "../../../../types/db-catalog-country-response";
import DbLanguageResponse from "../../../../types/db-catalog-lenguage-response";
import DbJobResponse from "../../../../types/db-job-response";
import DbResponseHandler from "../../../../types/common/db-response-handler";
import { GenericCatalogsService } from "../../../../services/GenericCatalogs.service";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";

@Component({
  selector: "app-import-collaborator",
  templateUrl: "./import-collaborator.component.html",
  styleUrls: ["./import-collaborator.component.css"],
})
export class ImportCollaboratorComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  group: any;
  country: any;
  csvContent: any;
  groupsList = [];
  lentTestList: DbLanguageResponse[] = [];
  countriesList: DbCountryResponse[] = [];
  jobProfileList: DbJobResponse[] = [];
  groups: any = [];
  files: File[] = [];
  fileUpload: any = [];
  moduleSurvey = true;
  importCollaboratorForm: FormGroup;
  groupInfo = { visible: false, description: null };
  tree_selected: FormControl;

  constructor(
    private jsonconector: ConectorJsonService,
    private generic: GenericCatalogsService,
    private job: ConnectorJobProfileService,
    private groupConnector: ConnectorGroupService,
    private form: FormBuilder,
    private dialog: MatDialog,
    private ngxCsvParser: NgxCsvParser,
    private translate: TranslateService
  ) {}
  async loadDataGroups() {
    try {
      const data = await this.groupConnector.group_tree().toPromise();
      this.groups = data;
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    let module = localStorage.getItem("module");

    if (module === "Survey") {
      this.moduleSurvey = false;
    }

    this.tree_selected = new FormControl();
    this.tree_selected.valueChanges.subscribe((e: any) => {
      this.saveGroup(e);
    });

    this.importCollaboratorForm = this.form.group({
      lengtest: module === "Honestidad" ? [null, Validators.required] : [0],
      country: [null, Validators.required],
      group: [0],
      jobProfile: [module === "Honestidad" ? null : 0],
      files: [[], this.fileRequired],
    });

    let dataSubscription = this.getLengTestAll().subscribe(
      (lentTest: DbLanguageResponse[]) => (this.lentTestList = lentTest)
    );
    this.subscriptions.push(dataSubscription);

    dataSubscription = this.getCountriesAll().subscribe(
      (countries: DbCountryResponse[]) => (this.countriesList = countries)
    );
    this.subscriptions.push(dataSubscription);

    dataSubscription = this.getJobProfilesAll().subscribe(
      (jobProfiles: DbJobResponse[]) => (this.jobProfileList = jobProfiles)
    );
    this.subscriptions.push(dataSubscription);

    this.loadDataGroups();
  }

  async submit(formdata) {
    const model_send = [];
    let resp1: any = await readFile(this.files);
    let itemList = resp1.result.split("\n");

    let lines = resp1.result.split("\r");
    let data = lines.map((line) => line.split(";"));
    let [isValid, msg] = this.isValidCSVData(data);
    if (!isValid) {
      Swal.fire({
        title: "Error!",
        text: msg,
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    itemList.map((item) => {
      let delimiter;
      if (item.indexOf(";") == -1) delimiter = ",";
      else delimiter = ";";
      if (item.indexOf("/") == -1 && item != "") {
        let x = item.split(delimiter);
        let model = {
          country: formdata.country,
          group: formdata.group == null ? 0 : formdata.group,
          jobProfile: formdata.jobProfile == null ? 0 : formdata.jobProfile,
          lengtest: formdata.lengtest == null ? 1 : formdata.lengtest,
          nickname: x[0],
          name: x[1],
          firstname: x[2],
          lastname: x[3],
          email: x[4],
          genre: x[5] == "M" ? 1 : x[5] == "F" ? 2 : 3,
        };
        model_send.push(model);
      }
    });
    this.groupConnector
      .import_users(model_send)
      .subscribe((res: ResponseHandler) => {
        console.log(res);
        if (res.ok === true) {
          this.submit_confirm(res.data);
        } else {
          Swal.fire("Error", res.msg, "error");
        }
      });
  }

  async submit_confirm(existEvaluateds) {
    const tableRows = JSON.parse(existEvaluateds).map((evaluated, index) => {
      return `
        <tr>
          <td style="border: 1px solid #ccc;
          text-align: left;
          padding: 8px;">${index + 1}</td>
          <td style="border: 1px solid #ccc;
          text-align: left;
          padding: 8px;"> ${evaluated.nickname}</td>
          <td style="border: 1px solid #ccc;
          text-align: left;
          padding: 8px;">${evaluated.evaluated}</td>
        </tr>
      `;
    });
    const tableHTML = `
    Evaluados importados, a excepción de los evaluados ya existentes:
    </br>
    <table style="font-family: arial, sans-serif; width: 100%;  border-collapse: collapse;">
      <thead>
        <tr>
          <th style="border: 1px solid #ccc;
          text-align: left;
          padding: 8px;">#</th>
          <th style="border: 1px solid #ccc;
          text-align: left;
          padding: 8px;">Id Evaluado</th>
          <th style="border: 1px solid #ccc;
          text-align: left;
          padding: 8px;">Nombre/Apellido</th>
        </tr>
      </thead>
      <tbody>
        ${tableRows.join("")}
      </tbody>
    </table>
  `;
    Swal.fire({
      title: await this.translate.get("success").toPromise(),
      text: await this.translate.get("importar_confirmacion").toPromise(),
      width: 800,
      html: tableHTML,
      icon: "success",
      showCancelButton: false,
      confirmButtonText: " Ok ",
    }).then((result) => {
      this.dialog.closeAll();
    });
  }

  saveGroup(action) {
    //this.importCollaboratorForm.get('group').setValue(action.id);
    this.importCollaboratorForm.value.group = action.id;

    // this.jsonconector.reporte_category_description(this.importCollaboratorForm.value).subscribe( (opts: ResponseHandler) => {
    //   console.info(opts.data);
    //   this.groupInfo = opts.data;
    // });
  }

  download(event) {
    // // Note: Ie and Edge don't support the new File constructor,
    // // so it's better to construct blobs and use saveAs(blob, filename)
    // var file = new File(["Hello, world!"], "hello world.txt", {type: "text/plain;charset=utf-8"});
    // FileSaver.saveAs(file);
  }

  async onSelect(event) {
    // Agregar visualmente
    this.files.push(...event.addedFiles);
    this.importCollaboratorForm.controls.files.setValue(event.addedFiles);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.importCollaboratorForm.controls.files.setValue(event.addedFiles);
  }

  private isValidCSVData(data: string[][]): [boolean, string] {
    const nonEmptyLines = data.filter(
      (line) => line.length > 1 || (line.length === 1 && line[0].trim() !== "")
    );
    if (nonEmptyLines.length <= 1) {
      return [false, "El archivo no contiene informacion"];
    }

    const firstColumnValues = new Set();
    for (let i = 1; i < nonEmptyLines.length; i++) {
      if (firstColumnValues.has(nonEmptyLines[i][0])) {
        return [
          false,
          `El archivo contiene identificadores duplicados: ${nonEmptyLines[i][0]}`,
        ];
      }
      firstColumnValues.add(nonEmptyLines[i][0]);
    }
    return [true, ""];
  }

  private fileRequired(control: AbstractControl): ValidationErrors | null {
    return control.value && control.value.length > 0
      ? null
      : { required: true };
  }
  private getJobProfilesAll(): Observable<DbJobResponse[]> {
    return this.mapResponseToData(this.job.getJobProfilesAll());
  }
  private getCountriesAll(): Observable<DbCountryResponse[]> {
    return this.mapResponseToData(this.generic.catalogCountries());
  }
  private getLengTestAll(): Observable<DbLanguageResponse[]> {
    return this.mapResponseToData(this.generic.catalogLengTest());
  }
  private mapResponseToData<T>(
    obs$: Observable<DbResponseHandler<T>>
  ): Observable<T> {
    return obs$.pipe(
      map((response: DbResponseHandler<T>) => {
        const { ok, data, msg } = response;
        return data;
      })
    );
  }
}

export const readFile = (file = {}, method = "readAsText") => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsText(file[0], "ASCII");
    reader.onload = () => {
      resolve(reader);
    };
    reader.onerror = (error) => reject(error);
  });
};
