import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import Swal from "sweetalert2";
import { DialogData } from "../../modals/link-jobprofile/link-jobprofile.component";
import { IntegrityReportService } from "../../../../services/integrity-report.service";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-modal-profile-report-config",
  templateUrl: "./modal-profile-report-config.component.html",
  styleUrls: ["./modal-profile-report-config.component.css"],
})
export class modalProfileReportConfig implements OnInit {
  openModal: boolean = true;
  formGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private report: IntegrityReportService,
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<modalProfileReportConfig>
  ) {
    this.formGroup = this._formBuilder.group({
      coverPage: [],
      competencies: [],
      personVsJobComparation: [],
      fundamentalConductes: [],
      brainDominance: [],
      conductualMap: [],
      coaching: [],
      trueIdentity: [],
    });
  }
  idEvaluation = 3;
  ngOnInit() {
    this.report
      .reportConfiguration(this.idEvaluation, null)
      .subscribe((response: any) => {
        const { ok, data, msg } = response;
        if (ok) {
          if (data.length <= 0) {
            this.formGroup = this._formBuilder.group({
              coverPage: [true],
              competencies: [true],
              personVsJobComparation: [true],
              fundamentalConductes: [true],
              brainDominance: [true],
              conductualMap: [true],
              coaching: [true],
              trueIdentity: [true],
            });
          } else {
            data.map((x) => {
              this.formGroup.controls[x.section].setValue(true);
            });
          }
        } else {
          Swal.fire("Error", msg, "error");
        }
      });
  }

  save() {
    const sections = [
      { id: 1, name: "coverPage", disabled: true },
      { id: 2, name: "competencies", disabled: true },
      { id: 3, name: "personVsJobComparation", disabled: true },
      { id: 4, name: "fundamentalConductes", disabled: true },
      { id: 5, name: "brainDominance", disabled: true },
      { id: 6, name: "conductualMap", disabled: true },
      { id: 7, name: "coaching", disabled: true },
      { id: 8, name: "trueIdentity", disabled: true },
    ];

    let listSelectedSection = [];

    sections.forEach((section) => {
      if (this.formGroup.controls[section.name].value) {
        let selectedSection = {
          id: section.id,
          name: section.name,
          selected: true,
          disabled: true,
        };
        listSelectedSection.push(selectedSection);
      }
    });

    this.report
      .reportConfiguration(this.idEvaluation, listSelectedSection)
      .subscribe((response: any) => {
        const { ok, data, msg } = response;
        if (ok) {
          Swal.fire("Buen trabajo !", "Configuración guardada", "success");
          this.dialogRef.close();
        } else {
          return Swal.fire("Error", msg, "error");
        }
      });
  }
}
