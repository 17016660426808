
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../services/authentication.service';
@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    constructor(private auth: AuthenticationService, private translate: TranslateService
    ) {
        super();
        this.setLanguageAndGetTranslation();
    }
    itemsPerPageLabel = '';
    nextPageLabel = '';
    previousPageLabel = '';
    firstPageLabel = '';
    lastPageLabel = '';
    tableOf = '';
    translations: { [key: string]: string } = {
        "table-footer-elements-page": "",
        "table-footer-next-page": "",
        "table-footer-previus-page": "",
        "table-footer-first-page": "",
        "table-footer-last-page": "",
        "table-footer-of": ""
    };
    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.tableOf} ${length}`;
        }
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.tableOf} ${length}`;
    }
    async setLanguageAndGetTranslation(): Promise<void> {
        const language = await this.auth.setLanguage();
        this.translate.setDefaultLang(language);
        const translationPromises = Object.keys(this.translations).map(key => {
            return new Promise<void>((resolve, reject) => {
                this.translate.get(key).subscribe((res: string) => {
                    this.translations[key] = res;
                    resolve();
                });
            });
        });

        await Promise.all(translationPromises);
        this.updateLabels();

    }

    updateLabels() {
        this.itemsPerPageLabel = this.translations['table-footer-elements-page'];
        this.nextPageLabel = this.translations['table-footer-next-page'];
        this.previousPageLabel = this.translations['table-footer-previus-page'];
        this.firstPageLabel = this.translations['table-footer-first-page'];
        this.lastPageLabel = this.translations['table-footer-last-page'];
        this.tableOf = this.translations['table-footer-of'];
        this.changes.next();
    }
}