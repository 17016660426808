import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { ConectorJsonService } from "../../../../services/conector-json.service";
import { ConectorModuleService } from "../../../../services/conector-module.service";

@Component({
  selector: "app-modal-children",
  templateUrl: "./modal-children.component.html",
  styleUrls: ["./modal-children.component.css"],
})
export class ModalChildrenComponent implements OnInit {
  children: any = [];
  parent: any = {};
  groupForm: FormGroup;
  length = 0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 15];
  isEditable = false;
  selected = null;

  permissionCreateSubgroup: boolean = true;
  permissionUpdateSubgroup: boolean = true;
  permissionDeleteSubgroup: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ModalChildrenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private conector: ConectorJsonService,
    private conectorModuleService: ConectorModuleService,
    private route: Router,

    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    const idModuleLibrary = this.getIdModuleLibrary();
    this.getFunctionalityPermissions(idModuleLibrary);
    this.groupForm = this.fb.group({
      parent: [0, [Validators.required]],
      name: [null, Validators.required],
      description: [null],
      visible: [true, Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    const { row } = this.data;
    this.parent = row;
    this.conector.groups_childs(row.id).subscribe((d: any) => {
      this.children = d.data;
    });
  }

  updateRows($events) {
    const { pageIndex, pageSize } = $events;

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.children = [];

    this.conector
      .groups_childs(this.parent.id, this.pageSize, this.pageIndex)
      .subscribe((x: any) => {
        this.children = x.data;
      });
  }

  onClick(formdata) {
    formdata.parent = this.parent.id;

    if (this.isEditable === false) {
      formdata.visible = formdata.visible.toString();
      formdata.description = formdata.name;

      this.conector.groups_post(formdata).subscribe((d: any) => {
        this.groupForm.reset();

        this.groupForm.controls["parent"].setValue(0);
        this.groupForm.controls["visible"].setValue("true");

        this.children.unshift(d.data);
        this.length++;
      });
    } else {
      Object.keys(formdata).forEach((key) => {
        this.selected[key] = formdata[key];
      });

      this.conector.groups_put(this.selected).subscribe((d) => {
        this.groupForm.reset();
        this.isEditable = false;
        this.groupForm.reset();
      });
    }
  }

  editRow(row) {
    this.isEditable = true;
    this.selected = row;
    Object.keys(this.groupForm.controls).forEach((key) => {
      this.groupForm.get(key).setValue(row[key]);
    });
  }

  async deleteRow(row) {
    Swal.fire({
      title: await this.translate.get("warning").toPromise(),
      icon: "warning",
      text: await this.translate.get("action-irreversible").toPromise(),
      showCancelButton: true,
      confirmButtonText: await this.translate.get("submit").toPromise(),
      cancelButtonText: await this.translate.get("Cancel").toPromise(),
    }).then((d: any) => {
      if (d.value === true) {
        this.conector.groups_delete(row.id).subscribe((x: any) => {
          const { ok, msg } = x;
          if (ok === true) {
            this.deleteConfirm();
            const { row } = this.data;
            this.parent = row;
            this.conector.groups_childs(row.id).subscribe((d: any) => {
              this.children = d.data;
            });
          } else {
            Swal.fire("Error", msg, "error");
          }
        });
      }
    });
  }

  async deleteConfirm() {
    Swal.fire(
      await this.translate.get("success").toPromise(),
      await this.translate.get("registro_eliminado").toPromise(),
      "success"
    );
  }

  getIdModuleLibrary() {
    const currentRoute = this.route.url;
    const routesString = localStorage.getItem("menu-sidebar");
    const routesArray = JSON.parse(routesString);
    const routeMatch = routesArray.find(
      (route) => route.route === currentRoute
    );
    return routeMatch.id;
  }
  getFunctionalityPermissions(idModuleLibrary: number) {
    this.conectorModuleService
      .getFunctionalityPermissionByModuleAndUser(idModuleLibrary)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          const result = JSON.parse(data);
          result.forEach((item) => {
            if (item.code === "createSubgroup") {
              this.permissionCreateSubgroup = item.havePermission;
            } else if (item.code === "updateSubgroup") {
              this.permissionUpdateSubgroup = item.havePermission;
            } else if (item.code === "deleteSubgroup") {
              this.permissionDeleteSubgroup = item.havePermission;
            }
          });
        } else {
          console.log("Error get functionality permissions");
        }
      });
  }
}
