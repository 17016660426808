import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RankingService } from "../../../../services/ranking.service";

@Component({
  selector: "app-comparative",
  templateUrl: "./comparative.component.html",
  styleUrls: ["./comparative.component.css"],
})
export class ComparativeComponent implements OnInit {
  displayedColumns1: string[] = [
    "name",
    "average",
    "integrity",
    "profiles",
    "brainDominance",
    "cognitiveSkills",
  ];
  displayedColumns2: string[] = ["name", "d", "i", "s", "c", "e"];
  displayedColumns3: string[] = ["name", "score", "ci", "range"];
  displayedColumns4: string[] = ["name", "value", "note"];
  evaluatedsId: number[];
  evaluateds: any;
  showSliders = false;
  slidersEnabled = [false, false, false];
  slidersValue = [50, 50, 50];
  profilesChart;
  cognitiveSkillsChart;
  integrityChart;
  expandedIndex = 0;
  evaluations: number[];

  constructor(private rankingService: RankingService, private router: Router) {
    if (!!!this.router.getCurrentNavigation().extras.state) {
      this.router.navigateByUrl("/manager/Ranking");
    }

    this.evaluatedsId =
      this.router.getCurrentNavigation().extras.state.evaluateds;
  }

  ngOnInit() {
    this.rankingService
      .getEvaluatedsRankingInformation(this.evaluatedsId)
      .subscribe((response) => {
        this.evaluateds = response["evaluateds"];
        this.evaluations = response["evaluations"];
        this.enableSliders();
        this.orderRanking();
        this.loadProfilesChart();
        this.loadCognitiveSkillsChart();
        this.loadIntegrityChart();
      });
  }

  orderRanking() {
    for (const evaluated of this.evaluateds) {
      evaluated.grade = this.getEvaluationsGrade(
        evaluated.profiles,
        evaluated.cognitiveSkills,
        evaluated.integrity
      );
    }
    this.evaluateds.sort((e1, e2) => {
      return e2.grade - e1.grade;
    });
  }

  getEvaluationsGrade(profiles, cognitiveSkills, integrity) {
    if (profiles === "NA") {
      profiles = 0;
    }
    if (cognitiveSkills === "NA") {
      cognitiveSkills = 0;
    }
    if (integrity === "NA") {
      integrity = 0;
    }

    const dividend =
      (profiles * this.slidersValue[0]) / 100 +
      (cognitiveSkills * this.slidersValue[1]) / 100 +
      (integrity * this.slidersValue[2]) / 100;

    const divisor =
      (this.slidersValue[0] * Number(this.slidersEnabled[0])) / 100 +
      (this.slidersValue[1] * Number(this.slidersEnabled[1])) / 100 +
      (this.slidersValue[2] * Number(this.slidersEnabled[2])) / 100;

    return dividend / divisor;
  }

  enableSliders() {
    this.slidersEnabled[0] = this.evaluations.includes(3);
    this.slidersEnabled[1] = this.evaluations.includes(4);
    this.slidersEnabled[2] = this.evaluations.includes(1);
    this.showSliders = this.slidersEnabled.filter((s) => s).length >= 2;
  }

  onSliderChange() {
    this.orderRanking();
  }

  loadProfilesChart() {
    const seriesProfile = [];
    const profilesChart = {
      chart: {
        type: "bar",
        height: 0,
      },
      title: {
        text: "Comparativo de Comportamientos/Competencias",
      },
      legend: { enabled: false },
      xAxis: {
        categories: [],
      },
      yAxis: {
        title: {
          text: null,
        },
        min: 0,
        max: 100,
        opposite: true,
      },
      plotOptions: {
        bar: {
          borderRadius: "25%",
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `<b> ${this.y}</b> - ${this.point.name}`;
            },
          },
          groupPadding: 0.1,
          pointWidth: 13,
        },
      },
      series: [],
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    };

    // adding categories
    for (let i = 0; i < this.evaluateds.length; i++) {
      const evaluated = this.evaluateds[i];
      for (let j = i; j < evaluated.profileCompetencies.length; j++) {
        if (
          profilesChart.xAxis.categories.indexOf(
            evaluated.profileCompetencies[j].name
          ) === -1
        ) {
          var colorCompetencie = evaluated.profileCompetencies[j].assignedJob
            ? "blue"
            : "red";
          profilesChart.xAxis.categories.push(
            `<span style="color: ${colorCompetencie};">${evaluated.profileCompetencies[j].name}</span>`
          );
        }
        if (!seriesProfile.includes(evaluated.profileCompetencies[j].name)) {
          seriesProfile.push(evaluated.profileCompetencies[j].name);
        }
      }
    }

    // adding series
    for (let i = 0; i < this.evaluateds.length; i++) {
      const evaluated = this.evaluateds[i];
      for (let j = 0; j < evaluated.profileCompetencies.length; j++) {
        profilesChart.series.push({
          type: "bar",
          data: [
            {
              x: j,
              y: evaluated.profileCompetencies[j].percentage,
              name: `${evaluated.fullName}`,
              color: this.getColorProfile(
                evaluated.profileCompetencies[j].percentage
              ),
            },
          ],
        });
      }
    }

    // sorting competencies
    for (
      let i = 0;
      i < Math.max(...profilesChart.series.map((s) => s.data[0].x));
      i++
    ) {
      for (let j = 0; j < profilesChart.series.length; j++) {
        for (let k = j + 1; k < profilesChart.series.length; k++) {
          if (
            profilesChart.series[j].data[0].x === i &&
            profilesChart.series[k].data[0].x === i &&
            profilesChart.series[j].data[0].y <
              profilesChart.series[k].data[0].y
          ) {
            const auxiliar = profilesChart.series[j];
            profilesChart.series[j] = profilesChart.series[k];
            profilesChart.series[k] = auxiliar;
          }
        }
      }
    }
    const hightChart = Math.max(seriesProfile.length, this.evaluateds.length);

    profilesChart.chart.height = hightChart * 100 + 900;
    //this.profilesChart = new Chart(profilesChart);

    // @ts-ignore
    Highcharts.chart("chart-profile", profilesChart);
  }

  loadCognitiveSkillsChart() {
    const cognitiveSkillsChart = {
      chart: {
        inverted: true,
        height: this.evaluateds.length * 100 + 800,
      },
      title: {
        text: "Comparativo Series Evaluadas (Habilidades Cognitivas)",
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [
          "Información",
          "Juicio",
          "Vocabulario",
          "Síntesis",
          "Concentración",
          "Análisis",
          "Abstración",
          "Planeación",
          "Organización",
          "Atención",
        ],
        labels: {
          useHTML: true,
          allowOverlap: true,
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        min: 0,
        max: 8,
        categories: ["", "DEF", "INF", "TMB", "TM", "TMA", "SUP", "SOB", ""],
        labels: {
          useHTML: true,
          style: {
            fontSize: "13px",
            fontWeight: "bold",
          },
        },
        opposite: true,
      },
      plotOptions: {
        series: {
          pointWidth: 10,
          groupPadding: 0.2,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.point.name}`;
            },
            y: -11,
            verticalAlign: "middle",
          },
        },
      },
      series: [],
      exporting: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },
    };

    for (let i = 0; i < this.evaluateds.length; i++) {
      if (this.evaluateds[i].cognitiveSkillsSeriesResults.length === 0) {
        continue;
      }
      for (
        let j = 0;
        j < this.evaluateds[i].cognitiveSkillsSeriesResults.length;
        j++
      ) {
        cognitiveSkillsChart.series.push({
          type: "bar",
          name: this.evaluateds[i].fullName,
          data: [
            {
              x: j,
              y: this.evaluateds[i].cognitiveSkillsSeriesResults[j].GraphValue,
              name: `${this.evaluateds[i].fullName}`,
              range: this.evaluateds[i].cognitiveSkillsSeriesResults[j].Range,
              color: this.evaluateds[i].cognitiveSkillsSeriesResults[j].Color,
            },
          ],
        });
      }
    }

    //this.cognitiveSkillsChart = new Chart(cognitiveSkillsChart);
    // @ts-ignore
    Highcharts.chart("chart-cognitiveSkillsChart", cognitiveSkillsChart);
  }

  loadIntegrityChart() {
    const integrityChart = {
      chart: {
        inverted: true,
        height: this.evaluateds.length * 200 + 800,
      },
      title: {
        text: "Comparativo Dimensiones (Honestidad)",
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: null,
        },
        categories: [],
        labels: {
          useHTML: true,
          allowOverlap: true,
        },
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      plotOptions: {
        series: {
          pointWidth: 10,
          groupPadding: 0.13,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `<b> ${this.y}</b> - ${this.point.name}`;
            },
            y: -11,
            verticalAlign: "middle",
          },
          turboThreshold: 10000,
        },
      },
      series: [],
      exporting: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },
    };

    const categories = [];

    // adding categories
    for (let i = 0; i < this.evaluateds.length; i++) {
      const evaluated = this.evaluateds[i];
      for (let j = i; j < evaluated.integrityInfoDimensions.length; j++) {
        if (
          categories.indexOf(evaluated.integrityInfoDimensions[j].name) === -1
        ) {
          categories.push(evaluated.integrityInfoDimensions[j].name);
        }
      }
    }
    integrityChart.xAxis.categories = categories;

    // adding series
    for (let i = 0; i < this.evaluateds.length && i < 5; i++) {
      const evaluated = this.evaluateds[i];
      for (let j = 0; j < evaluated.integrityInfoDimensions.length; j++) {
        integrityChart.series.push({
          type: "bar",
          data: [
            {
              x: categories.indexOf(evaluated.integrityInfoDimensions[j].name),
              y: evaluated.integrityInfoDimensions[j].value,
              name: `${evaluated.fullName}`,
              color: this.getColorIntegrity(
                evaluated.integrityInfoDimensions[j].value,
                evaluated.integrityInfoDimensions[j].r1,
                evaluated.integrityInfoDimensions[j].r2,
                evaluated.integrityInfoDimensions[j].r3,
                evaluated.integrityInfoDimensions[j].r4,
                evaluated.integrityInfoDimensions[j].r5
              ),
            },
          ],
        });
      }
    }

    for (
      let i = 0;
      i < Math.max(...integrityChart.series.map((s) => s.data[0].x));
      i++
    ) {
      for (let j = 0; j < integrityChart.series.length; j++) {
        for (let k = j + 1; k < integrityChart.series.length; k++) {
          if (
            integrityChart.series[j].data[0].x === i &&
            integrityChart.series[k].data[0].x === i &&
            integrityChart.series[j].data[0].y <
              integrityChart.series[k].data[0].y
          ) {
            const auxiliar = integrityChart.series[j];
            integrityChart.series[j] = integrityChart.series[k];
            integrityChart.series[k] = auxiliar;
          }
        }
      }
    }

    // this.integrityChart = new Chart(integrityChart);

    // @ts-ignore
    Highcharts.chart("chart-integrity", integrityChart);
  }
  getColorIntegrity(
    value: number,
    r1: number,
    r2: number,
    r3: number,
    r4: number,
    r5: number
  ) {
    if (value < r1) return "#B5102F";
    if (value < r2) return "#E04B24";
    if (value < r3) return "#F3AF1F";
    if (value < r4) return "#D9B730";
    if (value < r5) return "#8AB324";
    return "#2F8C2B";
  }
  getColorProfile(percentage: number) {
    if (percentage > 0 && percentage <= 20) return "#D9000C";
    if (percentage > 20 && percentage <= 40) return "#E04B25";
    if (percentage > 40 && percentage <= 60) return "#F3AF20";
    if (percentage > 60 && percentage <= 80) return "#89B324";
    if (percentage > 80 && percentage <= 100) return "#2F8C2A";
  }
}
