import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { WebCamComponent } from "ack-angular-webcam";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import Swal from "sweetalert2";

import { GenericCatalogsService } from "../../../services/GenericCatalogs.service";
import { ResponseHandler } from "./../../../interfaces/ResponseHandler";
import { ConectorJsonService } from "./../../../services/conector-json.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  @ViewChild("webcam", { static: false }) webcam: any;

  profileFrm: FormGroup;
  public destroy$: Subject<boolean> = new Subject<boolean>();
  webdata: any = {};
  lentTestList = [];
  countries = [];
  genres = [];
  languages = [];
  seniorityRangeList = [];
  typeContractRange = [];
  ageRangeList = [];
  seniorityRangeEnabled = false;
  typeContractRangeEnabled = false;
  ageRangeEnabled = false;
  identityTryeEnabled = false;
  mexEnabled = false;
  showCam: boolean = false;
  showDrop: boolean = false;
  base64: string = "/assets/images/anon.jpg";
  enableGender: false;
  enableDateOfBirth: false;
  evaluacionIniciada: false;
  containsBackgroundCheck: boolean = false;
  regexCURP =
    "^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$";
  publicFolder = environment.public;
  constructor(
    public dialog: MatDialog,
    private conector: ConectorJsonService,
    private conectorgeneric: GenericCatalogsService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.profileFrm = this.fb.group({
      birthday: [null],
      country: [null, Validators.required],
      email: [null, Validators.nullValidator],
      lengtest: [null, Validators.required],
      genre: [null, Validators.required],
      firstname: [null, Validators.required],
      lastname: [null, Validators.nullValidator],
      name: [null, Validators.required],
      nickname: [null, Validators.required],
      phone: [null],
      seniorityRange: [0],
      typeContract: [0],
      ageRange: [0],
      curp: [null, Validators.nullValidator],
      company: [null],
    });
  }

  ngOnInit() {
    this.conectorgeneric
      .catalogLengTest()
      .pipe(
        takeUntil(this.destroy$),
        map((res) => res.data)
      )
      .subscribe((res) => (this.lentTestList = res));

    this.conectorgeneric.ct_SeniorityRange().subscribe((response: any) => {
      const { data } = response;
      this.seniorityRangeList = data;
    });

    this.conectorgeneric.ct_TypeContract().subscribe((response: any) => {
      const { data } = response;
      this.typeContractRange = data;
    });

    this.conectorgeneric.ct_AgeRange().subscribe((response: any) => {
      const { data } = response;
      this.ageRangeList = data;
    });

    this.conector.getEvaluation_profile().subscribe((res: ResponseHandler) => {
      this.webdata = res.data;
      this.enableGender = res.data.complements.enableGender;
      this.enableDateOfBirth = res.data.complements.enableDateOfBirth;
      if (res.data.country === 9) {
        this.mexEnabled = true;
        this.containsBackgroundCheck = res.data.complements.evals.includes(12);
        if (this.containsBackgroundCheck) {
          this.profileFrm
            .get("email")
            .setValidators([Validators.required, Validators.email]);
          this.profileFrm
            .get("curp")
            .setValidators([
              Validators.required,
              Validators.pattern(this.regexCURP),
            ]);
          this.profileFrm.get("lastname").setValidators(Validators.required);
        } else {
          this.profileFrm.get("email").clearValidators();
          this.profileFrm.get("curp").clearValidators();
          this.profileFrm.get("lastname").clearValidators();
        }
        this.profileFrm.get("email").updateValueAndValidity();
        this.profileFrm.get("curp").updateValueAndValidity();
        this.profileFrm.get("lastname").updateValueAndValidity();
      } else {
        this.mexEnabled = false;
      }

      this.countries = this.webdata.complements.countries;
      this.genres = this.webdata.complements.genres;
      this.languages = this.webdata.complements.languages;
      if (!this.enableGender) {
        this.profileFrm.get("genre").setValue(3);
      }
      var isSurvey = res.data.complements.evals.find(
        (subm) => subm === 7 || subm === 8 || subm === 9
      );

      if (isSurvey === undefined) {
        this.seniorityRangeEnabled = false;
        this.typeContractRangeEnabled = false;
        this.ageRangeEnabled = false;
        this.identityTryeEnabled = true;
      } else {
        this.seniorityRangeEnabled = true;
        this.typeContractRangeEnabled = true;
        this.ageRangeEnabled = true;
        this.identityTryeEnabled = false;
      }

      Object.keys(this.profileFrm.value).forEach((item) => {
        if (this.webdata[item]) {
          this.profileFrm.get(item).setValue(this.webdata[item]);
        }
      });

      if (this.profileFrm.get("birthday").value == "0001-01-01T00:00:00") {
        this.profileFrm.get("birthday").setValue("");
      }

      this.profileFrm.get("nickname").disable();
      this.profileFrm.get("company").disable();

      this.evaluacionIniciada = this.webdata.complements.evalIni;
      if (res.data.complements.evalIni == true) {
        this.profileFrm.get("birthday").disable();
        this.profileFrm.get("country").disable();
        this.profileFrm.get("email").disable();
        this.profileFrm.get("lengtest").disable();
        this.profileFrm.get("genre").disable();
        this.profileFrm.get("firstname").disable();
        this.profileFrm.get("lastname").disable();
        this.profileFrm.get("name").disable();
        this.profileFrm.get("phone").disable();
        this.profileFrm.get("seniorityRange").disable();
        this.profileFrm.get("typeContract").disable();
        this.profileFrm.get("ageRange").disable();
        this.profileFrm.get("curp").disable();
      }

      this.profileFrm.updateValueAndValidity();
    });
  }

  update(formdata) {
    Swal.fire({
      icon: "warning",
      title: "Confirmación",
      text: "¿Los datos ingresados son correctos?",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Continuar",
      cancelButtonText: "Quiero verificar",
    }).then((result: any) => {
      if (result.isConfirmed == false) {
        return;
      }

      formdata.genre = this.enableGender ? formdata.genre : 3;
      formdata.language = String(formdata.language);
      //formdata.language = String(formdata.language) != 'undefined' ? String(formdata.language) : this.profileFrm.get("lengtest").value;

      if (formdata.birthday == "") {
        formdata.birthday = "0001-01-01T00:00:00";
      }

      if (this.evaluacionIniciada) {
        formdata.company = 0;
        formdata.nickname = null;
      }

      this.conector
        .putEvaluation_profile(formdata)
        .subscribe((res: ResponseHandler) => {
          if (res.ok == false) {
            return Swal.fire("Error", res.msg, "error");
          }

          this.router.navigateByUrl("/evaluations/survey");
        });
    });
  }

  openDialog(): void {
    this.enableWebCam();
  }
  onCamError(err) {
    console.log(err);
  }

  onCamSuccess(err) {
    console.log(err);
  }

  savePhoto(webcam: WebCamComponent) {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => {
          webcam
            .getBase64()
            .then((base) => {
              this.base64 = base;
              this.showCam = false;

              base = base.replace("data:image/png;base64,", "");
              base = base.replace("data:image/jpeg;base64,", "");
              this.conector
                .save_photo_profile({ idBase64: base })
                .subscribe((res) => {
                });
            })
            .catch(() => this.showCam = false);
        })
        .catch(() => {
          Swal.fire("Permiso de cámara denegado", "Por favor habilite los permisos de la cámara para tomar una foto", "error");
          this.showCam = false;
        });
    }
  }

  enableWebCam() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        this.showCam = true;
      })
      .catch(() => {
        Swal.fire(
          "Permiso de cámara denegado",
          "Por favor habilite los permisos de la cámara para tomar una foto",
          "error"
        );
        this.showCam = false;
      });
  }
  fileUpload(event) {
    try {
      const file = event.addedFiles[0];
      const validationTypeImage = this.validateTypeImage(file);
      if (validationTypeImage) {
        const validationImageWeight = this.validateWeightImage(file);
        if (validationImageWeight) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let res = reader.result.toString();
            this.base64 = res;
            res = res.replace("data:image/png;base64,", "");
            res = res.replace("data:image/jpeg;base64,", "");
            this.conector
              .save_photo_profile({ idBase64: res })
              .subscribe((res) => {
                this.showDrop = false;
              });
          };
        } else {
          Swal.fire({
            icon: "error",
            title: "Peso de imagen excedido.",
            text: "El peso de la imagen máximo debe de ser de 3MB",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Tipo de imagen no permitido.",
          text: "Se permite únicamente imagenes del tipo JPG, JPEG, y PNG",
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
  validateTypeImage(file) {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedTypes.includes(file.type)) {
      return false;
    }
    return true;
  }
  validateWeightImage(file) {
    const maxSizeInBytes = 3 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      return false;
    }
    return true;
  }
}
