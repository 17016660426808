import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import Swal from "sweetalert2";
import { DialogData } from "../../modals/link-jobprofile/link-jobprofile.component";
import { IntegrityReportService } from "../../../../services/integrity-report.service";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-modal-seleccion-report-config",
  templateUrl: "./modal-seleccion-report-config.component.html",
  styleUrls: ["./modal-seleccion-report-config.component.css"],
})
export class modalSeleccionReportConfig implements OnInit {
  openModal: boolean = true;
  formGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private report: IntegrityReportService,
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<modalSeleccionReportConfig>
  ) {
    this.formGroup = this._formBuilder.group({
      frontPage: [],
      dimensionHonesty: [],
      dimensionIncident: [],
      SampleResponsesInterviewGuide: [],
      AdditionalInformation: [],
      TrueIdentity: [],
      Comments: [],
      ownQuestionsAndAnswers: []
    });
  }
  idEvaluation = 1;
  ngOnInit() {
    this.report
      .reportConfiguration(this.idEvaluation, null)
      .subscribe((response: any) => {
        const { ok, data, msg } = response;
        if (ok === false) {
          return Swal.fire("Error", msg, "error");
        } else {
          if (data.length <= 0) {
            this.formGroup = this._formBuilder.group({
              frontPage: [true],
              dimensionHonesty: [true],
              dimensionIncident: [true],
              SampleResponsesInterviewGuide: [true],
              AdditionalInformation: [true],
              TrueIdentity: [true],
              Comments: [true],
              ownQuestionsAndAnswers:[true]
            });
          } else {
            data.map((x) => {
              this.formGroup.controls[x.section].setValue(true);
            });
          }
        }
      });
  }

  save() {
    const sections = [
      { id: 1, name: "frontPage", disabled: true },
      { id: 2, name: "dimensionHonesty", disabled: true },
      { id: 3, name: "dimensionIncident", disabled: true },
      { id: 4, name: "SampleResponsesInterviewGuide", disabled: true },
      { id: 5, name: "AdditionalInformation", disabled: true },
      { id: 6, name: "TrueIdentity", disabled: true },
      { id: 7, name: "Comments", disabled: true },
      { id: 8, name: "ownQuestionsAndAnswers", disabled: true }
    ];

    let listSelectedSection = [];

    sections.forEach((section) => {
      if (this.formGroup.controls[section.name].value) {
        let selectedSection = {
          id: section.id,
          name: section.name,
          selected: true,
          disabled: true,
        };
        listSelectedSection.push(selectedSection);
      }
    });

    this.report
      .reportConfiguration(this.idEvaluation, listSelectedSection)
      .subscribe((response: any) => {
        const { ok, data, msg } = response;
        if (ok) {
          Swal.fire("Buen trabajo !", "Configuración guardada", "success");
          this.dialogRef.close();
        } else {
          return Swal.fire("Error", msg, "error");
        }
      });
  }
}
