import Swal from 'sweetalert2';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {

  mobileQuery: MediaQueryList;
  dir = 'ltr';

  green: boolean;
  blue: boolean;

  dark: boolean;
  minisidebar: boolean;

  boxed: boolean;
  danger: boolean;

  showHide: boolean;
  url: string;

  sidebarOpened;
  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  moduleName: any = [];
  loginForm: FormGroup;

  makeHttp = false;

  constructor(
    private service: AuthenticationService,
    private translate: TranslateService,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    public fb: FormBuilder,
    public router: Router,
    private conector: ConectorJsonService
  ) {

    this.service.setLanguage().then( k => this.translate.setDefaultLang(k));

    this.loginForm = this.fb.group({
      uname: [null, Validators.required],
      password: [null, Validators.required]
    });

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnInit() {
  }

  validateTicket(e) {

    console.info('login', e);

    this.conector.report_auth(e).subscribe( (k: any) => {

      console.info('auth', k);

      const { ok, data, msg } = k;

      if ( ok === true && data !== null) {

        localStorage.setItem('token', data);

        this.router.navigateByUrl('/tracking/information');

      } else {
        Swal.fire('Error', 'Ticket or password not found', 'error');
      }

    });

  }

  onSubmit() {}

}
