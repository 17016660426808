import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Chart } from 'angular-highcharts';
import { BehavioralMapDistributionService } from '../../../../charts/services/behavioral-map-distribution.service';
export interface DialogData {
	competence: string;
	valuesCompetence: Array<Number>;
}
@Component({
	templateUrl: './behavioral-map.component.html',
	styleUrls: ['./behavioral-map.component.css'],
})
export class BehavioralMapComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<BehavioralMapComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private _behavioralMapDistributionService: BehavioralMapDistributionService
	) {}
	chartMapa: any = null;
	ngOnInit() {
		this.generateGraph();
	}

	generateGraph() {
		const values = this.data.valuesCompetence;
		this.chartMapa = this._behavioralMapDistributionService.generateChart({
			data: {
				relationship: Number(values[3]),
				pause: Number(values[2]),
				normsAdaptability: Number(values[1]),
				fightSpirit: Number(values[0]),
			},
			width: 500,
			height: 500,
		});
	}
}
