import { Router } from "@angular/router";
import { AuthenticationService } from "../../../services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { EvaInstructionsComponent } from "./eva-instructions/eva-instructions.component";
import { SharedCommunicationService } from "../../../services/shared-communication.service";
import Swal from "sweetalert2";
import { ConectorJsonService } from "../../../services/conector-json.service";
import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { WebcamInitError, WebcamUtil, WebcamImage } from "ngx-webcam";
import { Subject } from "rxjs";
import { EvaInstructionsGeneralComponent } from "./eva-instructions-general/eva-instructions-general.component";
import { WebCamComponent } from "ack-angular-webcam";
import * as moment from "moment";
@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.css"],
})
export class SurveyComponent implements OnInit {
  @ViewChild(EvaInstructionsComponent, { static: true })
  instructions: EvaInstructionsComponent;

  @ViewChild(EvaInstructionsGeneralComponent, { static: true })
  generalInstructions: EvaInstructionsGeneralComponent;

  // colocamos la etiqueta en false para poder condicionar con un ngIf
  @ViewChild("webcam", { static: false }) webcam: any;

  surveys: any = [];
  options: any;
  enableWebCam = false;
  selectedSurvey = {
    hasCamera: false,
    solved: null,
    sections: null,
    evaluationNumber: null,
    name: null,
  };
  section = null;
  numQuestion = "0/0";
  question = null;
  userip = null;
  factor = 0;
  base64: any = null;
  hasCamera = false;
  allowCam = false;
  contCamara = 0;
  minFactor = 200; // 340 papu
  multipleWebcamsAvailable = false;
  startDate: Date = null;
  trueIdentity: boolean = false;
  trueIdentityRequired: boolean = false;

  public showWebcam = true;
  public allowCameraSwitch = true;
  public deviceId: string;
  public facingMode = "environment";
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  constructor(
    private nz: NgZone,
    private conector: ConectorJsonService,
    private shared: SharedCommunicationService,
    public translate: TranslateService,
    private auth: AuthenticationService,
    private nav: Router
  ) {
    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
  }

  async ngOnInit() {
    this.userip = await this.conector.get_user_ip().toPromise();

    const { data }: any = await this.conector.getEvaluation().toPromise();
    let newEntryEvaluation = localStorage.getItem("newEntryEvaluation");
    if (newEntryEvaluation != null)
      localStorage.removeItem("newEntryEvaluation");

    this.surveys = data;

    if (this.surveys.length === 0) {
      return Swal.fire({
        title: "Información",
        text: "Evaluaciones finalizadas correctamente, da clic en el botón de salir.",
        icon: "info",
        confirmButtonText: "Salir",
        allowOutsideClick: false,
      }).then(() => this.log_out());
    }
  }

  async log_out() {
    localStorage.clear();
    await this.nav.navigateByUrl("/");
  }

  async abrirSurvey(row) {
    /*
          #rules generales
          Siempre mostrar instrucciones de la prueba

          #rules secciones
          en cada seccion mostrar instrucciones si tiene
          si no tiene evitar el modal
        */

    this.selectedSurvey = row;

    this.trueIdentity = this.selectedSurvey["trueIdentity"];
    this.trueIdentityRequired = this.selectedSurvey["trueIdentityRequired"];
    var typeEvaluation = this.selectedSurvey["typeEvaluation"];

    if (this.selectedSurvey.solved === true) {
      this.finalizaEvaluacion();
    } else {
      const idx = this.selectedSurvey.sections.findIndex(
        (s) => !s.hasOwnProperty("solved")
      );

      this.section = idx >= 0 ? this.selectedSurvey.sections[idx] : null;

      console.log(1110, "Next section", this.section);

      if (this.section === undefined || this.section === null) {
        this.finalizaEvaluacion();
      } else {
        this.generalInstructions.startLen(
          this.trueIdentity,
          this.trueIdentityRequired
        );
      }
    }
  }

  async confirmSection(ev: any) {
    console.log(163, ev);

    this.section.visible = true;

    //this.enableWebCam = this.showWebcam;

    this.genBase64(this.webcam, 0, this.enableWebCam);

    await this.getNextQuestion();
  }

  async finalizaEvaluacion() {
    Swal.fire({
      title: await this.translate.get("Completado").toPromise(),
      text: "La evaluación ha sido finalizada",
      icon: "success",
      allowOutsideClick: false,
    }).then(() => {
      this.surveys = [];

      this.shared.httpCall.next(true);

      this.conector.getEvaluation().subscribe(async (k: any) => {
        if (k.data.length === 0) {
          localStorage.clear();

          await this.nav.navigateByUrl("/");
        } else {
          window.location.reload();
        }
      });
    });
  }

  async NoFinalizaEvaluacion() {
    Swal.fire({
      title: await this.translate.get("Atención").toPromise(),
      text: "Los resultados no se guardaron correctamente. Por favor re-inicie el test y sólo se le presentarán las preguntas que hayan quedado pendientes.",
      icon: "warning",
      allowOutsideClick: false,
    }).then(() => {
      this.surveys = [];

      this.shared.httpCall.next(true);

      this.conector.getEvaluation().subscribe(async (k: any) => {
        if (k.data.length === 0) {
          localStorage.clear();

          await this.nav.navigateByUrl("/");
        } else {
          window.location.reload();
        }
      });
    });
  }

  getNextObject(group: any) {
    return group.find(
      (x) => x.solved === null || x.solved === undefined || x.solve === false
    );
  }

  async saveQuestion(ev) {
    let multi = [];

    var idRes =
      ev.idrespuesta === null ||
      this.question.type === 6 ||
      this.question.type === 7
        ? "0"
        : ev.idrespuesta;

    //this.enableWebCam = this.hasCamera;
    this.genBase64(this.webcam, 3, this.enableWebCam, idRes);
    //this.genBase64(this.webcam, 3, this.enableWebCam, ev.idrespuesta);

    const optionsFilter = [6, 7];

    if (optionsFilter.includes(this.question.type)) {
      multi = ev.idrespuesta;
    }

    const model = {
      IdQuestion: ev.idpregunta,
      IdEvaluation: this.selectedSurvey.evaluationNumber,
      Answer:
        ev.idrespuesta === null ||
        this.question.type === 6 ||
        this.question.type === 7
          ? "0"
          : ev.idrespuesta.toString(),
      hasAnswer: !(ev.idrespuesta === 0 || ev.idrespuesta === null),
      IpHostName: this.userip.data,
      MultipleAnswers: multi,
      RemainingTime: 0,
    };

    if (!!this.startDate) {
      model.RemainingTime = Math.ceil(
        Math.abs(new Date().getTime() - this.startDate.getTime()) / 1000
      );
    }

    if (
      this.selectedSurvey.evaluationNumber === null ||
      this.selectedSurvey.evaluationNumber === undefined
    ) {
      console.log("===== Entro al if =====");

      this.conector.postEvaluation(model).subscribe(async (r: any) => {
        console.log("r.msg");
        console.log(r.msg);

        if (r.msg === "Finalizada") {
          return this.finalizaEvaluacion();
        } else if (r.msg === "Invalidada") {
          return Swal.fire({
            title: "Información",
            text: await this.translate.get("evaluacion_invalida").toPromise(),
            icon: "info",
            allowOutsideClick: false,
          }).then(() => window.location.reload());
        }
      });
    } else {
      console.log("===== Entro al else =====");
      /*
            this.conector.fetchEvaluation(model);

            const idx = this.section.questions.findIndex(x => x.id === ev.idpregunta);

            this.section.questions[idx].solved = true;

            await this.getNextQuestion();
            */

      const found = this.section.questions.findIndex(
        (x) => !x.hasOwnProperty("solved")
      );
      const base_question = this.section.questions[found];

      const numero =
        this.section.questions.findIndex((x) => x === base_question) + 1;
      const total = this.section.questions.length;

      console.log(numero + "/" + total);

      if (numero < total) {
        if (this.selectedSurvey.name === "Dominancia Cerebral") {
          this.conector.postEvaluation(model).subscribe(async (r: any) => {
            if (r.msg === "Invalidada") {
              return Swal.fire({
                title: "Información",
                text: await this.translate
                  .get("evaluacion_invalida")
                  .toPromise(),
                icon: "info",
                allowOutsideClick: false,
              }).then(() => window.location.reload());
            }

            const idx = this.section.questions.findIndex(
              (x) => x.id === ev.idpregunta
            );

            this.section.questions[idx].solved = true;

            await this.getNextQuestion();
          });
        } else {
          this.conector.fetchEvaluation(model);

          const idx = this.section.questions.findIndex(x => x.id === ev.idpregunta);

          this.section.questions[idx].solved = true;

          await this.getNextQuestion();

          // // Enviamos la respuesta a la base de datos y esperamos la respuesta del servidor
          // this.conector
          //   .postEvaluation(model)
          //   .subscribe(async (response: any) => {
          //     // pintamos la respuesta que nos devuelve el server
          //     console.log("pregunta {0} - {1}", numero, response);

          //     const idx = this.section.questions.findIndex(
          //       (x) => x.id === ev.idpregunta
          //     );

          //     this.section.questions[idx].solved = true;

          //     await this.getNextQuestion();
          //   });
        }
      } else {
        Swal.fire({
          html: '<div><h2 style="text-align: center;"><strong>Procesando informaci&oacute;n</strong></h2><div><div style="text-align: center;">Por favor<strong> <span style="color: #ff0000;">NO CIERRE</span></strong> la ventana hasta que aparezca el mensaje confirmando que se han guardado los resultados</div></div></div>',
          // title: "Procesando información",
          // text: "Por favor NO CIERRE la ventana hasta que se aparezca el mensaje confirmando que se han guardado los resultados",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        this.conector.postEvaluation(model).subscribe(async (r: any) => {
          Swal.close();

          if (r.msg === "Sin terminar" && this.section.name != "Ejercicio" && this.selectedSurvey.name === "Honestidad Selección") {
            return this.NoFinalizaEvaluacion();
          }
          else if (r.msg === "Finalizada") {
            return this.finalizaEvaluacion();
          } else if (r.msg === "Invalidada") {
            return Swal.fire({
              title: "Información",
              text: await this.translate.get("evaluacion_invalida").toPromise(),
              icon: "info",
              allowOutsideClick: false,
            }).then(() => window.location.reload());
          }
          

          const idx = this.section.questions.findIndex(
            (x) => x.id === ev.idpregunta
          );

          this.section.questions[idx].solved = true;

          await this.getNextQuestion();
        });
      }
    }
  }

  async getNextQuestion() {
    // reinicia estado;
    this.shared.evaluationTimEnabler.next(false);

    // Limpia foto
    this.base64 = null;

    // Obtiene secccion
    // this.section = this.getNextObject(this.selectedSurvey.sections);

    if (this.section !== null && this.section !== undefined) {
      // pregunta sin resolver

      const found = this.section.questions.findIndex(
        (x) => !x.hasOwnProperty("solved")
      );

      // Verifica la session no ha sido resuelta

      if (found >= 0) {
        this.nz.run(() => {
          const base_question = this.section.questions[found];

          // numero de pregunta

          // total de preguntas
          this.numQuestion =
            this.section.questions.findIndex((x) => x === base_question) +
            1 +
            "/" +
            this.section.questions.length;

          // Desabilita el modal de tiempo
          base_question.enableTimer = false;

          // Clona respuestas
          const base_answers = base_question.answers;

          // Vacia respuestas
          base_question.answers = [];

          // Contabiliza las palabras de la pregunta
          const number_words = this.questionWords(base_question.name);

          // Establece primer medida
          let starTime = number_words * this.minFactor;

          if (Array.isArray(base_answers) && base_answers.length > 0) {
            console.log(308, base_answers);

            base_answers.forEach((e: any, idx: number, array: any[]) => {
              // Asigna bandera de ultimo item
              if (idx === array.length - 1) {
                e.lastItem = true;
              }

              // Aplica timmer
              setTimeout(() => {
                base_question.answers.push(e);
              }, starTime);

              // Tiempo de la siguiente pregunta + acumulado
              const next = this.questionWords(e.name) * this.minFactor;

              // Agrega tiempo
              starTime += next;
            });
          }

          // Asigna tiempos y pregunta
          this.question = base_question;

          // Toma screen
          //this.enableWebCam = this.hasCamera;
          this.genBase64(this.webcam, 1, this.enableWebCam);
        });
      } else {
        this.section.solved = true;

        await this.abrirSurvey(this.selectedSurvey);
      }
    } else {
      window.location.reload();
    }
  }

  async startSections(ev) {
    // ev.factor;
    var evresp = ev;
    this.factor = 2000;

    this.enableWebCam = this.hasCamera ? this.hasCamera : ev.allow;

    this.section = this.getNextObject(this.selectedSurvey.sections);

    // Cerrar en caso de undefined

    if (this.section === undefined || this.section === null) {
      this.finalizaEvaluacion();
    } else {
      this.section.hasCamera = this.hasCamera;

      // recibe la instruccion del checkbox

      if (
        this.section.instructions != null &&
        this.section.instructions.length > 0
      ) {
        this.instructions.startLen();

        this.section.visible = false;
      } else {
        this.section.visible = true;

        await this.getNextQuestion();
      }
    }
  }

  genBase64(
    webcam: WebCamComponent,
    momento = 0,
    enabled = false,
    qresponse = 0
  ) {
    if (enabled === false) {
      return;
    }

    webcam
      .getBase64()
      .then((base) => {
        this.base64 = base;

        const model = {
          idEvaluacion: this.selectedSurvey.evaluationNumber,
          idPregunta: this.question.id,
          idrespuesta: qresponse,
          idMomento: momento,
          idBase64: base.replace("data:image/png;base64,", ""),
        };

        this.conector.save_photo(model).subscribe((e) => console.log(e));
      })
      .catch((e) => console.error(e));
  }

  onCamError(err) {
    console.log(383, err);
  }

  onCamSuccess(err) {
    console.log(387, err);
  }

  savePhoto(ev) {
    //this.enableWebCam = this.hasCamera;
    this.genBase64(this.webcam, 2, this.enableWebCam);
    console.log(392, ev);
  }

  questionWords(ev: string) {
    return ev.match(/(\w+)/g).length;
  }

  async finishSection(ev: any) {
    if (this.selectedSurvey.name === "Habilidades Cognitivas") {
      const body = {
        sectionId: this.section.id,
        evaluationId: this.selectedSurvey.evaluationNumber,
      };

      this.conector.cognitiveSkillsTimeOut(body).subscribe(async (r: any) => {
        this.section.questions.forEach((x: any) => {
          x.solved = true;
        });
        await this.getNextQuestion();
      });
      return;
    }

    this.section.questions.forEach((x: any) => {
      x.solved = true;
    });

    await this.getNextQuestion();
  }

  setStartDate(event: Date) {
    this.startDate = event;
  }
}
