import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { OwnQuestionsServices } from "../../../../services/own-questions.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../../../services/authentication.service";

@Component({
  selector: "app-answers-own-questions",
  templateUrl: "./answers-own-questions.component.html",
  styleUrls: ["./answers-own-questions.component.css"],
})
export class AnswersOwnQuestionComponent implements OnInit {
  answerForm: FormGroup;
  questionId: number = 0;
  answers: any = [];
  displayedColumns: string[] = ["answer", "actions"];
  newRegister: boolean = true;

  constructor(
    public dialog: MatDialog,
    private form: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ownQuestionsServices: OwnQuestionsServices,
    public translate: TranslateService,
    public auth: AuthenticationService
  ) {
    this.initializateForm();
  }
  ngOnInit(): void {
    this.getAnswersByOwnQuestion();
  }
  initializateForm() {
    this.questionId = this.data;
    this.answerForm = this.form.group({
      IdAnswer: [null],
      IdQuestion: [this.questionId, [Validators.required]],
      Answer: [null, [Validators.required]],
    });
    this.newRegister = true;
  }

  getAnswersByOwnQuestion() {
    this.ownQuestionsServices
      .getAnswersByOwnQuestion(this.questionId)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          this.answers = data;
        } else {
          console.log("Error get answers by own question");
        }
      });
  }
  updateAnswer(element: Answer) {
    this.newRegister = false;
    this.answerForm = this.form.group({
      IdAnswer: [element.answerId],
      IdQuestion: [this.questionId, [Validators.required]],
      Answer: [element.answer, [Validators.required]],
    });
  }
  async deleteAnswer(element: Answer) {
    const title = await this.translate.get("advertencia").toPromise();
    const text = await this.translate.get("accionIrreversible").toPromise();
    Swal.fire({
      title: title,
      icon: "warning",
      text: text,
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "No",
    }).then((d: any) => {
      if (d.value) {
        this.ownQuestionsServices
          .deleteAnswerOwnQuestion(element.answerId)
          .subscribe((response: any) => {
            const { ok } = response;
            if (ok) {
              Swal.fire({
                title: "Success",
                text: "Eliminado correctamente.",
                icon: "success",
              });
              this.getAnswersByOwnQuestion();
            } else {
              Swal.fire("Error", "error");
            }
          });
      }
    });
  }
  save(answerForm) {
    if (this.newRegister) {
      this.ownQuestionsServices
        .createAnswer(answerForm.value)
        .subscribe((response: any) => {
          const { ok, data } = response;
          if (ok) {
            Swal.fire({
              title: "Respuesta agregada correctamente.",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Aceptar",
              reverseButtons: true,
            }).then((result) => {
              this.getAnswersByOwnQuestion();
              this.initializateForm();
            });
          } else {
            Swal.fire("Error", "error");
          }
        });
    } else {
      this.ownQuestionsServices
        .updateAnswerOwnQuestion(answerForm.value)
        .subscribe((response: any) => {
          const { ok, data } = response;
          if (ok) {
            Swal.fire({
              title: "Respuesta actualizada correctamente.",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Aceptar",
              reverseButtons: true,
            }).then((result) => {
              this.getAnswersByOwnQuestion();
              this.initializateForm();
            });
          } else {
            Swal.fire("Error", "error");
          }
        });
    }
  }
  cancelUpdate() {
    this.initializateForm();
  }
}
export interface Answer {
  answerId: number;
  questionId: number;
  answer: string;
}
