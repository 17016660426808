import { ConectorJsonService } from "./../../../../services/conector-json.service";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material";
import { ResponseHandler } from "../../../../interfaces/ResponseHandler";
import Swal from "sweetalert2";
import { SurveysAnswersComponent } from "../surveys-answers/surveys-answers.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-survey-create",
  templateUrl: "./survey-create.component.html",
  styleUrls: ["./survey-create.component.css"],
})
export class SurveyCreateComponent implements OnInit {
  @Output() savesurvey: EventEmitter<any> = new EventEmitter();
  @ViewChild(SurveysAnswersComponent, { static: false })
  answersComponent: SurveysAnswersComponent;
  @ViewChild("stepper", null) private myStepper: MatStepper;
  @Input() surveySelected: any = null;

  isLinear = true;
  surveyForm: FormGroup;
  lisToSurveys = [];
  enableQuestionsForm = false;

  baseSurvey: any = {};
  baseSections = [];
  baseQuestions = [];
  baseAnswers = [];
  questionTypes = [];
  currentSection: any = {};
  currentQuestion: any = {};
  lstEncuestas: any;
  formTypeSurvey: FormGroup;
  formCalendar: FormGroup;
  isNewSurvey: boolean = true;
  isConfigurated: boolean = false;
  idSurvey: number = 0;
  haveCompletedSurveys: boolean = false;

  constructor(
    private conector: ConectorJsonService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.surveyForm = this.fb.group({
      name: [null, Validators.required],
      instructions: [null, Validators.required],
      defaults: [false],
    });
  }

  ngOnInit() {
    this.isNewSurvey = this.surveySelected == null;
    if (this.isNewSurvey) {
      this.surveyForm = this.fb.group({
        name: [null, Validators.required],
        instructions: [null, Validators.required],
        defaults: [false],
      });
    } else {
      this.isConfigurated = this.surveySelected["isConfigurated"];
      this.haveCompletedSurveys = this.surveySelected["numberAnswered"] > 0;
      this.surveyForm = this.fb.group({
        name: [this.surveySelected["name"], Validators.required],
        instructions: [
          this.surveySelected["instructions"],
          Validators.required,
        ],
        defaults: [false],
      });
      if (this.haveCompletedSurveys) {
        this.surveyForm.get("name").disable();
        this.surveyForm.get("instructions").disable();
      }
    }
  }

  saveGeneralInformation(data) {
    if (this.isNewSurvey) {
      this.conector
        .post_eConfig(data)
        .subscribe(async (response: ResponseHandler) => {
          if (response.ok) {
            this.idSurvey = response.data;
            this.myStepper.next();
            this.getQuestionsType();
            this.setCurrentSurvey(this.idSurvey);
          }
        });
    } else {
      this.idSurvey = this.surveySelected["id"];
      data.id = this.idSurvey;
      this.conector
        .updateSurvey(data)
        .subscribe(async (response: ResponseHandler) => {
          if (response.ok) {
            this.myStepper.next();
            this.getQuestionsType();
            this.setCurrentSurvey(this.idSurvey);
          }
        });
    }
  }
  getQuestionsType() {
    this.conector.get_eConfigQuestionTypes().subscribe((r: ResponseHandler) => {
      this.questionTypes = r.data;
      Swal.close();
    });
  }

  getQuestions(ev) {
    this.conector
      .get_eConfigQuestions(ev.id)
      .subscribe((r: ResponseHandler) => {
        this.baseQuestions = r.data;
        this.currentSection = ev;
      });
  }
  async addSection(ev) {
    ev.IdTst = this.idSurvey;
    await this.conector.post_eConfigSections(this.idSurvey, ev).toPromise();
    this.conector
      .get_eConfingSections(this.idSurvey)
      .subscribe((r: ResponseHandler) => {
        this.baseSections = r.data;
      });
  }

  addQuestion(ev) {
    this.conector
      .post_eConfigQuestions(ev.IdSrs, ev)
      .subscribe((r: ResponseHandler) => {
        this.getQuestions(this.currentSection);
      });
  }

  addAnswer(ev) {
    ev.IdQstn = this.currentQuestion.id;
    ev.Weight = 0;

    this.conector
      .post_eConfigAnswers(ev.IdQstn, ev)
      .subscribe((r: ResponseHandler) => {
        this.openAnswers(this.currentQuestion);
      });
  }

  setCurrentSurvey(idSurvey) {
    this.conector
      .get_eConfingSections(idSurvey)
      .subscribe((r: ResponseHandler) => {
        this.baseSections = r.data;
      });
  }

  async updateQuestion(ev) {
    await this.conector
      .put_eConfigQuestions(ev.id, {
        id: ev.id,
        type: ev.typeId,
        name: ev.name,
      })
      .toPromise();
  }

  async updateSection(ev) {
    await this.conector.put_eConfigSection(ev.id, ev).toPromise();
  }

  openAnswers(data) {
    this.conector
      .get_eConfigAnswers(data.id)
      .subscribe((r: ResponseHandler) => {
        this.baseAnswers = r.data;
        this.currentQuestion = data;
        this.answersComponent.openModal = true;
      });
  }

  destroyQuestion(ev) {
    Swal.fire({
      icon: "warning",
      title: "&iquest; Está seguro de realizar esta acción ?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.conector
          .del_eConfigQuestion(ev.id)
          .subscribe((r: ResponseHandler) => {
            this.getQuestions(this.currentSection);
          });
      }
    });
  }

  deleteAnswer(ev) {
    Swal.fire({
      icon: "warning",
      title: "&iquest; Está seguro de realizar esta acción ?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.conector
          .del_eConfigAnswer(ev.id)
          .subscribe((r: ResponseHandler) => {
            this.openAnswers(this.currentQuestion);
          });
      }
    });
  }

  deleteSection(ev) {
    Swal.fire({
      icon: "warning",
      title: "&iquest; Está seguro de realizar esta acción ?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.conector
          .del_eConfigSections(ev.id)
          .subscribe((r: ResponseHandler) => {
            this.conector
              .get_eConfingSections(this.idSurvey)
              .subscribe((r: ResponseHandler) => {
                this.baseSections = r.data;
              });
          });
      }
    });
  }

  resetForm() {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(async () => {
        await this.router.navigate(["/manager/evaluation-surveys"]);
      });
  }

  get areAllInputsHadValue(): boolean {
    let submodule = this.formTypeSurvey.get("typeSurvey").value;
    return submodule;
  }
  confirm() {
    var title = this.isNewSurvey ? "Encuesta creada" : "Encuesta actualizada";
    Swal.fire({
      title: title,
      icon: "success",
      showCancelButton: false,
      confirmButtonText: " Aceptar ",
    }).then(() => {
      this.savesurvey.emit();
    });
  }
  exit() {
    this.savesurvey.emit();
  }
}
