import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ConnectorEthicsSurveyService } from '../../../../services/ethics-survey/conector-ethics-survey.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';
import { answerQuestionsSurveyConfig, answerUsers } from '../../ViewModel/config-model/config-model.component';
import { EvaluatedAnswerComponent } from '../evaluated-answer.component';
import {NavigationStart, Router, ActivatedRoute, Params} from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-ethics-survey-type-dialog',
  templateUrl: './ethics-survey-type-dialog.component.html',
})
export class ethics_survey_type_dialog implements OnInit {
  
  constructor(  
    public dialogRef: MatDialogRef<ethics_survey_type_dialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {

  }
}


