import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehavioralMapDistributionService } from '../../services/behavioral-map-distribution.service';
import { Chart } from 'angular-highcharts';
import { IBehavioralMapDistributionData } from '../../interfaces/IBehavioralMapDistributionData';
@Component({
	selector: 'chart-behavioral-map-distribution',
	templateUrl: './behavioral-map-distribution.component.html',
	styleUrls: ['./behavioral-map-distribution.component.css'],
})
export class BehavioralMapDistributionComponent implements OnInit, OnChanges {
	@Input() data: IBehavioralMapDistributionData;
	@Input() height: number = 300;
	@Input() width: number = 300;
	chart: Chart;
	constructor(private _behavioralMapDistributionService: BehavioralMapDistributionService) {}

	ngOnInit() {
		this.generateGraph();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if ('data' in changes || 'width' in changes || 'height' in changes) {
			this.generateGraph();
		}
	}
	private generateGraph() {
		this.chart = this._behavioralMapDistributionService.generateChart({
			data: this.data,
			width: this.width,
			height: this.height,
		});
	}
}
