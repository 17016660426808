import {ViewChild} from '@angular/core';
import {Component, OnInit} from '@angular/core';
import {MatSort} from '@angular/material';
import {MatTableDataSource} from '@angular/material';
import {MatDialog} from '@angular/material';
import {MatPaginator} from '@angular/material';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {AuthenticationService} from '../../../../services/authentication.service';
import {OwnCompetencyService} from '../../../../services/own-competency.service';

export interface OwnCompetency {
    id: number;
    name: string;
}

@Component({
    selector: 'app-own-comptency',
    templateUrl: './own-comptency.component.html',
    styleUrls: ['./own-comptency.component.css']
})
export class OwnComptencyComponent implements OnInit {
    displayedColumns: string[] = ['Name', 'Definition', 'actions'];
    dataSource: MatTableDataSource<OwnCompetency>;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    constructor(
        private conector: OwnCompetencyService,
        public dialog: MatDialog,
        private route: Router,
        public translate: TranslateService,
        public auth: AuthenticationService
    ) {
        this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
        this.dataSource = new MatTableDataSource([]);
    }

    ngOnInit() {
        this.getCompetencies();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    getCompetencies() {
        this.conector.getOwnComptencies()
        .subscribe((response: any) => {
            this.dataSource.data = response;
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    goToNewCompetency() {
        this.route.navigateByUrl('/manager/OwnCompetencies/Create');
    }

    goToEditCompetency(item) {
        this.route.navigateByUrl(`/manager/OwnCompetencies/Edit/${item.Id}`);
    }

    deleteCompetency(item) {
        this.conector.deleteOwnCompetency(item.Id)
        .subscribe(() => {
            Swal.fire({
                text: 'Competencia propia eliminada',
                icon: 'success',
                timer: 1300,
                showConfirmButton: false
            }).then(() => this.getCompetencies());
        }, this.showErrorMessage);
    }

    showErrorMessage(error: any) {
        console.error(error);
        Swal.fire('Error', 'Ha existido un error al eliminar la competencia propia', 'error');
    }
}
