import { EditorModule } from '@tinymce/tinymce-angular';
import { ManagerItems } from './manager-items';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HandlerTicketComponent } from '../../views/integriline/handler-ticket/handler-ticket.component';
import { HandlerHomeComponent } from '../../views/integriline/handler-home/handler-home.component';
import { PickersModule } from '../../views/shared/pickerLoader.module';
import { EstadisticasCuestionariosComponent } from '../../views/comunika/estadisticas-cuestionarios/estadisticas-cuestionarios.component';
import { EstadisticasEncuestasComponent } from '../../views/comunika/estadisticas-encuestas/estadisticas-encuestas.component';
import { EstadisticasEvaluacionesComponent } from '../../views/comunika/estadisticas-evaluaciones/estadisticas-evaluaciones.component';
import { EstadisticasActividadComponent } from '../../views/comunika/estadisticas-actividad/estadisticas-actividad.component';
import { EstadisticasUsuariosComponent } from '../../views/comunika/estadisticas-usuarios/estadisticas-usuarios.component';
import { EstadisticasGuardadosComponent } from '../../views/comunika/estadisticas-guardados/estadisticas-guardados.component';
import { EstadisticasArticulosComponent } from '../../views/comunika/estadisticas-articulos/estadisticas-articulos.component';
import { EstadisticasEventosComponent } from '../../views/comunika/estadisticas-eventos/estadisticas-eventos.component';
import { EstadisticasActivosComponent } from '../../views/comunika/estadisticas-activos/estadisticas-activos.component';
import { ConfiguracionUsuariosComponent } from '../../views/comunika/configuracion-usuarios/configuracion-usuarios.component';
import { ConfiguracionCategoriasComponent } from '../../views/comunika/configuracion-categorias/configuracion-categorias.component';
import { ConfiguracionEmpresaComponent } from '../../views/comunika/configuracion-empresa/configuracion-empresa.component';
import { ComunicaCuestionariosComponent } from '../../views/comunika/comunica-cuestionarios/comunica-cuestionarios.component';
import { ComunicaEncuestasComponent } from '../../views/comunika/comunica-encuestas/comunica-encuestas.component';
import { ComunicaEventosComponent } from '../../views/comunika/comunica-eventos/comunica-eventos.component';
import { ComunicaEvaluacionesComponent } from '../../views/comunika/comunica-evaluaciones/comunica-evaluaciones.component';
import { ComunicaArticulosComponent } from '../../views/comunika/comunica-articulos/comunica-articulos.component';
// import { IconPickerModule } from 'ngx-icon-picker';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { DemoMaterialModule } from '../../material-module';
import { GroupAddonsComponent } from '../../views/integriline/group-addons/group-addons.component';
import { QuestionsComponent } from '../../views/integriline/questions/questions.component';
import { RelationsComponent } from '../../views/integriline/relations/relations.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routes } from './manager.routing';
import { PermissionComponent } from '../../views/manager/permission/permission.component';
import { UsersComponent } from '../../views/manager/users/users.component';
import { GroupsComponent } from '../../views/manager/groups/groups.component';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule, MatStepperModule } from '@angular/material';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FlexModule } from '@angular/flex-layout';
import { CKEditorModule } from 'ckeditor4-angular';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AccountsComponent } from "../../views/admin/accounts/accounts.component";
import { HttpModule } from '@angular/http';

import { CcCrearComponent } from '../../views/comunika/comunica-cuestionarios/cc-crear/cc-crear.component';
import { CcRetroComponent } from '../../views/comunika/comunica-cuestionarios/cc-retro/cc-retro.component';
import { CcPreguntasComponent } from '../../views/comunika/comunica-cuestionarios/cc-preguntas/cc-preguntas.component';
import { IntegrityReportsComponent } from '../../views/manager/integrity-reports/integrity-reports.component';

import { NgxCsvParserModule } from 'ngx-csv-parser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

export const CUSTOM_DATE_TIME_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  imports: [
    SharedPipesModule,
    CommonModule,
    BrowserModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSliderModule,
    DemoMaterialModule,
    MatTreeModule,
    SharedPipesModule,
    CKEditorModule,
    // IconPickerModule,
    DragDropModule,
    HttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    FlexModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MaterialFileInputModule,
    PickersModule,
    SharedPipesModule,
    NgxDropzoneModule,
    NgxCsvParserModule,
    DragDropModule,
    MatDialogModule,
    EditorModule,
    MatStepperModule
  ],
  declarations: [
    AccountsComponent,
    UsersComponent,
    GroupsComponent,
    RelationsComponent,
    QuestionsComponent,
    GroupAddonsComponent,
    ComunicaArticulosComponent,
    ComunicaEvaluacionesComponent,
    ComunicaEventosComponent,
    ComunicaEncuestasComponent,
    ComunicaCuestionariosComponent,
    ConfiguracionEmpresaComponent,
    ConfiguracionCategoriasComponent,
    ConfiguracionUsuariosComponent,
    EstadisticasActivosComponent,
    EstadisticasEventosComponent,
    EstadisticasArticulosComponent,
    EstadisticasGuardadosComponent,
    EstadisticasUsuariosComponent,
    EstadisticasActividadComponent,
    EstadisticasEvaluacionesComponent,
    EstadisticasEncuestasComponent,
    EstadisticasCuestionariosComponent,
    HandlerHomeComponent,
    HandlerTicketComponent,
    CcCrearComponent,
    CcRetroComponent,
    CcPreguntasComponent,
    IntegrityReportsComponent,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    ManagerItems,
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }
  ],
  entryComponents: []
})

export class ManagerModule { }
