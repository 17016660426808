import {ResponseHandler} from './../../../interfaces/ResponseHandler';
import {SharedCommunicationService} from './../../../services/shared-communication.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from './../../../services/authentication.service';
import {ConectorJsonService} from './../../../services/conector-json.service';
import {Pagination} from './../../../models/pagination';
import {Component, OnInit, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts';
import {MatSort, MatSortable, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material';

export interface Dessert {
    id: number;
    criticity: string;
    created: string;
    relation: string;
    status: string;
  }

@Component({
    selector: 'app-handler-home',
    templateUrl: './handler-home.component.html',
    styleUrls: ['./handler-home.component.css']
})
export class HandlerHomeComponent implements OnInit {

    @ViewChild(MatSort, {static: false}) sort: MatSort;

    pagination = new Pagination();
    dataSource: any;
    chartist = [];
    chartist2 = [];
    records:any;
    sortedData: Dessert[];

    height = 400;
    type = 'pie2d';
    dataFormat = 'json';

    datas = {
        chart: {
            caption: '',
            plottooltext: '',
            showlegend: '1',
            showpercentvalues: '',
            legendposition: 'right',
            usedataplotcolorforlabels: '',
            theme: 'fusion'
        },
        data: []
    };

    public chartStatus: any = {
        chart: {
            type: 'pie'
        },
        title: {
            text: ''
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Status',
            data: [{
                name: 1,
                y: 2
            }]
        }]
    };

    public crityChart: any = {
        chart: {
            type: 'pie'
        },
        title: {
            text: ''
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Priority',
            data: [{
                name: 1,
                y: 2
            }]
        }]
    };

    constructor(private conector: ConectorJsonService, private auth: AuthenticationService, private translate: TranslateService, private shared: SharedCommunicationService) {

        this.dataSource = this.datas;

        this.auth.setLanguage().then(k => this.translate.setDefaultLang(k));

        this.pagination.length = 10;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
    }

    sortData(sort: Sort) {

        console.log(this.records);

        const data = this.records._data._value.slice();

        if (!sort.active || sort.direction === '') {
          this.sortedData = data;
          return;
        }
    
        this.sortedData = data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'id':
              return compare(a.id, b.id, isAsc);
            case 'criticality':
              return compare(a.criticality, b.criticality, isAsc);
            case 'created':
              return compare(a.created, b.created, isAsc);
            case 'relation':
              return compare(a.relation, b.relation, isAsc);
            case 'status':
              return compare(a.status, b.status, isAsc);
            default:
              return 0;
          }
        });
      }

    ngOnInit() {

        //this.conector.handler_chart().subscribe((d: ResponseHandler) => {
        this.conector.handler_tickets(0, 500).subscribe((d: any) => {

            const {ok, data, msg} = d;

            const res_translate = [];

            const series_mk = [];

            var dataChart = data[1];
            var criticalityChart = data[2];

            dataChart.forEach(async (item) => {

                this.translate.get(item.label).subscribe(k => {
                    item.label = k;
                    res_translate.push(item);
                });

                this.translate.get(item.label).subscribe(px => {
                    series_mk.push({name: px, y: item.value});
                });

            });

            this.chartist = res_translate;
            this.chartStatus.series[0].data = series_mk;
            Highcharts.chart('status', this.chartStatus);


            //---------------------------------------
            const series_lbl = [];
            const series_clt = [];

            criticalityChart.forEach(async (item) => {
                this.translate.get(item.label).subscribe(k => {
                    item.label = k;
                    series_lbl.push(item);
                });

                this.translate.get(item.label).subscribe(px => {
                    series_clt.push({name: px, y: item.value});
                });
            });

            this.chartist2 = series_lbl;
            this.crityChart.series[0].data = series_clt;
            Highcharts.chart('crityChart', this.crityChart);
            //-------------------------------------

            // this.conector.handler_criticalityChart().subscribe((x: ResponseHandler) => {

            //     const series_lbl = [];
            //     const series_clt = [];

            //     x.data.forEach(async (item) => {
            //         this.translate.get(item.label).subscribe(k => {
            //             item.label = k;
            //             series_lbl.push(item);
            //         });

            //         this.translate.get(item.label).subscribe(px => {
            //             series_clt.push({name: px, y: item.value});
            //         });
            //     });

            //     this.chartist2 = series_lbl;
            //     this.crityChart.series[0].data = series_clt;
            //     Highcharts.chart('crityChart', this.crityChart);
            // });
        });

        this.reload();
    }

    reload() {
        this.records = [];
        this.conector.handler_tickets(this.pagination.init, this.pagination.limit).subscribe((d: any) => {
            const {ok, data, msg} = d;

            this.pagination.length = msg;
            // this.pagination.pageSize = 10;
            // this.pagination.limit = 10;

            var dataTodo = data[0];
            dataTodo.forEach(async (item) => {

                this.translate.get(item.status).subscribe(k => {
                    item.status = k;
                });

                this.translate.get(item.criticality).subscribe(px => {
                    item.criticality = px;
                });

            });

            this.records = new MatTableDataSource(dataTodo);

            this.sortedData = this.records;
            
        });
    }

    changePage($event) {

        const {pageIndex, pageSize} = $event;

        this.pagination.length = 0;
        this.records = [];
        

        this.pagination.changePage($event);

        this.sortedData = this.records;

        this.conector.handler_tickets($event.pageIndex, $event.pageSize).subscribe((d: any) => {
            const {ok, data, msg} = d;
            this.pagination.length = msg;
            // this.pagination.pageSize = 10;
            // this.pagination.limit = 10;
            //this.records = data;

            this.records = new MatTableDataSource(data[0]);

            this.sortedData = this.records;
        });


    }

    getHumanDate(row) {
        return this.shared.dateToString(row, false);
    }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
