import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatCheckboxChange, MatDialog } from "@angular/material";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { ConnectorGroupService } from "../../../services/Admin/conector-group.service";
import { ConnectorCollaboratorService } from "../../../services/Collaborator/conector-collaborator.service";
import { GenericCatalogsService } from "../../../services/GenericCatalogs.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { ConectorJsonService } from "../../../services/conector-json.service";
import { ConectorModuleService } from "../../../services/conector-module.service";
import { ConnectorJobProfileService } from "../../../services/job-profile.service";
import IFormDataCollaborator from "../../../types/collaborator/form-data-collaborator";

@Component({
  selector: "app-create-collaborator",
  templateUrl: "./create-collaborator.component.html",
  styleUrls: ["./create-collaborator.component.css"],
})
export class CreateCollaboratorComponent implements OnInit {
  id = 0;
  evaluations = [];
  displayedColumns: string[] = ["selected", "name"];
  collaboratorForm: FormGroup;
  lentTestList = [];
  countriesList = [];
  nationalitiesList = [];
  genreList = [];
  jobProfileList = [];
  moduleSurvey = true;
  mexEnabled = false;
  identity: any = [];
  trueIdentity = false;
  true_Identity_requerido = 0;
  True_Identity = false;
  TI_Requerido = false;
  IdentityOptions: any = [];
  jobProfileSelect: any = [];
  enableGender: false;
  enableDateOfBirth: false;
  isEditingValue: boolean;
  haveCompletedEvaluations: boolean = false;

  public AllowParentSelection = true;
  public RestructureWhenChildSameName = false;
  public ShowFilter = true;
  public Disabled = false;
  public FilterPlaceholder = "Type here to filter elements...";
  public MaxDisplayed = 5;

  treeWords: FormControl;
  showTrueIdentity: boolean = false;
  optionRequiredValue: number = 2;
  groupsCategoryCompany: any = [];
  subgroupsOfSelectedGroup: any = [];

  constructor(
    public router: Router,
    private jsonconector: ConectorJsonService,
    private groupConnector: ConnectorGroupService,
    private conector: ConnectorCollaboratorService,
    private conectorModule: ConectorModuleService,
    private form: FormBuilder,
    private generic: GenericCatalogsService,
    private job: ConnectorJobProfileService,
    private dialog: MatDialog,
    private snap: ActivatedRoute,
    public translate: TranslateService,
    private auth: AuthenticationService
  ) {
    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));

    this.collaboratorForm = this.form.group({
      id: [0],
      username: [null, Validators.required],
      name: [null, Validators.required],
      firstname: [null, Validators.required],
      namesRegisteredBy: [null],
      lastname: [null],
      email: [null, [Validators.email]],
      phone: [null, [Validators.pattern("[0-9]{10}$")]],
      birthday: [null],
      registeredBy: [null],
      lengtest:
        localStorage.getItem("module") == "Honestidad"
          ? [null, Validators.required]
          : [0],
      country: [null, Validators.required],
      nationality: [0],
      genre: [0, Validators.required],
      groupId: [0],
      subgroupId: [0],
      rfc: [
        null,
        [
          Validators.pattern(
            "^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$"
          ),
        ],
      ],
      curp: [
        null,
        [
          Validators.pattern(
            "^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$"
          ),
        ],
      ],
      nss: [null],
      jobProfile:
        localStorage.getItem("module") == "Honestidad"
          ? [null, Validators.required]
          : [0],
      True_Identity: [false],
      TI_Requerido: [false],
      valueTIRequired: 1,
    });
  }

  async ngOnInit() {
    if (localStorage.getItem("module") == "Survey") {
      this.moduleSurvey = false;
    }
    this.treeWords = new FormControl();

    this.treeWords.valueChanges.subscribe((e: any) => {
      this.saveGroup(e);
    });

    this.snap.paramMap.subscribe(async (p: Params) => {
      this.id = p.params.id;
    });
    if (localStorage.getItem("idCountry") == "9") {
      this.mexEnabled = true;
    } else {
      this.mexEnabled = false;
    }
    if (this.id != 0) {
      this.conector.collaborator_getbyid(this.id).subscribe((response: any) => {
        if (response != null) {
          const { ok, data, msg } = response;
          if (data.groupId) {
            this.getSubgroupsOfSelectedGroup(data.groupId);
          }
          this.collaboratorForm.get("id").setValue(data.id);
          this.collaboratorForm.get("username").setValue(data.nickname);
          this.collaboratorForm.get("name").setValue(data.name);
          this.collaboratorForm.get("firstname").setValue(data.firstname);
          this.collaboratorForm.get("lastname").setValue(data.lastname);
          this.collaboratorForm
            .get("namesRegisteredBy")
            .setValue(data.namesRegisteredBy);
          this.collaboratorForm.get("email").setValue(data.email);
          this.collaboratorForm.get("phone").setValue(data.phone);
          this.collaboratorForm.get("birthday").setValue(data.birthday);
          this.collaboratorForm.get("lengtest").setValue(data.lengtest);
          this.collaboratorForm.get("country").setValue(data.country);
          this.collaboratorForm.get("nationality").setValue(data.nationality);
          this.collaboratorForm.get("genre").setValue(data.genre);
          this.collaboratorForm.get("groupId").setValue(data.groupId);
          this.collaboratorForm.get("subgroupId").setValue(data.subgroupId);
          this.collaboratorForm.get("jobProfile").setValue(data.jobProfile);
          this.collaboratorForm.get("rfc").setValue(data.rfc);
          this.collaboratorForm.get("curp").setValue(data.curp);
          this.collaboratorForm.get("nss").setValue(data.nss);
          this.collaboratorForm
            .get("True_Identity")
            .setValue(data.true_Identity);
          this.collaboratorForm.get("TI_Requerido").setValue(data.tI_Requerido);
          this.collaboratorForm
            .get("valueTIRequired")
            .setValue(data.tI_Requerido ? 1 : 2);

          //this.groupId = data.group;

          this.True_Identity = data.true_Identity;
          this.TI_Requerido = data.tI_Requerido;
          this.trueIdentity = data.true_Identity;
          this.showTrueIdentity = true;

          this.haveCompletedEvaluations = data.haveCompletedEvaluations;
          if (data.haveCompletedEvaluations) {
            this.collaboratorForm.disable();
          }
        }
      });
      this.isEditingValue = true;
    }

    this.generic.ct_LengTest().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.lentTestList = data;
    });

    this.generic.ct_Countries().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.countriesList = data;
    });

    this.generic.ct_Nationalities().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.nationalitiesList = data;
    });

    this.generic.ct_Genres().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.genreList = data;
    });

    this.job.jobprofile_all().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.jobProfileList = data;
    });
    //this.loadDataGroups();

    this.conectorModule.submodule_get(1, this.id).subscribe((response: any) => {
      const { data, msg } = response;
      this.evaluations = data.filter((f) => f.id != 2);
    });

    this.generic.identity_tree().subscribe((response: any) => {
      const { ok, data } = response;
      this.identity = data;
    });

    this.job.trueIdentityOptions().subscribe((response: any) => {
      this.IdentityOptions = response.data;
    });

    this.jsonconector.cfg_get().subscribe((response: any) => {
      const { ok, msg, data }: any = response;
      this.enableGender = data.enableGender;
      this.enableDateOfBirth = data.enableDateOfBirth;
    });
    this.groupConnector
      .getAllGroups()
      .subscribe((groups) => (this.groupsCategoryCompany = groups));
  }
  obtenerTIPuesto(job: any) {
    this.job.jobprofileById(job.value).subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.jobProfileSelect = data;

      this.True_Identity = this.jobProfileSelect.true_Identity;
      this.TI_Requerido = this.jobProfileSelect.tI_Requerido;

      this.collaboratorForm.get("True_Identity").setValue(this.True_Identity);
      this.trueIdentity = this.True_Identity;

      var option = this.True_Identity && this.TI_Requerido ? 1 : 2;
      this.collaboratorForm.get("valueTIRequired").setValue(option);

      this.showTrueIdentity = true;
    });
  }
  NumSeleccionados(row?: Submodule, evento?: any) {
    row.selected = evento.checked;
  }

  public saveCollaborator(formdata: IFormDataCollaborator): void {
    if (formdata.groupId != null || formdata.subgroupId != null) {
      formdata.groupId = formdata.subgroupId
        ? formdata.subgroupId
        : formdata.groupId;
    }
    formdata.lengtest = formdata.lengtest || 0;
    formdata.genre = this.enableGender ? formdata.genre : 3;
    formdata.id = this.id > 0 ? this.id : 0;
    formdata.TI_Requerido =
      formdata.True_Identity &&
        this.collaboratorForm.get("valueTIRequired").value == 1
        ? true
        : false;
    const action = this.id > 0 ? "collaborator_put" : "collaborator_post";
    this.conector[action](formdata).subscribe(async (response: any) => {
      const { ok, msg } = response;
      const icon = ok ? "success" : "error";
      const confirmButtonText = ok ? "Aceptar" : "Cerrar";
      const esTitle = await this.translate
        .get("ColaboradorActualizado")
        .toPromise();
      console.info("esTitle", esTitle);
      const title = ok ? esTitle : msg;

      Swal.fire({
        title,
        icon,
        showCancelButton: false,
        confirmButtonText,
      }).then(() => {
        if (ok) this.router.navigateByUrl("/manager/collaborators");
      });
    });
  }

  saveGroup(action: any) {
    this.collaboratorForm.value.group = action.id;

    // this.jsonconector.reporte_category_description(this.collaboratorForm.value).subscribe((opts: ResponseHandler) => {

    //   console.info('response', opts.data);

    //   this.groupInfo = opts.data;

    // });
  }

  enableTrueIdentity($event: MatCheckboxChange) {
    this.trueIdentity = $event.checked;
    this.True_Identity = this.trueIdentity;
  }

  opcionalRequerido(action: any) {
    this.trueIdentity = action.checked;
  }
  getTypeIdentityTrue(action: any) {
    this.collaboratorForm.get("valueTIRequired").setValue(action.value);
  }
  getSubgroupsOfSelectedGroup(seletedGroupId: number) {
    this.groupConnector
      .getAllSubGroupsByGroupId(seletedGroupId)
      .subscribe((subgroups) => (this.subgroupsOfSelectedGroup = subgroups));
  }
}

export interface Submodule {
  id: number;
  name: string;
  selected: boolean;
}

enum True_Identity {
  Sin_True_Identity = 1,
  Requerido_True_Identity = 2,
  Opcional_True_Identity = 3,
}
