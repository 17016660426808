import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-jobprofile-surveys',
  templateUrl: './jobprofile-surveys.component.html',
  styleUrls: ['./jobprofile-surveys.component.css']
})
export class JobprofileSurveysComponent implements OnInit {

  @Input() modules = [];
  @Input() isMetodAnalisisRiesgo: boolean;
  @Input() isMetodClasico: boolean;
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() stepBackConfigJobProfile: EventEmitter<any> = new EventEmitter();
  @Output() stepClassicalMethod: EventEmitter<any> = new EventEmitter();
  @Output() stepRiskAnalysis: EventEmitter<any> = new EventEmitter();
  @Output() checkEval: EventEmitter<any> = new EventEmitter();
  openModal:boolean = false;

  constructor() { }

  ngOnInit() {
    if(this.isMetodAnalisisRiesgo == true)
    {
      this.riskAnalysis();
    }
    if(this.isMetodClasico == true)
    {
      this.classicalMethod();
    }
    console.info("OPEN JP");
  }

  saveAction() {
    this.update.emit(this.modules);
  }

  riskAnalysis(){
    this.stepRiskAnalysis.emit();
  }

  classicalMethod(){
    this.stepClassicalMethod.emit();
  }

  check(row: any){
    var datos = row;
    if(row.idSubmodulo == '1')
    {
      this.checkEval.emit();
    }
    
  }

 

}
