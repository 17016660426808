import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { MatDialog, MatStepper } from "@angular/material";
import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ConnectorEthicsSurveyService } from "../../../../services/ethics-survey/conector-ethics-survey.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

import { ResponseHandler } from "../../../../interfaces/ResponseHandler";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Submit } from "./controller/submit";
import { Step3 } from "./controller/step3";

@Component({
  selector: "app-create-config",
  templateUrl: "./create-config.component.html",
  styleUrls: ["./create-config.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class CreateConfigComponent implements OnInit {
  //////////////////////////////////////////////////////////////////////
  //// GLOBALES /////////////////
  //////////////////////////////////////////////////////////////////////

  //// STEP FORM
  isLinear = true;
  // STEP 0  ============================ Tipo de Proyecto
  level1Current: number = 1.91;
  level1CurrentMax: string = ((this.level1Current * 100) / 4).toString();
  level2Current: number = 2.51;
  level2CurrentMax: string = ((this.level2Current * 100) / 4).toString();
  level3Current: number = 3.51;
  level3CurrentMax: string = ((this.level3Current * 100) / 4).toString();
  level4Current: number = 4;
  level4CurrentMax: string = ((this.level4Current * 100) / 4).toString();
  ceroFormGroup: FormGroup;
  // STEP 1  ============================ Tipo de Proyecto
  firstFormGroup: FormGroup;
  // STEP 2  ============================= Datos Generales
  secondFormGroup: FormGroup;
  // STEP 3  ============================= Asociar Usuarios
  thirdFormGroup: FormGroup;
  // STEP 4  ============================= Valores de la compañía
  quarterFormGroup: FormGroup;
  // STEP 5  ============================= Adapta el proyecto
  fifthFormGroup: FormGroup;
  // series
  seriesCategory = "0";
  // Preguntas
  questionsSeriesCategory = "0";
  questionsSeries = "0";
  // STEP 6  ============================= Configuración de respuestas
  sixFormGroup: FormGroup;
  // STEP 7  ============================= Finalizar
  sevenFormGroup: FormGroup;

  //// CONTROL VIEW
  title = "% module"; // Titulo inicial
  isShownEngagement: boolean = false; // var control view div hiden
  stepControl1: boolean = true; // var control view div hiden
  stepControl2: boolean = false; // var control view div hiden
  stepControl3: boolean = false; // var control view div hiden
  stepControl4: boolean = false; // var control view div hiden
  stepControl5: boolean = false; // var control view div hiden
  stepControl6: boolean = false; // var control view div hiden
  stepControl7: boolean = false; // var control view div hiden
  stepControl8: boolean = false; // var control view div hiden
  isDivEdit: boolean = false; // var control view div hiden
  //// VALIDA SUBMIT
  submitNom035Valid: boolean = false;
  submitValid: boolean = false;
  stateSubmit: boolean = true;
  //////////////////////////////////////////////////////////////////////
  //// STEP 3  ============================= Asociar Usuarios /////////////////
  //////////////////////////////////////////////////////////////////////
  displayedColumns: string[] = ["name", "count"];
  step3 = new Step3();
  treeControl = this.step3.treeControl;
  treeFlattener = this.step3.treeFlattener;
  dataSource = this.step3.dataSource;
  ///

  listGroupForm = []; // lista Grupos
  listUsersForm = []; // lista Grupos

  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private route: Router,
    private conector: ConnectorEthicsSurveyService,
    private controllerSubmit: Submit,
    public datepipe: DatePipe
  ) {
    // STEP 0  ============================= Datos Generales

    this.ceroFormGroup = this._formBuilder.group({
      level1: [this.level1Current],
      level2: [this.level2Current],
      level3: [this.level3Current],
      level4: [this.level4Current],
    });
    // STEP 1  ============================= Datos Generales
    this.firstFormGroup = this._formBuilder.group({
      stepFirstType: [""],
      stepSecondEngagement: [""],
    });
    // STEP 2  ============================= Datos Generales
    this.secondFormGroup = this._formBuilder.group({
      nameProyect: ["", Validators.required],
      nameCompany: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      description: ["", Validators.required],
      random: [""],
    });
    // STEP 3  ============================= Asociar Usuarios
    this.thirdFormGroup = this._formBuilder.group({
      item: ["", Validators.required],
      groups: this._formBuilder.array([]),
      evaluated: this._formBuilder.array([]),
    });
    // STEP 4  ============================= Valores de la compañía
    this.quarterFormGroup = this._formBuilder.group({
      questions: this._formBuilder.array([
        this._formBuilder.group({
          question : ['',Validators.required],
          answer : ['',Validators.required]
        }),
      ]),
    });


    // STEP 5  ============================= Adapta el proyecto
    this.fifthFormGroup = this._formBuilder.group({
      categorys: this._formBuilder.array([
        this._formBuilder.group({
          id: [""],
          name: [""],
          status: [""],
        }),
      ]),
      newCategory: [""],

      series: this._formBuilder.array([
        this._formBuilder.group({
          id: [""],
          idCategory: [""],
          name: [""],
          status: [""],
          idSeries: [""],
          isNewEngagement: [""],
        }),
      ]),
      newSerie: [""],
      newSerieCategory: [""],
      newSeriesQuestions: [""],
      newQuestionsCategory: [""],

      questions: this._formBuilder.array([
        this._formBuilder.group({
          id: [""],
          name: [""],
          status: [""],
          idSeries: [""],
          idCatQuestions: [""],
          idFactors: [""],
          GroupFactor: [""],
          idrelacion: [""],
          isNewEngagement: [""],
        }),
      ]),
      newNameQuestions: [""],

      questionsTypes: this._formBuilder.array([
        this._formBuilder.group({
          id: [""],
          typeid: [""],
          section1: [""],
          section2: [""],
          section3: [""],
          description: [""],
          orderlist: [""],
          active: [""],
        }),
      ]),
      newQuestionsType: [""],
    });
    // STEP 6  ============================= Configuración de respuestas
    this.sixFormGroup = this._formBuilder.group({
      questions: this._formBuilder.array([
        this._formBuilder.group({ id: [""], question: [""], answer: [""] }),
      ]),
    });
    // STEP 7  ============================= Finalizar
    this.sevenFormGroup = this._formBuilder.group({});

    //////////////////////////////////////////////////////////////////////
    //// STEP 3  ============================= Asociar Usuarios /////////////////
    //////////////////////////////////////////////////////////////////////
    this.conector.tree_category(2).subscribe((k: ResponseHandler) => {
      this.dataSource.data = k.data;
    });
  }

  ngOnInit() {
    this.categorySurveyGet();
    this.seriesSurveyGet();
    this.qestionsSurveyGet();
    this.questionsTypeGet();
    this.questionsWorkingEnvironmentGet();
    const questionsArray = this.sixFormGroup.get("questions") as FormArray;
    questionsArray.valueChanges.subscribe(() => {
      this.checkRadioInputs();
    });
  }

  //////////////////////////////////////////////////////////////////////
  //// GLOBALES /////////////////
  //////////////////////////////////////////////////////////////////////
  submit() {
    let entity = this.controllerSubmit.submitPsot(this);

    if (entity == null) {
      this.stateSubmit = true;
      return Swal.fire("Error", "Esta Configuración ya se Guardo ", "error");
    } else {
      if (this.firstFormGroup.get("stepFirstType").value == "2") {
        if (this.submitNom035Valid == false) {
          this.conector.postNom035(entity).subscribe(
            (response: any) => {
              const { ok, data, msg } = response;
              if (ok === false) {
                var MsgError = "";
                for (var k in data) {
                  MsgError =
                    MsgError +
                    "Error en " +
                    data[k].cam +
                    " ( " +
                    data[k].msg +
                    " ) \n";
                }
                return Swal.fire("Error", MsgError, "error");
              } else {
                data.submitNom035Valid = true;
                data.stateSubmit = true;
                Swal.fire("Buen trabajo!", "Configuración Guardada", "success");
                data.route.navigate(["manager/Survey"]);
              }
            },
            (err) => {
              this.stateSubmit = true;
              return Swal.fire(
                "Error",
                err.status + " " + err.statusText,
                "error"
              );
            }
          );
        } else {
          this.stateSubmit = true;
          return Swal.fire(
            "Error",
            "Esta Configuración ya se Guardo ",
            "error"
          );
        }
      } else {
        if (this.submitValid == false) {
          this.conector.post(entity).subscribe(
            (response: any) => {
              const { ok, data, msg } = response;
              if (ok === false) {
                var MsgError = "";
                for (var k in data) {
                  MsgError =
                    MsgError +
                    "Error en " +
                    data[k].cam +
                    " ( " +
                    data[k].msg +
                    " ) \n";
                }
                this.stateSubmit = true;
                return Swal.fire("Error", MsgError, "error");
              } else {
                this.submitValid = true;
                this.stateSubmit = true;
                Swal.fire(
                  "Buen trabajo !",
                  "Configuración guardada",
                  "success"
                );
                this.route.navigate(["manager/Survey"]);
              }
            },
            (err) => {
              this.stateSubmit = true;
              return Swal.fire(
                "Error",
                err.status + " " + err.statusText,
                "error"
              );
            }
          );
        } else {
          this.stateSubmit = true;
          return Swal.fire(
            "Error",
            "Esta Configuración ya se Guardo ",
            "error"
          );
        }
      }
    }
  }

  stepperPrevious(stepper: MatStepper, idStepper) {
    const control = this.firstFormGroup.value;
    switch (idStepper) {
      case 2:
        this.stepControl1 = true;
        stepper.previous();
        break;

      case 3:
        this.stepControl2 = true;
        stepper.previous();
        break;

      case 4:
        this.stepControl3 = true;
        stepper.previous();
        break;

      case 5:
        this.stepControl4 = true;
        stepper.previous();
        break;

      //  if (control.stepSecondEngagement == "1") {
      //    this.stepControl4 = true;
      //    stepper.previous();
      // } else if (control.stepSecondEngagement == "2") {
      //   this.stepControl5 = true;
      //   stepper.previous();
      // } else if (control.stepSecondEngagement == "3") {
      // }
      // break;

      case 6:
        if (control.stepSecondEngagement == "1") {
          this.stepControl4 = true;
          stepper.previous();
        } else if (control.stepSecondEngagement == "2") {
          this.stepControl5 = true;
          stepper.previous();
        } else if (control.stepSecondEngagement == "3") {
        }

        break;

      case 7:
        if (control.stepSecondEngagement == "1") {
          this.stepControl5 = true;
          stepper.previous();
        } else if (control.stepSecondEngagement == "2") {
          this.stepControl6 = true;
          stepper.previous();
        } else if (control.stepSecondEngagement == "3") {
          this.stepControl3 = true;
          stepper.previous();
        }
        break;
      case 8:
        if (control.stepFirstType == "1") {
          if (control.stepSecondEngagement == "1") {
            this.stepControl7 = true;
            stepper.previous();
          } else if (control.stepSecondEngagement == "2") {
            this.stepControl7 = true;
            stepper.previous();
          } else if (control.stepSecondEngagement == "3") {
            this.stepControl5 = true;
            stepper.previous();
          }
        } else {
          this.stepControl3 = true;
          stepper.previous();
        }
        break;
      default:
        break;
    }
  }

  stepperNext(stepper: MatStepper, idStepper) {
    switch (idStepper) {
      case 6:
        this.stepControl6 = false;
        this.stepControl5 = false;
        break;

      case 3:
        this.stepControl3 = false;
        break;

      default:
        break;
    }
  }

  //////////////////////////////////////////////////////////////////////
  //// STEP 0  ============================ config de Proyecto /////////////////
  //////////////////////////////////////////////////////////////////////

  change(level: number) {
    let MaxValue: number = this.ceroFormGroup.get("level4").value;

    let valueLevel1 = this.ceroFormGroup.get("level1").value;
    this.level1Current = valueLevel1;
    this.level1CurrentMax = ((valueLevel1 * 100) / MaxValue).toString();

    let valueLevel2 = this.ceroFormGroup.get("level2");
    this.level2Current = valueLevel2.value;
    if (valueLevel2.value < this.ceroFormGroup.get("level1").value) {
      valueLevel2.setValue(this.level2Current);
      return Swal.fire("Error", "El valor debe ser mayor a CORREGIR", "error");
    } else {
      this.level2CurrentMax = ((valueLevel2.value * 100) / MaxValue).toString();
    }

    let valueLevel3 = this.ceroFormGroup.get("level3");
    this.level3Current = valueLevel3.value;
    if (valueLevel3.value < this.ceroFormGroup.get("level2").value) {
      valueLevel3.setValue(this.level3Current);
      Swal.fire("Error", "El valor debe ser mayor a REVISAR", "error");
    } else {
      this.level3CurrentMax = ((valueLevel3.value * 100) / MaxValue).toString();
    }

    let valueLevel4 = this.ceroFormGroup.get("level4");
    this.level4Current = valueLevel4.value;
    if (valueLevel4.value < this.ceroFormGroup.get("level3").value) {
      valueLevel4.setValue(this.level4Current);
      return Swal.fire("Error", "El valor debe ser mayor a REFORZAR", "error");
    } else {
      this.level4CurrentMax = ((valueLevel4.value * 100) / MaxValue).toString();
    }
  }

  //////////////////////////////////////////////////////////////////////
  //// STEP 1  ============================ Tipo de Proyecto /////////////////
  //////////////////////////////////////////////////////////////////////

  // change type proyect  stepper 1

  onChangeType(deviceValue) {
    if (deviceValue == 1) this.isShownEngagement = true;
    else this.isShownEngagement = false;
  }
  // MUESTA LOS STEP
  validarStepperfirst(stepper: MatStepper) {
    const control = this.firstFormGroup.value;
    console.log(control);
    if (control.stepFirstType == "1") {
      if (control.stepSecondEngagement != "") {
        if (control.stepSecondEngagement == "2") {
          this.stepControl1 = true;
          this.stepControl2 = true;
          this.stepControl1 = false;
          this.stepControl3 = true;
          this.stepControl4 = true;
          this.stepControl5 = true;
          this.stepControl6 = true;
          this.stepControl7 = true;
          this.stepControl8 = true;
        } else if (control.stepSecondEngagement == "1") {
          this.stepControl1 = true;
          this.stepControl2 = true;
          this.stepControl3 = true;
          this.stepControl4 = true;
          this.stepControl5 = true;
          this.stepControl6 = false;
          this.stepControl7 = true;
          this.stepControl8 = true;
        } else if (control.stepSecondEngagement == "3") {
          this.stepControl1 = true;
          this.stepControl2 = true;
          this.stepControl3 = true;
          this.stepControl4 = true;
          this.stepControl5 = false;
          this.stepControl6 = true;
          this.stepControl7 = false;
          this.stepControl8 = true;
        }
        this.stepControl1 = false;
      } else {
        this.firstFormGroup.value.stepSecondEngagement = "";
      }
    } else if (control.stepFirstType == "2") {
      this.stepControl2 = true;
      this.stepControl3 = false;
      this.stepControl4 = true;
      this.stepControl5 = false;
      this.stepControl6 = false;
      this.stepControl7 = false;
      this.stepControl8 = true;
      this.stepControl1 = false;
    } else {
      this.firstFormGroup.value.stepFirstType = "";
    }
  }

  //////////////////////////////////////////////////////////////////////
  //// STEP 2  ============================= Datos Generales /////////////////
  //////////////////////////////////////////////////////////////////////

  validarStepperSecond(stepper: MatStepper) {
    const control = this.secondFormGroup.value;
    var startDateParse = new Date(control.startDate);
    var endDateParse = new Date(control.endDate);

    startDateParse.setDate(startDateParse.getDate() + 1);
    endDateParse.setDate(endDateParse.getDate() + 1);
    var currentDayParse = new Date();

    startDateParse.setHours(0);
    startDateParse.setMilliseconds(0);
    startDateParse.setMinutes(0);
    startDateParse.setSeconds(0);

    endDateParse.setHours(0);
    endDateParse.setMilliseconds(0);
    endDateParse.setMinutes(0);
    endDateParse.setSeconds(0);

    currentDayParse.setHours(0);
    currentDayParse.setMilliseconds(0);
    currentDayParse.setMinutes(0);
    currentDayParse.setSeconds(0);

    if (this.secondFormGroup.status == "VALID") {
      if (startDateParse > endDateParse) {
        return Swal.fire(
          "Error",
          "La fecha de inicio no puede ser mayor a la fecha de finalizacion de la encuesta  ",
          "error"
        );
      } else if (startDateParse < currentDayParse) {
        return Swal.fire(
          "Error",
          "La fecha de inicio no puede ser menor a la fecha actual  ",
          "error"
        );
      } else {
        //stepper.next();
        this.stepControl2 = false;
      }
    }
  }

  //////////////////////////////////////////////////////////////////////
  //// STEP 3  ============================= Asociar Usuarios /////////////////
  //////////////////////////////////////////////////////////////////////
  isUserSelected = false;
  validarStepperGroupUsers(stepper: MatStepper) {
    const control = this.listUsersForm.filter((x) => x.status == true).length;
    if (control == 0) {
     this.isUserSelected = false;
    } else {
      this.thirdFormGroup.controls["item"].setValue("1");
      this.stepControl3 = false; // var control view div hiden
      this.stepControl4 = false;
    }
  }
  getGroups(id) {
    const resultado = this.listGroupForm.find((item) => item.idGroup === id);

    if (resultado == undefined) {
      // So no lo encuentra agrega a la lista

      this.listGroupForm.push({ idGroup: id, status: true });

      this.conector.GetUsersGroup(id).subscribe((response: any) => {
        const { ok, data, msg } = response;
        if (ok === false) {
          return Swal.fire("Error", msg, "error");
        } else {
          for (var key in response.data) {
            this.listUsersForm.push({
              id: response.data[key].id,
              idGroup: id,
              status: false,
              name: response.data[key].name,
              firstname: response.data[key].firstname,
              lastname: response.data[key].lastname,
              nickname: response.data[key].nickname,
              phone: response.data[key].phone,
              email: response.data[key].email,
              groups: response.data[key].group,
              namegroup: response.data[key].namegroup,
            });
          }
        }
      });
    } else {
      // si lo encuentra elimnina de la lista
      this.listGroupForm = this.listGroupForm.filter(
        (item) => item.idGroup !== id
      );
      this.listUsersForm = this.listUsersForm.filter(
        (item) => item.idGroup !== id
      );
    }
    this.checkUserSelection();
  }
  getUsersGroup(id, idGroup) {
    const resultado = this.listUsersForm.find((item) => item.id === id);
    if (resultado == undefined) {
      this.listUsersForm.push({ id: id, idGroup: idGroup, status: true });
    } else {
      // this.listUsersForm = this.listUsersForm.filter( item => item.id !== id);
      if (resultado.status == false) {
        resultado.status = true;
      } else {
        resultado.status = false;
      }
    }
    this.checkUserSelection();
  }
  changeCheckGroup(idGroup) {
    let search = this.listGroupForm.find((X) => X.idGroup === idGroup);
    if (search) {
      return true;
    } else {
      return false;
    }
  }
  changeCheckGroupUser(idUser) {
    let search = this.listUsersForm.find((item) => item.id === idUser);
    if (search.status == false) {
      return false;
    } else {
      return true;
    }
  }
  checkUserSelection() {
    this.isUserSelected = this.listUsersForm.some(item => item.status);
  }
  

  //////////////////////////////////////////////////////////////////////
  //// STEP 4  ============================= Valores de la compañía /////////////////
  //////////////////////////////////////////////////////////////////////
  get getQuestionsQuarter() {
    return this.quarterFormGroup.get("questions") as FormArray;
  }

  addQuestionsQuarter(type: number = 0) {
    const control = <FormArray>this.quarterFormGroup.controls["questions"];
    let controlValue: any = {};

    if (type == 0) {
      control.push(
        this._formBuilder.group({
          question: ["", Validators.required],
          answer: ["", Validators.required],
        })
      );
    }
    if (control.status === "VALID") {
      controlValue = control.value[control.value.length - 1];
      let arrayCategorys = this.fifthFormGroup.get("categorys") as FormArray;

      let findCategory = arrayCategorys.value.find(
        (x) => x.name == "valores de la compañia"
      );

      if (findCategory) {
        // valida si ya se creo la categoria
        let arraySeries = this.fifthFormGroup.get("series") as FormArray;
        let findSeries = arraySeries.value.find(
          (x) => x.name == "valores de la compañia"
        );

        // se crea la pregunta
        this.newQuestionsQuarter(
          controlValue.question,
          controlValue.answer,
          findSeries.id,
          findCategory.id
        );
      } else {
        // agregamos la nueva categoria

        var idCategoryMaxList: any = [];
        arrayCategorys.value.map((x) => {
          if (x.id != "") idCategoryMaxList.push(x.id);
        });
        let idCategorys =
          idCategoryMaxList.reduce(function (a, b) {
            return Math.max(a, b);
          }) + 1;

        arrayCategorys.push(
          this._formBuilder.group({
            id: [idCategorys],
            name: ["valores de la compañia"],
            status: [0],
            view: [false],
          })
        );

        // se crea la serie
        let arraySeries = this.fifthFormGroup.get("series") as FormArray;

        var idSerieMaxList: any = [];
        arraySeries.value.map((x) => {
          if (x.id != "") idSerieMaxList.push(x.id);
        });
        let idSeries =
          idSerieMaxList.reduce(function (a, b) {
            return Math.max(a, b);
          }) + 1;

        arraySeries.push(
          this._formBuilder.group({
            id: [idSeries],
            idCategory: [idCategorys],
            name: ["valores de la compañia"],
            status: [0],
            isNewEngagement: 0,
          })
        );

        // se crea la pregunta
        this.newQuestionsQuarter(
          controlValue.question,
          controlValue.answer,
          idSeries,
          idCategorys
        );
      }

      // if (type == 0) {
      //   control.push(
      //     this._formBuilder.group({
      //       question: ["", Validators.required],
      //       answer: ["", Validators.required],
      //     })
      //   );
      // }
    }
  }

  RemoveQuestionsQuarter(index: number) {
    const control = <FormArray>this.quarterFormGroup.controls["questions"];
    if (control.length > 1) {
      control.removeAt(index);
    } else {
      return Swal.fire(
        "Error",
        "No es posible eliminar, la compañía debe de contener al menos un valor  ",
        "error"
      );
    }
  }

  validarStepperQuarter(stepper: MatStepper) {
    const control = <FormArray>this.quarterFormGroup.controls["questions"];
    if (control.status == "VALID") {
      this.addQuestionsQuarter(1);
      this.stepControl5 = false; // var control view div hiden
    }
  }

  newQuestionsQuarter(question, answer, idSeries, idCatQuestions) {
    let arrayQuestions = this.fifthFormGroup.get("questions") as FormArray;
    let findQuestion = arrayQuestions.value.find((x) => x.name == question);
    if (!findQuestion) {
      arrayQuestions.push(
        this._formBuilder.group({
          name: [answer],
          status: "1",
          idSeries: [idSeries],
          idCatQuestions: [idCatQuestions],
          idFactors: "0",
          GroupFactor: 0,
          isNewEngagement: 0,
          CodigoPreg: [question],
        })
      );
    }
  }

  //////////////////////////////////////////////////////////////////////
  //// STEP 5  ============================= Adapta el proyecto /////////////////
  //////////////////////////////////////////////////////////////////////
  allRadioSelected: boolean = false;
  validarStepperFifth(stepper: MatStepper) {
    const control = <FormArray>this.sixFormGroup.controls["questions"];
    if (control.status == "VALID") {
      this.stepControl7 = false;
    } else {
      this.allRadioSelected = false;
    }
  }
  checkRadioInputs() {
    const questionsArray = this.sixFormGroup.get("questions") as FormArray;
    const allSelected = questionsArray.controls.every(
      (control) => control.get("answer").value !== null
    );

    this.allRadioSelected = allSelected;
  }

  //// CATEGORIAS /////////////////
  categorySurveyGet() {
    this.conector.categorySurveyGet().subscribe((response: any) => {
      const { ok, data, msg } = response;
      if (ok === false) {
        return Swal.fire("Error", msg, "error");
      } else {
        const control = <FormArray>this.fifthFormGroup.controls["categorys"];
        for (var key in response.data) {
          var QuestionKey = response.data[key];
          control.push(
            this._formBuilder.group({
              id: [QuestionKey.id],
              name: [QuestionKey.name],
              status: [QuestionKey.status],
              view: [true],
            })
          );
        }
      }
    });
  }
  get getCategorySurveyArray() {
    let categorys = this.fifthFormGroup.get("categorys") as FormArray;
    return categorys;
  }

  getCategorySurveyArrayFind(Index: string, type: number) {
    let Question = "";

    if (Index != "0") {
      let array = this.fifthFormGroup.get("categorys") as FormArray;
      let item = array.value[Index];

      if (item.question != "" && item.view == true) {
        if (type == 1) {
          Question = item.name;
        } else {
          Question = item.id;
        }
      }
    }
    return Question;
  }

  editCategorys(selector) {
    if (
      document.getElementById("divEditInput" + selector).style.display ==
      "block"
    ) {
      document.getElementById("divEditInput" + selector).style.display = "none";
    } else {
      document.getElementById("divEditInput" + selector).style.display =
        "block";
    }

    if (
      document.getElementById("divEditLabel" + selector).style.display ==
      "block"
    ) {
      document.getElementById("divEditLabel" + selector).style.display = "none";
    } else {
      document.getElementById("divEditLabel" + selector).style.display =
        "block";
    }
  }

  addCategory() {
    const control = this.fifthFormGroup.controls["newCategory"];
    if (control.status == "VALID") {
      let array = this.fifthFormGroup.get("categorys") as FormArray;
      var idMaxList: any = [];
      array.value.map((x) => {
        if (x.id != "") idMaxList.push(x.id);
      });
      let id =
        idMaxList.reduce(function (a, b) {
          return Math.max(a, b);
        }) + 1;

      array.push(
        this._formBuilder.group({
          id: [id],
          name: [control.value],
          status: [0],
          view: [true],
        })
      );
    }
  }

  removeCategory(index: number) {
    const control = <FormArray>this.fifthFormGroup.controls["categorys"];
    let item = control.value[index];

    if (item.id != "") {
      if (item.id == "4") {
        return Swal.fire(
          "Error",
          "La categoría de Engagement no se puede borrar, puede modificar su nombre  ",
          "error"
        );
      } else {
        control.removeAt(index);
      }
    }
  }
  //// SERIES /////////////////
  seriesSurveyGet() {
    this.conector.seriesSurveyGet("1").subscribe((response: any) => {
      const { ok, data, msg } = response;
      if (ok === false) {
        return Swal.fire("Error", msg, "error");
      } else {
        const control = <FormArray>this.fifthFormGroup.controls["series"];
        for (var key in response.data) {
          var QuestionKey = response.data[key];
          control.push(
            this._formBuilder.group({
              id: [{ value: QuestionKey.id, disabled: false }],
              idCategory: [{ value: QuestionKey.idCategory, disabled: false }],
              name: [{ value: QuestionKey.name, disabled: false }],
              status: [{ value: QuestionKey.status, disabled: false }],
              idSeries: [{ value: QuestionKey.id, disabled: false }],
            })
          );
        }
      }
    });
  }

  get getSeriesSurveyArray() {
    let series = this.fifthFormGroup.get("series") as FormArray;
    return series;
  }

  getSeriesSurveyArrayFind(Index: string, type: number, init: number) {
    let Question = "N_A";
    if (Index != "0") {
      if (init != 0) {
        let array = this.fifthFormGroup.get("series") as FormArray;
        let item = array.value[Index];

        if (item.question != "") {
          if (type == 1) {
            Question = item.name;
          } else if (type == 2) {
            Question = item.id;
          } else if (type == 3) {
            Question = item.idCategory;
          }
        }
      }
    }
    return Question;
  }

  editSeries(selector) {
    if (
      document.getElementById("divEditSeriesInput" + selector).style.display ==
      "block"
    ) {
      document.getElementById("divEditSeriesInput" + selector).style.display =
        "none";
    } else {
      document.getElementById("divEditSeriesInput" + selector).style.display =
        "block";
    }

    if (
      document.getElementById("divEditSeriesLabel" + selector).style.display ==
      "block"
    ) {
      document.getElementById("divEditSeriesLabel" + selector).style.display =
        "none";
    } else {
      document.getElementById("divEditSeriesLabel" + selector).style.display =
        "block";
    }
  }

  addSeries() {
    const control = this.fifthFormGroup.controls["newSerie"];
    if (control.status == "VALID") {
      let array = this.fifthFormGroup.get("series") as FormArray;
      var idSerieMaxList: any = [];
      array.value.map((x) => {
        if (x.id != "") idSerieMaxList.push(x.id);
      });
      let id =
        idSerieMaxList.reduce(function (a, b) {
          return Math.max(a, b);
        }) + 1;

      let getIsNewEngagement;
      if (this.seriesCategory == "4") {
        getIsNewEngagement = "1";
      }

      array.push(
        this._formBuilder.group({
          id: [id],
          idCategory: this.seriesCategory,
          name: [control.value],
          status: [0],
          isNewEngagement: getIsNewEngagement,
        })
      );
    }
  }

  removeSeries(index: number) {
    const control = <FormArray>this.fifthFormGroup.controls["series"];
    let itemSerie = control.value[index];

    const controlCategory = <FormArray>(
      this.fifthFormGroup.controls["categorys"]
    );
    let itemCategoryValues = controlCategory.value;

    var itemCategory = itemCategoryValues.filter(
      (x) => x.id === itemSerie.idCategory
    );

    if (itemCategory[0].id != "") {
      if (itemSerie.idCategory == 4 && itemSerie.isNewEngagement != "1") {
        return Swal.fire(
          "Error",
          "Las dimensiones de Engagement, no se puede borrar",
          "error"
        );
      } else {
        control.removeAt(index);
      }
    }
  }

  changeCategory(id: string) {
    this.seriesCategory = id;
  }
  //// PREGUNTAS /////////////////
  getSeriesSurveyArrayByCategoryFind(
    Index: string,
    type: number,
    init: number
  ) {
    let Question = "N_A";

    if (Index != "0") {
      if (init != 0) {
        let array = this.fifthFormGroup.get("series") as FormArray;
        let item = array.value[Index];

        if (item.question != "") {
          if (type == 1) {
            Question = item.name;
          } else if (type == 2) {
            Question = item.id;
          } else if (type == 3) {
            Question = item.idCategory;
          }
        }
      }
    }
    return Question;
  }

  changeQuestionsSeriesCategory(id: string) {
    this.questionsSeriesCategory = id;
  }

  qestionsSurveyGet() {
    this.conector.qestionsSurveyGet().subscribe((response: any) => {
      const { ok, data, msg } = response;
      if (ok === false) {
        return Swal.fire("Error", msg, "error");
      } else {
        const control = <FormArray>this.fifthFormGroup.controls["questions"];
        for (var key in response.data) {
          var QuestionKey = response.data[key];
          control.push(
            this._formBuilder.group({
              id: [QuestionKey.id],
              name: [QuestionKey.name],
              status: [QuestionKey.status],
              idSeries: [QuestionKey.idSeries],
              GroupFactor: [QuestionKey.groupFactor],
              idFactors: [QuestionKey.idFactors],
              CodigoPreg: [QuestionKey.codigopreg],
              pertEngagement: [QuestionKey.pertEngagement],
            })
          );
        }
      }
    });
  }

  get getQuestionsSurveyArray() {
    let questions = this.fifthFormGroup.get("questions") as FormArray;
    return questions;
  }

  getQuestionsSurveyArrayFind(Index: string, type: number) {
    let Question = "N_A";
    if (Index != "0") {
      let array = this.fifthFormGroup.get("questions") as FormArray;
      let item = array.value[Index];

      if (item.question != "") {
        if (type == 1) {
          Question = item.name;
        } else if (type == 2) {
          Question = item.id;
        } else if (type == 3) {
          Question = item.idSeries;
        }
      }
    }
    return Question;
  }

  editQuestions(selector) {
    if (
      document.getElementById("divEditQuestionsInput" + selector).style
        .display == "block"
    ) {
      document.getElementById(
        "divEditQuestionsInput" + selector
      ).style.display = "none";
    } else {
      document.getElementById(
        "divEditQuestionsInput" + selector
      ).style.display = "block";
    }

    if (
      document.getElementById("divEditQuestionsLabel" + selector).style
        .display == "block"
    ) {
      document.getElementById(
        "divEditQuestionsLabel" + selector
      ).style.display = "none";
    } else {
      document.getElementById(
        "divEditQuestionsLabel" + selector
      ).style.display = "block";
    }
  }

  addQuestions() {
    const control = this.fifthFormGroup.controls["newNameQuestions"];
    let getIsNewEngagement;
    if (this.questionsSeriesCategory == "4") {
      getIsNewEngagement = "1";
    }

    if (control.status == "VALID") {
      let array = this.fifthFormGroup.get("questions") as FormArray;
      array.push(
        this._formBuilder.group({
          name: [control.value],
          status: "1",
          idSeries: [this.questionsSeries],
          idCatQuestions: [this.questionsSeriesCategory],
          idFactors: "0",
          GroupFactor: 0,
          isNewEngagement: getIsNewEngagement,
        })
      );
    }
  }

  removeQuestions(index: number) {
    const control = <FormArray>this.fifthFormGroup.controls["questions"];
    let itemQuestions = control.value[index];

    const controlSeries = <FormArray>this.fifthFormGroup.controls["series"];
    let itemSeriesValues = controlSeries.value;
    var itemSeries = itemSeriesValues.filter(
      (x) => x.id === itemQuestions.idSeries
    );

    const controlCategory = <FormArray>(
      this.fifthFormGroup.controls["categorys"]
    );
    let itemCategoryValues = controlCategory.value;

    var itemCategory = itemCategoryValues.filter(
      (x) => x.id === itemSeries[0].idCategory
    );
    if (itemCategory[0].id != "") {
      if (
        this.questionsSeriesCategory == "4" &&
        itemQuestions.isNewEngagement != "1"
      ) {
        return Swal.fire(
          "Error",
          "Las preguntas de Engagement, no se puede borrar",
          "error"
        );
      } else {
        control.removeAt(index);
      }
    }
    //control.removeAt(index);
  }

  changeSeries(id: string) {
    this.questionsSeries = id;
  }

  //// TIPO DE RESPUESTA /////////////////
  questionsTypeGet() {
    let MaxValue: number = this.ceroFormGroup.get("level4").value / 4;

    const control = <FormArray>this.fifthFormGroup.controls["questionsTypes"];
    control.removeAt(0);

    control.push(
      this._formBuilder.group({
        id: ["4"],
        typeid: ["1"],
        section1: [(MaxValue * 4).toString()],
        section2: ["Totalmente de acuerdo"],
        section3: ["Totalmente de acuerdo"],
        description: ["Totalmente de acuerdo"],
        orderlist: ["1"],
        active: ["1"],
      })
    );
    control.push(
      this._formBuilder.group({
        id: ["3"],
        typeid: ["1"],
        section1: [(MaxValue * 3).toString()],
        section2: ["Parcialmente de acuerdo"],
        section3: ["Parcialmente de acuerdo"],
        description: ["Parcialmente de acuerdo"],
        orderlist: ["2"],
        active: ["1"],
      })
    );

    control.push(
      this._formBuilder.group({
        id: ["2"],
        typeid: ["1"],
        section1: [MaxValue * 2],
        section2: ["Parcialmente en desacuerdo"],
        section3: ["Parcialmente en desacuerdo"],
        description: ["Parcialmente en desacuerdo"],
        orderlist: ["3"],
        active: ["1"],
      })
    );
    control.push(
      this._formBuilder.group({
        id: ["1"],
        typeid: ["1"],
        section1: [(MaxValue * 1).toString()],
        section2: ["Totalmente en desacuerdo"],
        section3: ["Totalmente en desacuerdo"],
        description: ["Totalmente en desacuerdo"],
        orderlist: ["4"],
        active: ["1"],
      })
    );
  }

  get getQuestionsTypeArray() {
    const array = <FormArray>this.fifthFormGroup.controls["questionsTypes"];
    return array;
  }

  getQuestionsTypeArrayFind(Index: string, type: number) {
    let Question = "";

    // if(Index != "0"){
    let array = this.fifthFormGroup.get("questionsTypes") as FormArray;
    let item = array.value[Index];

    if (item.question != "") {
      if (type == 1) {
        Question = item.section1;
      } else {
        Question = item.id;
      }
      //  }
    }
    return Question;
  }

  editQuestionsType(selector) {
    if (
      document.getElementById("divEditQuestionsTypeInput" + selector).style
        .display == "block"
    ) {
      document.getElementById(
        "divEditQuestionsTypeInput" + selector
      ).style.display = "none";
    } else {
      document.getElementById(
        "divEditQuestionsTypeInput" + selector
      ).style.display = "block";
    }

    if (
      document.getElementById("divEditQuestionsTypeLabel" + selector).style
        .display == "block"
    ) {
      document.getElementById(
        "divEditQuestionsTypeLabel" + selector
      ).style.display = "none";
    } else {
      document.getElementById(
        "divEditQuestionsTypeLabel" + selector
      ).style.display = "block";
    }
  }

  addQuestionsType() {
    const control = this.fifthFormGroup.controls["newQuestionsType"];
    if (control.status == "VALID") {
      let array = this.fifthFormGroup.get("questionsTypes") as FormArray;
      array.push(
        this._formBuilder.group({
          id: ["0"],
          typeid: ["1"],
          section1: [control.value],
          section2: [control.value],
          section3: [control.value],
          description: [control.value],
          orderlist: ["1"],
          active: ["1"],
        })
      );
    }
  }

  removeQuestionsType(index: number) {
    const control = <FormArray>this.fifthFormGroup.controls["questionsTypes"];
    return Swal.fire(
      "Error",
      "No esta permitido borrar las respuestas",
      "error"
    );

    //control.removeAt(index);
  }

  qestionsTypeSurveyGet(id: string) {
    this.conector.qestionsTypeSurveyGet(id).subscribe((response: any) => {
      const { ok, data, msg } = response;
      if (ok === false) {
        return Swal.fire("Error", msg, "error");
      } else {
        const control = <FormArray>this.fifthFormGroup.controls["category"];
        for (var key in response.data) {
          var QuestionKey = response.data[key];
          control.push(
            this._formBuilder.group({
              id: [QuestionKey.id],
              typeid: [QuestionKey.typeid],
              section1: [QuestionKey.section1],
              section2: [QuestionKey.section2],
              section3: [QuestionKey.section3],
              description: [QuestionKey.description],
              orderlist: [QuestionKey.orderlist],
              active: [QuestionKey.active],
            })
          );
        }
      }
    });
  }

  get getQuestionsTypeSurveyArray() {
    let questionsType = this.fifthFormGroup.get("questionsType") as FormArray;
    return questionsType;
  }

  //////////////////////////////////////////////////////////////////////
  //// STEP 6  ============================= Configuración de respuestas /////////////////
  //////////////////////////////////////////////////////////////////////
  questionsWorkingEnvironmentGet() {
    const control = <FormArray>this.sixFormGroup.controls["questions"];
    if (control.value.length <= 1) {
      this.conector
        .questionsWorkingEnvironmentGet()
        .subscribe((response: any) => {
          const { ok, data, msg } = response;
          if (ok === false) {
            return Swal.fire("Error", msg, "error");
          } else {
            this.sixFormGroup.value.questions[0].answer = "1";
            for (var key in response.data) {
              var QuestionKey = response.data[key];
              control.push(
                this._formBuilder.group({
                  id: [QuestionKey.id],
                  question: [QuestionKey.preguntaEmp],
                  answer: ["", Validators.required],
                })
              );
            }
          }
        });
    }
  }

  get getQuestionsSix() {
    let questions = this.sixFormGroup.get("questions") as FormArray;
    return questions;
  }

  getQuestionsSixForm(Index: string) {
    let Question = "";

    if (Index != "0") {
      let array = this.sixFormGroup.get("questions") as FormArray;
      let item = array.value[Index];

      if (item.question != "") {
        Question = item.question;
      }
    }
    return Question;
  }

  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
}
