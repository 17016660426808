import { AfterContentInit, Component, OnInit } from '@angular/core';

declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.css']
})
export class DocsComponent implements AfterContentInit {
  
  constructor() { }
  
  ngAfterContentInit(): void {

    const ui = SwaggerUIBundle({
      dom_id: '#swagger-ui',
      url: '/assets/swagger/integriline.json',
      operationsSorter: 'alpha'
    });
  }
}