import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import * as moment from 'moment';

@Component({
  selector: 'app-callcenter',
  templateUrl: './callcenter.component.html',
  styleUrls: ['./callcenter.component.css']
})
export class CallcenterComponent implements OnInit {

  ccForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private conector: ConectorJsonService,
    private router: Router,
    private rt: ActivatedRoute,
    public translate: TranslateService,
    private auth: AuthenticationService) {

    this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );

    this.ccForm = this.fb.group({
      reporting: [null, [Validators.required]],
      reporter: [null, [Validators.required]]
    });

  }

  ngOnInit() {

    this.rt.queryParams.subscribe((qp) => {

      if( Object.keys(qp).length == 3) {

        this.ccForm.get('reporting').setValue(qp.rid);
        this.ccForm.get('reporter').setValue(qp.ope);

        this.onSubmit(this.ccForm.value);

      }

    });

  }

  onSubmit(formdata) {

    console.info('Fdata', formdata);

    this.conector.reporte_validarCompany(formdata.reporting).subscribe( (k: any) => {

      const { ok, data, msg } = k;

      if (ok === true) {

        const model = {
          'company': formdata.reporting,
          'reporter': formdata.reporter,
          'isCallCenter': 'yes',
          'isCallDate': moment().format('YYYY-M-D').toString(),
          'isCallTime': moment().format('HH:mm:ss').toString()
        };

        console.info(model);

        localStorage.setItem('callcenter', JSON.stringify(model));

        localStorage.setItem('company', formdata.reporting);

        this.router.navigateByUrl('/report');

      } else {

        Swal.fire('Error', 'Company not found', 'error');

      }

    });

  }

}
