import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-evaluated-link',
  templateUrl: './evaluated-link.component.html',
  styleUrls: ['./evaluated-link.component.css']
})
export class EvaluatedLinkComponent {

  constructor(public dialogRef: MatDialogRef<EvaluatedLinkComponent>, @Inject(MAT_DIALOG_DATA) public url: string) { }

    onClose(): void {
        this.dialogRef.close();
    }

  copyToClipboard() {
    const el = document.createElement('textarea');
    el.value = this.url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    Swal.fire({
      position: 'center',
      icon: 'success',
      text: 'Enlace copiado',
      showConfirmButton: false,
      timer: 900
    });
  }

}
