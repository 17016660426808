import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  configModelType1,
  configModelType2,
  answerUsers,
  configModelType1Copia,
} from "../../views/ethics-survey/ViewModel/config-model/config-model.component";
import { Observable } from "rxjs";
import DbResponseHandler from "../../types/common/db-response-handler";
import Groups from "../../types/db-groups-response";
import User from "../../types/db-user-profile-response";

@Injectable({
  providedIn: "root",
})
export class ConnectorEthicsSurveyService {
  constructor(private http: HttpClient) {}

  getAllSurveys() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/AllSurveys`,
      { headers }
    );
  }

  ethicsSurveyGet(filterPagin = 0, init = 0, limit = 5) {
    //return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/Get`);
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/Get?limit=${limit}&offset=${init}&active=${filterPagin}`,
      { headers }
    );
  }
  getGroupsAndSubgroupsByProject(idProject: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetGroupsByProject/${idProject}`,
      { headers }
    );
  }
  ethicsSurveyGetId(id: number) {
    //return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/Get`);
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetId/${id}`,
      { headers }
    );
  }

  ethicsSurveyGetIdUpdate(id) {
    //return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/Get`);
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetUpdateId/${id}`,
      { headers }
    );
  }

  ethicsSurveyPostUpdate(formdata: configModelType1) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    console.log(formdata);
    let varformdata = JSON.stringify(formdata);

    return this.http.put(
      `${environment.server}/EthicsSurvey/SuveyConfig/Put`,
      varformdata,
      { headers }
    );
  }

  ethicsSurveyDelete(idSurvey) {
    //return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/Get`);
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.delete(
      `${environment.server}/EthicsSurvey/SuveyConfig/DeleteSurvey/${idSurvey}`,
      { headers }
    );
  }

  ethicsSurveyUpdate(formdata: object) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    let varformdata = JSON.stringify(formdata);
    return this.http.post(
      `${environment.server}/EthicsSurvey/SuveyConfig/UpdateSurvey`,
      varformdata,
      { headers }
    );
  }

  categorySurveyGet() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetCategory`,
      { headers }
    );
  }

  seriesSurveyGet(id: string) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetSeries/` + id,
      { headers }
    );
  }

  qestionsSurveyGet() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetQuestions`,
      { headers }
    );
  }

  qestionsTypeSurveyGet(id: string) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetQuestionsType/` + id,
      { headers }
    );
  }

  userGroupsGet() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetGroup`,
      { headers }
    );
  }

  post(formdata: configModelType1Copia) {
    debugger;
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    let varformdata = JSON.stringify(formdata);
    console.log(varformdata);
    return this.http.post(
      `${environment.server}/EthicsSurvey/SuveyConfig/Post`,
      varformdata,
      { headers }
    );
  }

  postNom035(formdata: any) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    let varformdata = JSON.stringify(formdata);
    console.log(varformdata);

    return this.http.post(
      `${environment.server}/EthicsSurvey/SuveyConfig/PostNom035`,
      varformdata,
      { headers }
    );
  }

  postAnswerUser(formdata: answerUsers) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    let varformdata = JSON.stringify(formdata);
    return this.http.post(
      `${environment.server}/EthicsSurvey/SuveyConfig/PostAnswersUser`,
      varformdata,
      {
        headers,
      }
    );
  }

  questionsWorkingEnvironmentGet() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetQuestionsWorkingEnvironment`,
      {
        headers,
      }
    );
  }

  evaluatedAnswerSurveyGet() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetSurveyEvaluted`,
      { headers }
    );
  }

  evaluatedAnswerSurveyTypeOneGet(idSurvey: string, idUser: string) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetSurveyEvalutedTypeOne/${idSurvey}/${idUser}`,
      { headers }
    );
  }

  evaluatedAnswerSurveyTypeTwoGet(idSurvey: string, idUser: string) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetSurveyEvalutedTypeTwo/${idSurvey}/${idUser}`,
      { headers }
    );
  }

  PdfGetReportEngagement(id_SurveyConfig: number, id_Group: string) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetReportEngagement/${id_SurveyConfig}/${id_Group}`,
      { headers }
    );
  }

  PdfGetReportNom035(id_SurveyConfig: number, IdUsers: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetReportNom035/` +
        id_SurveyConfig +
        `/` +
        IdUsers,
      { headers }
    );
  }

  PdfGetReportClima(id_SurveyConfig: number, id_Group: string) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetReportClima/${id_SurveyConfig}/${id_Group}`,
      { headers }
    );
  }

  public GetUsersGroup(id: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetUsersGroup/` + id,
      { headers }
    );
  }

  GetUsersProyect(id: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetUsersProyect/` + id,
      { headers }
    );
  }

  GetEvaluatedAll(id: number, send: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetEvaluatedAll/` +
        id +
        `/` +
        send,
      {
        headers,
      }
    );
  }

  tree_category(id: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/EthicsSurvey/SuveyConfig/tree/${id}`,
      { headers }
    );
  }
  public getAllGroups(): Observable<DbResponseHandler<Groups[]>> {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get<DbResponseHandler<Groups[]>>(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetAllGroups`,
      {
        headers,
      }
    );
  }

  public getAllSubgroups(): Observable<DbResponseHandler<Groups[]>> {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get<DbResponseHandler<Groups[]>>(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetAllSubgroups`,
      {
        headers,
      }
    );
  }

  public getUsersByGroup(id: number): Observable<DbResponseHandler<User[]>> {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get<DbResponseHandler<User[]>>(
      `${environment.server}/EthicsSurvey/SuveyConfig/GetUsersByGroup/${id}`,
      {
        headers,
      }
    );
  }
}
