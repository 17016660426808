import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../services/authentication.service';
import {ResponseHandler} from '../../../interfaces/ResponseHandler';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {ConectorJsonService} from '../../../services/conector-json.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
    selector: 'app-handler-ticket',
    templateUrl: './handler-ticket.component.html',
    styleUrls: ['./handler-ticket.component.css']
})
export class HandlerTicketComponent implements OnInit {

    publicFolder = environment.public;
    attachments: any;
    questions: any;

    relation: any;
    subgroup: any;

    ticket: any = {criticality: null, group: null};
    group: any;
    subcategory: any;
    id: any;
    lang = 'en';

    history = [];
    nodes = [];
    showAgent = false;
    activity: FormGroup;
    private: FormGroup;
    statuses = [
        'new',
        'received',
        'inprogress',
        'awaiting',
        'aborted',
        'solved'
    ];

    anon: any = 'Anonymous';
    crityNodes: any = [
        'veryhigh',
        'high',
        'medium',
        'low',
        'verylow'
    ];

    constructor(private _Activatedroute: ActivatedRoute, private conector: ConectorJsonService, private fb: FormBuilder, private auth: AuthenticationService, public translate: TranslateService) {

        this.activity = this.fb.group({
            ticketid: [0, Validators.required],
            comments: [null, Validators.required],
            kind: ['public']
        });

        this.private = this.fb.group({
            ticketid: [0, Validators.required],
            comments: [null, Validators.required],
            kind: ['secret']
        });

        this.auth.setLanguage().then(lang => {
            this.translate.setDefaultLang(lang);
            this.lang = lang;
        });

    }

    ngOnInit() {

        this.id = this._Activatedroute.snapshot.paramMap.get('id');

        this.conector.handler_ticket_single(this.id).subscribe((d: ResponseHandler) => {

            console.info('G3T TICKET', d);

            const {ok, data, msg} = d;

            if (ok === true) {

                const {ticket, questions, attachments, group, subgroup, relation, customer, subcategory} = data;

                this.attachments = attachments;

                this.questions = questions;

                this.ticket = ticket;

                this.group = group;

                this.subcategory = subcategory;

                this.subgroup = subgroup;

                this.relation = relation;

                this.activity.controls['ticketid'].setValue(ticket.id);

                this.private.controls['ticketid'].setValue(ticket.id);

                this.conector.handler_get_activity(ticket.id).subscribe((k: ResponseHandler) => {
                    this.history = k.data;
                });

                this.conector.getByRelation(ticket.group).subscribe((m: any) => {
                    console.info('all', m);
                    const {ok, data, msg} = m;
                    this.nodes = data;
                });

                // tslint:disable-next-line:triple-equals
                if (ticket.anonymous == 0) {

                    this.anon = `${customer.username} \n ${customer.email}`;

                }

            }

        });

    }

    getMethod(variable: string) {

        if (variable == null) {
            return;
        }

        // tslint:disable-next-line:triple-equals
        this.showAgent = (variable.toLocaleLowerCase() == 'true');

        // tslint:disable-next-line:triple-equals
        return (variable.toLocaleLowerCase() == 'true') ? 'Call center' : 'Website';
    }

    async fileUpload(event) {

        const formData = new FormData();

        const {addedFiles, rejectedFiles, source} = event;

        formData.append('file', addedFiles[0]);

        formData.append('ticket', this.ticket.id);

        this.conector.handler_upload(formData).subscribe((response: any) => {

            const {ok, msg, data} = response;

            if (ok === true) {
                this.attachments.push(data);
            } else {
                Swal.fire('Error', msg, 'error');
            }

        }, (err) => {
            console.info(err);
        });

    }

    publicMessage(formdata) {
        formdata.kind = 'public';
        this.conector.handler_post_activity(formdata).subscribe((d: any) => {
            this.getcomments();
        });
    }

    privateMessage(formdata) {
        formdata.kind = 'private';
        this.conector.handler_post_activity(formdata).subscribe((d: any) => {
            this.getcomments();
        });
    }

    getcomments() {
        this.conector.handler_get_activity(this.ticket.id).subscribe((k: any) => {
            this.history = k.data;

            this.activity.reset();
            this.private.reset();

            this.activity.get('ticketid').setValue(this.ticket.id);
            this.private.get('ticketid').setValue(this.ticket.id);

        });
    }

    onChange(event) {

        const {source, value} = event;

        this.ticket.status = value;

        this.conector.handler_ticket(this.ticket).subscribe(async (p: any) => {

            const {ok, data, msg} = p;

            if (ok === true) {

                await Swal.fire({
                    title: await this.translate.get("UpdatedTicket").toPromise(),
                    icon:'success'});

            } else {

                await Swal.fire('Error', msg, 'error');

                console.info('Fail ', msg);
            }

        });
    }

    async onSelectGroup(event) {

        const {source, value} = event;

        Swal.fire({
            title: await this.translate.get("ConfirmRequired").toPromise(),
            text: await this.translate.get("ConfirmRequiredText").toPromise(),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {

                this.ticket.group = value;

                console.log("group : " + value);

                const model = {
                    id: this.ticket.id,
                    group: value
                };

                this.conector.handler_ticket(model).subscribe(async (u: any) => {
                    await Swal.fire({
                        title: await this.translate.get("UpdatedGroup").toPromise(), 
                        icon: 'success'});
                });

            }
        });
    }

    removeItem(item) {
        const op = this.attachments.findIndex(x => x === item);
        if (op !== -1) {
            this.attachments.splice(op, 1);
        }
    }

    getPrivateFile(fileObj) {

        console.log(fileObj);

        let file = fileObj.encoded_name === undefined ? fileObj.keys : fileObj.encoded_name;

        console.log("file: " + file);

        this.conector.private_file(file).subscribe(async (data: any) => {

            await Swal.fire({
                title:'Success', 
                text:await this.translate.get("archivoDescargado").toPromise(),
                icon:'success'
            });

            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(data);
            a.href = objectUrl;
            a.download = file;
            a.click();
            URL.revokeObjectURL(objectUrl);

        });

    }

    getLangQuestion(row) {
        // tslint:disable-next-line:triple-equals
        if (this.lang == 'es') {
            return row.spanish;
        } else {
            return row.english;
        }
    }

    translate_date(dates: string) {
        return moment(dates).format('YYYY-MM-DD');
    }

    changeCrityNode($event: any) {

        const model = {
            id: this.ticket.id,
            criticality: $event.value
        };

        this.conector.handler_criticality(model).subscribe(async (u: any) => {
            await Swal.fire({
                title: await this.translate.get("UpdatedCriticality").toPromise(),
                icon:'success'});
        });

    }
}
