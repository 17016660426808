import { Chart } from "angular-highcharts";
import { forkJoin, Observable, Subject } from "rxjs";
import { first, map, startWith } from "rxjs/operators";
import Swal from "sweetalert2";

import { BreakpointObserver } from "@angular/cdk/layout";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatPaginator, MatTableDataSource } from "@angular/material";
import { ActivatedRoute, Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { Pagination } from "../../../../models/pagination";
import { AuthenticationService } from "../../../../services/authentication.service";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";
import { BehavioralMapComponent } from "../behavioral-map/behavioral-map.component";
import { IBehavioralMapDistributionData } from "../../../../charts/interfaces/IBehavioralMapDistributionData";
import { ITableDataCompetency } from "../../interfaces/table-data-competency";
export interface CompetenceElement {
  seleccionado: boolean;
  idCompetencia: number;
  competencia: string;
  descriptor: string;
  mapa: number;
  l: number;
  r: number;
  p: number;
  a: number;
  me: number;
  idCategoria: number;
  categoria: string;
  visible: boolean;
}

export interface ProfileElement {
  id: number;
  name: string;
  level: number;
  companyId: number;
  userId: number;
  elu: number;
  rel: number;
  pau: number;
  adp: number;
  me: number;
  competences: CompetenceElement[];
}

enum TranslationKeys {
  IS_ONEHUNDERED_PERCENT_SELECT_MESSAGE = "isSelectOneHunderedPercetMessage",
  ACCEPT = "Accept",
}
@Component({
  selector: "app-config-jobprofiles",
  templateUrl: "./config-jobprofiles.component.html",
  styleUrls: ["./config-jobprofiles.component.css"],
})
export class ConfigJobProfilesComponent implements OnInit {
  @ViewChild("paginatorSources", null) paginator: MatPaginator;
  @Input() IdProfile = "";
  @Input() humanID = 0;
  @Input() isEditing: boolean;
  @Input() competencies: any = { competencies: [], total: 0 };

  @Output() sendSelection: EventEmitter<any> = new EventEmitter();
  @Input() selectedOptions: any[];

  @Output() showBar: EventEmitter<any> = new EventEmitter();
  @Output() minMaxValidator: EventEmitter<any> = new EventEmitter();

  @Input() predesigned = [];
  @Output() predesignedAction: EventEmitter<any> = new EventEmitter();
  @Output() predesignedOption: EventEmitter<any> = new EventEmitter();
  DEFAULT_MAGNITUDE = 60;

  behavioralMapData: IBehavioralMapDistributionData;
  jobCtrl: FormControl = new FormControl();
  jobFilterCtrl: FormControl = new FormControl();
  filteredPredesigned: Observable<any[]>;

  // paginator no funciona
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSources: any = [];

  //pagination
  pagination = new Pagination();

  competencies_data: CompetenceElement[];
  dataSource: CompetenceElement[];
  profileData: ProfileElement = {
    id: 0,
    name: "",
    level: 0,
    companyId: 0,
    userId: 0,
    elu: 0,
    rel: 0,
    pau: 0,
    adp: 0,
    me: 0,
    competences: [],
  };

  showSelectionBar = false;
  configurePredesign = true;
  predesignedField = null;
  activateGridPredesign = false;
  displayedColumns: string[] = ["seleccionado", "Competencia", "Descriptor"];
  chartMapa: any = null;
  id = 0;
  public currentlyExpandedElement: any = null;
  public chartTemp: any = null;
  public collapsing: boolean = false;
  selectedOptionDataSource: MatTableDataSource<any> =
    new MatTableDataSource<any>([]);
  constructor(
    private conector: ConnectorJobProfileService,
    breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private snap: ActivatedRoute,
    public translate: TranslateService,
    private auth: AuthenticationService
  ) {
    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
    this.pagination.length = 10;
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;

    if (this.IdProfile) {
      this.id = parseInt(this.IdProfile);
    } else {
      this.snap.paramMap.subscribe(async (p: Params) => {
        this.id = p.params.id;
      });
    }

    breakpointObserver.observe(["(max-width: 600px)"]).subscribe((result) => {
      this.displayedColumns = result.matches
        ? ["seleccionado", "Competencia", "Descriptor"]
        : ["seleccionado", "Competencia", "Descriptor"];
    });
  }

  ngOnInit(): void {
    this.initializePagination();
    this.initializeIdProfile();
    this.loadCompetencies();
    this.initializeFilter();
  }

  initializePagination() {
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;
  }

  initializeIdProfile() {
    if (this.IdProfile) {
      this.id = parseInt(this.IdProfile);
      this.configurePredesign = !this.isEditing;
    } else {
      this.snap.paramMap.subscribe((p: Params) => {
        this.id = p.params.id;
      });
    }
  }

  loadCompetencies() {
    this.conector
      .get_competencies(
        this.humanID,
        this.pagination.init,
        this.pagination.limit
      )
      .subscribe((competencies: CompetenceElement[]) => {
        this.processCompetenciesResponse(competencies);
      });
  }

  processCompetenciesResponse(competencies: CompetenceElement[]) {
    this.competencies_data = competencies;
    this.dataSource = competencies;

    this.conector.get_profile(this.id).subscribe((profile: ProfileElement) => {
      this.processProfileResponse(profile);
    });
  }

  processProfileResponse(profile: ProfileElement) {
    this.profileData = profile;
    this.genChart(this.competencies_data);

    profile.competences.forEach((comp) => {
      const matchingDataSource = this.dataSource.find(
        (ds) => ds.idCompetencia === comp.idCompetencia
      );
      if (matchingDataSource) {
        matchingDataSource.seleccionado = true;
      }
    });
  }

  initializeFilter() {
    this.filteredPredesigned = this.jobFilterCtrl.valueChanges.pipe(
      startWith(""),
      map((value) => this.jobFilter(value))
    );
  }
  private jobFilter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.predesigned.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  loadRemove() {
    var competenciesSelected = this.competencies.competencies.filter(
      (item) => item.selected
    );
    var hasObsoleteCompetencies =
      competenciesSelected.filter((item) => !item.visible).length > 0;
    if (hasObsoleteCompetencies) {
      Swal.fire({
        title: "Advertencia",
        icon: "warning",
        text: 'Debido a una revisión de validez de las competencias, algunas de ellas se eliminaron por duplicidad en la redacción. Por lo tanto, Una vez que guarde un perfil modificado, ya no se mostrarán las competencias obsoletas de ese puesto. De clic en el botón de "Continuar" para guardar los cambios, o "Cancelar" para dejar la configuración actual.',
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then((d: any) => {
        if (d.value) {
          this.showSelectionBar = true;
          this.showBar.emit(true);
        }
      });
    } else {
      this.showSelectionBar = true;
      this.showBar.emit(true);
    }
  }

  filterOptions() {
    this.resetDisplayFlags();
    this.selectedOptions = this.filterSelectedCompetencies().filter(
      (item) => item.visible
    );
    this.ensureMinimumMagnitude(this.selectedOptions);
    this.sendSelection.emit(this.selectedOptions);
    this.genChart(this.selectedOptions);
    return this.selectedOptions;
  }

  updateSources(e, omitChart = false) {
    this.dataSources = new MatTableDataSource(
      e.filter((item) => item.visible === true)
    );
    this.dataSources.paginator = this.pagination;

    if (omitChart === false) {
      this.genChart(e);
    }
  }

  changePage($event) {
    this.pagination.length = 0;

    this.pagination.changePage($event);

    this.competencies = this.conector
      .get_competencies(this.humanID, $event.pageIndex, $event.pageSize)
      .subscribe((d: any) => {
        const { msg, data } = d;
        this.pagination.length = msg;
        this.dataSources = data;
      });
  }

  genChart(options = []) {
    if (!Array.isArray(options)) return;

    const selectedOptions = options.filter(
      (option) => option.assigned || option.selected
    );

    this.selectedOptions.forEach((option) => {
      if (option.magnitude < 0) {
        option.magnitude = 20;
      }
    });

    if (selectedOptions.length <= 0) {
      return;
    }

    const model = {
      count: selectedOptions.length,
      fightSpirit: this.calculatePropertySum("fightSpirit", selectedOptions),
      relationship: this.calculatePropertySum("relationship", selectedOptions),
      pause: this.calculatePropertySum("pause", selectedOptions),
      normsAdaptability: this.calculatePropertySum(
        "normsAdaptability",
        selectedOptions
      ),
      emotionalManagment: this.calculatePropertySum(
        "emotionalManagment",
        selectedOptions
      ),
    };
    this.conector.post_com_chart(model).subscribe((response: any) => {
      if (response !== null) {
        this.behavioralMapData = response;
      }
    });
  }

  filterTask(keys) {
    keys = this.removeAccentsAndNormalize(keys);

    if (keys.trim() === "") {
      const competenciesD = [];
      competenciesD.push(...this.competencies.competencies);
      this.updateSources(competenciesD, true);
    } else {
      const result = this.transform(keys.trim().toLowerCase());
      this.updateSources(result, true);
    }
  }

  removeAccentsAndNormalize(text: string): string {
    const normalizedText = text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return normalizedText.trim().toLowerCase();
  }

  transform(arg: any) {
    const result = [];
    const competenciesD = [];
    arg = arg.trim().toLowerCase();

    competenciesD.push(...this.competencies.competencies);

    for (const post of competenciesD) {
      var cat = this.removeAccentsAndNormalize(post.category);
      var nam = this.removeAccentsAndNormalize(post.name);
      var desc = this.removeAccentsAndNormalize(post.description);
      if (
        cat.indexOf(arg) > -1 ||
        nam.indexOf(arg) > -1 ||
        desc.indexOf(arg) > -1
      ) {
        result.push(post);
      }
    }

    return result;
  }

  recountFields() {
    var sel = this.competencies.competencies.filter((x) => x.selected == true);

    const contable = sel.length;
    this.minMaxValidator.emit(!(contable >= 4 && contable <= 20));

    if (contable >= 21) {
      return Swal.fire(
        "Alerta",
        "Por favor seleccione entre 4 y 20 conductas de la lista, que sean indispensables para el correcto desempeño en el trabajo.",
        "warning"
      );
    }

    //Actualizar gráfica
    sel.forEach((item) => {
      if (item.magnitude < 0) {
        item.magnitude = 60;
      }
    });

    this.genChart(sel);
  }

  public actualizagrafica(element: ITableDataCompetency) {
    const value = element.magnitude;
    const index = this.selectedOptions.findIndex((x) => x.id == element.id);
    this.selectedOptions[index].magnitude = value;

    const generateSelectedCompetencies = () => {
      return this.competencies.competencies.filter(
        (x: { selected: boolean }) => x.selected
      );
    };

    if (value !== 100) {
      const sel = generateSelectedCompetencies();
      this.genChart(sel);
      return;
    }

    forkJoin([
      this.translate.get(TranslationKeys.IS_ONEHUNDERED_PERCENT_SELECT_MESSAGE),
      this.translate.get(TranslationKeys.ACCEPT),
    ]).subscribe(([message, accept]) => {
      Swal.fire({
        title: "Alerta",
        text: message,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: accept,
        reverseButtons: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          const sel = generateSelectedCompetencies();
          this.genChart(sel);
        }
      });
    });
  }

  setAction(v) {
    this.configurePredesign = v;
    this.activateGridPredesign = v;
    this.predesignedAction.emit(v);
  }

  updatePredesignField(event: any) {
    this.predesignedOption.emit(this.predesignedField);
  }

  showBehavioralMap(row: ITableDataCompetency) {
    const values = [
      row.fightSpirit,
      row.normsAdaptability,
      row.pause,
      row.relationship,
      row.emotionalManagment,
    ];
    const dialogRef = this.dialog.open(BehavioralMapComponent, {
      data: { competence: row.name, valuesCompetence: values },
    });
    dialogRef.afterClosed().subscribe();
  }
  calculatePropertySum(property: string, selectedOptions: any[]): number {
    return selectedOptions.reduce(
      (acc, item) => acc + (item[property] * item.magnitude) / 100,
      0
    );
  }

  resetDisplayFlags() {
    this.showSelectionBar = false;
    this.showBar.emit(false);
  }

  filterSelectedCompetencies() {
    return this.competencies.competencies.filter((comp) => comp.selected);
  }

  ensureMinimumMagnitude(options) {
    options.forEach((item) => {
      if (item.magnitude < 0) {
        item.magnitude = this.DEFAULT_MAGNITUDE;
      }
    });
  }
}
