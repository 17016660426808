import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../environments/environment";
import DbResponseHandler from "../types/common/db-response-handler";
import DbCountryResponse from "../types/db-catalog-country-response";
import DbLanguageResponse from "../types/db-catalog-lenguage-response";
import DbNationality from "../types/db-catalog-nacionalities-response";

@Injectable({
  providedIn: "root",
})
export class GenericCatalogsService {
  constructor(private http: HttpClient) {}

  ct_SiNo() {
    return this.http.get(`${environment.server}/genericcatalogs/getcatsino`);
  }

  ct_LengTest() {
    return this.http.get(`${environment.server}/genericcatalogs/getlengtest`);
  }

  ct_Countries() {
    return this.http.get(`${environment.server}/genericcatalogs/getcountries`);
  }

  ct_Submodules(id) {
    return this.http.get(
      `${environment.server}/genericcatalogs/getsubmodules/${id}`
    );
  }

  ct_Nationalities() {
    return this.http.get(
      `${environment.server}/genericcatalogs/getnationalities`
    );
  }

  ct_Genres() {
    return this.http.get(`${environment.server}/genericcatalogs/getgenres`);
  }

  ct_NivelPuestos() {
    return this.http.get(`${environment.server}/genericcatalogs/getjoblevels`);
  }

  ct_SeniorityRange() {
    return this.http.get(
      `${environment.server}/genericcatalogs/getseniorityrange`
    );
  }

  ct_TypeContract() {
    return this.http.get(
      `${environment.server}/genericcatalogs/gettypecontract`
    );
  }
  getCivilStatus() {
    return this.http.get(
      `${environment.server}/genericcatalogs/GetCivilStatus`
    );
  }
  ct_AgeRange() {
    return this.http.get(`${environment.server}/genericcatalogs/getagerange`);
  }

  identity_tree() {
    return this.http.get(
      `${environment.server}/genericcatalogs/GetTrueIdentity`
    );
  }

  ct_Groups() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(`${environment.server}/groups/all`, { headers });
  }

  //Implmenthing new methods using Observables and types
  public catalogCountries(): Observable<
    DbResponseHandler<DbCountryResponse[]>
  > {
    return this.http.get<DbResponseHandler<DbCountryResponse[]>>(
      `${environment.server}/genericcatalogs/getcountries`
    );
  }
  public catalogLengTest(): Observable<
    DbResponseHandler<DbLanguageResponse[]>
  > {
    return this.http.get<DbResponseHandler<DbLanguageResponse[]>>(
      `${environment.server}/genericcatalogs/getlengtest`
    );
  }
  public catalogNationalities(): Observable<
    DbResponseHandler<DbNationality[]>
  > {
    return this.http.get<DbResponseHandler<DbNationality[]>>(
      `${environment.server}/genericcatalogs/getnationalities`
    );
  }
}
