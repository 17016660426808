import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";

@Component({
  selector: "app-category-relations",
  templateUrl: "./category-relations.component.html",
  styleUrls: ["./category-relations.component.css"],
})
export class CategoryRelationsComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();

  @Output() save: EventEmitter<any> = new EventEmitter();

  @Output() delete: EventEmitter<any> = new EventEmitter();

  @Input() source: any = { id: 0 };

  @Input() relations: any[] = [];

  @Input() registry: any[] = [];
  @Input() havePermissionCreateRol: boolean = true;
  @Input() havePermissionDeleteRol: boolean = true;

  relationForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.relationForm = this.fb.group({
      relations: [null, Validators.required],
    });
  }

  ngOnInit() {}

  hideSidebar() {
    this.close.emit();
  }

  addRelation(formdata: any) {
    this.save.emit({ relation: formdata.relations, category: this.source });
    this.relationForm.reset();
  }

  deleteRelation(row) {
    this.delete.emit(row);
  }
}
