import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../services/authentication.service';
import { SharedCommunicationService } from './../../../services/shared-communication.service';

import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatTreeNestedDataSource } from '@angular/material';
import { Pagination } from '../../../models/pagination';
import { ConectorJsonService } from '../../../services/conector-json.service';

interface PerNode {
    id: number;
    node: string;
    checked: boolean;
    items?: PerNode[];
}

@Component({
    selector: 'app-permission',
    templateUrl: './permission.component.html',
    styleUrls: ['./permission.component.css'],
})
export class PermissionComponent implements OnInit {

    user = {
        email: '123@aaab',
        firstname: 'asd',
        id: null,
    };
    typeCall: callsFrom;
    rows: any = [];
    showLoader = true;
    pagination = new Pagination();

    master = new MatTreeNestedDataSource<PerNode>();
    treeControl = new NestedTreeControl<PerNode>((node) => node.items);

    usermodules: any = [];

    gridPermissions = {
        Integriline: [],
        Honestidad: [],
        Survey: [],
        Learning: [],
        Background: [],
        Comunika: [],
    };

    constructor(
        private shared: SharedCommunicationService,
        private conector: ConectorJsonService,
        private auth: AuthenticationService,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.auth.setLanguage().then((k) => {
            this.translate.setDefaultLang(k);
        });
    }

    async ngOnInit() {
        this.implementLoadPermissions(callsFrom.edit);
    }

    async implementLoadPermissions(type: callsFrom, users: any = null) {
        this.typeCall = type;

        this.user = JSON.parse(localStorage.getItem('userPermission'));

        // debugger

        if (this.user.id == 0) {
            this.typeCall = callsFrom.create;
        }

        const { ok, data }: any = await this.conector.modules_by_user().toPromise(); // consulta los modulos del usuario

        this.usermodules = data.map((x) => { // si es diferente de edicion se marcan
            if (type != callsFrom.edit) {
                x.checked = true;
            }
            return x;
        });

        await this.loadPermissions(this.usermodules[0].nickname);
    }

    async loadPermissions(modulename) {
        this.showLoader = true;

        this.conector
            .modules_user({
                id: this.user.id,
                module: modulename
            }) // consulta los modulos y las aplciaciones del usuario
            .subscribe((k: any) => {
                let { ok, data, msg } = k;

                if (ok === true) {
                    this.gridPermissions[modulename] = [];

                    if (modulename == 'Integriline') {
                        const filterdata = [];

                        data.forEach((item) => {
                            filterdata.push(item);
                        });

                        data = filterdata;
                    }

                    /// cambia de estado el checkbox validando el estado del ncikname y si es nuevo o edicion
                    data.map((x) => {
                        if (this.typeCall != callsFrom.edit) {
                            x.enabled = true;
                        }
                        return x;
                    });

                    this.usermodules.map((z) => {
                        // se cargan los modulos en la grilla
                        const itemModule = data.filter((x) => x.module === z.nickname);
                        if (itemModule.length > 0) {
                            this.gridPermissions[z.nickname] = itemModule;
                        }
                    });
                    this.showLoader = false;

                    // this.gridPermissions[modulename] = data;
                } else {
                    console.log(msg);
                }
            });
    }

    SelectedAndUnSelected(type) {

        this.usermodules.map((x) => {
            if (x.nickname == type) {
                x.checked = x.checked ? false : true;
            }
            return x;
        });

        this.gridPermissions[type].map((x) => {
            x.enabled = this.usermodules.find((x) => x.nickname == type).checked;
            return x;
        });
    }

    setPermision() {

        let applications = [];
        for (var prop in this.gridPermissions) {

            this.gridPermissions[prop].map((x) => {
                if (x.enabled == true) {
                    const model = {
                        id: this.user.id,
                        module: prop,
                        application: x.id.toString(),
                        enabled: x.enabled,
                        company: 0,
                    };
                    applications.push(model);
                }
            });
        }

        let model = {
            In_user_List: applications,
        };
        this.conector.modules_update(model).subscribe((k: any) => {
        });
    }
}


enum callsFrom {
    edit = 1,
    create = 2,
    change = 3
}
