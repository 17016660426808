import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { ConectorJsonService } from "../../../services/conector-json.service";
import { ConectorModuleService } from "../../../services/conector-module.service";
import { AuthenticationService } from "./../../../services/authentication.service";
import { Pagination } from "./../../shared/pagination";
import { ModalChildrenComponent } from "./../modals/modal-children/modal-children.component";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.css"],
})
export class GroupsComponent implements OnInit {
  pagination = new Pagination();
  categoryForm: FormGroup;
  masterGroups = [];
  length = 0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 15];
  isEditable = false;
  selected = null;
  filtering = null;

  permissionCreateGroup: boolean = true;
  permissionUpdateGroup: boolean = true;
  permissionDeleteGroup: boolean = true;

  constructor(
    private fb: FormBuilder,
    private conector: ConectorJsonService,
    private conectorModuleService: ConectorModuleService,
    public dialog: MatDialog,
    private router: Router,
    private auth: AuthenticationService,
    private translate: TranslateService
  ) {
    const idModuleLibrary = this.getIdModuleLibrary();
    this.getFunctionalityPermissions(idModuleLibrary);

    this.pagination.length = 10;

    this.categoryForm = this.fb.group({
      parent: [0, [Validators.required]],
      name: [null, Validators.required],
      description: [null],
      visible: [true, Validators.required],
    });

    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
  }

  async ngOnInit() {
    this.conector
      .groups_get(this.pagination.init, this.pagination.pageSize)
      .subscribe((res: any) => {
        const { ok, data, msg } = res;
        this.pagination.length = msg;
        this.masterGroups = data;
      });
  }

  openDialog(row): void {
    const dialogRef = this.dialog.open(ModalChildrenComponent, {
      data: { row },
    });

    dialogRef.afterClosed().subscribe((res) => {
      console.info("&modal&", res);
    });
  }

  updateRows($events) {
    const { pageIndex, pageSize } = $events;

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.masterGroups = [];
    this.conector
      .groups_get(this.pageIndex, this.pageSize, this.filtering)
      .subscribe((x: any) => {
        this.masterGroups = x.data;
      });
  }

  searchUsers() {
    this.conector
      .groups_get(0, this.pageSize, this.filtering)
      .subscribe((x: any) => {
        this.masterGroups = x.data;
      });
  }

  onClick(formdata) {
    formdata.visible = "" + formdata.visible;
    console.log(formdata);

    if (this.isEditable === false) {
      this.conector.groups_post(formdata).subscribe((d: any) => {
        this.length++;
        this.categoryForm.reset();

        this.categoryForm.controls["parent"].setValue(0);
        this.categoryForm.controls["visible"].setValue(true);

        this.masterGroups.unshift(d.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Process completed",
          showConfirmButton: false,
          timer: 1000,
        });
      });
    } else {
      Object.keys(formdata).forEach((key) => {
        this.selected[key] = formdata[key];
      });

      this.conector.groups_put(this.selected).subscribe((d) => {
        this.categoryForm.reset();
        this.isEditable = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Process completed",
          showConfirmButton: false,
          timer: 1000,
        });
      });
    }
  }

  editRow(row) {
    this.isEditable = true;
    this.selected = row;
    Object.keys(this.categoryForm.controls).forEach((key) => {
      this.categoryForm.get(key).setValue(row[key]);
    });
  }

  async deleteRow(row) {
    Swal.fire({
      title: await this.translate.get("warning").toPromise(),
      icon: "warning",
      text: await this.translate.get("action-irreversible").toPromise(),
      showCancelButton: true,
      confirmButtonText: await this.translate.get("submit").toPromise(),
      cancelButtonText: await this.translate.get("Cancel").toPromise(),
    }).then((d: any) => {
      if (d.value === true) {
        this.conector.groups_delete(row.id).subscribe((x: any) => {
          const { ok, msg } = x;
          if (ok === true) {
            this.deleteConfirm();
            this.conector
              .groups_get(this.pagination.init, this.pagination.pageSize)
              .subscribe((res: any) => {
                const { ok, data, msg } = res;
                this.pagination.length = msg;
                this.masterGroups = data;
              });
          } else {
            Swal.fire("Error", msg, "error");
          }
        });
      }
    });
  }

  async deleteConfirm() {
    Swal.fire(
      await this.translate.get("success").toPromise(),
      await this.translate.get("registro_eliminado").toPromise(),
      "success"
    );
  }
  getIdModuleLibrary() {
    const currentRoute = this.router.url;
    const routesString = localStorage.getItem("menu-sidebar");
    const routesArray = JSON.parse(routesString);
    const routeMatch = routesArray.find(
      (route) => route.route === currentRoute
    );
    return routeMatch.id;
  }
  getFunctionalityPermissions(idModuleLibrary: number) {
    this.conectorModuleService
      .getFunctionalityPermissionByModuleAndUser(idModuleLibrary)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          const result = JSON.parse(data);
          result.forEach((item) => {
            if (item.code === "createGroup") {
              this.permissionCreateGroup = item.havePermission;
            } else if (item.code === "updateGroup") {
              this.permissionUpdateGroup = item.havePermission;
            } else if (item.code === "deleteGroup") {
              this.permissionDeleteGroup = item.havePermission;
            }
          });
        } else {
          console.log("Error get functionality permissions");
        }
      });
  }
}
