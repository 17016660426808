import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { fromEvent } from "rxjs";
import { skip } from "rxjs/operators";

@Component({
    selector: 'app-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.css'],
  })
  export class IFrameComponent implements OnInit {

    @Input() page: any;

    openModal:boolean = false;

    @ViewChild('frameset', { static: true }) frameset: ElementRef;

    constructor(public sanitizer: DomSanitizer) { }

    // ngOnChanges( c: SimpleChanges) {

    //   console.log(c);

    // }

    ngAfterViewInit(): void {
      fromEvent(this.frameset.nativeElement, 'load')
        .pipe(skip(1))
        .subscribe(
          (event: Event) => 
          {

            let newpage = this.sanitizer.bypassSecurityTrustResourceUrl(event.target["src"]);
            console.log('LA URL CAMBIO PAPU', newpage);

            // if (this.page != newpage) {

            //   this.page = newpage;

            // }

            

          });
    }

    ngOnInit(): void {

    }

  }