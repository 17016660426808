import {Component, OnInit} from '@angular/core';
import {ConectorJsonService} from '../../../services/conector-json.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../services/authentication.service';
import {Router} from '@angular/router';
import {SharedCommunicationService} from '../../../services/shared-communication.service';
import {UserIdleService} from 'angular-user-idle';

import Swal from 'sweetalert2';
import * as jwt from 'jwt-decode';
import * as moment from 'moment';

@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit {

    authForm: FormGroup;
    isFired = false;
    userIp: any;
    isLoading = false;
    kindOF = 'password';
    require2FA = false;
    year : number = new Date().getFullYear();

    constructor(
        private trans: TranslateService,
        private service: AuthenticationService,
        private router: Router,
        private fb: FormBuilder,
        private shared: SharedCommunicationService,
        private conector: ConectorJsonService,
        private translate: TranslateService,
        private userIdle: UserIdleService) {

        this.service.setLanguage().then(k => this.trans.setDefaultLang(k));

        this.authForm = this.fb.group({
            reporting: [null, Validators.required],
            username: [null, Validators.required],
            password: [null, Validators.required],
            verification: [null]
        });

    }

    ngOnInit() {

        this.shared.httpCall.subscribe(k => this.isLoading = k);

        const {data}: any = this.conector.get_user_ip().toPromise();

        this.userIp = data;

        this.shared.setJsonObject('userIp', {userIp: data});

    }

    toogleKind() {
        this.kindOF = (this.kindOF == 'text') ? 'password' : 'text';
    }

    onSubmit(formdata) {

        localStorage.clear();

        const model = {
            username: formdata.username,
            password: formdata.password,
            reporting: formdata.reporting,
            IpHostName: this.userIp
        };

        this.conector.validarAcceso(model).subscribe(async (res: any) => {

            const {ok, data, msg} = res;

            if (ok == true) {

                if (data.factor == true) {

                    Swal.fire({
                        title: await this.translate.get('IngresarCodigo2Fa').toPromise(),
                        html: await this.translate.get('EnviadoCodigoAuth').toPromise(),
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: false,
                        confirmButtonText: await this.translate.get('continue').toPromise(),
                        showLoaderOnConfirm: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then((result) => {

                        if (result.value == res.data.challenge) {

                            this.initializeSystem(data);

                        }

                    });

                } else {

                    this.initializeSystem(data);

                }

            } else {
                Swal.fire('Error', msg, 'error');
            }

        });

    }

    initializeSystem(data: any) {

        this.startWatch();

        const decodes = jwt(data.token);

        localStorage.setItem('token', data.token);

        switch (parseInt(decodes.role)) {

            // Super admin
            case 1:
            case 2: {
                localStorage.setItem('roleName', 'Super Administrator');
                this.router.navigateByUrl('/admin');
                break;
            }

            /* Manager, handler, supervisor en orden estricto, se homologan los templates y la navegación se limita a la asignacion desde dbo */

            case 3:
            case 4:
            case 5: {
                localStorage.setItem('roleName', 'Account manager');
                this.router.navigateByUrl('/manager');
                break;
            }

            // Ticket
            case 6: {
                localStorage.setItem('roleName', 'Ticket');
                this.router.navigateByUrl('/ticket');
                break;
            }

            // Evaluado
            case 7: {
                localStorage.setItem('roleName', 'Evaluated');
                this.router.navigateByUrl('/evaluated');
                break;
            }

            default: {
                Swal.fire('Integriline', 'User does not have a valid permission', 'error');
                break;
            }

        }

    }

    startWatch() {

        this.userIdle.startWatching();

        this.userIdle.onTimerStart().subscribe(async (count: any) => {

            if (count && this.isFired === false) {

                let timerInterval;

                Swal.fire({
                    title: await this.translate.get('session-alert').toPromise(),
                    html: await this.translate.get('session-alert-msg').toPromise(),
                    icon: 'warning',
                    timer: 10000,
                    showConfirmButton: true,
                    cancelButtonColor: '#9F5CC0',
                    confirmButtonText: await this.translate.get('refresh').toPromise(),
                    cancelButtonText: await this.trans.get('x-cancel').toPromise(),
                    onBeforeOpen: () => {
                        timerInterval = setInterval(() => {
                            Swal.getContent().querySelector('strong').textContent = moment(Swal.getTimerLeft()).format('s').toString();
                        }, 1000);
                    },
                    onClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result: any) => {
                    if (result.value) {
                        console.info('session reset');
                        this.isFired = false;
                        this.userIdle.resetTimer();
                    }
                });

                this.isFired = true;

            }

        });

        this.userIdle.onTimeout().subscribe(() => {

            const token = localStorage.getItem('token');

            const decodes = jwt(token);

            this.conector.log_out(decodes.unique_name).subscribe(() => {

                localStorage.clear();

                this.router.navigateByUrl('selection');

            });

        });

    }

    async requestMail() {

        const ts = await this.translate.get('username').toPromise();
        const cm = await this.translate.get('company_code').toPromise();

        Swal.fire({
            html:
                '<div class="resetForm"><h2>' + await this.translate.get('Reset password').toPromise() + '</h2> ' +
                '<p>' + await this.translate.get('reset_context').toPromise() + '</p>' +
                '<label>' + ts + '</label>' +
                '<input id="swal-input1" class="swal2-input">' +
                '<label>' + cm + '</label>' +
                '<input id="swal-input2" class="swal2-input"></div>',
            cancelButtonColor: '#9F5CC0',
            showCancelButton: true,
            cancelButtonText: await this.trans.get('x-cancel').toPromise(),
            preConfirm: function () {
                return new Promise(function (resolve) {
                    resolve({
                        reporting: $('#swal-input2').val(),
                        username: $('#swal-input1').val()
                    });
                });
            }
        }).then(async (response: any) => {

            const {value} = response;

            if (value) {

                const {ok, msg}: any = await this.conector.resetPassword(value).toPromise();

                const handler = (ok === true) ? 'success' : 'error';

                const tt = await this.translate.get(handler).toPromise();

                await Swal.fire(tt, await this.translate.get(msg).toPromise(), handler);

            }

        });
    }

}
