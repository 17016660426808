import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehavioralMapDistributionComponent } from './components/behavioral-map-distribution-component/behavioral-map-distribution.component';
import { BehavioralMapDistributionService } from './services/behavioral-map-distribution.service';
import { ChartModule } from 'angular-highcharts';
import { MatCardModule } from '@angular/material';
import * as Highcharts from 'highcharts';
import Boost from 'highcharts/modules/boost';
Boost(Highcharts);
@NgModule({
	declarations: [BehavioralMapDistributionComponent],
	imports: [CommonModule, ChartModule, MatCardModule],
	providers: [BehavioralMapDistributionService],
	exports: [BehavioralMapDistributionComponent],
})
export class ChartsModule {}
