import { filter } from "rxjs/operators";
import { environment } from "./../../../../environments/environment.prod";
import { Router } from "@angular/router";
import { ResponseHandler } from "./../../../interfaces/ResponseHandler";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { Pagination } from "../../../models/pagination";
import { AuthenticationService } from "../../../services/authentication.service";
import { ConectorJsonService } from "../../../services/conector-json.service";
import { ModalChildrenComponent } from "../../manager/modals/modal-children/modal-children.component";
import { ConectorModuleService } from "../../../services/conector-module.service";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.css"],
})
export class CategoryComponent implements OnInit {
  pagination = new Pagination();
  categoryForm: FormGroup;
  masterGroups = [];
  length = 0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 15];
  isEditable = false;
  selected = null;
  parent_list = [];
  tree_master = [];
  relations_list = [];
  filteredParent = [];
  filteredGroups = [];
  categoryRelations = [];
  activeClass = null;
  current = null;
  activeClassRelation: string = null;
  akey = environment.tiny;
  tiny_options: any = { height: 500, menubar: true, language: "en" };
  permissionCreateCategory: boolean = true;
  permissionCategoryCreateRol: boolean = true;
  permissionCategoryDeleteRol: boolean = true;
  permissionUpdateSubcategory: boolean = true;
  permissionUpdateCategory: boolean = true;
  permissionDeleteCategory: boolean = true;
  language: string = "";
  selectedYesAnonymous: boolean = false;
  disabledFeedback: boolean = false;
  messageFeedback: string = "";
  atValues = [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' }
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' }
  ]

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ],
    },
  }

  constructor(
    private fb: FormBuilder,
    private conector: ConectorJsonService,
    public dialog: MatDialog,
    private auth: AuthenticationService,
    private translate: TranslateService,
    private conectorModuleService: ConectorModuleService,
    private router: Router
  ) {
    const idModuleLibrary = this.getIdModuleLibrary();
    this.getFunctionalityPermissions(idModuleLibrary);
    this.categoryForm = this.fb.group({
      parent: [null, [Validators.required]],
      name: [null, Validators.required],
      description: [null],
      relation: [0],
      visible: [null, Validators.required],
      anon: [null, Validators.required],
      disableFeedback: [false],
      messageFeedback: [null],
      languageMessageFeedback: [this.language.toUpperCase()],
    });

    this.auth.setLanguage().then((k) => {
      this.language = k;
      this.translate.setDefaultLang(k);
      this.tiny_options.language = k;
    });
  }

  async ngOnInit() {
    this.conector.get_category().subscribe((res: ResponseHandler) => {
      this.masterGroups = res.data;
      this.filteredParent = this.masterGroups.filter((x) => x.parent == 0);
      this.length = res.msg.length;
    });

    this.loadTree();

    this.reloadRelations();
    this.getDefaultFeedbackMessageCategory();
  }

  reloadRelations() {
    this.conector.relations_get().subscribe((e: any) => {
      this.relations_list = e.data;
    });
  }

  async loadTree() {
    await this.conector.parent_category().subscribe((k: ResponseHandler) => {
      this.parent_list = k.data;
    });

    await this.conector.tree_category().subscribe((k: ResponseHandler) => {
      this.tree_master = k.data;
      this.filteredParent = k.data;
      this.pagination.length = parseInt(k.msg);
    });
  }

  loadParents(row) {
    this.conector
      .children_category(row.id, 0, 50)
      .subscribe((response: any) => {
        this.filteredGroups = response.data;
        this.activeClass = "active";
        this.current = row.id;
      });
  }

  loadRelations(row) {
    this.conector.category_relations(row.id).subscribe((response: any) => {
      this.categoryRelations = response.data;
      this.activeClassRelation = "active";
      this.current = row.id;
    });
  }

  hideSidebar() {
    this.filteredGroups = [];
    this.activeClass = "";
    this.current = null;
    this.activeClassRelation = null;
  }

  updateRows($events) {
    const { pageIndex, pageSize } = $events;

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.masterGroups = [];

    this.conector
      .groups_get(this.pageIndex, this.pageSize)
      .subscribe((x: any) => {
        this.masterGroups = x.data;
      });
  }

  onClick(formdata) {
    formdata.anon = /true/i.test(formdata.anon);
    formdata.relation = formdata.relation == null ? 0 : formdata.relation;
    formdata.languageMessageFeedback = this.language.toUpperCase();
    formdata.disableFeedback = formdata.disableFeedback === null ? false : formdata.disableFeedback;

    this.conector.post_category(formdata).subscribe(async (response: any) => {
      const { ok, data, msg } = response;
      if (ok) {
        this.categoryForm.reset();
        this.selectedYesAnonymous = false;
        this.disabledFeedback = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: msg,
          showConfirmButton: false,
          timer: 1000,
        });
        this.loadTree();
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: msg,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    });
  }

  editRow(row) {
    this.isEditable = true;
    this.selected = row;
    Object.keys(this.categoryForm.controls).forEach((key) => {
      this.categoryForm.get(key).setValue(row[key]);
    });
  }

  setPermission(row) {
    this.router.navigateByUrl("/manager/category-groups/" + row.id);
  }

  changePage(event) {
    this.conector
      .tree_category(event.pageIndex, event.pageSize)
      .subscribe((k: ResponseHandler) => {
        this.tree_master = k.data;
        this.filteredParent = k.data;
      });
  }

  category_relations() {
    this.conector
      .category_relations(this.current)
      .subscribe((response: any) => {
        this.categoryRelations = response.data;
      });
  }

  deleteRow(row) {
    this.conector.del_categories(row.id).subscribe((e: any) => {
      this.category_relations();
    });
  }

  addRelation(row) {
    this.conector.category_add_relations(row).subscribe((e: any) => {
      this.category_relations();
    });
  }

  delRelation(row) {
    row.category = this.current;

    this.conector.category_del_relations(row).subscribe((e: any) => {
      this.category_relations();
    });
  }

  async selectionConfirm(event) {
    this.selectedYesAnonymous = event.value === 'true';

    if (!this.selectedYesAnonymous) {
      this.categoryForm.get("disableFeedback").setValue(false);
      this.categoryForm.get("messageFeedback").setValue(null);
      this.categoryForm.get("messageFeedback").clearValidators();
      this.disabledFeedback = false;
    }
    this.categoryForm.get("messageFeedback").updateValueAndValidity();

    if (event.value == "false") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: await this.translate.get("warning").toPromise(),
        html: await this.translate.get("category_warning").toPromise(),
      });
    }
  }
  getIdModuleLibrary() {
    const currentRoute = this.router.url;
    const routesString = localStorage.getItem("menu-sidebar");
    const routesArray = JSON.parse(routesString);
    const routeMatch = routesArray.find(
      (route) => route.route === currentRoute
    );
    return routeMatch.id;
  }
  getFunctionalityPermissions(idModuleLibrary: number) {
    this.conectorModuleService
      .getFunctionalityPermissionByModuleAndUser(idModuleLibrary)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          const result = JSON.parse(data);
          result.forEach((item) => {
            if (item.code === "createCategory") {
              this.permissionCreateCategory = item.havePermission;
            } else if (item.code === "categoryCreateRol") {
              this.permissionCategoryCreateRol = item.havePermission;
            } else if (item.code === "categoryDeleteRol") {
              this.permissionCategoryDeleteRol = item.havePermission;
            } else if (item.code === "updateSubcategory") {
              this.permissionUpdateSubcategory = item.havePermission;
            } else if (item.code === "updateCategory") {
              this.permissionUpdateCategory = item.havePermission;
            } else if (item.code === "deleteCategory") {
              this.permissionDeleteCategory = item.havePermission;
            }
          });
        } else {
          console.log("Error get functionality permissions");
        }
      });
  }
  getDefaultFeedbackMessageCategory() {
    this.conector
      .getDefaultFeedbackMessageCategory()
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          this.messageFeedback = data.filter(
            (item) => item.languageCode === this.language.toUpperCase()
          )[0]["message"];
        } else {
          console.log("Error get default disableFeedback messages category");
        }
      });
  }
  enableAndDisableFeedback(event) {
    this.disabledFeedback = event.checked;
    if (this.disabledFeedback) {
      this.categoryForm.get("messageFeedback").setValue(this.messageFeedback);
      this.categoryForm
        .get("messageFeedback")
        .setValidators([Validators.required]);
    } else {
      this.categoryForm.get("messageFeedback").setValue(null);
      this.categoryForm.get("messageFeedback").clearValidators();
    }
    this.categoryForm.get("messageFeedback").updateValueAndValidity();
  }
}
