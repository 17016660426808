export class Pagination {
    length: number = 0;
    pageSize: number = 10
    init: number = 0
    end: number = 10
    pageSizeOptions: number[] = [5, 10, 25, 100]
    filterValue: String = ""


    changePage = (event) => {
        let pageIndex = event['pageIndex']
        this.pageSize = event['pageSize']

        length = Math.max(length, 0);
        const startIndex = pageIndex * this.pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + this.pageSize, length) : startIndex + this.pageSize;

        this.init = startIndex;
        this.end = endIndex

    }

    setPageIndex =( index) => {
      this.init = index;
    }

}
