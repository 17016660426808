import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  SimpleChanges,
  EventEmitter,
  Output,
  OnChanges,
  AfterViewInit,
} from "@angular/core";
import { Options } from "@angular-slider/ngx-slider";
@Component({
  selector: "slider-range",
  templateUrl: "./slider-range.component.html",
  styleUrls: ["./slider-range.component.scss"],
})
export class SlideRangeComponent implements OnChanges, AfterViewInit {
  @Input() minValue: any;
  @Input() maxValue: any;
  @Input() options: Options;
  @Input() lowRange: number;
  @Input() midRangeMin: number;
  @Input() midRangeMed: number;
  @Input() midRangeMax: number;
  @Input() highRange: number;
  @Output() minChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() maxChange: EventEmitter<number> = new EventEmitter<number>();
  gradientStyle: string;
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.updateGradient();
      this.applyGradientStyle();
    }
  }
  ngAfterViewInit() {
    this.applyGradientStyle();
  }

  updateGradient() {
    const ranges = this.getRanges();
    const gradientArray = ranges.map((range, index) => {
      const color = this.getTickColor(range.value);
      const startPercentage = index === 0 ? 0 : ranges[index - 1].value;
      const endPercentage = range.value;
      return `${color} ${startPercentage}%, ${color} ${endPercentage}%`;
    });

    this.gradientStyle = `linear-gradient(to right, ${gradientArray.join(
      ", "
    )})`;
  }
  getTickColor(value: number): string {
    const colorRanges = [
      { limit: this.lowRange, color: "#B5102F" },
      { limit: this.midRangeMin, color: "#E04B24" },
      { limit: this.midRangeMed, color: "#F3AF1F" },
      { limit: this.midRangeMax, color: "#D9B730" },
      { limit: this.highRange, color: "#8AB324" },
      { limit: Infinity, color: "#2F8C2B" },
    ];

    const foundRange = colorRanges.find((range) => value <= range.limit);
    return foundRange ? foundRange.color : "#2F8C2B";
  }
  getRanges() {
    const rangeValues = [
      { start: 0, end: this.lowRange },
      { start: this.lowRange, end: this.midRangeMin },
      { start: this.midRangeMin, end: this.midRangeMed },
      { start: this.midRangeMed, end: this.midRangeMax },
      { start: this.midRangeMax, end: this.highRange },
      { start: this.highRange, end: 100 },
    ];
    return rangeValues.map((range) => {
      const startPercentage = (range.start / 100) * 100;
      const endPercentage = (range.end / 100) * 100;
      const percentage = parseInt((endPercentage - startPercentage).toFixed(2));

      return {
        value: range.end,
        percentage: `${percentage}%`,
      };
    });
  }
  applyGradientStyle() {
    const ngxSlider = this.el.nativeElement.querySelector(".ngx-slider");
    if (ngxSlider) {
      this.renderer.setStyle(ngxSlider, "background", this.gradientStyle);
      this.renderer.setStyle(ngxSlider, "height", "14px");
    }
  }
  onSliderMinValueChange(value: number) {
    this.minChange.emit(value);
  }

  onSliderMaxValueChange(value: number) {
    this.maxChange.emit(value);
  }
}
