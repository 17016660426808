import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import * as moment from 'moment';
import { isNumeric } from 'rxjs/util/isNumeric';
import Swal from 'sweetalert2';
import { ResponseKnow } from '../../../interfaces/ResponseHandler';
import { ConectorKnowService } from '../../../services/conector-know.service';
import { SharedCommunicationService } from '../../../services/shared-communication.service';

@Component({
  selector: 'app-comunica-encuestas',
  templateUrl: './comunica-encuestas.component.html',
  styleUrls: ['./comunica-encuestas.component.css']
})
export class ComunicaEncuestasComponent implements OnInit {

  isEditable = false;
  idEditable = 0;
  isNewRequest= false;

  showRecords = true;
  isTitle = 'Crear nueva ';

  encuestaForm: FormGroup;
  editor;

  option_value = null;
  evaluaciones = [];

  categorias = [];
  areas = [];

  //Para el multiselect
  builder = [];

  //para el formulario de listado
  area_id = 0;

  articulos = [];

  //form extras
  correct;
  option_id;
  option_name;
  imageSrc = null;

  constructor(private shared: SharedCommunicationService, private snap: ActivatedRoute, private know: ConectorKnowService, private fb: FormBuilder) {


    this.encuestaForm = this.fb.group({
      area_id:        [0, [Validators.required]],
      title:         [null, [Validators.required]],
      video_link:     [null],
      imagen:         [null],
      select_media:   [null, [Validators.required]],
      question:       [null, [Validators.required]],
      start_date:     [null, [Validators.required]],
      end_date:       [null, [Validators.required]],
      question_type:  [null, [Validators.required]],
      enable_message: [1, [Validators.required]],
      active:         [1, [Validators.required]],
      message:        [null],
      push_text:      [null],
      push_notifications: [1, [Validators.required]],
    })
  }

  ngOnInit() {

    this.snap.params.subscribe( async(ks: Params) => {

      if (ks.id == null) {

        this.showRecords = true;

      } else {

        this.showRecords = false;

        this.isNewRequest = true;

        const { areas, success }: ResponseKnow = await this.know.questions_list().toPromise();

        this.areas = areas;

        if( isNumeric(ks.id) ) {

          this.isEditable = true;

          this.isTitle = 'Editar';

          const { question, questionOptions }: ResponseKnow =  await this.know.question_single(ks.id).toPromise();

          Object.keys(this.encuestaForm.value).forEach( key  => {
            this.encuestaForm.get(key).setValue( question[key] );
          });

          const ask = (question.image == null) ? 2 : 1 ;

          this.encuestaForm.get('select_media').setValue(ask);

          this.imageSrc = question.image_url;

          // Save global id
          this.idEditable = question.id;

          // Set date time
          this.encuestaForm.get('start_date').setValue( new Date(question.start_date));
          this.encuestaForm.get('end_date').setValue(question.end_date);

          // Pass data to builder
          questionOptions.forEach( item => {
            this.builder.push({
              option_name: item.name,
              option_id: item.id,
              correct: item.correct
            });
          });

        }

      }

    })

    this.basicLoad();

  }

  basicLoad() {
    this.know.questions_list().subscribe( (k: any) => {
      const { areas, questions} : any = k;
      this.evaluaciones = questions.data;
      this.areas =  (this.shared.isJsonObject(areas)) ? this.shared.ObjecToArray(areas) : areas;
    })
  }

  deleteRow(row) {

    Swal.fire({
      title: 'Confirmación requerida',
      icon: 'warning',
      html:'Esta acción es irreverisble.',
      showCancelButton: true,
      focusCancel: true,
      confirmButtonText:'Eliminar',
      cancelButtonText:'Cancelar',
    }).then( value => {

      console.info('opt', value);

    });

    // this.know.question_delete(row.id).subscribe( x => {
    //   Swal.fire('Completado', 'Cuestionario eliminado', 'success');
    //   this.basicLoad();
    // })
  }

  pickQuestionModel(event) {
    console.info('Opt', event)
    console.info( this.encuestaForm.get("question_type").value);
  }

  enviarFormulario(formdata: any) {

    //Static values
    formdata.location = 1;
    formdata.content_type = 2;
    formdata.active = 1;
    formdata.company_id = this.shared.KnowSesion();
    formdata["select-media"] = formdata.select_media;

    formdata.start_date = moment(formdata.start_date).format("YYYY-MM-DD HH:mm a").toString();
    formdata.end_date = moment(formdata.end_date).format("YYYY-MM-DD").toString();

    let data_send = new FormData();

    Object.keys(formdata).forEach( key => {
      data_send.append(key, formdata[key]);
    })

    this.builder.forEach( item => {
      data_send.append('option_name[]', item.option_name);
      data_send.append('option_id[]', item.option_id);
      data_send.append('correct[]', item.correct);
    });

    if(formdata.imagen) {

      console.info(formdata.imagen);

      data_send.set("imagen", formdata.imagen._files[0]);

    }

    if( this.isEditable == true ) {

      data_send.append("_method", "PATCH");

      data_send.append("id", this.idEditable + '');

      this.know.questions_put(data_send, this.idEditable).subscribe( (x: any) => {

        const { success, message }: any = x;

        if(success === false ) {

          Swal.fire('Error', message[0], 'error');

        } else {

          Swal.fire('Actualizado', message, 'success');

        }

      })

    } else {
      this.know.questions_post(data_send).subscribe( (x: any) => {

        const { success, message }: any = x;

        if(success === false ) {

          Swal.fire('Error', message[0], 'error');

        } else {

          Swal.fire('Completado', message, 'success');

          this.encuestaForm.reset();

          this.builder = [];

        }

      })

    }

  }

  eliminaRespuestas() {
    this.builder = [];
  }

  addOptions(value) {

    this.builder.push({
      option_name: value,
      option_id:0,
      correct:0
    });

    this.option_value = null;

  }

  setUnique(event) {
    console.info(event)
  }

  convertText(data) {
    return (data == 1) ? 'Si': 'No';
  }

  filterTable(ev) {
    console.info('e', ev);
  }

}
