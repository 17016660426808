import { UserComponent } from './user.component';
import { Routes } from '@angular/router';

export const UserLayoutRoutes: Routes = [
  {
    path: 'user',
    component: UserComponent,
    children: [
      {
        path: '**',
        redirectTo: '404'
      }
    ]
  }
];
