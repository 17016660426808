import { Component, OnInit } from "@angular/core";

import Swal from "sweetalert2";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../../services/authentication.service";
import { Router } from "@angular/router";
import { SharedCommunicationService } from "../../../services/shared-communication.service";
import { ConectorJsonService } from "../../../services/conector-json.service";
import { UserIdleService } from "angular-user-idle";

@Component({
  selector: "app-complaint",
  templateUrl: "./complaint.component.html",
  styleUrls: ["./complaint.component.css"],
})
export class ComplaintComponent implements OnInit {
  authForm: FormGroup;
  newComplaint: boolean = false;
  isFired: boolean = false;
  userIp: any;
  isLoading: boolean = false;
  value_company = null;
  newReport: boolean = true;
  year: number = new Date().getFullYear();

  constructor(
    private trans: TranslateService,
    private service: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private shared: SharedCommunicationService,
    private conector: ConectorJsonService,
    private translate: TranslateService
  ) {
    this.service.setLanguage().then((k) => this.trans.setDefaultLang(k));

    this.authForm = this.fb.group({
      reportingId: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.shared.httpCall.subscribe((k) => (this.isLoading = k));

    const { data }: any = this.conector.get_user_ip().toPromise();

    this.userIp = data;

    this.shared.setJsonObject("userIp", { userIp: data });
  }

  validateTicket(e) {
    e.IpHostName = this.userIp;

    this.conector.report_auth(e).subscribe((k: any) => {
      const { ok, data } = k;

      if (ok === true && data !== null) {
        localStorage.setItem("token", data);

        this.router.navigateByUrl("/tracking/information");
      } else {
        Swal.fire("Error", "Ticket or password not found", "error");
      }
    });
  }

  async saveComplaint() {
    const { ok, data }: any = await this.conector
      .reporte_validarCompany(this.value_company)
      .toPromise();

    if (ok) {
      if (!data.expiredEvaluationAccount) {
        await localStorage.setItem("company", this.value_company);
        this.router.navigateByUrl("/license");
      } else {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Su licencia caducó.",
          showConfirmButton: true,
        });
      }
    } else {
      Swal.fire(
        "Error",
        await this.translate.get("checkyoutlogindata").toPromise(),
        "error"
      );
    }
  }
}
