import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { CommonModule, DatePipe } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatBadgeModule,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatTreeModule,
} from "@angular/material";
import { MatStepperModule } from "@angular/material/stepper";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { EditorModule } from "@tinymce/tinymce-angular";
import { ChartModule } from "angular-highcharts";
import { UserIdleModule } from "angular-user-idle";
import { CKEditorModule } from "ckeditor4-angular";
import { JwPaginationModule } from "jw-angular-pagination";
import { MomentTimezonePickerModule } from "moment-timezone-picker";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ClipboardModule, ClipboardService } from "ngx-clipboard";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { NgPipesModule } from "ngx-pipes";
import { QuillModule } from "ngx-quill";

import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";
import { ChartsModule } from "./charts/charts.module";
import { AdminComponent } from "./layouts/admin/admin.component";
import { AdminLayoutModule } from "./layouts/admin/admin.module";
import { ComunikatHomeComponent } from "./layouts/comunikat/comunikat-home/comunikat-home.component";
import { ComunikatModule } from "./layouts/comunikat/comunikat.module";
import { EvaluationsComponent } from "./layouts/evaluations/evaluations.component";
import { EvaluationsModule } from "./layouts/evaluations/evaluations.module";
import { LearningHomeComponent } from "./layouts/learning/learning-home/learning-home.component";
import { LearningModule } from "./layouts/learning/learning.module";
import { ManagerComponent } from "./layouts/manager/manager.component";
import { ManagerModule } from "./layouts/manager/manager.module";
import { AppBreadcrumbComponent } from "./layouts/shared/breadcrumb/breadcrumb.component";
import { AppHeaderComponent } from "./layouts/shared/header/header.component";
import { AppSidebarComponent } from "./layouts/shared/sidebar/sidebar.component";
import { SupervisorComponent } from "./layouts/supervisor/supervisor.component";
import { SupervisorModule } from "./layouts/supervisor/supervisor.module";
import { UserComponent } from "./layouts/user/user.component";
import { UserLayoutModule } from "./layouts/user/user.module";
import { DemoMaterialModule } from "./material-module";
import { AllPipe, FilterParentPipe, GroupByPipe } from "./pipes/all.pipe";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { CustomErrorHandlerService } from "./services/custom-error-handler.service";
import CustomPaginatorIntlService from "./services/custom-paginator-intl.service";
import { InterceptorService } from "./services/interceptor.service";
import { SharedModule } from "./shared/shared.module";
import { SpinnerComponent } from "./shared/spinner.component";
import { CatalogsComponent } from "./views/admin/catalogs/catalogs.component";
import { AdministrationComponent } from "./views/authentication/administration/administration.component";
import { ComplaintComponent } from "./views/authentication/complaint/complaint.component";
import { EvaluationComponent } from "./views/authentication/evaluation/evaluation.component";
import { LicenseComponent } from "./views/authentication/license/license.component";
import { LoginComponent } from "./views/authentication/login/login.component";
import { PrivacyPolicyBackgroundComponent } from "./views/authentication/privacy-policy-background/privacy-policy-background.component";
import { RequestComponent } from "./views/authentication/request/request.component";
import { ResetComponent } from "./views/authentication/reset/reset.component";
import { SelectionComponent } from "./views/authentication/selection/selection.component";
import { ComunicaEncuestasResultadosComponent } from "./views/comunika/comunica-encuestas-resultados/comunica-encuestas-resultados.component";
import { ComunicaEvaluacionesResultadosComponent } from "./views/comunika/comunica-evaluaciones-resultados/comunica-evaluaciones-resultados.component";
import { ErrorComponent } from "./views/error/error.component";
import { ConfigProyectComponent } from "./views/ethics-survey/config-proyect/config-proyect.component";
import { EthicsSurveyTypeOneComponent } from "./views/ethics-survey/evaluated-answer/ethics-survey-type-one/ethics-survey-type-one.component";
import { ethics_survey_type_dialog } from "./views/ethics-survey/evaluated-answer/ethics-survey-type-two/ethics-survey-type-dialog.component";
import { EthicsSurveyTypeTwoComponent } from "./views/ethics-survey/evaluated-answer/ethics-survey-type-two/ethics-survey-type-two.component";
import { EvaluatedAnswerComponent } from "./views/ethics-survey/evaluated-answer/evaluated-answer.component";
import { CreateConfigComponent } from "./views/ethics-survey/modal/create-config/create-config.component";
import { DeleteConfigComponent } from "./views/ethics-survey/modal/delete-config/delete-config.component";
import { SendEmailUnanwersComponent } from "./views/ethics-survey/modal/send-email-unanwers/send-email-unanwers.component";
import { UpdateConfigComponent } from "./views/ethics-survey/modal/update-config/update-config.component";
import { ReportsEthicsSurveyComponent } from "./views/ethics-survey/reports-ethics-survey/reports-ethics-survey.component";
import { CompletionComponent } from "./views/evaluations/completion/completion.component";
import { CreateCollaboratorComponent } from "./views/general/create-collaborator/create-collaborator.component";
import { CallcenterComponent } from "./views/integriline/callcenter/callcenter.component";
import { CategoryAddonsComponent } from "./views/integriline/category-addons/category-addons.component";
import { CategoryRelationsComponent } from "./views/integriline/category-relations/category-relations.component";
import { CategoryComponent } from "./views/integriline/category/category.component";
import { ChartsComponent } from "./views/integriline/charts/charts.component";
import { DocsComponent } from "./views/integriline/docs/docs.component";
import { EnterpriseComponent } from "./views/integriline/enterprise/enterprise.component";
import { HomeSupervisorComponent } from "./views/integriline/home-supervisor/home-supervisor.component";
import { InformationComponent } from "./views/integriline/information/information.component";
import { GroupHandlersComponent } from "./views/integriline/modals/group-handlers/group-handlers.component";
import { GroupQuestionsComponent } from "./views/integriline/modals/group-questions/group-questions.component";
import { ReportComponent } from "./views/integriline/report/report.component";
import { TrackingComponent } from "./views/integriline/tracking/tracking.component";
import { CollaboratorComponent } from "./views/manager/collaborator/collaborator.component";
import { ConfigJobprofileTableComponent } from "./views/manager/components/config-jobprofile-table/config-jobprofile-table.component";
import { SlideRangeComponent } from "./views/manager/components/slider-range-component/slider-range.component";
import { ConfigurationComponent } from "./views/manager/configuration/configuration.component";
import { ExportDataComponent } from "./views/manager/export-data/export-data.component";
import { HomeManagerComponent } from "./views/manager/home-manager/home-manager.component";
import { IntegrityHomeComponent } from "./views/manager/home/integrity-home/integrity-home.component";
import { SurveyHomeComponent } from "./views/manager/home/survey-home/survey-home.component";
import { modalCommentComponent } from "./views/manager/integrity-reports/modal-comments/modal-comments.component";
import { modalProfileReportConfig } from "./views/manager/integrity-reports/modal-profile-report-config/modal-profile-report-config.component";
import { modalSeleccionReportConfig } from "./views/manager/integrity-reports/modal-seleccion-report-config/modal-seleccion-report-config.component";
import { JobProfileComponent } from "./views/manager/jobprofile/jobprofile.component";
import { LicenseManagerComponent } from "./views/manager/license/license.component";
import { MeComponent } from "./views/manager/me/me.component";
import { AdjustmentNormComponent } from "./views/manager/modals/adjustment-norm/adjustment-norm.component";
import { AnswersOwnQuestionComponent } from "./views/manager/modals/answers-own-questions/answers-own-questions.component";
import { BehavioralMapComponent } from "./views/manager/modals/behavioral-map/behavioral-map.component";
import { CatquestionsSelectionComponent } from "./views/manager/modals/catquestions-selection/catquestions-selection.component";
import { CollaboratorExitSurveysComponent } from "./views/manager/modals/collaborator-exit-surveys/collaborator-exit-surveys.component";
import { CollaboratorSurveysComponent } from "./views/manager/modals/collaborator-surveys/collaborator-surveys.component";
import { ComparativeComponent } from "./views/manager/modals/comparative/comparative.component";
import { ConfigJobProfilesComponent } from "./views/manager/modals/config-jobprofiles/config-jobprofiles.component";
import { ConfigurateJobProfileComponent } from "./views/manager/modals/configurate-jobprofile/configurate-jobprofile.component";
import { CreateAndUpdateOwnQuestionComponent } from "./views/manager/modals/create-and-update-own-questions/create-and-update-own-questions.component";
import { CreateJobProfileComponent } from "./views/manager/modals/create-jobprofile/create-jobprofile.component";
import { CreateManyCollaboratorComponent } from "./views/manager/modals/create-many-collaborator/create-many-collaborator.component";
import { CreateUserComponent } from "./views/manager/modals/create-user/create-user.component";
import { EvaluatedLinkComponent } from "./views/manager/modals/evaluated-link/evaluated-link/evaluated-link.component";
import { ExitSurveyFormComponent } from "./views/manager/modals/exit-survey-form/exit-survey-form.component";
import { ExportDataSurveyComponent } from './views/manager/modals/export-data-survey/export-data-survey.component';
import { GroupSurveyReportComponent } from "./views/manager/modals/group-survey-report/group-survey-report.component";
import { IFrameComponent } from "./views/manager/modals/iframe/iframe.component";
import { ImportCollaboratorComponent } from "./views/manager/modals/import-collaborator/import-collaborator.component";
import { JobProfileAnalisisRiesgoComponent } from "./views/manager/modals/jobprofile-analisisriesgo/jobprofile-analisisriesgo.component";
import { JobprofileSurveysComponent } from "./views/manager/modals/jobprofile-surveys/jobprofile-surveys.component";
import { LinkJobprofileComponent } from "./views/manager/modals/link-jobprofile/link-jobprofile.component";
import { ManageUserComponent } from "./views/manager/modals/manage-user/manage-user.component";
import { ModalChildrenComponent } from "./views/manager/modals/modal-children/modal-children.component";
import { OwnCompetencyFormComponent } from "./views/manager/modals/own-competency-form/own-competency-form.component";
import { QuestionsSelectionComponent } from "./views/manager/modals/questions-selection/questions-selection.component";
import { RankingContentComponent } from "./views/manager/modals/ranking-content/ranking-content.component";
import { SubmoduleCollaboratorComponent } from "./views/manager/modals/submodule-collaborator/submodule-collaborator.component";
import { TransferAllInformationOfAUserComponent } from "./views/manager/modals/transfer-all-information-of-auser/transfer-all-information-of-auser.component";
import { TycosComponent } from "./views/manager/modals/tycos/tycos.component";
import { ModuleComponent } from "./views/manager/module/module.component";
import { OwnComptencyComponent } from "./views/manager/own-competency/own-comptency/own-comptency.component";
import { OwnQuestionsComponent } from "./views/manager/own-questions/own-questions.component";
import { PermissionComponent } from "./views/manager/permission/permission.component";
import { permissionForAccountComponent } from "./views/manager/permissionForAccount/permissionForAccount.component";
import { RankingComponent } from "./views/manager/ranking/ranking/ranking.component";
import { ReportsComponent } from "./views/manager/reports/reports.component";
import { SurveyBuilderComponent } from "./views/manager/surveys/survey-builder/survey-builder.component";
import { SurveyCreateComponent } from "./views/manager/surveys/survey-create/survey-create.component";
import { SurveysAnswersComponent } from "./views/manager/surveys/surveys-answers/surveys-answers.component";
import { SurveysComponent } from "./views/manager/surveys/surveys.component";
import { PickersModule } from "./views/shared/pickerLoader.module";
import { MyEvaluationComponent } from "./views/validators/my-evaluation/my-evaluation.component";
import { ToDoComponent } from "./views/validators/to-do/to-do.component";
import { ToReportComponent } from "./views/validators/to-report/to-report.component";
import { CustomMatPaginatorIntl } from "./views/shared/mat-paginator";

// import { IconPickerModule } from 'ngx-icon-picker';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    ManagerComponent,
    UserComponent,
    CollaboratorComponent,
    JobProfileComponent,
    SubmoduleCollaboratorComponent,
    ReportsComponent,
    SpinnerComponent,
    LoginComponent,
    SelectionComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    AppBreadcrumbComponent,
    HomeManagerComponent,
    CatalogsComponent,
    ReportComponent,
    ConfigurationComponent,
    ModalChildrenComponent,
    TycosComponent,
    ModuleComponent,
    SelectionComponent,
    GroupQuestionsComponent,
    GroupHandlersComponent,
    ManageUserComponent,
    CreateUserComponent,
    SupervisorComponent,
    HomeSupervisorComponent,
    AllPipe,
    ErrorComponent,
    TrackingComponent,
    InformationComponent,
    ConfigProyectComponent,
    CreateConfigComponent,
    SendEmailUnanwersComponent,
    CreateCollaboratorComponent,
    CreateManyCollaboratorComponent,
    ImportCollaboratorComponent,
    EvaluatedAnswerComponent,
    CreateJobProfileComponent,
    ConfigJobProfilesComponent,
    PermissionComponent,
    permissionForAccountComponent,
    ConfigurateJobProfileComponent,
    JobProfileAnalisisRiesgoComponent,
    EnterpriseComponent,
    CallcenterComponent,
    ChartsComponent,
    EthicsSurveyTypeOneComponent,
    EthicsSurveyTypeTwoComponent,
    ethics_survey_type_dialog,
    ComunicaEvaluacionesResultadosComponent,
    LicenseComponent,
    CategoryComponent,
    CategoryAddonsComponent,
    ComunikatHomeComponent,
    LearningHomeComponent,
    FilterParentPipe,
    GroupByPipe,
    ResetComponent,
    ReportsEthicsSurveyComponent,
    ExportDataComponent,
    modalCommentComponent,
    modalSeleccionReportConfig,
    modalProfileReportConfig,
    EvaluationsComponent,
    ComunicaEncuestasResultadosComponent,
    ReportsEthicsSurveyComponent,
    UpdateConfigComponent,
    DeleteConfigComponent,
    CompletionComponent,
    SurveysComponent,
    SurveyCreateComponent,
    SurveyBuilderComponent,
    SurveysAnswersComponent,
    SurveyHomeComponent,
    IntegrityHomeComponent,
    ToReportComponent,
    ToDoComponent,
    JobprofileSurveysComponent,
    CollaboratorSurveysComponent,
    QuestionsSelectionComponent,
    IFrameComponent,
    MeComponent,
    LicenseManagerComponent,
    LinkJobprofileComponent,
    MyEvaluationComponent,
    AdministrationComponent,
    EvaluationComponent,
    ComplaintComponent,
    RequestComponent,
    CategoryRelationsComponent,
    OwnComptencyComponent,
    //JwPaginationComponent
    OwnCompetencyFormComponent,
    DocsComponent,
    EvaluatedLinkComponent,
    RankingComponent,
    RankingContentComponent,
    ComparativeComponent,
    AdjustmentNormComponent,
    BehavioralMapComponent,
    ConfigJobprofileTableComponent,
    PrivacyPolicyBackgroundComponent,
    CatquestionsSelectionComponent,
    SlideRangeComponent,
    GroupSurveyReportComponent,
    CollaboratorExitSurveysComponent,
    OwnQuestionsComponent,
    CreateAndUpdateOwnQuestionComponent,
    AnswersOwnQuestionComponent,
    ExitSurveyFormComponent,
    TransferAllInformationOfAUserComponent,
    ExportDataSurveyComponent,
    // MatPaginatorModule
  ],

  imports: [
    ChartModule,
    JwPaginationModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(AppRoutes),
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    //MatPaginatorModule,
    MatCheckboxModule,
    MatStepperModule,
    UserLayoutModule,
    QuillModule.forRoot(),
    MomentTimezonePickerModule,
    EditorModule,
    NgxDropzoneModule,
    NgxSliderModule,
    NgPipesModule,
    //FusionChartsModule,
    MatBadgeModule,
    MatTreeModule,
    CKEditorModule,
    // IconPickerModule,
    NgxQRCodeModule,
    ClipboardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient],
      },
    }),

    /**************************************** Start Layouts ****************************************/

    AdminLayoutModule,
    ManagerModule,
    SupervisorModule,
    SharedPipesModule,
    ComunikatModule,
    LearningModule,
    EvaluationsModule,

    /**************************************** End Layouts ****************************************/

    UserIdleModule.forRoot({ idle: 3600, timeout: 30, ping: 120 }),

    /**************************************** Arboles de datos ****************************************/

    PickersModule,

    DragDropModule,
    NgxMatSelectSearchModule,
    ChartsModule,
  ],

  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    DatePipe,
    ClipboardService,
  ],

  exports: [],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent],

  entryComponents: [
    ModalChildrenComponent,
    GroupHandlersComponent,
    GroupQuestionsComponent,
    ManageUserComponent,
    CreateUserComponent,
    SubmoduleCollaboratorComponent,
    TycosComponent,
    CreateCollaboratorComponent,
    CreateManyCollaboratorComponent,
    ImportCollaboratorComponent,
    CreateJobProfileComponent,
    ConfigurateJobProfileComponent,
    JobProfileAnalisisRiesgoComponent,
    EvaluatedLinkComponent,
    GroupSurveyReportComponent,
    CreateAndUpdateOwnQuestionComponent,
    AnswersOwnQuestionComponent,
    ExitSurveyFormComponent,
    TransferAllInformationOfAUserComponent,
    QuestionsSelectionComponent,
  ],
})
export class AppModule { }
