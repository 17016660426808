import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedCommunicationService } from '../../../services/shared-communication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuracion-empresa',
  templateUrl: './configuracion-empresa.component.html',
  styleUrls: ['./configuracion-empresa.component.css']
})

export class ConfiguracionEmpresaComponent implements OnInit {

  companyFrm: FormGroup;

  constructor(private shared: SharedCommunicationService, private snap: ActivatedRoute, private know: ConectorKnowService, private fb: FormBuilder) {

    this.companyFrm = this.fb.group({
      "id": [null],
      "city_id": [null],
      "department_id": [null],
      "city_name": [null],
      "country_name": [null],
      "name": [null],
      "address": [null],
      "phone": [null],
      "email": [null],
      "contact_name": [null],
      "contact_phone": [null],
      "color": [null],
      "coordinates": [null],
      "welcome_message": [null],
      "image": [null],
      "active": [null]
    })
  }

  ngOnInit() {

    this.know.company_get().subscribe( (res: any) => {
      Object.keys(this.companyFrm.value).forEach( item => {
        this.companyFrm.get(item).setValue(res.company[item]);
      });

      console.info('GC', res);

    })

  }

  sendUpdate(formdata) {

    formdata._method = "PATCH";

    let model = new FormData();

    Object.keys(formdata).forEach( item => {
      model.append(item, formdata[item]);
    })

    this.know.company_update(model).subscribe( (res: ResponseKnow) => {
      Swal.fire( ( res.success == true ) ? 'Completado': 'Error', res.message, (res.success == true) ? 'success' : 'error');
    });

  }

}
