import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router } from '@angular/router';
import { SharedCommunicationService } from '../../../services/shared-communication.service';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { UserIdleService } from 'angular-user-idle';

import Swal from 'sweetalert2';
import * as jwt from 'jwt-decode';
import * as moment from 'moment';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {

  authForm: FormGroup;
  isFired: boolean = false;
  userIp: any;
  isLoading: boolean = false;
  year : number = new Date().getFullYear();

  constructor(
    private trans: TranslateService,
    private service: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private shared: SharedCommunicationService,
    private conector: ConectorJsonService,
    private translate: TranslateService,
    private userIdle: UserIdleService) {

    this.service.setLanguage().then(k => this.trans.setDefaultLang(k));

    this.authForm = this.fb.group({
      company: [null, Validators.required],
      username: [null, Validators.required],
      password: [null],
      verification: [null]
    });

  }

  ngOnInit() {

    this.shared.httpCall.subscribe(k => this.isLoading = k);

    const { data }: any = this.conector.get_user_ip().toPromise();

    this.userIp = data;

    this.shared.setJsonObject('userIp', { userIp: data });

  }

  validateSurvey(formdata) {

    formdata.IpHostName = this.userIp;
    this.conector.postEvaluation_login(formdata).subscribe((res: any) => {

      console.info('e', res);

      if (res.ok == true) {

        localStorage.setItem('token_evaluaciones', res.data);

        this.router.navigateByUrl('evaluations');

      } else {
        Swal.fire('Error', res.msg, 'error');
      }

    });

  }

}
