import { MatSelectChange } from "@angular/material/select";
import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { OwnCompetencyService } from "../../../../services/own-competency.service";

@Component({
  selector: "app-own-competency-form",
  templateUrl: "./own-competency-form.component.html",
  styleUrls: ["./own-competency-form.component.css"],
})
export class OwnCompetencyFormComponent implements OnInit {
  ownCompetencyForm: FormGroup;
  categories = [];
  id = null;
  name = "";
  category = "";
  otherCategory = "";
  definition = "";
  fightSpirit = 10;
  relationship = 10;
  pause = 10;
  normsAdaptation = 10;
  emotionalManagement = 10;

  constructor(
    private form: FormBuilder,
    private ownCompetencyService: OwnCompetencyService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.ownCompetencyForm = this.form.group({
      id: [null],
      name: [null, Validators.required],
      category: [null, Validators.required],
      otherCategory: [null],
      definition: [null, Validators.required],
      fightSpirit: [0, Validators.required],
      relationship: [0, Validators.required],
      pause: [0, Validators.required],
      normsAdaptation: [0, Validators.required],
      emotionalManagement: [0, Validators.required],
    });

    if (!!activatedRoute.snapshot.params.id) {
      this.id = parseInt(activatedRoute.snapshot.params.id);
      this.ownCompetencyForm.value.id = this.id;
    }
  }

  ngOnInit() {
    this.ownCompetencyService
      .getCompetencyCategories()
      .subscribe((response: object[]) => {
        this.categories = response;

        if (!!this.id) {
          this.getOwnCompetency();
        }
      });
  }

  getOwnCompetency() {
    this.ownCompetencyService
      .getOwnCompetencyById(this.id)
      .subscribe((response: any) => {
        this.name = response.Name;
        this.category = response.Category;
        this.definition = response.Definition;
        this.fightSpirit = response.FightSpirit;
        this.relationship = response.Relationship;
        this.pause = response.Pause;
        this.normsAdaptation = response.NormsAdaptability;
        this.emotionalManagement = response.EmotionalManagment;

        if (!!!this.categories.find((c) => c.nombre === response.Category)) {
          this.category = "OTRA";
          this.otherCategory = response.Category;
        }
      });
  }

  save() {
    if (!this.ownCompetencyForm.valid) {
      return;
    }

    if (this.ownCompetencyForm.value.category === "OTRA") {
      this.ownCompetencyForm.value.category =
        this.ownCompetencyForm.value.otherCategory;
    }

    if (!!!this.id) {
      this.create();
    } else {
      this.edit();
    }
  }

  create() {
    this.ownCompetencyService
      .postOwnCompetency(this.ownCompetencyForm.value)
      .subscribe(() => {
        Swal.fire({
          text: "Competencia propia creada",
          icon: "success",
          timer: 1300,
          showConfirmButton: false,
        }).then(() => this.router.navigateByUrl("/manager/OwnCompetencies"));
      }, this.showErrorMessage);
  }

  edit() {
    this.ownCompetencyForm.value.id = this.id;
    this.ownCompetencyService
      .putOwnCompetency(this.ownCompetencyForm.value)
      .subscribe(() => {
        Swal.fire({
          text: "Competencia propia modificada",
          icon: "success",
          timer: 1300,
          showConfirmButton: false,
        }).then(() => this.router.navigateByUrl("/manager/OwnCompetencies"));
      }, this.showErrorMessage);
  }

  showErrorMessage(error: any) {
    console.error(error);
    Swal.fire(
      "Error",
      "Ha existido un error al guardar la competencia propia",
      "error"
    );
  }

  onCategorySelectionChange(selectedOption: MatSelectChange) {
    const nameCategory = selectedOption.value;
    const selectedCategory = this.categories.find(
      (category) => category.nombre === nameCategory
    );

    const description =
      nameCategory === "OTRA" ? null : selectedCategory.descripcion;
    this.ownCompetencyForm.get("definition").setValue(description);
  }
}
