import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from '../../../services/authentication.service';
import { ConnectorExportDataService } from '../../../services/exportData/exportData.service';
import { ConnectorJobProfileService } from '../../../services/job-profile.service';
import Swal from 'sweetalert2';
import { ConnectorGroupService } from '../../../services/Admin/conector-group.service';

@Component({
	selector: 'app-export-data',
	templateUrl: './export-data.component.html',
	styleUrls: ['./export-data.component.css'],
})
export class ExportDataComponent implements OnInit {
	// ::::::::::::::::::::::::::::::::::::::::
	// search select
	// ::::::::::::::::::::::::::::::::::::::::
	//
	/** list of banks */
	protected proyectList = [];

	/** control for the selected bank */
	public proyectCtrl: FormControl = new FormControl();

	/** control for the MatSelect filter keyword */
	public proyectFilterCtrl: FormControl = new FormControl();

	/** list of banks filtered by search keyword */
	public filteredProyect: ReplaySubject<Proyect[]> = new ReplaySubject<Proyect[]>(1);

	@ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

	/** Subject that emits when the component has been destroyed. */
	protected _onDestroy = new Subject<void>();

	// ::::::::::::::::::::::::::::::::::::::::
	// Global
	// ::::::::::::::::::::::::::::::::::::::::

	module = '';
	viewTypeEvaluated = '';
	viewCalendar = true;
	downloadDisabled = false;

	jobSubmodules: any;
	viewStepProyect: true;
	ListGetUsersProyect: any[];
	ListGropup = [];

	formTypeProyect: FormGroup;
	formNameProyect: FormGroup;
	formCalendar: FormGroup;

	formJob: FormGroup;
	selectListJob = [];
	idSelectListJob = 0;
	firsSelectedJob = 0;
	finalSelectedJob = 0;

	selectProyectView = false;
	jobProfileList;
	moduleSelected: number = 0;
	jobProfileSelected: number = 0;
	groupsCategoryCompany: any = [];
	subgroupsOfSelectedGroup: any = [];
	groupSelected: number = 0;
	subgroupSelected: number = 0;
	constructor(
		public translate: TranslateService,
		public auth: AuthenticationService,
		private _formBuilder: FormBuilder,
		private conectorExportData: ConnectorExportDataService,
		private job: ConnectorJobProfileService,
		private groupService: ConnectorGroupService
	) {
		this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));

		this.formNameProyect = this._formBuilder.group({
			proyectCtrl: [''],
			proyectFilterCtrl: [''],
		});

		this.formTypeProyect = this._formBuilder.group({
			typeProyect: [''],
			jobProfile: 0,
		});

		this.formCalendar = this._formBuilder.group({
			initialDate: [null],
			endlDate: [null],
		});

		this.formJob = this._formBuilder.group({
			ListJob: this._formBuilder.array([
				{
					JobId: new FormControl(0),
				},
			]),
		});
	}

	ngOnInit() {
		this.getSubModules(localStorage.getItem('module'));
		this.job.jobprofile_all().subscribe((response: any) => {
			const { ok, data, msg } = response;
			data.sort(function (a, b) {
				return a.label.localeCompare(b.label);
			});
			this.jobProfileList = data;
			this.jobProfileList.unshift({ id: 0, label: '' });
		});
		this.groupService.getAllGroups().subscribe((groups) => {
			this.groupsCategoryCompany = [...groups, { id: 0, name: '-Seleccionar grupo-' }];
			this.groupsCategoryCompany.sort((a, b) => a.id - b.id);
		});
	}
	getSubgroupsOfSelectedGroup(seletedGroupId: number) {
		this.groupSelected = seletedGroupId;
		this.subgroupSelected = 0;
		this.subgroupsOfSelectedGroup = [];
		if (seletedGroupId != 0) {
			this.groupService
				.getAllSubGroupsByGroupId(seletedGroupId)
				.subscribe((subgroups) => {
					if (subgroups.length > 0) {
						this.subgroupsOfSelectedGroup = [...subgroups, { id: 0, name: '-Seleccionar subgrupo-' }];
						this.subgroupsOfSelectedGroup.sort((a, b) => a.id - b.id);
					}
				});
		}
	}
	getSubgroupSelected(selectedSubgroupId: number) {
		this.subgroupSelected = 0;
		if (selectedSubgroupId != 0) {
			this.subgroupSelected = selectedSubgroupId;
		}
	}
	getJobSelected(selectedJobId: number) {
		this.jobProfileSelected = selectedJobId;
	}

	get areAllInputsHadValue(): boolean {
		return this.moduleSelected != 0 && this.formCalendar.get('initialDate').value && this.formCalendar.get('endlDate').value;
	}

	// ::::::::::::::::::::::::::::::::::::::::
	// Get Change typeProyect local module
	// ::::::::::::::::::::::::::::::::::::::::
	get changeModule(): string {
		if (this.module != localStorage.getItem('module')) {
			this.module = localStorage.getItem('module');
			this.getSubModules(this.module);
		}
		return '';
	}
	// ::::::::::::::::::::::::::::::::::::::::
	// control select
	// ::::::::::::::::::::::::::::::::::::::::
	addJob(id, index) {
		console.log('id, index' + id + ' ' + index);
		const trabajo = this._formBuilder.control({
			JobId: new FormControl(id),
		});
		let arrayJob = this.formJob.get('ListJob') as FormArray;
		arrayJob.push(trabajo);
	}

	deleteArrayListJob(index, type) {
		this.ListGropup = [];
		this.firsSelectedJob = 0;
		this.finalSelectedJob = 0;
		let ListJobArray = this.formJob.get('ListJob') as FormArray;
		if (type == 1) {
			const tamaño = ListJobArray.controls.length;
			let del = index + 1;
			if (ListJobArray.controls.length - index != 1) {
				for (index; index < tamaño; index++) {
					if (ListJobArray.controls[del]) {
						ListJobArray.removeAt(del);
					}
				}
			}
		}
	}
	// ::::::::::::::::::::::::::::::::::::::::
	// change type proyect
	// ::::::::::::::::::::::::::::::::::::::::

	getChangeTypeProyect(id: number) {
		this.moduleSelected = id;
		// this.formTypeProyect.get('jobProfile').setValue(0);

		// if (this.module == 'Survey') {
		// 	this.viewCalendar = false;
		// 	this.selectProyectView = true;
		// 	this.viewTypeEvaluated = 'Encuestados';
		// } else {
		// 	this.viewTypeEvaluated = 'Evaluados';
		// 	if (id == 5) {
		// 		this.viewCalendar = false;
		// 		this.selectProyectView = true;
		// 	} else {
		// 		this.viewCalendar = true;
		// 		this.selectProyectView = false;
		// 		this.viewSelectGroup(0);
		// 	}
		// }

		// this.changeTypeProyect(id);

		// // this.getSubModules(this.module)
	}
	getSubModules(module) {
		this.jobSubmodules = [];
		this.conectorExportData.getSubModule({ modulo: module, puesto: 1 }).subscribe((e: any) => {
			this.jobSubmodules = e.data.filter(
				(f) =>
					f.idSubmodulo == 1 ||
					f.idSubmodulo == 3 ||
					f.idSubmodulo == 2 ||
					f.idSubmodulo == 4 ||
					f.idSubmodulo == 6
			);
		});
	}

	changeTypeProyect(id) {
		this.conectorExportData.ethicsSurveyGet(id).subscribe((response: any) => {
			console.log(response);

			const { data, msg } = response;
			this.viewStepProyect = true;
			this.proyectList = data.map((proyect) => ({
				id: proyect.id,
				name: proyect.nameProyect,
				total: proyect.evaluatedTotal,
			}));

			// search select

			// set initial selection
			this.proyectCtrl.setValue(this.proyectList[10]);

			// load the initial bank list
			this.filteredProyect.next(this.proyectList.slice());

			// listen for search field value changes
			this.formNameProyect.controls['proyectFilterCtrl'].valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					this.filterProyect();
				});
		});
	}
	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}
	// ::::::::::::::::::::::::::::::::::::::::
	// select search proyect
	// ::::::::::::::::::::::::::::::::::::::::

	protected filterProyect() {
		if (!this.proyectList) {
			return;
		}
		// get the search keyword
		let search = this.formNameProyect.controls['proyectFilterCtrl'].value;
		if (!search) {
			this.filteredProyect.next(this.proyectList.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the banks
		this.filteredProyect.next(this.proyectList.filter((bank) => bank.name.toLowerCase().indexOf(search) > -1));
	}
	viewSelectGroup(id) {
		this.deleteArrayListJob(0, 1);
		let idSubModule = this.formTypeProyect.controls['typeProyect'].value;
		this.selectListJob = [];
		this.conectorExportData.getGroupbyIdDad(id, idSubModule).subscribe((e: any) => {
			this.selectListJob = e.data.map((data) => {
				return {
					id: data.id,
					name: data.name,
					parent: data.parent,
					total: data.total,
				};
			});
		});
	}

	// ::::::::::::::::::::::::::::::::::::::::
	// Export data
	// ::::::::::::::::::::::::::::::::::::::::
	exportData() {
		this.updateListGropup();
		var groupId = this.subgroupSelected === 0 ? this.groupSelected : this.subgroupSelected;
		let information = {
			moduleId: this.moduleSelected,
			initialDate: this.formCalendar.controls['initialDate'].value,
			endDate: this.formCalendar.controls['endlDate'].value,
			jobId: this.jobProfileSelected,
			groupId: groupId,
		};
		this.conectorExportData.exportDataGet(information).subscribe((response: any) => {
			this.downloadFile(response.data);
		});
	}
	updateListGropup() {
		this.ListGropup = [];
		let arrayJob = this.formJob.get('ListJob') as FormArray;
		arrayJob.controls.forEach((element) => {
			console.log(element.value.JobId.value);
			this.ListGropup.push(element.value.JobId.value);
		});
		this.ListGropup.push(this.finalSelectedJob);
	}
	public downloadFile(response: any, filename = 'data') {
		const { testType, headers, data } = response;
		if (data == null || data.length == 0) {
			return Swal.fire({
				icon: 'error',
				text: 'No hay datos para exportar',
			});
		}
		let csvData = '';

		if (testType === 'Profiles') {
			csvData = this.ConvertToCSVProfileTest(data, headers);
		} else {
			csvData = this.ConvertToCSV(data);
		}

		let encodedData = [];

		for (let i = 0; i < csvData.length; i++) {
			encodedData.push(csvData.charCodeAt(i));
		}

		let blob = new Blob([new Uint8Array(encodedData)], {
			type: 'text/csv;charset=iso-8859-1;',
		});
		let dwldLink = document.createElement('a');
		let url = URL.createObjectURL(blob);
		let isSafariBrowser =
			navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {
			dwldLink.setAttribute('target', '_blank');
		}
		dwldLink.setAttribute('href', url);
		dwldLink.setAttribute('download', filename + '.csv');
		dwldLink.style.visibility = 'hidden';
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

	private ConvertToCSV(objArray: any): string {
		if (!objArray.length) {
			return '';
		}
		const oldHeaderList = Object.keys(objArray[0]);
		const newHeaderList = oldHeaderList.map((header) => header.toUpperCase());
		let csvStr = newHeaderList.join(':') + '\n';
		for (const obj of objArray) {
			const row = [];
			for (const header of oldHeaderList) {
				let cellValue = obj[header];
				if (cellValue === null || cellValue === undefined) {
					cellValue = '';
				}
				if (typeof cellValue === 'string') {
					cellValue = cellValue.replace(/\n/g, ' ');
				}
				row.push(cellValue);
			}
			csvStr += row.join(':') + '\n';
		}
		return csvStr;
	}

	private ConvertToCSVProfileTest(data: any, headers: any) {
		const cleanedData = this.CleanData(data);

		let csvStr = '';
		csvStr += headers.join(':') + '\n';

		for (const obj of cleanedData) {
			const row = headers.map((header) => {
				let cellValue = obj[header];
				if (cellValue === null || cellValue === undefined) {
					cellValue = '';
				} else if (typeof cellValue === 'string') {
					cellValue = `"${cellValue.replace(/"/g, '""')}"`;
				}
				return cellValue;
			});
			csvStr += row.join(':') + '\n';
		}
		return csvStr;
	}

	private CleanData(data: any[]): any[] {
		return data.map((obj) => {
			const cleanedObj = { ...obj };
			for (const key in cleanedObj) {
				if (typeof cleanedObj[key] === 'string') {
					cleanedObj[key] = cleanedObj[key].replace(/\n|:/g, ' ');
				}
			}
			return cleanedObj;
		});
	}
}

// ::::::::::::::::::::::::::::::::::::::::
// structure search type proyect
// ::::::::::::::::::::::::::::::::::::::::
export interface Proyect {
	id: string;
	name: string;
	total: string;
}
