import { DocsComponent } from "./views/integriline/docs/docs.component";
import { ResetComponent } from "./views/authentication/reset/reset.component";
import { LicenseComponent } from "./views/authentication/license/license.component";
import { CallcenterComponent } from "./views/integriline/callcenter/callcenter.component";
import { EnterpriseComponent } from "./views/integriline/enterprise/enterprise.component";
import { AuthGuardService } from "./services/auth-guard.service";
import { InformationComponent } from "./views/integriline/information/information.component";
import { TrackingComponent } from "./views/integriline/tracking/tracking.component";
import { ErrorComponent } from "./views/error/error.component";
import { SelectionComponent } from "./views/authentication/selection/selection.component";
import { ReportComponent } from "./views/integriline/report/report.component";
import { Routes } from "@angular/router";
import { ToReportComponent } from "./views/validators/to-report/to-report.component";
import { ToDoComponent } from "./views/validators/to-do/to-do.component";
import { MyEvaluationComponent } from "./views/validators/my-evaluation/my-evaluation.component";
import { AdministrationComponent } from "./views/authentication/administration/administration.component";
import { ComplaintComponent } from "./views/authentication/complaint/complaint.component";
import { EvaluationComponent } from "./views/authentication/evaluation/evaluation.component";
import { RequestComponent } from "./views/authentication/request/request.component";
import { PrivacyPolicyBackgroundComponent } from "./views/authentication/privacy-policy-background/privacy-policy-background.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "/selection",
    pathMatch: "full",
  },
  {
    path: "selection",
    component: SelectionComponent,
  },
  {
    path: "report",
    component: ReportComponent,
  },
  {
    path: "request/:id",
    component: RequestComponent,
  },
  {
    path: "reset/:id",
    component: ResetComponent,
  },
  {
    path: "enterprise/:id",
    component: EnterpriseComponent,
  },
  {
    path: "callcenter",
    component: CallcenterComponent,
  },
  {
    path: "license",
    component: LicenseComponent,
  },
  {
    path: "link-to/:id",
    component: ToReportComponent,
  },
  {
    path: "to-do/:id",
    component: ToDoComponent,
  },
  {
    path: "administration",
    component: AdministrationComponent,
  },
  {
    path: "complaint",
    component: ComplaintComponent,
  },
  {
    path: "evaluation",
    component: EvaluationComponent,
  },
  {
    path: "privacy-policy-background/:id",
    component: PrivacyPolicyBackgroundComponent,
  },
  {
    path: "my-evaluation/:id",
    component: MyEvaluationComponent,
  },
  {
    path: "tracking",
    children: [
      {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
      },
      {
        path: "login",
        component: TrackingComponent,
      },
      {
        path: "information",
        component: InformationComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "docs",
    component: DocsComponent,
  },
  {
    path: "404",
    component: ErrorComponent,
  },
  {
    path: "**",
    redirectTo: "selection",
  },
];
