import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { AuthenticationService } from '../../../services/authentication.service';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { GenericCatalogsService } from '../../../services/GenericCatalogs.service';
import DbCountryResponse from '../../../types/db-catalog-country-response';
import DbNationality from '../../../types/db-catalog-nacionalities-response';
import DbMyUserProfile from '../../../types/db-my-user-profile-response';

@Component({
	selector: 'app-me',
	templateUrl: './me.component.html',
	styleUrls: ['./me.component.css'],
})
export class MeComponent implements OnInit, OnDestroy {
	public configForm: FormGroup;
	public destroy$: Subject<boolean> = new Subject<boolean>();
	public countriesList: DbCountryResponse[];
	public nacionalitiesList: DbNationality[];
	public genresList: any[];
	constructor(
		private conector: ConectorJsonService,
		private fb: FormBuilder,
		private conectorgeneric: GenericCatalogsService,
		public translate: TranslateService,
		private auth: AuthenticationService
	) {
		this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
		this.initializeForm();
	}
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
	ngOnInit(): void {
		this.initMyUserProfile();
		this.initCountries();
		this.initNacionalities();
	}
	private initCountries(): void {
		this.conectorgeneric
			.catalogCountries()
			.pipe(
				takeUntil(this.destroy$),
				map((response) => response.data)
			)
			.subscribe((data: DbCountryResponse[]) => (this.countriesList = data));
	}

	private initNacionalities(): void {
		this.conectorgeneric
			.catalogNationalities()
			.pipe(
				takeUntil(this.destroy$),
				map((response) => response.data)
			)
			.subscribe((data: DbNationality[]) => (this.nacionalitiesList = data));
	}

	private initMyUserProfile(): void {
		this.conector
			.getMyUserProfile()
			.pipe(
				takeUntil(this.destroy$),
				map((response) => response.data),
				tap((data) => {
					data.lastname = (data.firstname + ' ' + data.lastname).trim();
					data.firstname = '';
					this.configForm.patchValue(data);
				})
			)
			.subscribe((data: DbMyUserProfile) => (this.genresList = data.genres));
	}

	update(data: DbMyUserProfile) {
		this.conector.my_profile_put(data).subscribe(async (res: any) => {
			if (res.ok === true) {
				await Swal.fire({
					position: 'center',
					icon: 'success',
					title: await this.translate.get('Completado').toPromise(),
					html: await this.translate.get('PerfilActualizado').toPromise(),
				});
			} else {
				await Swal.fire({
					position: 'center',
					icon: 'error',
					title: 'Error',
					html: res.msg,
				});
			}
		});
	}

	private initializeForm(): void {
		const formFields = {
			name: [null, Validators.required],
			firstname: [null, Validators.required],
			lastname: [null, Validators.required],
			phone: [null, Validators.required],
			email: [null, Validators.required],
			require2AF: [false, Validators.required],
			birthday: [null, Validators.required],
			country: [null, Validators.required],
			genre: [null, Validators.required],
			ageRange: [null, Validators.required],
			language: [null, Validators.required],
		};
		this.configForm = this.fb.group(formFields);
	}
}
