import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RankingService {
  constructor(private http: HttpClient) {}

  getJobsWithEvaluations() {
    return this.http.get(
      `${environment.server}/jobProfile/getJobsWithEvaluations`
    );
  }

  getRankingEvaluateds(body: object, init = 0, limit = 10) {
    return this.http.post(
      `${environment.server}/evaluation/rankingEvaluatedsList?limit=${limit}&offset=${init}`,
      body
    );
  }

  getEvaluatedsRankingInformation(body) {
    return this.http.post(`${environment.server}/evaluation/getRanking`, body);
  }
}
