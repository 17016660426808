import { Component, EventEmitter, Input, OnInit, Output, ViewChild  } from '@angular/core';

@Component({
  selector: 'app-collaborator-exit-surveys',
  templateUrl: './collaborator-exit-surveys.component.html',
  styleUrls: ['./collaborator-exit-surveys.component.css']
})
export class CollaboratorExitSurveysComponent implements OnInit {

  @Input() evaluationsExit = [];

  openModalExitSurvey: boolean = false;
  evaluationsExitSurvey = [];

  constructor() { }

  ngOnInit() {

  }

}
