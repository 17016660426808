import {AuthenticationService} from './authentication.service';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {UserIdleService} from 'angular-user-idle';
import {SharedCommunicationService} from './shared-communication.service';
import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

    constructor(private router: Router,
                private translate: TranslateService,
                private auth: AuthenticationService,
                private sharing: SharedCommunicationService,
                private userIdle: UserIdleService) {
        this.auth.setLanguage().then(k => this.translate.setDefaultLang(k));
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Reset idle
        this.userIdle.resetTimer();

        // Token
        let token = localStorage.getItem('token');

        // Peticiones donde NO se debe mostrar cortina de loading
        const noScreen = [
            'photo'
        ];

        if (!noScreen.find(x => request.url.includes(x))) {

            // Barra de progreso en peticiones http
            this.sharing.httpCall.next(true);

        }

        // Lista de urls sin token
        const exceptions = [
            'out',
            'login'
        ];

        // valida las excepciones
        const found = exceptions.find(I => request.url.includes(I));

        /* si no esta en lista de exeptions */
        if (!found) {

            /* Filtro para la carga de archivos */
            if (request.url.includes('store') || request.url.includes('ticket_upload')) {

                request = request.clone({
                    setHeaders: {
                        'Authorization': `Bearer ${token}`
                    }
                });

            } else if (request.headers.get('App') == 'Denuncia') {

                request = request.clone();

            } else {

                // Excepcion para evaluaciones
                if (request.headers.get('App') != null) {
                    token = localStorage.getItem('token_evaluaciones');
                }

                request = request.clone({
                    setHeaders: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                });

            }

        }

        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 403 || error.status === 401) {

                    this.translate.get('session-expired').subscribe(k => {
                        Swal.fire({
                            title: 'Error',
                            html: k,
                            icon: 'error'
                        }).then(() => {
                            this.router.navigateByUrl('selection');
                            //this.router.navigateByUrl('/');
                        });
                    });

                } else {
                    console.info('ErrorHG', error);
                    return throwError(error);
                }
            }), finalize(() => {
                this.sharing.httpCall.next(false);
            })
        );

    }

}
