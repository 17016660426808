import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
  })
  
  export class ConnectorHiringroomService {

    constructor(private http: HttpClient) { }

    authorize(account) {

        // let headers = new HttpHeaders({
        //     // 'Content-type': 'application/json',
        //     'Access_Control_Allow_Origin': '*',
        //     'Access_Control_Allow_Methods': 'GET, POST, DELETE, PUT, HEAD, OPTIONS',
        //     'Access_Control_Allow_Headers': 'Origin, Accept, Authorization, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers', 
        //     'Access_Control_Expose_Headers': 'Content-Length, X-JSON'
        //   });

        // let params = new HttpParams()
        //     .set('Accept', 'text/html')
        //     .set('response_type', 'authcode')
        //     .set('client_id', environment.hiringroom_client_id)
        //     .set('account', account)
        //     .set('state', 'state');

        // return this.http.get(`v0/oauth2/authorization`, { headers, params });

        // fetch(`${environment.hiringroom_server}/oauth2/authorization?response_type=authcode&client_id=${environment.hiringroom_client_id}&account=${account}&state=state`).then(function(response){
        //     response.text().then(function(html){
        //         var iframe = document.createElement('iframe');
        //         document.body.appendChild(iframe);
        //         iframe.contentWindow.document.open();
        //         iframe.contentWindow.document.write(html);
        //         iframe.contentWindow.document.close();
        //     });
        // });


        return fetch(`${environment.hiringroom_server}/oauth2/authorization?response_type=authcode&client_id=${environment.hiringroom_client_id}&account=${account}&state=state`, {
          method: 'GET',
          mode: 'no-cors',
          // headers: new Headers(
          //   {
          //     'Access_Control_Allow_Origin': '*',
          //     'Access_Control_Allow_Methods': 'GET, POST, DELETE, PUT, HEAD, OPTIONS',
          //     'Access_Control_Allow_Headers': 'Origin, Accept, Authorization, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers', 
          //     'Access_Control_Expose_Headers': 'Content-Length, X-JSON'
          //   }),
            redirect: 'follow'
        });

        // let apiURL = `${environment.hiringroom_server}/oauth2/authorization?response_type=authcode&client_id=${environment.hiringroom_client_id}&account=${account}&state=state`;

        // return this.http.jsonp(apiURL, "callback");

    }

  }