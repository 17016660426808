import {
  Component,
  Input,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { Router } from "@angular/router";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import Swal from "sweetalert2";
import { AuthenticationService } from "../../../services/authentication.service";
import { ConectorJsonService } from "../../../services/conector-json.service";
import { SharedCommunicationService } from "../../../services/shared-communication.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["../../manager/manager.component.css"],
})
export class AppHeaderComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};

  @Input() username: any;
  @Input() lang: string = "en-US";
  @Input() multiaccount = 0;

  notificationList: any = [];
  accounts: any[] = [];
  enableMc = false;
  blink = false;
  currentAccountName: string = "";

  constructor(
    private sharedService: SharedCommunicationService,
    private auth: AuthenticationService,
    private router: Router,
    private conector: ConectorJsonService
  ) { }

  ngOnInit(): void {
    this.sharedService.contableNotifications.subscribe((data: any) => {
      if (data != null) {
        this.notificationList = data;

        if (Array.isArray(this.notificationList)) {
          const b_size = data.filter(
            (x) => x.open_at == null || x.open_at === ""
          ).length;

          this.blink = b_size !== 0;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.multiaccount != undefined &&
      changes.multiaccount.currentValue != null &&
      changes.multiaccount.currentValue == 1
    ) {
      this.enableMc = true;
      console.log("entro");
      this.conector.get_mc_accounts().subscribe((e: any) => {
        console.log(e);
        if (e.ok) {
          console.log(e.data);
          this.accounts = e.data;
          var currentAccount = this.accounts.filter(
            (item) => item.companyCurrent
          );
          this.accounts = this.accounts.filter((item) => !item.companyCurrent);
          this.currentAccountName = currentAccount[0].companyName;
        }
      });
    }
  }

  setEnterprise($event) {
    this.sharedService.isVisibleSource.next($event);
  }

  async setLang(defLng: string) {
    localStorage.setItem("user_lng", defLng);

    await this.conector.postLanguage({ language: defLng }).toPromise();

    this.sharedService.reloadSite.next({
      status: true,
      route: this.router.url,
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl("/");
  }

  toggle_company(company) {
    this.conector.switch_ac(company).subscribe((e: any) => {
      if (e.ok) {
        window.location.reload();
      }
    });
  }

  openNotifications() {
    this.sharedService.openMenuNotifications.next(true);
  }

  async showNotification(row) {
    row.open_at = Date.now();

    await this.conector.notifications_Read(row.id).subscribe((e: any) => {
      Swal.fire({
        title: row.title,
        html: row.contents,
        icon: row.typeicon == null ? "info" : row.typeicon,
        confirmButtonText: row.textbutton == null ? "Ok" : row.textbutton,
      });

      if (Array.isArray(this.notificationList)) {
        let b_size = this.notificationList.filter(
          (x) => x.open_at == null || x.open_at == ""
        ).length;

        this.blink = b_size == 0 ? false : true;
      }
    });
  }
}
