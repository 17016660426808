import { DomSanitizer } from "@angular/platform-browser";
import { SharedCommunicationService } from "../../../services/shared-communication.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";
import { ConectorJsonService } from "../../../services/conector-json.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MatStepper } from "@angular/material";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MediaMatcher } from "@angular/cdk/layout";
import { AuthenticationService } from "../../../services/authentication.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import * as moment from "moment";
import { v4 as uuidv4 } from "uuid";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
})
export class ReportComponent implements OnInit {
  snav: any;

  mobileQuery: MediaQueryList;
  dir = "ltr";

  green: boolean;
  blue: boolean;

  dark: boolean;
  minisidebar: boolean;

  boxed: boolean;
  danger: boolean;

  showHide: boolean;
  url: string;

  groupInfo = { visible: false, description: null };

  sidebarOpened;
  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  moduleName: any = [];
  isCallCenter = false;

  public companyFrm: FormGroup;
  enableFeedbackOption = false;

  relations = [];
  groups: any = [];
  categories: any = [];
  subcategories: any = [];

  gotQuestions: any = [];
  comQuestions: any = [];
  myQuestions: any = [];
  baseQuestions: any = [];

  textAlfa = "";
  verAlfa = false;
  textBeta = "";
  verBeta = false;
  textGama = "";
  verGama = false;
  textDelta = "";
  verDelta = false;

  makeResume: any = {
    relation: {},
    category: {},
    reportingId: "0000",
    finalMessage: null,
  };

  mainLogo = "assets/images/logo_without_slogan.png";
  my_trans = null;

  localTranslations = {
    confirmation: "Confirmación requerida",
    confirmation_text: "¿ Estás seguro de realizar esta acción ?",
    badPass: "Las contraseñas no coinciden",
    submit: "Enviar",
    cancel: "Detener",
  };

  publicFolder = environment.public;
  attachments: any = [];
  reportId: any = 0;

  files = [];

  step1 = true;
  step2 = true;
  step3 = true;
  step4 = true;
  step5 = true;
  step6 = true;
  step7 = true;
  step8 = true;
  index = 0;

  setLng = null;

  @ViewChild("ref", { static: false }) ref;
  @ViewChild("stepper", { static: false }) private stepper: MatStepper;

  makeHttp = false;

  frm_relation: FormGroup;
  frm_group: FormGroup;
  frm_questions: FormGroup;
  new_user: FormGroup;
  data: any = { logo: null };
  mainLang = "en";
  selectedYesAnonymus: boolean = false;
  disableFeedback: boolean = false;
  messageFeedback: string = "";

  constructor(
    private fb: FormBuilder,
    private conector: ConectorJsonService,
    private shared: SharedCommunicationService,
    private service: AuthenticationService,
    private translate: TranslateService,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private sanitizer: DomSanitizer,
    public router: Router
  ) {
    this.mobileQuery = media.matchMedia("(min-width: 768px)");

    this._mobileQueryListener = () => changeDetectorRef.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);

    this.shared.httpCall.subscribe((k) => {
      this.makeHttp = k;
    });

    this.frm_relation = this.fb.group({
      relation: [null, Validators.required],
    });

    this.frm_group = this.fb.group({
      group: null,
      category: [null, Validators.required],
      subcategory: null,
      anonymous: false,
    });

    this.frm_questions = this.fb.group({
      alfa: [null],
      beta: [null],
      gama: [null],
      delta: [null],
      incident: [null],
    });

    this.companyFrm = this.fb.group({
      reporting: [null, Validators.required],
      anonymous: [false, Validators.required],
      relation: [null, Validators.required],
      group: [null, Validators.required],
      subcategory: [0, Validators.required],
      enableFeedback: [false],
      alfa: [null],
      beta: [null],
      gama: [null],
      delta: [null],
      uname: [null],
      umail: [null],
      upass: [null],
      urepeat: [null],
      userid: [0],
      incident: [null],
      isCallCenter: [null],
      isCallDate: [null],
      isCallTime: [null],
      isCallEnd: [null],
      reporter: [0],
      method: ["Website"],
    });

    this.new_user = this.fb.group({
      uname: [null],
      umail: [null],
      upass: ["pass"],
      urepeat: ["pass"],
    });

    localStorage.removeItem("token");

    this.service.setLanguage().then(async (k) => {
      this.mainLang = k;
      this.translate.setDefaultLang(k);

      this.my_trans = this.sanitizer.bypassSecurityTrustHtml(
        await this.translate.get("pwd_complaint_rules").toPromise()
      );
    });

    this.reportId = localStorage.getItem("company");

    if (this.reportId === null) {
      this.router.navigateByUrl("/");
    }

    // Save data
    localStorage.setItem("token", this.reportId);

    // Set rid
    this.companyFrm.get("reporting").setValue(this.reportId);

    // Ask for CC
    const model = localStorage.getItem("callcenter");

    if (model !== null) {
      const params = JSON.parse(model);

      params.isCallCenter = true;

      Object.keys(params).forEach((x) => {
        if (this.companyFrm.value.hasOwnProperty(x)) {
          this.companyFrm.controls[x].setValue(params[x]);
        }
      });
    }
  }

  // e!
  company: any;

  async ngOnInit() {
    this.conector
      .reporte_relations({ reporting: this.reportId })
      .subscribe((e: any) => {
        this.relations = e.data;
      });

    this.localTranslations.confirmation = await this.translate
      .get("confirmation")
      .toPromise();

    this.localTranslations.confirmation_text = await this.translate
      .get("confirmation_text")
      .toPromise();

    this.localTranslations.badPass = await this.translate
      .get("passwords-bad")
      .toPromise();

    this.localTranslations.cancel = await this.translate
      .get("x-cancel")
      .toPromise();

    this.localTranslations.submit = await this.translate
      .get("submit")
      .toPromise();

    this.isCallCenter = localStorage.getItem("callcenter") != null;

    if (this.isCallCenter === true && this.isCallCenter !== undefined) {
      this.companyFrm.controls["method"].setValue("callcenter");
    }

    this.setLng = localStorage.getItem("usr_lng");

    this.company = localStorage.getItem("company");

    this.conector.company_tycos(this.company).subscribe((d: any) => {
      const { ok, data, msg } = d;

      if (data != null) {
        this.data = data;

        fetch(this.publicFolder + "/logos/" + data.logo).then((d: any) => {
          if (d.status == 200) {
            this.mainLogo = this.publicFolder + "/logos/" + data.logo;
          }
        });
      }
    });
  }
  nextStepCategory() {
    this.caty = {
      visible: false,
      description: null
    }
    this.frm_group.get("category").setValue(null);
    this.subcategories = [];
    this.suty = {
      visible: false,
      description: null,
    };
  }
  enableFeedback() {
    if (!this.companyFrm.value.enableFeedback) {
      this.new_user.controls["upass"].setValue(null);

      this.new_user.controls["urepeat"].setValue(null);
    } else {
      this.new_user.controls["upass"].setValue("pass");

      this.new_user.controls["urepeat"].setValue("pass");
    }
  }

  validatePassword(formdata) {
    const user_model = this.new_user.value;

    const pwd = user_model.upass;

    const rand = uuidv4();

    if (this.companyFrm.value.enableFeedback) {
      const test = pwd.match(
        /(?=[A-Za-z0-9@#$*\-\/.%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$*\-\/.%^&+!=])(?=.{8,}).*$/g
      );

      if (
        user_model.upass === user_model.urepeat &&
        test !== null &&
        test.length > 0
      ) {
        const model = {
          username:
            formdata.anonymous === false ? user_model.uname : `user-${rand}`,
          email:
            formdata.anonymous === false
              ? user_model.umail
              : `${rand}@no-mail.com`,
          password: user_model.upass,
          reporting: this.reportId,
        };

        console.info("Gen_user", model);

        this.conector.report_user(model).subscribe((res: any) => {
          console.info("gen_user_response", res);

          if (res.ok === true) {
            this.companyFrm.get("userid").setValue(res.data.id);

            this.stepper.next();
          }
        });
      } else {
        Swal.fire({
          title: "Ooops",
          html: this.localTranslations.badPass,
          icon: "error",
          customClass: { confirmButton: "btn btn-morado" },
        });
      }
    } else {
      // guardar los datos del usuario incluso si no quiere feed

      const model = {
        username:
          formdata.anonymous === false ? user_model.uname : `user-${rand}`,
        email:
          formdata.anonymous === false
            ? user_model.umail
            : `${rand}@no-mail.com`,
        password: user_model.upass,
        reporting: this.reportId,
      };

      this.conector.report_user(model).subscribe((res: any) => {
        if (res.ok === true) {
          this.companyFrm.get("userid").setValue(res.data.id);

          this.stepper.next();
        }
      });
    }
  }

  getRelations(formdata: any) {
    this.conector.reporte_relations(formdata).subscribe((res: any) => {
      const { ok, data, msg } = res;

      if (ok === false) {
        return Swal.fire({
          title: "Ops!",
          text: msg,
          icon: "error",
          customClass: { confirmButton: "btn btn-morado" },
        });
      }

      this.relations = data;

      this.index = 1;

      this.stepper.next();
    });
  }

  getGroups(formdata) {
    this.conector.reporte_tree(formdata).subscribe((k: any) => {
      const { data } = k;

      this.groups = data;

      this.makeResume.relation = this.relations.find(
        (x) => x.id === parseInt(formdata.relation)
      );

      this.index = 2;

      this.stepper.next();
    });
  }

  busquedaProfunda(needle, baseline: Array<any>) {
    let result = null;

    baseline.forEach((row) => {
      if (row.id === needle && result == null) {
        result = row;
      } else if (row.subs.length !== 0 && result == null) {
        result = this.busquedaProfunda(needle, row.subs);
      }
    });

    return result;
  }

  getQuestions() {
    const data = this.frm_group.value;
    this.selectedYesAnonymus = data.anonymous;
    this.updateFeedbackCategorySelected(this.selectedYesAnonymus, this.disableFeedback);
    const model = {
      relation: this.frm_relation.get("relation").value,
      category: data.category.id,
      subcategory: data.subcategory,
      group: 0,
      reporting: this.reportId,
    };

    /* Obtiene las preguntas fijas */
    this.conector.report_questions_base(model).subscribe((res: any) => {
      if (Array.isArray(res.data)) {
        this.baseQuestions = res.data;
      }

      var x = 1;
      this.baseQuestions.forEach((item) => {
        if (
          item.spanish ==
          "¿Quién es la o las personas responsables o involucradas?"
        ) {
          this.textAlfa = item.spanish;
          this.verAlfa = true;
        }
        if (
          item.spanish ==
          "¿Cómo sucedió? (Dé el mayor detalle posible de los hechos)"
        ) {
          this.textBeta = item.spanish;
          this.verBeta = true;
        }
        if (
          item.spanish ==
          "¿Cuándo se dieron los hechos?, o ¿Cuándo se enteró de los mismos?"
        ) {
          this.textGama = item.spanish;
          this.verGama = true;
        }
        if (
          item.spanish ==
          "¿Dónde se dieron los hechos? o ¿a través de qué medio?"
        ) {
          this.textDelta = item.spanish;
          this.verDelta = true;
        }

        x = x + 1;
      });
    });
    /* Obtiene las presuntas */
    this.conector.report_questions(model).subscribe((res: any) => {
      if (Array.isArray(res.data)) {
        this.gotQuestions = res.data;
      }

      this.stepper.next();
    });
  }

  async validateQuestions(formdata) {
    const a = formdata.alfa;
    const b = formdata.beta;
    const c = formdata.gama;
    const d = formdata.delta;

    if (
      a == null ||
      a === "" ||
      b == null ||
      b === "" ||
      c == null ||
      c === "" ||
      d == null ||
      d === ""
    ) {
      Swal.fire({
        title: "Error",
        html: "please-response",
        icon: "error",
        customClass: { confirmButton: "btn btn-morado" },
      });
    } else {
      this.index = 4;
      this.stepper.next();
    }
  }

  completeForm(formdata) {
    const anonymus = this.frm_group.controls["anonymous"].value ? 1 : 0;
    if (anonymus === 1) {
      this.saveInformation(formdata);
    } else {
      Swal.fire({
        title: this.localTranslations.confirmation,
        text: this.localTranslations.confirmation_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.localTranslations.submit,
        cancelButtonText: this.localTranslations.cancel,
        customClass: { confirmButton: "btn btn-morado" },
      }).then((result) => {
        if (result.value) {
          this.saveInformation(formdata);
        }
      });
    }
  }
  saveInformation(formdata) {
    try {
      const model = {
        anonymous: this.frm_group.controls["anonymous"].value ? 1 : 0,
        status: "new",
        relation: this.frm_relation.value.relation,
        incident: this.frm_questions.value.incident,
        ticketID: Math.floor(Math.random() * 1000000000).toString(),
        reporting: formdata.reporting.toString(),
        reporter: formdata.reporter.toString(),
        repname: formdata.repname,
        comments: formdata.comments,
        isCallCenter: formdata.isCallCenter == true ? "true" : "false",
        isCallDate: formdata.isCallcenter == true ? formdata.isCallDate : null,
        isCallTime: formdata.isCallcenter == true ? formdata.isCallTime : null,
        isCallEnd:
          formdata.isCallcenter == true
            ? moment().format("HH:mm:ss").toString()
            : null,
        type: formdata.type,
        method: formdata.method,
        alfa: this.frm_questions.value.alfa,
        beta: this.frm_questions.value.beta,
        gama: this.frm_questions.value.gama,
        delta: this.frm_questions.value.delta,
        created: moment().format().toString(),
        enableFeedback: formdata.false,
        group: this.frm_group.value.category.id,
        subgroup: this.frm_group.value.subcategory,
        userid: formdata.userid,
        attachments: this.attachments,
        questions: this.gotQuestions,
        language: this.mainLang,
      };

      this.conector.report_create(model).subscribe(async (resp: any) => {
        const { ok, data, msg } = resp;

        if (ok) {
          this.makeResume.reportingId = data.reporting;
          this.makeResume.finalMessage = data.message_es;
          this.index = 6;
          this.stepper.next();
        } else {
          alert(msg);
        }
      });
    } catch (error) {
      console.info("SUCEDIO UN ERROR", error);
    }
  }

  async fileUpload(event) {
    const formData = new FormData();

    const { addedFiles } = event;

    formData.append("file", addedFiles[0]);

    this.conector
      .report_upload(formData, this.companyFrm.value.reporting, this.mainLang)
      .subscribe(
        (response: any) => {
          const { ok, msg, data } = response;

          if (ok === true) {
            this.attachments.push(data);
          } else {
            Swal.fire({
              title: "Error",
              text: msg,
              icon: "error",
              customClass: { confirmButton: "btn btn-morado" },
            });
          }
        },
        (err) => {
          console.info(err);
        }
      );
  }

  removeItem(item) {
    const op = this.attachments.findIndex((x) => x === item);
    if (op !== -1) {
      this.attachments.splice(op, 1);
    }
  }

  async resetForm() {
    localStorage.clear();

    Swal.fire({
      html: this.makeResume.finalMessage,
      icon: "success",
      title: await this.translate.get("success").toPromise(),
      customClass: { confirmButton: "btn btn-morado" },
    }).then(() => {
      this.router.navigateByUrl("/selection");
    });
  }

  async move(pos: number) {
    const { ok, msg }: any = await this.conector
      .reporte_validarCompany(this.companyFrm.value)
      .toPromise();

    if (ok === false) {
      return Swal.fire({
        title: "Error",
        html: msg,
        icon: "error",
        customClass: { confirmButton: "btn btn-morado" },
      });
    }

    localStorage.setItem("token", this.companyFrm.value.reporting);

    this.index = pos;
    this.stepper.next();
  }

  log_out() {
    localStorage.clear();
    this.router.navigateByUrl("/");
  }

  saveGroup(action) {
    this.companyFrm.get("group").setValue(action.id);

    // this.conector.reporte_category_description(this.companyFrm.value).subscribe((opts: ResponseHandler) => {
    //   console.info(opts.data);
    //   this.groupInfo = opts.data;
    // });
  }

  getBackTo(step) {
    this.index = step;
  }

  /****************************
   * VERSION 2
   *****************************/

  stringBuilder = {
    relation: null,
    category: null,
    incident: null,
  };

  async showTycos(row) {
    const idx =
      this.relations[this.relations.findIndex((x: any) => x.id === row.value)];

    const title_ = await this.translate.get("privacy policy").toPromise();

    this.stringBuilder.relation = idx.relation;

    if (
      idx.tycos !== null &&
      idx.tycos !== "false" &&
      idx.enableTycos === true
    ) {
      await Swal.fire({
        title: title_,
        icon: "info",
        html: idx.tycos,
        allowOutsideClick: false,
        customClass: { confirmButton: "btn btn-morado" },
      });
    }

    this.conector
      .get_category_relations({ reporting: this.reportId, relation: row.value })
      .subscribe((e: any) => {
        if (e.data != null && Array.isArray(e.data)) {
          this.categories = e.data;
        }
      });
  }

  enbaleAnon = false;
  caty: any = {
    visible: false,
    description: "",
  };

  enabler_anon(category) {
    const categorySelected = category.value;
    this.disableFeedback = categorySelected.disableFeedback;
    this.messageFeedback = categorySelected.messageFeedback;
    this.enbaleAnon = categorySelected.anon;
    this.stringBuilder.category = categorySelected.name;
    this.frm_group.value.reporting = this.reportId;
    this.frm_group.value.category = categorySelected.id;
    this.suty = {
      visible: false,
      description: null,
    };
    this.caty.description = categorySelected.description;
    if (this.caty.description != "")
      this.caty.visible = true;

    this.conector
      .reporte_subcategories(this.frm_group.value)
      .subscribe((e: any) => {
        if (e.data != null) {
          this.subcategories = e.data;
        }
      });
  }
  updateFeedbackCategorySelected(disableFeedback: boolean, selectedYesAnonymus: boolean): void {
    if (disableFeedback && selectedYesAnonymus) {
      this.companyFrm.get("enableFeedback").disable();
      this.companyFrm.get("enableFeedback").setValue(true);
      this.companyFrm.value.enableFeedback = true;
      this.enableFeedback();
    } else {
      this.companyFrm.get("enableFeedback").enable();
    }
  }

  enableSubs = false;
  suty = {
    visible: false,
    description: null,
  };

  selected_subcat(row) {
    const idx =
      this.subcategories[
      this.subcategories.findIndex((x: any) => x.id === row.value)
      ];

    if (idx != null) {
      this.suty = idx;
      this.enableSubs = idx.anon == true;
    } else {
      this.suty.description = null;
    }
  }

  saveIncident(data) {
    if (this.frm_group.value.anonymous) {
      this.new_user.controls["uname"].setValue("uname");
      this.new_user.controls["umail"].setValue("umail");
    } else {
      this.new_user.controls["uname"].setValue(null);
      this.new_user.controls["umail"].setValue(null);
    }

    if (this.companyFrm.value.enableFeedback) {
      this.new_user.controls["upass"].setValue(null);
      this.new_user.controls["urepeat"].setValue(null);
    } else {
      this.new_user.controls["upass"].setValue("pass");
      this.new_user.controls["urepeat"].setValue("pass");
    }

    console.info("STEP", data);

    this.stringBuilder.incident = data.incident;
  }

  switch_lng(lng: string) {
    localStorage.setItem("user_lng", lng);

    this.setLng = lng;

    this.service.setLanguage().then((k) => this.translate.setDefaultLang(k));

    this.translate.reloadLang(lng);
  }
}
