import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from './../../../models/pagination';
import { AuthenticationService } from './../../../services/authentication.service';
import { ConectorJsonService } from './../../../services/conector-json.service';

interface PerNode {
  id: number;
  node: string;
  checked: boolean;
  items?: PerNode[];
}

@Component({
  selector: "app-permissionForAccount",
  templateUrl: "./permissionForAccount.component.html",
  styleUrls: ["./permissionForAccount.component.css"],
})
export class permissionForAccountComponent implements OnInit {
  selectedIndex = 0;
  activeRow = null;
  users: any[] = [];
  userForm: FormGroup;

  userCurrent: any;
  selectAllFutures = false;
  selectAllUsers = false;

  pagination = new Pagination();
  showActive = 0;

  filtering: any = null;

  @ViewChild(MatPaginator, { static: true }) paginationBar: MatPaginator;

  displayedColumns: string[] = ["name", "actions"];
  // displayedColumns: string[] = ['nickname', 'name', 'email', 'actions'];

  constructor(
    private conector: ConectorJsonService,
    public dialog: MatDialog,
    private route: Router,
    public translate: TranslateService,
    public auth: AuthenticationService
  ) {
    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
    this.pagination.length = 10;
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;
  }

  ngOnInit() {
    this.userCurrent = JSON.parse(localStorage.getItem("userPermission"));
    this.selectAllFutures = this.userCurrent.futures === null ? false : this.userCurrent.futures;
    this.conector
      .PermissionForAccount(
        this.showActive,
        this.pagination.init,
        this.pagination.limit,
        "",
        this.userCurrent.id
      )
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.users = data;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  async implementLoadPermissionsForAccount() {
    this.userCurrent = JSON.parse(localStorage.getItem("userPermission"));
    this.conector
      .PermissionForAccount(
        this.showActive,
        this.pagination.init,
        this.pagination.limit,
        "",
        this.userCurrent.id
      )
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.users = data;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  onChange($event) {
    const { checked } = $event;

    this.users = [];
    this.pagination.length = 0;
    this.showActive = checked === true ? 1 : 0;

    this.conector
      .PermissionForAccount(
        this.showActive,
        this.pagination.init,
        this.pagination.limit,
        "",
        this.userCurrent.id
      )
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;

        this.users = data;

        if (this.paginationBar) {
          this.paginationBar.firstPage();
        }
      });
  }

  changePage($event) {
    this.pagination.length = 0;
    this.users = [];

    this.pagination.changePage($event);

    console.log(this.filtering);

    this.conector
      .PermissionForAccount(
        this.showActive,
        this.pagination.init,
        this.pagination.limit,
        this.filtering,
        this.userCurrent.id
      )
      .subscribe((d: any) => {
        const { msg, data } = d;

        console.log(d);

        this.pagination.length = msg;
        this.users = data;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  searchUsers() {
    this.conector
      .PermissionForAccount(
        this.showActive,
        0,
        this.pagination.limit,
        this.filtering,
        this.userCurrent.id
      )
      .subscribe((d: any) => {
        const { msg, data } = d;
        this.pagination.length = msg;
        this.users = data;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  changeFuture(id) {
    let futureChange =
      this.users.find((user) => user.id === id).futures == false ? true : false;
    this.users.find((user) => user.id === id).futures = futureChange;
    console.log(this.users.find((user) => user.id === id).futures);
  }

  changeFutureAll() {
    this.selectAllFutures = this.selectAllFutures == true ? false : true;

    this.conector.PermissionForAccountSetFutures(this.userCurrent.id, this.selectAllFutures).subscribe((d: any) => {
      const { msg, data } = d;
    });
    this.users.map((user) => {
      user.futures = this.selectAllFutures
      return user;
    });
  }

  selectAll() {
    this.selectAllUsers = this.selectAllUsers == true ? false : true;
    var selectAllType = 0
    if (this.selectAllUsers == true) { selectAllType = 1 } else { selectAllType = 2 }

    this.conector
      .PermissionForAccount(
        this.showActive,
        this.pagination.init,
        this.pagination.limit,
        "",
        this.userCurrent.id,
        1
      )
      .subscribe((response: any) => {
        const { data, msg } = response;
        data.map((user) => {
          const formdata = {
            idPrimary: user.idPrimary,
            id: user.id,
            futures: this.selectAllFutures,
          };

          this.conector
            .PermissionForAccountSet(formdata, selectAllType)
            .subscribe((d: any) => {
              const { msg, data } = d;
            });
        });

        this.users.map((user) => {
          user.enable = this.selectAllUsers;
          return user;
        });
      });
  }

  setPermission(idPrimaryIn, idIn, futuresIn, event) {
    const formdata = {
      idPrimary: idPrimaryIn,
      id: idIn,
      futures: futuresIn,
    };
    this.conector.PermissionForAccountSet(formdata).subscribe((d: any) => {
      const { msg, data } = d;
      this.conector
        .PermissionForAccount(
          this.showActive,
          this.pagination.init,
          this.pagination.limit,
          "",
          this.userCurrent.id
        )
        .subscribe((response: any) => {
          const { data, msg } = response;
          this.users = data;
          this.pagination.length = msg;
          this.pagination.pageSize = 10;
          this.pagination.limit = 10;
        });
    });
  }
}