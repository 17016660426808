import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
  render: string;
  permission: Array<any>;
}

const MENUITEMS = [
  {
    path: 'dashboard',
    state: 'starter',
    name: 'Dashboard',
    type: 'link',
    render: 'material',
    icon: 'dashboard',
    permission: [1, 2, 3]
  },
  {
    path: 'accounts',
    state: '',
    name: 'Accounts',
    type: 'link',
    render: 'material',
    icon: 'account_box',
    permission: [2]
  },
  {
    path: 'categories',
    state: '',
    name: 'Categories',
    type: 'link',
    render: 'fontawesome',
    icon: 'fa fa-sitemap',
    permission: [1]
  },
  {
    path: 'companies',
    state: '',
    name: 'Companies',
    type: 'link',
    render: 'material',
    icon: 'layers',
    permission: [1]
  },
  {
    path: 'configuration',
    state: '',
    name: 'Configuration',
    type: 'link',
    render: 'fontawesome',
    icon: 'fa fa-cog',
    permission: [2]
  },
  {
    path: 'groups',
    state: '',
    name: 'Groups',
    type: 'link',
    render: 'material',
    icon: 'group',
    permission: [2]
  },
  {
    path: 'questions',
    state: '',
    name: 'Bank of questions',
    type: 'link',
    render: 'fontawesome',
    icon: 'far fa-question-circle',
    permission: [2]
  },
  {
    path: 'relations',
    state: '',
    name: 'Relations',
    type: 'link',
    render: 'fontawesome',
    icon: 'fa fa-handshake',
    permission: [2]
  },
  {
    path: 'reports',
    state: '',
    name: 'Reports',
    type: 'link',
    render: 'material',
    icon: 'content_copy',
    permission: [2]
  },
  {
    path: 'users',
    state: '',
    name: 'Users',
    type: 'link',
    render: 'fontawesome',
    icon: 'fa fa-users',
    permission: [2]
  },
  {
    path: 'tracking',
    state: '',
    name: 'Tracking',
    type: 'link',
    render: 'material',
    icon: 'gps_fixed',
    permission: [2]
  },
  {
    path: 'expedient',
    state: '',
    name: 'Expedient',
    type: 'link',
    render: 'fontawesome',
    icon: 'fab fa-expeditedssl',
    permission: [2]
  },
  {
    path: 'settings',
    state: '',
    name: 'Settings',
    type: 'link',
    render: 'material',
    icon: 'settings',
    permission: [2]
  },
  {
    path: 'permissions',
    state: '',
    name: 'Permissions ',
    type: 'link',
    render: 'material',
    icon: 'lock',
    permission: [2]
  }

];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
