import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-surveys-answers",
  templateUrl: "./surveys-answers.component.html",
  styleUrls: ["./surveys-answers.component.css"],
})
export class SurveysAnswersComponent implements OnInit {
  @Input() openModal: boolean = false;
  @Input() answers: any = [];
  @Input() question: any = {};
  @Input() haveCompletedSurveys: boolean = true;

  @Output() addanswer: EventEmitter<any> = new EventEmitter();
  @Output() deleteAnswer: EventEmitter<any> = new EventEmitter();

  answerFrm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.answerFrm = this.fb.group({
      Name: [null, Validators.required],
    });
  }

  ngOnInit() {}

  dimiss() {
    this.openModal = false;
  }

  saveTo(formdata) {
    this.addanswer.emit(formdata);
    this.answerFrm.reset();
  }

  deleteRow(ev) {
    this.deleteAnswer.emit(ev);
  }
}
