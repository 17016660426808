import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { keyframes } from '@angular/animations';

@Component({
  selector: 'app-comunica-eventos',
  templateUrl: './comunica-eventos.component.html',
  styleUrls: ['./comunica-eventos.component.css']
})
export class ComunicaEventosComponent implements OnInit {

  isEditable = false;
  idEditable = 0;

  isNewRequest= false;
  showRecords = true;

  isTitle = 'Crear nuevo ';
  eventos = [];

  areas = [];
  categorias = [];

  articleForm: FormGroup;
  imageURL = null;

  constructor(private conector: ConectorKnowService, private snap: ActivatedRoute, private shared: SharedCommunicationService, private fb: FormBuilder) {

    this.articleForm = this.fb.group({
      area_id:      [0, Validators.required],
      structure_id: [0],
      name:         [null, Validators.required],
      place:        [null, Validators.required],
      start_date:   [null, Validators.required],
      end_date:     [null, Validators.required],
      publish_date: [null, Validators.required],
      image:        [null],
      video_link:   [null],
      push_text:    [null],
      summary:      [null],
      content:      [null, Validators.required],
      push_notifications: [1, Validators.required]
    });

  }

  async ngOnInit() {

    this.conector.events_list().subscribe( (init: ResponseKnow) => {
      console.info(init);
      this.areas =  init.areas;
    });

    this.snap.params.subscribe( async(ks: Params) => {

      if (ks.id == null) {

        this.showRecords = true;

        this.conector.events_list().subscribe( (k:any) => {

          const { success, events, areas, categorias } = k;

          if(success == true) {
            this.eventos = events.data;
            this.areas = (this.shared.isJsonObject(areas)) ? this.shared.ObjecToArray(areas) : areas;
            this.categorias = (this.shared.isJsonObject(categorias)) ? this.shared.ObjecToArray(categorias) : categorias;
          }

        });

      } else {

        this.showRecords = false;

        this.isNewRequest = true;

        const { id } = ks;

        if ( id > 0 ) {

          this.isEditable = true;

          this.idEditable = id;

          this.isTitle = 'Editar';

          this.conector.events_create().subscribe( (r:ResponseKnow) => {

            this.areas = r.areas;

            this.conector.event_single(ks.id).subscribe( async (k) => {

              const {areas, categories, event, success, structure }: ResponseKnow = k;

              event.image = null;

              Object.keys(this.articleForm.value).forEach( item => {
                this.articleForm.get(item).setValue(event[item]);
              });

              this.articleForm.get('end_date').setValue( new Date( event.end_date));
              this.articleForm.get('start_date').setValue( new Date( event.start_date));
              this.articleForm.get('publish_date').setValue( new Date( event.publish_date));

              this.imageURL = event.image_url;

              if(event.area_id != null) {

                this.conector.events_filter(event.area_id).subscribe( k => {

                  this.categorias = k.categories;

                  this.articleForm.controls.structure_id.setValue(event.category_id);

                });

              }

            })

          })

        }

      }

    })

  }

  filterByArea(area, isCrud = false) {

    if(isCrud == true) {
      area = this.articleForm.value.area_id;
    }

    this.conector.events_filter(area).subscribe( k => {

      const {events, categories}: any = k;

      this.eventos = events.data;

      this.categorias = categories;

    });

  }

  filterByCategory(cat) {
    this.conector.events_filter(this.articleForm.value.area_id, cat).subscribe( k => {
      const {events}: any = k;
      this.eventos = events.data;
    })
  }

  save(formdata) {

    formdata.company_id = localStorage.getItem('know_session');
    formdata.end_date = moment(formdata.end_date).format("YYYY-MM-DD HH:mm:ss a").toString();
    formdata.start_date = moment(formdata.start_date).format("YYYY-MM-DD HH:mm:ss a").toString();
    formdata.publish_date = moment(formdata.publish_date).format("YYYY-MM-DD HH:mm:ss a").toString();

    var data = new FormData();

    Object.keys(formdata).forEach( k => {
      data.append(k, formdata[k]);
    })

    if(formdata.image) {
      data.set("image", formdata.image._files[0]);
    }

    this.conector.events_post(data).subscribe( (k:ResponseKnow) => {

      console.info('ss', k);

      if(k.success === false ) {
        Swal.fire('Error', k.message[0], 'error');
      } else {
        Swal.fire('Completado', k.message, 'success');
        this.articleForm.reset();
      }

    });

  }

  update(formdata) {

    formdata.company_id = localStorage.getItem('know_session');
    formdata.end_date = moment(formdata.end_date).format("YYYY-MM-DD HH:mm:ss a").toString();
    formdata.start_date = moment(formdata.start_date).format("YYYY-MM-DD HH:mm:ss a").toString();
    formdata.publish_date = moment(formdata.publish_date).format("YYYY-MM-DD HH:mm:ss a").toString();

    var data = new FormData();

    Object.keys(formdata).forEach( k => {
      data.append(k, formdata[k]);
    })

    if(formdata.image) {
      if( typeof formdata.image.name == 'string'  ) {
        alert('string!')
      } else {
        data.set("image", formdata.image._files[0]);
      }
    }

    data.append("_method", "PATCH");

    this.conector.events_put(data, this.idEditable).subscribe( (k: ResponseKnow) => {

      const title = (!k.success) ? 'Error' : 'Completado';

      const color = (!k.success) ? 'error' : 'success';

      Swal.fire(title, k.message[0], color);

    });

  }

  toHumanDate(row) {
    return moment(row).format("YYYY-MM-DD HH:mm").toString()
  }


  delete_event(row){

    Swal.fire({
      title: 'Confirmar acción',
      icon: 'info',
      html:'Está seguro de realizar está acción ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then( response => {
      if(response.value === true) {
        this.conector.events_delete(row).subscribe( a => {

          const { success, message }:any = a;

          Swal.fire('Completado', message, 'success');

          this.eventos = [];

          this.conector.events_list().subscribe( k => {
            const { success, categories, events, areas }:any = k;
            this.eventos = events;
          });

        });
      }
    });

  }

}
