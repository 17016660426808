import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import IFormDataCollaborator from "../../types/collaborator/form-data-collaborator";

@Injectable({
  providedIn: "root",
})
export class ConnectorCollaboratorService {
  constructor(private http: HttpClient) {}

  collaborator_get(formdata, init = 0, limit = 10) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.post(
      `${environment.server}/collaborator/getcollaborators?limit=${limit}&offset=${init}`,
      formdata,
      { headers }
    );
  }

  collaborator_getbyid(id) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.get(`${environment.server}/collaborator/${id}`, {
      headers,
    });
  }

  collaborator_post(formdata: IFormDataCollaborator) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.post(`${environment.server}/collaborator`, formdata, {
      headers,
    });
  }

  collaborator_sendmail(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.post(
      `${environment.server}/collaborator/asingmail/`,
      formdata,
      {
        headers,
      }
    );
  }

  collaborator_post_multiple(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.post(
      `${environment.server}/collaborator/multipleevaluated`,
      formdata,
      {
        headers,
      }
    );
  }

  collaborator_put(formdata: IFormDataCollaborator) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.put(
      `${environment.server}/collaborator/${formdata.id}`,
      formdata,
      {
        headers,
      }
    );
  }

  collaborator_delete(rowId) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.delete(`${environment.server}/collaborator/${rowId}`, {
      headers,
    });
  }

  collaborator_asign(idu, formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.post(
      `${environment.server}/evaluation/asigneval/${idu}`,
      formdata,
      {
        headers,
      }
    );
  }

  collaborator_asign_single(idu, formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.post(
      `${environment.server}/evaluation/asigneval/single/${idu}`,
      formdata,
      { headers }
    );
  }

  requestReport(user) {
    return this.http.get(`${environment.server}/lucius/solicitud/${user}`);
  }

  responseReport(user) {
    return this.http.get(`${environment.server}/lucius/reporte/${user}`);
  }
}
