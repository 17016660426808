import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import Swal from 'sweetalert2';
import { ConnectorJobProfileService } from '../../../../services/job-profile.service';
//import { HighchartsChartModule } from 'highcharts-angular';

@Component({
	selector: 'app-adjustment-norm',
	templateUrl: './adjustment-norm.component.html',
	styleUrls: ['./adjustment-norm.component.css'],
})
export class AdjustmentNormComponent implements OnInit {
	@Input() item: any = { jobIdMhc: 0 };
	@Input() evaluationConfig: any;
	@Input() evaluationConfigMsg: any;

	@Output() gridView: EventEmitter<any> = new EventEmitter();

	isLinearAdjust = true;
	srcGrafica = 'assets/images/gauss.jpg';

	displayedColumns: string[] = ['Dimension', 'Norma', 'MediaLI'];
	dataSource: DimensionNorm[];
	itemId = 0;
	id = 0;

	lInferior = 0;
	lSuperior = 0;
	lMedio = 0;

	value: number = 40;
	highValue: number = 60;
	options: Options = {
		floor: 0,
		ceil: 100,
		step: 1,
		showTicks: true,
		showSelectionBar: false,
		getTickColor: (value: number): string => {
			if (value < 30) {
				return 'red';
			}
			if (value < 60) {
				return 'orange';
			}
			if (value < 90) {
				return 'yellow';
			}
			return '#2AE02A';
		},
	};

	linechart: any = {
		series: [
			{
				data: [1, 2, 3],
			},
		],
		chart: {
			type: 'line',
		},
		title: {
			text: 'linechart',
		},
	};

	Highcharts = Highcharts;

	chartOptions: Highcharts.Options = {
		chart: {
			inverted: true,
			margin: [0, 0, 0, 0],
			marginLeft: 0,
			type: 'bullet',
		},
		title: { text: null },
		legend: { enabled: false },
	};

	constructor(public translate: TranslateService, private conector: ConnectorJobProfileService) {}

	ngOnInit(): void {
		this.itemId = this.item.id;

		this.id = parseInt(this.item.id);

		this.conector.dimentionsNewNorm_get(this.itemId).subscribe((response: any) => {
			const { data, msg } = response;
			this.dataSource = data as DimensionNorm[];

			this.dataSource.forEach((dim) => {
				dim.chartNorm = new Chart({
					chart: {
						inverted: true,
						type: 'bullet',
					},
					title: {
						text: null,
					},
					legend: {
						enabled: false,
					},
					xAxis: {
						categories: [''],
					},
					yAxis: {
						title: null,
						max: 100,
						min: 0,
						gridLineWidth: 0,
						labels: {
							enabled: false,
						},
						plotBands: [
							{
								from: 0,
								to: dim.lowRange,
								color: '#B5102F',
							},
							{
								from: dim.lowRange,
								to: dim.midRangeMin,
								color: '#E04B24',
							},
							{
								from: dim.midRangeMin,
								to: dim.midRangeMed,
								color: '#F3AF1F',
							},
							{
								from: dim.midRangeMed,
								to: dim.midRangeMax,
								color: '#D9B730',
							},
							{
								from: dim.midRangeMax,
								to: dim.highRange,
								color: '#8AB324',
							},
							{
								from: dim.highRange,
								to: 100,
								color: '#2F8C2B',
							},
						],
					},
					series: [
						{
							name: 'Valor',
							type: 'line',
							data: [{ y: dim.linferior, color: '#000', name: '' }],
							pointInterval: 1,
							pointPlacement: 0,
							allowPointSelect: true,
						},
					],
					plotOptions: {
						series: {
							color: '#ffffff',
						},
					},
					credits: {
						enabled: false,
					},
				});
			});

			this.conector.GetPercentageApprovalRejection(this.id).subscribe((response: any) => {
				const { data, msg } = response;
				let dimension = this.dataSource.filter((x) => x.dimensionH == 2);

				if (data.length > 0) {
					this.lInferior = (data.filter((x) => x.result < dimension[0].linferior).length * 100) / data.length;

					this.lMedio =
						(data.filter((x) => x.result > dimension[0].linferior && x.result < dimension[0].lsuperior)
							.length *
							100) /
						data.length;

					this.lSuperior = (data.filter((x) => x.result > dimension[0].lsuperior).length * 100) / data.length;
				} else {
					this.lInferior = 0;
					this.lMedio = 0;
					this.lSuperior = 0;
				}
			});
		});
	}

	async closeGrid(id) {
		if (id == 1) {
			var idpuestoEnviar = this.itemId;

			var data = null;

			this.conector.dimentionsUpdateNorm_get(this.itemId, data).subscribe(async (d: any) => {
				const { ok, msg, data } = d;

				if (ok == false) {
					Swal.fire({
						title: d.msg,
						icon: 'error',
						showCancelButton: false,
						confirmButtonText: ' Aceptar ',
					}).then(() => {
						this.gridView.emit();
					});
				} else {
					Swal.fire({
						title: 'Norma actualizada correctamente.',
						icon: 'success',
						showCancelButton: false,
						confirmButtonText: ' Aceptar ',
					}).then(() => {
						this.gridView.emit();
					});
				}
			});
		} else {
			this.gridView.emit();
		}
	}
}

export interface DimensionNorm {
	id: number;
	dimension: string;
	orden: number;
	editable: boolean;
	dimensionH: number;
	cambioNorma: boolean;
	peso: number;
	linferior: number;
	lsuperior: number;
	lowRange: number;
	midRangeMin: number;
	midRangeMed: number;
	midRangeMax: number;
	highRange: number;
	difLinferior: number;
	options: Options;
	chartNorm: any;
}
