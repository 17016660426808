import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Pipe({
  name: 'filterParent'
})
export class FilterParentPipe implements PipeTransform {

    transform(value: any[], keyName: string, parentId: any, ): string {
        return _.filter(value, function (o) {
            return o[keyName] === +parentId;
        });
    }

}

@Pipe({
  name: 'all'
})
export class AllPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return null;
  }

}

@Pipe({
  name: 'filterMessages',
  pure: false
})

export class FilterMessages implements PipeTransform {
  transform(items: any[], filter: string): any {
    return items.filter(item => item.kind !== null && item.kind.indexOf(filter) !== -1);
  }
}

@Pipe({
  name: 'switchStatus',
  pure: false
})

export class SwitchStatus implements PipeTransform {

  statuses = [
    'new',
    'acknowledged',
    'inprogress',
    'standby',
    'aborted',
    'solved'
  ];

  translate = [
    'Nueva',
    'Recibido',
    'En progreso',
    'En espera',
    'Cancelado',
    'Progreso'
  ];

  transform(value: any): any {

    const index = this.statuses.indexOf(value);

    return this.translate[index];

  }

}

@Pipe({name: 'trimStr2'})
export class TrimStrTwo implements PipeTransform {
  transform(str: string): string {
      return str.substring(0, 1);
  }
}


@Pipe({ name: 'convertirFecha' })

export class ConvertirFecha implements PipeTransform {
  transform(value: any, args?: any): any { return moment(value).format('DD/MM/YYYY').toString(); }
}


@Pipe({ name: 'holdQuestion' })

export class HoldQuestion implements PipeTransform {
  transform(str: string, args?:any) {
    return new Promise(resolve => setTimeout(() => resolve(str), args));
  }
}

@Pipe({
  name: 'groupBy',
})
export class GroupByPipe implements PipeTransform {
  transform(collection: Array<any>, property: string): Array<any> {
       if(!collection) {
          return null;
      }

      const groupedCollection = collection.reduce((previous, current)=> {
          if(!previous[current[property]]) {
              previous[current[property]] = [current];
          } else {
              previous[current[property]].push(current);
          }

          return previous;
      }, {});

      return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }
}


@Pipe({
    name: 'filterTable',
    pure: false
})
export class filterTablePipe implements PipeTransform {
    transform(items: any[], filter: string): any {

      if (!items || !filter || items.length === 0) {
            return items;
        }

        let all_items = [];

        Object.keys(items[0]).forEach( (kp:any) => {

          let filtered = items.filter( item => item[kp] !== null && item[kp] !== undefined &&  item[kp].toString().toLowerCase().includes(filter.toLowerCase()));

          if( filtered != null && filtered.length > 0 ) {

            _.merge( all_items, filtered );

          }

        });

        return all_items;
    }
}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
