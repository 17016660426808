import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material';

/* Interface pre-definida de los valores unicos de cada nodo */
interface FoodNode {
  id: number;
  name: string;
  subs?: FoodNode[];
}

@Component({
  selector: 'app-tree-radio-picker',
  templateUrl: './tree-radio-picker.component.html',
  styleUrls: ['./tree-radio-picker.component.css']
})
export class TreeRadioPickerComponent implements OnInit {

  /* Eventos que se enviaran del lado del receptor */
  @Output() onSelectGroup: EventEmitter<any> = new EventEmitter();

  /* Información que ingresa para mostrarse
   * @params
   * data: JSONArray<JsonArray< N > >; donde N es la profundidad indefinida.
   * key: String; es la clave para identificar el siguiente nodo del arbol
  */

  @Input() data = new MatTreeNestedDataSource<FoodNode>();
  @Input() key: string;

  /* Requerido por material para dar forma al arbol, mediante una interface previamente definida */
  treeControl = new NestedTreeControl<FoodNode>(node => node.subs);

  hasChild = false;

  /* Constructor & primer ejecucion*/
  constructor() {}

  ngOnInit() {}

  /* Lanzador de actividad cuando hay click */
  optionWasClicked(row): void {
    this.onSelectGroup.emit(row);
  }

  checkScopeLevel(row): boolean {
    return row.subs.length == 0;
  }

}
