import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { decode } from 'punycode';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../services/authentication.service';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, Input, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: []
})

export class AppSidebarComponent implements OnDestroy, OnInit {

    @Input() receptorItems: any;
    @Input() layoutName: any;
    @Input() user_menu: any;

    parentIndex: any;
    childIndex: any;

    public config: PerfectScrollbarConfigInterface = {};
    mobileQuery: MediaQueryList;

    private _mobileQueryListener: () => void;
    status = true;

    itemSelect: number[] = [];
    menuItems: any;

    rolename = 'User';
    origin: any;

    moduloImage = '';
    hadImage = false;

    setClickedRow(i, j) {
        this.parentIndex = i;
        this.childIndex = j;
    }

    subclickEvent() {
        this.status = true;
    }

    scrollToTop() {
        document.querySelector('.page-wrapper').scroll({
            top: 0,
            left: 0
        });
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        private conector: ConectorJsonService,
        private translate: TranslateService,
        private auth: AuthenticationService,
        private shared: SharedCommunicationService,
        public router: Router) {

        this.auth.setLanguage().then( (k) => this.translate.setDefaultLang(k) );

        this.mobileQuery = media.matchMedia('(min-width: 768px)');

        this._mobileQueryListener = () => changeDetectorRef.detectChanges();

        this.mobileQuery.addListener(this._mobileQueryListener);

        this.shared.switchModule.subscribe( k => {
            if ( k !== null) {
              this.moduloImage = `/assets/images/modules/${k.image}`;
              this.hadImage = true;
            }
        });

    }

    ngOnInit(): void {

        this.menuItems = this.receptorItems;

        this.origin = (this.layoutName == null) ? '/' : this.layoutName;

        this.rolename = localStorage.getItem('roleName');

    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    log_out() {
        this.conector.log_out(this.auth.userdata().unique_name).subscribe( k => {
            localStorage.clear();
            return this.router.navigateByUrl('/');
        });
    }

}
