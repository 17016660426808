import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SharedCommunicationService } from './services/shared-communication.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  constructor(private shared: SharedCommunicationService, private router: Router) {


  }

  visible = false;

  ngOnInit() {
    this.shared.loader.subscribe((option) => {
      this.visible = option;
    });
  }

}
