import { ComparativeComponent } from "../../views/manager/modals/comparative/comparative.component";
import { RankingContentComponent } from "../../views/manager/modals/ranking-content/ranking-content.component";
import { RankingComponent } from "../../views/manager/ranking/ranking/ranking.component";
import { DocsComponent } from "./../../views/integriline/docs/docs.component";
import { OwnCompetencyFormComponent } from "../../views/manager/modals/own-competency-form/own-competency-form.component";
import { OwnComptencyComponent } from "../../views/manager/own-competency/own-comptency/own-comptency.component";
import { SurveysComponent } from "./../../views/manager/surveys/surveys.component";
import { HandlerTicketComponent } from "./../../views/integriline/handler-ticket/handler-ticket.component";
import { HandlerHomeComponent } from "./../../views/integriline/handler-home/handler-home.component";
import { CategoryAddonsComponent } from "./../../views/integriline/category-addons/category-addons.component";
import { CategoryComponent } from "./../../views/integriline/category/category.component";
import { ComunicaEvaluacionesResultadosComponent } from "./../../views/comunika/comunica-evaluaciones-resultados/comunica-evaluaciones-resultados.component";
import { JobProfileComponent } from "./../../views/manager/jobprofile/jobprofile.component";
import { ChildGuardService } from "../../services/child-guard.service";
import { AuthGuardService } from "../../services/auth-guard.service";
import { CreateUserComponent } from "../../views/manager/modals/create-user/create-user.component";
import { ManageUserComponent } from "../../views/manager/modals/manage-user/manage-user.component";
import { GroupHandlersComponent } from "../../views/integriline/modals/group-handlers/group-handlers.component";
import { GroupQuestionsComponent } from "../../views/integriline/modals/group-questions/group-questions.component";
import { CreateJobProfileComponent } from "./../../views/manager/modals/create-jobprofile/create-jobprofile.component";
import { LinkJobprofileComponent } from "./../../views/manager/modals/link-jobprofile/link-jobprofile.component";

import { ManagerComponent } from "./manager.component";
import { ModuleComponent } from "../../views/manager/module/module.component";
import { HomeManagerComponent } from "../../views/manager/home-manager/home-manager.component";
import { PermissionComponent } from "../../views/manager/permission/permission.component";
import { permissionForAccountComponent } from "../../views/manager/permissionForAccount/permissionForAccount.component";
import { UsersComponent } from "../../views/manager/users/users.component";
import { CollaboratorComponent } from "../../views/manager/collaborator/collaborator.component";
import { CreateCollaboratorComponent } from "../../views/general/create-collaborator/create-collaborator.component";
// import { QuestionsSelectionComponent } from '../../views/manager/modals/questions-selection/questions-selection.component';
import { ReportsComponent } from "../../views/manager/reports/reports.component";
import { CatalogsComponent } from "../../views/admin/catalogs/catalogs.component";
import { GroupsComponent } from "../../views/manager/groups/groups.component";
import { ConfigurationComponent } from "../../views/manager/configuration/configuration.component";
import { ConfigJobProfilesComponent } from "../../views/manager/modals/config-jobprofiles/config-jobprofiles.component";
import { ConfigurateJobProfileComponent } from "../../views/manager/modals/configurate-jobprofile/configurate-jobprofile.component";
import { JobProfileAnalisisRiesgoComponent } from "../../views/manager/modals/jobprofile-analisisriesgo/jobprofile-analisisriesgo.component";

import { GroupAddonsComponent } from "../../views/integriline/group-addons/group-addons.component";

import { ConfigProyectComponent } from "./../../views/ethics-survey/config-proyect/config-proyect.component";
import { EvaluatedAnswerComponent } from "./../../views/ethics-survey/evaluated-answer/evaluated-answer.component";
import { CreateConfigComponent } from "./../../views/ethics-survey/modal/create-config/create-config.component";
import { DeleteConfigComponent } from "./../../views/ethics-survey/modal/delete-config/delete-config.component";
import { UpdateConfigComponent } from "./../../views/ethics-survey/modal/update-config/update-config.component";
import { SendEmailUnanwersComponent } from "./../../views/ethics-survey/modal/send-email-unanwers/send-email-unanwers.component";
import { EthicsSurveyTypeOneComponent } from "./../../views/ethics-survey/evaluated-answer/ethics-survey-type-one/ethics-survey-type-one.component";
import { EthicsSurveyTypeTwoComponent } from "./../../views/ethics-survey/evaluated-answer/ethics-survey-type-two/ethics-survey-type-two.component";
import { ethics_survey_type_dialog } from "./../../views/ethics-survey/evaluated-answer/ethics-survey-type-two/ethics-survey-type-dialog.component";
import { ReportsEthicsSurveyComponent } from "./../../views/ethics-survey/reports-ethics-survey/reports-ethics-survey.component";

import { QuestionsComponent } from "../../views/integriline/questions/questions.component";
import { RelationsComponent } from "../../views/integriline/relations/relations.component";

import { Routes } from "@angular/router";
import { ReportComponent } from "../../views/integriline/report/report.component";
import { ChartsComponent } from "../../views/integriline/charts/charts.component";
import { ComunicaArticulosComponent } from "../../views/comunika/comunica-articulos/comunica-articulos.component";
import { ComunicaEvaluacionesComponent } from "../../views/comunika/comunica-evaluaciones/comunica-evaluaciones.component";
import { ComunicaEventosComponent } from "../../views/comunika/comunica-eventos/comunica-eventos.component";
import { ComunicaEncuestasComponent } from "../../views/comunika/comunica-encuestas/comunica-encuestas.component";
import { ComunicaCuestionariosComponent } from "../../views/comunika/comunica-cuestionarios/comunica-cuestionarios.component";
import { ConfiguracionEmpresaComponent } from "../../views/comunika/configuracion-empresa/configuracion-empresa.component";
import { ConfiguracionCategoriasComponent } from "../../views/comunika/configuracion-categorias/configuracion-categorias.component";
import { ConfiguracionUsuariosComponent } from "../../views/comunika/configuracion-usuarios/configuracion-usuarios.component";
import { EstadisticasEventosComponent } from "../../views/comunika/estadisticas-eventos/estadisticas-eventos.component";
import { EstadisticasArticulosComponent } from "../../views/comunika/estadisticas-articulos/estadisticas-articulos.component";
import { EstadisticasGuardadosComponent } from "../../views/comunika/estadisticas-guardados/estadisticas-guardados.component";
import { EstadisticasUsuariosComponent } from "../../views/comunika/estadisticas-usuarios/estadisticas-usuarios.component";
import { EstadisticasActividadComponent } from "../../views/comunika/estadisticas-actividad/estadisticas-actividad.component";
import { EstadisticasActivosComponent } from "../../views/comunika/estadisticas-activos/estadisticas-activos.component";
import { EstadisticasEvaluacionesComponent } from "../../views/comunika/estadisticas-evaluaciones/estadisticas-evaluaciones.component";
import { EstadisticasEncuestasComponent } from "../../views/comunika/estadisticas-encuestas/estadisticas-encuestas.component";
import { EstadisticasCuestionariosComponent } from "../../views/comunika/estadisticas-cuestionarios/estadisticas-cuestionarios.component";
import { ComunicaEncuestasResultadosComponent } from "../../views/comunika/comunica-encuestas-resultados/comunica-encuestas-resultados.component";
import { IntegrityReportsComponent } from "../../views/manager/integrity-reports/integrity-reports.component";
import { SurveyHomeComponent } from "../../views/manager/home/survey-home/survey-home.component";
import { IntegrityHomeComponent } from "../../views/manager/home/integrity-home/integrity-home.component";
import { MeComponent } from "../../views/manager/me/me.component";
import { LicenseManagerComponent } from "../../views/manager/license/license.component";
import { ExportDataComponent } from "../../views/manager/export-data/export-data.component";

import { modalCommentComponent } from "../../views/manager/integrity-reports/modal-comments/modal-comments.component";
import { modalSeleccionReportConfig } from "../../views/manager/integrity-reports/modal-seleccion-report-config/modal-seleccion-report-config.component";
import { modalProfileReportConfig } from "../../views/manager/integrity-reports/modal-profile-report-config/modal-profile-report-config.component";
import { BehavioralMapComponent } from "../../views/manager/modals/behavioral-map/behavioral-map.component";
import { OwnQuestionsComponent } from "../../views/manager/own-questions/own-questions.component";
export const routes: Routes = [
  {
    path: "manager",
    component: ManagerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
      },
      {
        path: "home",
        component: HomeManagerComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "permissions",
        component: PermissionComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },
      {
        path: "permissionForAccount",
        component: permissionForAccountComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "collaborators",
        component: CollaboratorComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "collaborator/:id",
        component: CreateCollaboratorComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "reports",
        component: ChartsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "catalogs",
        component: CatalogsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "configuration",
        component: ConfigurationComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "config-profiles/:id",
        component: ConfigJobProfilesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "config-profile-integrity/:id",
        component: ConfigurateJobProfileComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "config-profile-integrity-risk/:id",
        component: JobProfileAnalisisRiesgoComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "groups",
        component: GroupsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "category",
        component: CategoryComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "category-groups/:id",
        component: CategoryAddonsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "module",
        component: ModuleComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "Survey",
        component: ConfigProyectComponent,
      },
      {
        path: "SurveyAnswers",
        component: EvaluatedAnswerComponent,
      },
      {
        path: "SurveyAnswersTypeOne",
        component: EthicsSurveyTypeOneComponent,
      },
      {
        path: "SurveyAnswersTypeTwo",
        component: EthicsSurveyTypeTwoComponent,
      },
      {
        path: "ethics_survey_type_dialog",
        component: ethics_survey_type_dialog,
      },
      {
        path: "SurveyCreate",
        component: CreateConfigComponent,
      },
      {
        path: "survey-home",
        component: SurveyHomeComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "integrity-home",
        component: IntegrityHomeComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "SurveyDelete",
        component: DeleteConfigComponent,
      },
      {
        path: "SurveyUpdate/:id",
        component: UpdateConfigComponent,
      },
      {
        path: "SendEmailUnanwers/:id",
        component: SendEmailUnanwersComponent,
      },
      {
        path: "ReportsEthicsSurvey",
        component: ReportsEthicsSurveyComponent,
      },
      {
        path: "ReportsEthicsSurveyDialog",
        component: ReportsEthicsSurveyComponent,
      },
      {
        path: "ExportData",
        component: ExportDataComponent,
      },
      {
        path: "ModalComment",
        component: modalCommentComponent,
      },
      {
        path: "ModalSeleccionReportConfig",
        component: modalSeleccionReportConfig,
      },
      {
        path: "ModalProfileReportConfig",
        component: modalProfileReportConfig,
      },
      {
        path: "OwnCompetencies",
        component: OwnComptencyComponent,
      },
      {
        path: "OwnCompetencies/Create",
        component: OwnCompetencyFormComponent,
      },
      {
        path: "OwnCompetencies/Edit/:id",
        component: OwnCompetencyFormComponent,
      },
      {
        path: "Ranking",
        component: RankingComponent,
      },
      {
        path: "Ranking/Content",
        component: RankingContentComponent,
      },
      {
        path: "Ranking/Comparative",
        component: ComparativeComponent,
      },
      {
        path: "OwnQuestions",
        component: OwnQuestionsComponent,
      },

      /***************************************************************************************
       *************************** INTEGRILINE MANAGER ROUTES *********************************
       ***************************************************************************************/

      {
        path: "relations",
        component: RelationsComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },
      {
        path: "questions",
        component: QuestionsComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },
      {
        path: "group-addons",
        component: GroupAddonsComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },
      {
        path: "group-questions",
        component: GroupQuestionsComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },
      {
        path: "group-handlers",
        component: GroupHandlersComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },
      {
        path: "manage-users",
        component: ManageUserComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },
      {
        path: "create-users",
        component: CreateUserComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },

      {
        path: "tickets",
        component: HandlerHomeComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "ticket/:id",
        component: HandlerTicketComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "edit-user/:id",
        component: CreateUserComponent,
        canActivate: [ChildGuardService, AuthGuardService],
      },

      /***************************************************************************************
       *************************** COMUNIKA MANAGER ROUTES ***********************************
       ***************************************************************************************/

      {
        path: "comunica-articulos",
        component: ComunicaArticulosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-articulos/:id",
        component: ComunicaArticulosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-articulos/create",
        component: ComunicaArticulosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-evaluaciones",
        component: ComunicaEvaluacionesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-evaluaciones/:id",
        component: ComunicaEvaluacionesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-evaluaciones/create",
        component: ComunicaEvaluacionesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-evaluaciones/resultados/:id",
        component: ComunicaEvaluacionesResultadosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-eventos",
        component: ComunicaEventosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-eventos/:id",
        component: ComunicaEventosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-eventos/create",
        component: ComunicaEventosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-encuestas",
        component: ComunicaEncuestasComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-encuestas/resultados/:id",
        component: ComunicaEncuestasResultadosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-encuestas/:id",
        component: ComunicaEncuestasComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-encuestas/create",
        component: ComunicaEncuestasComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-cuestionarios",
        component: ComunicaCuestionariosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-cuestionarios/create",
        component: ComunicaCuestionariosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-cuestionarios/:id/preguntas",
        component: ComunicaCuestionariosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "comunica-cuestionarios/:id/retros",
        component: ComunicaCuestionariosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "configuracion-empresa",
        component: ConfiguracionEmpresaComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "configuracion-categorias",
        component: ConfiguracionCategoriasComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "configuracion-categorias/:id",
        component: ConfiguracionCategoriasComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "configuracion-usuarios",
        component: ConfiguracionUsuariosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "configuracion-usuarios/:id",
        component: ConfiguracionUsuariosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "estadisticas-eventos",
        component: EstadisticasEventosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "estadisticas-articulos",
        component: EstadisticasArticulosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "estadisticas-guardados",
        component: EstadisticasGuardadosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "estadisticas-usuarios",
        component: EstadisticasUsuariosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "estadisticas-actividad",
        component: EstadisticasActividadComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "estadisticas-activos",
        component: EstadisticasActivosComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "estadisticas-evaluaciones",
        component: EstadisticasEvaluacionesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "estadisticas-encuestas",
        component: EstadisticasEncuestasComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "estadisticas-cuestionarios",
        component: EstadisticasCuestionariosComponent,
      },

      /***************************************************************************************
       *********************************** HONESTIDAD *****************************************
       ***************************************************************************************/

      {
        path: "job-profile",
        component: JobProfileComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "integrityreports",
        component: IntegrityReportsComponent,
        canActivate: [AuthGuardService],
      },

      {
        path: "evaluation-surveys",
        component: SurveysComponent,
      },
      {
        path: "me",
        component: MeComponent,
      },
      {
        path: "license",
        component: LicenseManagerComponent,
      },
      {
        path: "job-profile-create",
        component: CreateJobProfileComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "link-profile",
        component: LinkJobprofileComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "behavioral-map",
        component: BehavioralMapComponent,
        canActivate: [AuthGuardService],
      },

      /***************************************************************************************
       ********************************* DEFAULT ROUTES ***************************************
       ***************************************************************************************/

      {
        path: "docs",
        component: DocsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "**",
        redirectTo: "home",
      },
    ],
  },
];
