import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
  render: string;
  permission: Array<any>;
}

const MENUITEMS = [
  {
    path: 'companies',
    state: '',
    name: 'Companies',
    type: 'link',
    render: 'material',
    icon: 'layers',
    permission: [1]
  }
];

@Injectable()
export class AdminItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
