import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../services/authentication.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { NestedTreeControl } from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';

interface FoodNode {
  id: number;
  node: string;
  items?: FoodNode[];
}
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-tree-permission',
  templateUrl: './tree-permission.component.html',
  styleUrls: ['./tree-permission.component.css']
})
export class TreePermissionComponent implements OnInit {

  /* Eventos que se enviaran del lado del receptor */
  @Output() onSelectGroup: EventEmitter<any> = new EventEmitter();

  /* Información que ingresa para mostrarse
   * @params
   * data: JSONArray<JsonArray< N > >; donde N es la profundidad indefinida.
   * key: String; es la clave para identificar el siguiente nodo del arbol
  */

  hasChild;

  @Input() data = new MatTreeNestedDataSource<FoodNode>();
  @Input() key: string;

  /* Requerido por material para dar forma al arbol, mediante una interface previamente definida */
  treeControl = new NestedTreeControl<FoodNode>(node => node.items);

  constructor( public auth: AuthenticationService, public translate: TranslateService ) {
    this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );
  }

  ngOnInit() {

  }

  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  todoItemSelectionToggle(row: any, $event: any) {

    if ( Array.isArray(row.items) && row.items.length > 0 ) {

      row.items = this.changeStatus(row.items, $event.checked);

    } else {

      row.checked = $event.checked;

    }

    this.onSelectGroup.emit(this.data);

  }

  private changeStatus( items: Array<any>, value: boolean) {

    const out = [];

    items.forEach( x =>  {

      x.checked = value;

      if ( Array.isArray(x.items) && x.items.length > 0 ) {
        x.items = this.changeStatus(x.items, value);
      }

      out.push(x);

    });

    return out;

  }

}
