import { Component, Inject, OnInit } from "@angular/core";
import {  MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import Swal from "sweetalert2";
import { DialogData } from "../../modals/link-jobprofile/link-jobprofile.component";
import { IntegrityReportService } from "../../../../services/integrity-report.service";

@Component({
  selector: "app-modal-comments",
  templateUrl: "./modal-comments.component.html",
  styleUrls: ["./modal-comments.component.css"],
})
export class modalCommentComponent implements OnInit {
  idEvaluation: any;
  openModal: boolean = true;

  comment = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private report: IntegrityReportService
  ) {
    this.idEvaluation = data;
    this.comment = this.idEvaluation.comment;
  }

  ngOnInit() {}

  changeComment() {
    const formdata = {
      Evaluation: this.idEvaluation.evaluation,
      Name: this.comment,
    };

    this.report.comment_post(formdata).subscribe((response: any) => {
      const { data, msg, ok } = response;
      if (ok) {
        Swal.fire("Perfecto!", "Comentario guardado", "success");
      }
      console.log(data);
    });
  }


}
