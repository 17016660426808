import Swal from 'sweetalert2';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthenticationService } from '../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {

  makeHttp = false;

  mobileQuery: MediaQueryList;
  dir = 'ltr';

  green: boolean;
  blue: boolean;

  dark: boolean;
  minisidebar: boolean;

  boxed: boolean;
  danger: boolean;

  showHide: boolean;
  url: string;

  relation: string;
  group: string;

  sidebarOpened;
  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  moduleName: any = [];
  loginForm: FormGroup;

  //publicFolder = environment.private;

  statuses = [
    'new',
    'acknowledged',
    'inprogress',
    'standby',
    'aborted',
    'solved'
  ];

  ticket = null;
  activity = [];
  history = [];
  questions = [];
  comments: FormGroup;
  attachments = [];
  constructor(
    private conector: ConectorJsonService,
    private fb: FormBuilder,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private service: AuthenticationService,
    private translate: TranslateService,
    private router: Router
  ) {

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.comments = this.fb.group({
      comments: [null, Validators.required]
    });

    this.service.setLanguage().then( k => this.translate.setDefaultLang(k));

  }

  ngOnInit() {
    this.reloadMyData();
  }

  reloadMyData() {
    this.conector.ticket_information().subscribe( (k: any) => {

      const { ok, data, msg} = k;

      if ( ok === true ) {

        const { ticket, activity, questions, attachments, relation, group } = data;

        this.group = group;
        this.ticket = ticket;

        this.relation = relation;
        this.activity = activity;

        this.questions = questions;
        this.attachments = attachments;

      }

    });
  }

  log_out() {
    localStorage.clear();
    localStorage.removeItem('token');
    this.router.navigateByUrl('/');
  }

  publicMessage(e) {
    e.kind = 'public';
    this.conector.publish_message(e).subscribe( (k: any) => {
      this.reloadMyData();
      this.comments.reset();
    });
  }

  fileUpload(event) {

    const formData = new FormData();

    const { addedFiles, rejectedFiles, source } = event;

    formData.append('file', addedFiles[0]);

    this.conector.ticket_upload(formData).subscribe( (response: any) => {

      const {ok, msg, data } = response;

      if (ok === true) {

        this.attachments.push(data);

      } else {
        Swal.fire('Error', msg, 'error');
      }

    }, (err) => {
      console.info(err);
    });
  }

  getPrivateFile(file) {

    console.info('req', file);

    this.conector.private_file(file).subscribe(async (data: any) => {

      Swal.fire({
        title:'Success', 
        text:await this.translate.get("archivoDescargado").toPromise(), 
        icon:'success'
      });

      const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(data);
        a.href = objectUrl;
        a.download = file;
        a.click();
        URL.revokeObjectURL(objectUrl);

    });

  }

}
