import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../services/authentication.service';
@Component({
	selector: 'app-transfer-all-information-of-auser',
	templateUrl: './transfer-all-information-of-auser.component.html',
	styleUrls: ["./transfer-all-information-of-auser.component.css"],
})
export class TransferAllInformationOfAUserComponent implements OnInit {
	public selectedUser: any = null;
	public showError: boolean = false;
	constructor(
		public dialogRef: MatDialogRef<TransferAllInformationOfAUserComponent>,
		public translate: TranslateService,
		public auth: AuthenticationService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));

	}

	ngOnInit() { }

	onNoClick(): void {
		if (!this.selectedUser) {
			this.showError = true;
		} else {
			this.dialogRef.close();
		}
	}
}
