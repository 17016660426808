import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SharedCommunicationService {

  isVisibleSource: BehaviorSubject<boolean> = new BehaviorSubject(false);

  loader: BehaviorSubject<boolean> = new BehaviorSubject(false);

  menubar: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  enableNav: BehaviorSubject<boolean> = new BehaviorSubject(false);

  openRoute: BehaviorSubject<string> = new BehaviorSubject(null);

  httpCall: BehaviorSubject<boolean> = new BehaviorSubject(false);

  switchModule: BehaviorSubject<any> = new BehaviorSubject(null);

  reloadSite: BehaviorSubject<any> = new BehaviorSubject(null);

  contableNotifications: BehaviorSubject<any> = new BehaviorSubject(0);

  loadNotifications: BehaviorSubject<any> = new BehaviorSubject(null);

  openMenuNotifications: BehaviorSubject<any> = new BehaviorSubject(null);

  evaluationTimEnabler: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor() { }

  /*
  * Verifica si la variable recibida es un objeto
  * @input: any
  * @return boolean
  */
  isJsonObject(model)  {
    return Object.prototype.toString.call(model) === '[object Object]';
  }

  /*
  * Convierte un objeto json a un array por { key: value }
  * @input: JSONObject
  * @return: JSONArray
  */
  ObjecToArray(model) {
    const toArray = [];
    Object.keys(model).forEach( x => { toArray.push({ id: x, name: model[x] }) });
    return toArray;
  }

  KnowSesion(){
    return localStorage.getItem('know_session');
  }

  dateToString(data, isHours = false) {
    const format = (isHours) ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
    return moment(data).format(format).toString();
  }

  async setJsonObject(key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, JSON.stringify(value));
    });
  }

  async getJsonObject(key) {
    return Promise.resolve().then(function () {
      return JSON.parse(localStorage.getItem(key));
    });
  }

}
