import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConectorJsonService } from '../../../../services/conector-json.service';
//import { provideNativeDateAdapter } from '@angular/material/core';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-export-data-survey',
    templateUrl: './export-data-survey.component.html',
    //providers: [provideNativeDateAdapter()],
})
export class ExportDataSurveyComponent {
    surveyReportForm: FormGroup;
    startDate: Date;
    endDate: Date;
    constructor(
        public dialogRef: MatDialogRef<ExportDataSurveyComponent>,
        @Inject(MAT_DIALOG_DATA) public groupsAndSubgroupsIds: any,
        private conector: ConectorJsonService,
        private form: FormBuilder
    ) {
        this.surveyReportForm = this.form.group({
            startDate: [null, Validators.required],
            endDate: [null, Validators.required],
        });
    }

    onClose(): void {
        this.dialogRef.close();
    }

    downloadExportData() {
        console.log(this.groupsAndSubgroupsIds);
        let formdata = {
            //idSurvey: this.idSurvey,
            initialDate: this.surveyReportForm.controls.startDate.value,
            endDate: this.surveyReportForm.controls.endDate.value,
        };

        if (formdata.initialDate != null && formdata.endDate != null) {
            if (formdata.initialDate.getTime() > formdata.endDate.getTime()) {
                Swal.fire({
                    text: 'La fecha final no puede ser menor a la inicial',
                    icon: 'error',
                    showConfirmButton: true,
                });
                this.surveyReportForm.get('endDate').setValue(null);
            }
            return;
        }

        // Swal.fire({
        //   title: 'Descargando datos',
        //   text: 'Espere un momento...',
        //   timerProgressBar: true,
        //   didOpen: () => {
        //     Swal.showLoading();
        //   },
        // });

        //Crear endpoint de exportar los datos
        // this.conector
        //   .downloadGroupReportExit(formdata)
        //   .subscribe((response: any) => {
        //     const { data, msg, ok } = response;
        //     Swal.close();
        //     if (ok) {
        //       const linkSource =
        //         'data:application/pdf;base64,' + response.data.pdfBase64;
        //       const downloadLink = document.createElement('a');
        //       const fileName = response.data.fileName;

        //       downloadLink.href = linkSource;
        //       downloadLink.download = fileName;
        //       downloadLink.click();
        //       this.dialogRef.close();
        //     } else {
        //       Swal.fire('Error', msg, 'error');
        //       this.dialogRef.close();
        //     }
        //   });
    }
}
