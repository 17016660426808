import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class OwnQuestionsServices {
  constructor(private http: HttpClient) {}

  getAllOwnQuestions(init = 0, limit = 10) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/JobQuestions/GetAllOwnQuestions?limit=${limit}&offset=${init}`,
      {
        headers,
      }
    );
  }
  createQuestion(data: any) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.post(`${environment.server}/JobQuestions`, data, {
      headers,
    });
  }
  updateQuestion(data: any) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.put(
      `${environment.server}/JobQuestions/UpdateQuestion/${data.id}`,
      data,
      {
        headers,
      }
    );
  }
  deleteQuestion(questionId: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.delete(
      `${environment.server}/JobQuestions/${questionId}`,
      {
        headers,
      }
    );
  }
  getAnswersByOwnQuestion(questionId: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/JobQuestions/GetAnswersByOwnQuestion/${questionId}`,
      {
        headers,
      }
    );
  }
  createAnswer(data: any) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.post(
      `${environment.server}/JobQuestions/AddAnswer`,
      data,
      {
        headers,
      }
    );
  }
  updateAnswerOwnQuestion(data: any) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.put(
      `${environment.server}/JobQuestions/UpdateAnswerOwnQuestion/${data.IdAnswer}`,
      data,
      {
        headers,
      }
    );
  }
  deleteAnswerOwnQuestion(answerId: number) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.delete(
      `${environment.server}/JobQuestions/DeleteAnswer/${answerId}`,
      {
        headers,
      }
    );
  }
  addQuestionsToJob(data: any) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.post(
      `${environment.server}/JobQuestions/AddQuestionsToJob`,
      data,
      {
        headers,
      }
    );
  }
}
