import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatStepper, MatPaginator, MatTableDataSource, MatCheckboxChange } from '@angular/material';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConnectorEthicsSurveyService } from '../../../../services/ethics-survey/conector-ethics-survey.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
	UsersSurvey,
	configModelType1,
	topicsSurvey,
	seriesSurveyConfig,
	questionsSurveyConfig,
	answerQuestionsSurveyConfig,
	ownQuestionsSurveyConfig,
	questionsTypeSurveyConfig,
} from '../../ViewModel/config-model/config-model.component';

import { FlatTreeControl } from '@angular/cdk/tree';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ConectorJsonService } from '../../../../services/conector-json.service';
import Groups from '../../../../types/db-groups-response';
import User from '../../../../types/db-user-profile-response';

@Component({
	selector: 'app-update-config',
	templateUrl: './update-config.component.html',
	styleUrls: ['./update-config.component.css'],
})
export class UpdateConfigComponent implements OnInit, AfterViewInit, OnDestroy {
	// arbol

	displayedColumns: string[] = ['name', 'count'];
	displayedColumnsUserInfo: string[] = ['nickname', 'fullname', 'email', 'group', 'checkbox'];

	// --------
	isShownEngagement: boolean = false; // var control view div hiden
	stepControl1: boolean = true; // var control view div hiden
	stepControl2: boolean = false; // var control view div hiden
	stepControl3: boolean = false; // var control view div hiden
	stepControl4: boolean = false; // var control view div hiden
	stepControl5: boolean = false; // var control view div hiden
	stepControl6: boolean = false; // var control view div hiden
	stepControl7: boolean = false; // var control view div hiden
	spinnerDivView: boolean = true;
	statusProyect: number = 0;
	statusInit: number = 0;

	// -- DISABLE

	// paso 3
	//----- valida submit
	groupUsersDisable = false;
	UsersListDisable = false;
	// paso 4
	RemoveQuestionsQuarterDisable = false;
	addQuestionsQuarterDisable = false;

	// paso 5
	//cat
	btnNewCategoryDisable = false;

	BtnInEditCategory = false;

	BtnEditCategory = false;
	BtnRemoveCategory = false;
	//srs
	//SrsCateDisable = false;
	SrsBntAddCateDisable = false;
	srsBtnEditInp = false;
	srsRemoveEdit = false;
	srsBtnEdit = false;

	// qestions
	quesBntAddDisable = false;
	qusBtnEditInp = false;
	qusBtnEdit = false;
	qusBtnRemove = false;

	// anwers
	anweBntAddDisable = false;
	anweBtnEditInp = false;
	anweBtnEdit = false;
	anweBtnRemove = false;

	// paso 6

	submitValid: boolean = false;
	stateSubmit: boolean = true;

	///

	listGroupForm = []; // lista Grupos
	listUsersForm = []; // lista Grupos
	listUsersAnswer = []; // lista Grupos

	// stips
	isLinear = true;
	// stips 1
	firstFormGroup: FormGroup;
	// stips 2
	secondFormGroup: FormGroup;
	// stips 3
	thirdFormGroup: FormGroup;
	// stips 4
	quarterFormGroup: FormGroup;
	// stips 4 2
	quarterFormGroupType2: FormGroup;
	// series
	seriesCategory = '0';
	// Preguntas
	questionsSeriesCategory = '0';
	questionsSeries = '0';
	// stips 5
	fifthFormGroup: FormGroup;
	// stips 6
	sixthFormGroup: FormGroup;

	/// update

	listGroupUserUpdate = []; // lista Grupos

	public dataSourceToPaginator = new MatTableDataSource([]);
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	private destroy$: Subject<boolean> = new Subject<boolean>();
	private groups: Groups[];
	private subgroups: Groups[];
	private subgroupsByGroup: Groups[] = [];
	constructor(
		public dialog: MatDialog,
		private _formBuilder: FormBuilder,
		private route: Router,
		private router: ActivatedRoute,
		private conector: ConnectorEthicsSurveyService,
		public datepipe: DatePipe
	) {
		// stip 1
		this.firstFormGroup = this._formBuilder.group({
			stepFirstType: [''],
			stepSecondEngagement: [''],
		});
		this.secondFormGroup = this._formBuilder.group({
			id: ['', Validators.required],
			nameProyect: ['', Validators.required],
			nameCompany: ['', Validators.required],
			startDate: ['', Validators.required],
			endDate: ['', Validators.required],
			status: ['', Validators.required],
			description: ['', Validators.required],
			idAccount: ['', Validators.required],
			random: [''],
		});
		this.thirdFormGroup = this._formBuilder.group({
			item: ['', Validators.required],
		});
		// stip 4
		this.quarterFormGroup = this._formBuilder.group({
			questions: this._formBuilder.array([
				this._formBuilder.group({
					question: [''],
					answer: [''],
					id: [''],
				}),
			]),
		});
		// Condicion si es subtype 2
		this.quarterFormGroupType2 = this._formBuilder.group({
			categorys: this._formBuilder.array([
				this._formBuilder.group({
					id: [''],
					name: [''],
					status: [''],
				}),
			]),
			newCategory: [''],

			series: this._formBuilder.array([
				this._formBuilder.group({
					id: [''],
					idCategory: [''],
					name: [''],
					status: [''],
					idSeries: [''],
				}),
			]),
			newSerie: [''],
			newSerieCategory: [''],
			newSeriesQuestions: [''],
			newQuestionsCategory: [''],

			questions: this._formBuilder.array([
				this._formBuilder.group({
					id: [''],
					name: [''],
					status: [''],
					idSeries: [''],
					idCatQuestions: [''],
					idFactors: [''],
					GroupFactor: [''],
					idrelacion: [''],
				}),
			]),
			newNameQuestions: [''],

			questionsTypes: this._formBuilder.array([
				this._formBuilder.group({
					id: [''],
					typeid: [''],
					section1: [''],
					section2: [''],
					section3: [''],
					description: [''],
					orderlist: [''],
					active: [''],
				}),
			]),
			newQuestionsType: [''],
		});
		// stip 5
		this.fifthFormGroup = this._formBuilder.group({
			questions: this._formBuilder.array([this._formBuilder.group({ id: [''], question: [''], answer: [''] })]),
		});
		// stip 6
		this.sixthFormGroup = this._formBuilder.group({});
	}

	ngOnInit() {
		this.conector
			.getAllGroups()
			.pipe(
				takeUntil(this.destroy$),
				map((response) => response.data)
			)
			.subscribe((groups) => (this.groups = groups));

		this.conector
			.getAllSubgroups()
			.pipe(
				takeUntil(this.destroy$),
				map((response) => response.data)
			)
			.subscribe((subgroups) => (this.subgroups = subgroups));
		if (this.statusInit == 0) {
			this.getDataUpate();
			this.questionsTypeGet();
		}
		this.statusInit = 1;
	}

	ngAfterViewInit() {
		this.dataSourceToPaginator.paginator = this.paginator;
	}
	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	////////// update

	getDataUpate() {
		var id = this.router.snapshot.paramMap.get('id');
		this.conector.ethicsSurveyGetIdUpdate(id).subscribe((response: any) => {
			// alert('Cargado 2 ');

			const { ok, data, msg } = response;
			if (ok === false) {
				return Swal.fire('Error', msg + ' ------- ', 'error');
			}
			console.log('data');
			console.log(data);

			var startDate = this.datepipe.transform(data.startDate, 'yyyy-MM-dd');
			var endDate = this.datepipe.transform(data.endDate, 'yyyy-MM-dd');
			this.secondFormGroup.patchValue({
				id: data.id,
				nameProyect: data.nameProyect,
				nameCompany: data.nameCompany,
				startDate: startDate,
				endDate: endDate,
				idAccount: data.idAccount,
				description: data.description,
				status: data.status,
			});

			this.statusProyect = data.status;

			this.firstFormGroup = this._formBuilder.group({
				stepFirstType: [String(data.type)],
				stepSecondEngagement: [String(data.subType)],
			});

			if (data.random == 0) {
				this.secondFormGroup.controls['random'].setValue(false);
			} else {
				this.secondFormGroup.controls['random'].setValue(true);
			}
			this.spinnerDivView = false;

			if (data.type == 1) this.isShownEngagement = true;
			else this.isShownEngagement = false;

			/// load group user

			this.listGroupUserUpdate = data.listUsers;

			for (var key in data.listUsers) {
				var itemKey = data.listUsers[key].groups;

				const resultado = this.listGroupForm.find((item) => item.idGroup === itemKey.id);

				if (resultado == undefined) {
					this.listGroupForm.push({ idGroup: itemKey.id });
				}
			}

			//load topics

			const controlCategorys = <FormArray>this.quarterFormGroupType2.controls['categorys'];
			for (var key in data.listTpcs) {
				var tpcs = data.listTpcs[key];
				controlCategorys.push(
					this._formBuilder.group({
						id: [{ value: tpcs.idCategory, disabled: false }],
						name: [{ value: tpcs.name, disabled: false }],
						status: [{ value: tpcs.id, disabled: false }],
					})
				);

				// get series update

				for (var key in data.listSrs) {
					const controlSeries = <FormArray>this.quarterFormGroupType2.controls['series'];
					var srs = data.listSrs[key];
					if (srs.idTopicCat == tpcs.idCategory) {
						controlSeries.push(
							this._formBuilder.group({
								id: [{ value: srs.id, disabled: false }],
								idCategory: [{ value: srs.idTopicCat, disabled: false }],
								name: [{ value: srs.name, disabled: false }],
								status: [{ value: srs.status, disabled: false }],
								idSeries: [{ value: srs.idSeries, disabled: false }],
							})
						);
						for (var key in data.listQstns) {
							const controlQuestions = <FormArray>this.quarterFormGroupType2.controls['questions'];
							var QuestionKey = data.listQstns[key];
							if (QuestionKey.idserie == srs.idSeries) {
								controlQuestions.push(
									this._formBuilder.group({
										id: [QuestionKey.idquestion],
										name: [QuestionKey.name],
										status: [srs.id],
										idSeries: [srs.idSeries],
										idCatQuestions: [QuestionKey.active],
										idFactors: [QuestionKey.active],
										GroupFactor: [QuestionKey.active],
										idrelacion: [QuestionKey.idrelacion],
									})
								);
							}
						}
					}
				}
			}

			// get question engagement update
			const controlEngagement = <FormArray>this.fifthFormGroup.controls['questions'];
			if (controlEngagement.value.length <= 1) {
				for (var key in data.listQstnsEngagement) {
					var QuestionKey = data.listQstnsEngagement[key];
					var valueAnwers = 0;
					if (QuestionKey.anwers != null) {
						valueAnwers = QuestionKey.anwers.c.value;
						controlEngagement.push(
							this._formBuilder.group({
								id: [QuestionKey.idrelacion],
								question: [QuestionKey.name + ' ' + QuestionKey.idquestion],
								answer: [valueAnwers],
							})
						);
					}
				}
			}

			/// load values company
			const control = <FormArray>this.quarterFormGroup.controls['questions'];
			control.removeAt(0);
			for (var i = 0; i < data.valueEngagemen.length; i++) {
				let item = data.valueEngagemen[i];

				control.push(
					this._formBuilder.group({
						id: [item.id],
						question: [item.value, Validators.required],
						answer: [item.description, Validators.required],
					})
				);
			}

			// load user by project
			for (var key in data.listUsers) {
				var item = data.listUsers[key];
				this.listUsersForm.push({
					id: item.userProfile.id,
					idGroup: item.userProfile.group,
					status: '1',
					name: item.userProfile.name,
					firstname: item.userProfile.firstname,
					lastname: item.userProfile.lastname,
					nickname: item.userProfile.nickname,
					phone: item.userProfile.phone,
					email: item.userProfile.email,
					groups: item.userProfile.group,
					namegroup: item.groups.name,
					checked: true,
				});
			}

			this.dataSourceToPaginator = new MatTableDataSource(this.listUsersForm);
			this.dataSourceToPaginator.paginator = this.paginator;

			// list de user answers
			for (var key in data.answersUsers) {
				var item = data.answersUsers[key];
				this.listUsersAnswer.push({
					id: item,
				});
			}

			switch (this.statusProyect) {
				case 1: // agendado
					// paso 1
					this.firstFormGroup.get('stepFirstType').disable();
					this.firstFormGroup.get('stepSecondEngagement').disable();
					break;
				case 2: // iniciado
					// paso 1
					this.firstFormGroup.get('stepFirstType').disable();
					this.firstFormGroup.get('stepSecondEngagement').disable();
					// paso 2
					this.secondFormGroup.get('startDate').disable();
					// paso 4
					this.RemoveQuestionsQuarterDisable = true;
					this.addQuestionsQuarterDisable = true;
					this.quarterFormGroup.disable();

					// paso 5
					//cat
					this.quarterFormGroupType2.disable();
					this.btnNewCategoryDisable = true;
					this.BtnInEditCategory = true;
					this.BtnEditCategory = true;
					this.BtnRemoveCategory = true;
					//srs
					this.SrsBntAddCateDisable = true;
					this.srsBtnEditInp = true;
					this.srsRemoveEdit = true;
					this.srsBtnEdit = true;
					// qestions
					this.quesBntAddDisable = true;
					this.qusBtnEditInp = true;
					this.qusBtnEdit = true;
					this.qusBtnRemove = true;
					// anwers
					this.anweBntAddDisable = true;
					this.anweBtnEditInp = true;
					this.anweBtnEdit = true;
					this.anweBtnRemove = true;
					// paso 6
					this.fifthFormGroup.disable();
					break;
				case 4: // Finalizado
					this.firstFormGroup.get('stepFirstType').disable();
					this.firstFormGroup.get('stepSecondEngagement').disable();
					// paso 2
					this.secondFormGroup.get('nameProyect').disable();
					this.secondFormGroup.get('nameCompany').disable();
					this.secondFormGroup.get('startDate').disable();
					this.secondFormGroup.get('description').disable();

					// paso 3
					this.groupUsersDisable = false;
					this.UsersListDisable = false;
					// paso 4
					this.RemoveQuestionsQuarterDisable = true;
					this.addQuestionsQuarterDisable = true;
					this.quarterFormGroup.disable();
					// paso 5
					//cat
					this.quarterFormGroupType2.disable();

					//  (<FormArray>this.quarterFormGroupType2.get('categorys'))
					//   .controls
					//   .forEach(control => {
					//     control.get('name').disable();
					//     control.disable();
					//   })

					this.btnNewCategoryDisable = true;
					this.BtnInEditCategory = true;
					this.BtnEditCategory = true;
					this.BtnRemoveCategory = true;
					//srs
					this.SrsBntAddCateDisable = true;
					this.srsBtnEditInp = true;
					this.srsRemoveEdit = true;
					this.srsBtnEdit = true;
					// qestions
					this.quesBntAddDisable = true;
					this.qusBtnEditInp = true;
					this.qusBtnEdit = true;
					this.qusBtnRemove = true;
					// anwers
					this.anweBntAddDisable = true;
					this.anweBtnEditInp = true;
					this.anweBtnEdit = true;
					this.anweBtnRemove = true;
					// paso 6
					this.fifthFormGroup.disable();
					break;
				case 5: // expirado
					this.firstFormGroup.get('stepFirstType').disable();
					this.firstFormGroup.get('stepSecondEngagement').disable();
					// paso 2
					this.secondFormGroup.get('nameProyect').disable();
					this.secondFormGroup.get('nameCompany').disable();
					this.secondFormGroup.get('startDate').disable();
					this.secondFormGroup.get('description').disable();

					// paso 3
					// this.groupUsersDisable = false;
					// this.UsersListDisable = false;
					// paso 4
					this.RemoveQuestionsQuarterDisable = true;
					this.addQuestionsQuarterDisable = true;
					this.quarterFormGroup.disable();
					// paso 5
					//cat
					this.quarterFormGroupType2.disable();

					//  (<FormArray>this.quarterFormGroupType2.get('categorys'))
					//   .controls
					//   .forEach(control => {
					//     control.get('name').disable();
					//     control.disable();
					//   })

					this.btnNewCategoryDisable = true;
					this.BtnInEditCategory = true;
					this.BtnEditCategory = true;
					this.BtnRemoveCategory = true;
					//srs
					this.SrsBntAddCateDisable = true;
					this.srsBtnEditInp = true;
					this.srsRemoveEdit = true;
					this.srsBtnEdit = true;
					// qestions
					this.quesBntAddDisable = true;
					this.qusBtnEditInp = true;
					this.qusBtnEdit = true;
					this.qusBtnRemove = true;
					// anwers
					this.anweBntAddDisable = true;
					this.anweBtnEditInp = true;
					this.anweBtnEdit = true;
					this.anweBtnRemove = true;
					// paso 6
					this.fifthFormGroup.disable();
					break;
				default:
					break;
			}
			//return Swal.fire('Configuracion Cargada ...', '', 'success');
		});
	}

	//////// change check group

	changeCheckGroupUser(idUser) {
		let search = this.listUsersForm.find((item) => item.id === idUser);

		if (search && this.statusProyect == 4) {
			return true;
		} else if (search && this.statusProyect == 2) {
			// REGLA estado iniiciado  usuario no puede quitar si ya respondio

			let searchUser = this.listUsersAnswer.find((X) => X.id.id === idUser);

			if (searchUser) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	changeAnswerEngagement(value, type) {
		if (value == type) {
			return true;
		} else {
			return false;
		}
	}

	// submit form entity
	submit() {
		this.stateSubmit = false;
		if (this.submitValid == false) {
			const EntityConfig = new configModelType1();

			// -----------------------
			// DATOS BASICOS
			// -----------------------

			EntityConfig.id = this.secondFormGroup.get('id').value;
			EntityConfig.nameProyect = this.secondFormGroup.get('nameProyect').value;
			EntityConfig.nameCompany = this.secondFormGroup.get('nameCompany').value;
			(EntityConfig.idModule = 1),
				(EntityConfig.idUser = ''),
				(EntityConfig.idAccess = '1'),
				(EntityConfig.startDate = this.secondFormGroup.get('startDate').value);
			EntityConfig.endDate = this.secondFormGroup.get('endDate').value;

			var f = new Date();
			EntityConfig.createDate = this.datepipe.transform(f, 'yyyy-MM-dd');

			EntityConfig.idAccount = this.secondFormGroup.get('idAccount').value;

			EntityConfig.type = this.firstFormGroup.get('stepFirstType').value;
			EntityConfig.subType = this.firstFormGroup.get('stepSecondEngagement').value;
			EntityConfig.engagement = this.firstFormGroup.get('stepSecondEngagement').value;

			EntityConfig.description = this.secondFormGroup.get('description').value;
			EntityConfig.predetermined = '1';
			EntityConfig.idLanguaje = '1';
			EntityConfig.status = this.secondFormGroup.get('status').value;

			if (this.secondFormGroup.get('random').value == 'True') {
				EntityConfig.random = 'True';
			} else {
				EntityConfig.random = 'False';
			}

			// -----------------------
			// USUARIOS
			// -----------------------

			let UsersSurveyArrayModel = [];

			for (var i = 0; i < this.listUsersForm.length; i++) {
				let item = this.listUsersForm[i];
				const UsersSurvey_ = new UsersSurvey();
				UsersSurvey_.id = item.id;
				UsersSurvey_.idGroup = item.idGroup;
				UsersSurvey_.status = item.status;
				UsersSurveyArrayModel.push(UsersSurvey_);
			}
			EntityConfig.ListUsersSurvey = UsersSurveyArrayModel;

			// -----------------------
			// Categorias
			// -----------------------
			let categoryModel = this.quarterFormGroupType2.get('categorys') as FormArray;
			let categoryArrayModel = [new topicsSurvey()];

			for (var i = 0; i < categoryModel.value.length; i++) {
				let item = categoryModel.value[i];
				if (item.id != '') {
					const categorSuvery = new topicsSurvey();
					categorSuvery.id = item.id;
					categorSuvery.idSurveyConfig = 1;
					categorSuvery.idCategory = 1;
					categorSuvery.name = item.name;
					categorSuvery.description = item.name;
					categorSuvery.active = item.status;
					categorSuvery.orderlist = i;
					categoryArrayModel.push(categorSuvery);
				}
			}
			categoryArrayModel.splice(0, 1);
			EntityConfig.category = categoryArrayModel;

			// -----------------------
			// Series
			// -----------------------
			let seriesModel = this.quarterFormGroupType2.get('series') as FormArray;
			let seriesArrayModel = [new seriesSurveyConfig()];
			for (var i = 0; i < seriesModel.value.length; i++) {
				let item = seriesModel.value[i];
				if (item.id != '') {
					const seriesSurvey = new seriesSurveyConfig();

					seriesSurvey.id = item.id;
					seriesSurvey.idSurveyConfig = 1;
					seriesSurvey.name = item.name;
					seriesSurvey.description = item.name;
					seriesSurvey.idTopic = item.idCategory;
					seriesSurvey.active = 1;
					seriesSurvey.orderList = i;
					seriesSurvey.change = 0;
					seriesSurvey.idSeries = item.idSeries;

					seriesArrayModel.push(seriesSurvey);
				}
			}
			seriesArrayModel.splice(0, 1);
			EntityConfig.series = seriesArrayModel;

			// -----------------------
			// preguntas
			// -----------------------
			let questionsModel = this.quarterFormGroupType2.get('questions') as FormArray;
			let questionsArrayModel = [new questionsSurveyConfig()];

			for (var i = 0; i < questionsModel.value.length; i++) {
				let item = questionsModel.value[i];
				if (item.id != '') {
					const questionsSurvey = new questionsSurveyConfig();

					questionsSurvey.idquestion = item.id;
					questionsSurvey.idserie = item.idSeries;
					questionsSurvey.idtype = 1; // consultar
					questionsSurvey.name = item.name;
					questionsSurvey.active = 1;
					questionsSurvey.orderlist = i;
					questionsSurvey.idFactors = item.idFactors + '';
					questionsSurvey.GroupFactor = item.GroupFactor;
					//questionsSurvey.idrelacion =  item.idrelacion;
					questionsSurvey.change = 1;

					questionsArrayModel.push(questionsSurvey);
				}
			}
			questionsArrayModel.splice(0, 1);
			EntityConfig.questions = questionsArrayModel;

			// -----------------------
			// tipo de respuesta
			// -----------------------
			let questionsTypesModel = this.quarterFormGroupType2.get('questionsTypes') as FormArray;
			let questionsTypesArrayModel = [new questionsTypeSurveyConfig()];
			for (var i = 0; i < questionsTypesModel.value.length; i++) {
				let item = questionsTypesModel.value[i];
				if (item.id != '') {
					const questionsTypeSurvey = new questionsTypeSurveyConfig();
					questionsTypeSurvey.id = item.id;
					questionsTypeSurvey.typeid = item.typeid;
					questionsTypeSurvey.section1 = item.section1;
					questionsTypeSurvey.section2 = item.section2;
					questionsTypeSurvey.section3 = item.section3;
					questionsTypeSurvey.description = item.description;
					questionsTypeSurvey.orderlist = item.orderlist;
					questionsTypeSurvey.active = item.active;
					questionsTypesArrayModel.push(questionsTypeSurvey);
				}
			}
			questionsTypesArrayModel.splice(0, 1);
			EntityConfig.typeAnswer = questionsTypesArrayModel;

			// -----------------------
			//respuestas  engagement
			// -----------------------

			let answerQuestionsModel = this.fifthFormGroup.get('questions') as FormArray;
			let answerQuestionsArrayModel = [new answerQuestionsSurveyConfig()];

			if (EntityConfig.subType != '3') {
				for (var i = 0; i < answerQuestionsModel.value.length; i++) {
					let item = answerQuestionsModel.value[i];
					if (item.id != '') {
						const answerQuestionsSurvey = new answerQuestionsSurveyConfig();
						if (item.id == '-1') {
							answerQuestionsSurvey.id = item.id;
							answerQuestionsSurvey.answer = item.question + '||' + item.answer;
							answerQuestionsSurvey.type = '';
						} else {
							answerQuestionsSurvey.id = item.id;
							answerQuestionsSurvey.answer = item.answer + '';
							answerQuestionsSurvey.type = '';
						}
						answerQuestionsArrayModel.push(answerQuestionsSurvey);
					}
				}
			} else {
				const answerQuestionsSurvey = new answerQuestionsSurveyConfig();
				answerQuestionsSurvey.id = 0;
				answerQuestionsSurvey.answer = '||';
				answerQuestionsArrayModel.push(answerQuestionsSurvey);
			}

			answerQuestionsArrayModel.splice(0, 1);
			EntityConfig.answer = answerQuestionsArrayModel;

			// -----------------------
			// preguntas propias
			// -----------------------
			let ownQuestionsModel = this.quarterFormGroup.get('questions') as FormArray;
			let ownQuestionsArrayModel = [new ownQuestionsSurveyConfig()];
			if (EntityConfig.subType != '3') {
				for (var i = 0; i < ownQuestionsModel.value.length; i++) {
					let item = ownQuestionsModel.value[i];
					if (item.id != '' && item.id > 0) {
						const ownQuestionsSurvey = new ownQuestionsSurveyConfig();
						ownQuestionsSurvey.id = i;
						ownQuestionsSurvey.question = item.question;
						ownQuestionsSurvey.answer = item.answer;
						ownQuestionsArrayModel.push(ownQuestionsSurvey);
					}
				}
			} else {
				const ownQuestionsSurvey = new ownQuestionsSurveyConfig();
				ownQuestionsSurvey.id = 0;
				ownQuestionsSurvey.question = '0';
				ownQuestionsSurvey.answer = '0';
				ownQuestionsArrayModel.push(ownQuestionsSurvey);
			}
			ownQuestionsArrayModel.splice(0, 1);
			EntityConfig.ownQuestions = ownQuestionsArrayModel;

			// -----------------------
			// ENVIO FORMULARIO
			// -----------------------

			this.conector.ethicsSurveyPostUpdate(EntityConfig).subscribe(
				(response: any) => {
					const { ok, data, msg } = response;
					if (ok === false) {
						var MsgError = '';
						for (var k in data) {
							MsgError = MsgError + 'Error en ' + data[k].cam + ' ( ' + data[k].msg + ' ) \n';
						}
						return Swal.fire('Error', MsgError, 'error');
					} else {
						this.submitValid = true;
						this.stateSubmit = true;

						Swal.fire('Buen trabajo !', 'Configuración guardada', 'success');
						this.route.navigate(['manager/Survey']);
					}
				},
				(err) => {
					this.stateSubmit = true;
					return Swal.fire('Error', err.status + ' ' + err.statusText, 'error');
				}
			);
		} else {
			this.stateSubmit = true;
			return Swal.fire('Error', 'Esta Configuración ya se Guardo ', 'error');
		}

		// }
	}

	// change type proyect  stepper 1
	onChangeType(deviceValue) {
		if (deviceValue == 1) this.isShownEngagement = true;
		else this.isShownEngagement = false;
	}
	// stepper 4 // QuestionsOwner
	get getQuestionsQuarter() {
		return this.quarterFormGroup.get('questions') as FormArray;
	}

	addQuestionsQuarter() {
		const control = <FormArray>this.quarterFormGroup.controls['questions'];
		if (control.status == 'VALID') {
			control.push(
				this._formBuilder.group({ question: ['', Validators.required], answer: ['', Validators.required] })
			);
		}
	}

	RemoveQuestionsQuarter(index: number) {
		const control = <FormArray>this.quarterFormGroup.controls['questions'];
		control.removeAt(index);
	}

	// stepper 1

	validarStepperfirst(stepper: MatStepper) {
		const control = this.firstFormGroup.value;

		if (control.stepFirstType == '1') {
			if (control.stepSecondEngagement != '') {
				if (control.stepSecondEngagement == '2') {
					this.stepControl1 = true;
					this.stepControl2 = true;
					this.stepControl1 = false;

					this.stepControl3 = true;
					this.stepControl4 = true;
					this.stepControl5 = true;
					this.stepControl6 = true;
					this.stepControl7 = true;
				} else if (control.stepSecondEngagement == '1') {
					this.stepControl1 = true;
					this.stepControl2 = true;
					this.stepControl3 = true;
					this.stepControl4 = true;
					this.stepControl5 = false;
					this.stepControl6 = true;
					this.stepControl7 = true;
				} else if (control.stepSecondEngagement == '3') {
					this.stepControl1 = true;
					this.stepControl2 = true;
					this.stepControl3 = true;
					this.stepControl4 = false;
					this.stepControl5 = true;
					this.stepControl6 = false;
					this.stepControl7 = true;
				}
				//stepper.next();
				this.stepControl1 = false;
			} else {
				return Swal.fire('Error', 'Debe seleccionar una configuración ', 'error');
			}
		} else if (control.stepFirstType == '2') {
			this.stepControl2 = true;
			this.stepControl3 = true;
			this.stepControl4 = false;
			this.stepControl5 = false;
			this.stepControl6 = false;
			this.stepControl7 = true;
			this.stepControl1 = false;
		} else {
			return Swal.fire('Error', 'Debe seleccionar un  tipo de Poyecto ', 'error');
		}
	}

	// stepper 2

	validarStepperSecond(stepper: MatStepper) {
		const control = this.secondFormGroup.value;
		if (this.secondFormGroup.status == 'VALID') {
			if (control.startDate > control.endDate) {
				return Swal.fire(
					'Error',
					'La fecha de inicio no puede ser mayor a la fecha de finalizacion de la encuesta  ',
					'error'
				);
			} else {
				//stepper.next();
				this.stepControl2 = false;
			}
		}
	}

	// stepper 3

	validarStepperGroupUsers(stepper: MatStepper) {
		const control = this.listUsersForm.length;
		if (control == 0) {
			return Swal.fire('Error', 'Debe seleccionar por lo menos un usuario  ', 'error');
		} else {
			this.thirdFormGroup.controls['item'].setValue('1');
			this.stepControl3 = false; // var control view div hiden
		}
	}

	// stepper 4

	validarStepperQuarter(stepper: MatStepper) {
		const control = <FormArray>this.quarterFormGroup.controls['questions'];
		if (control.status == 'VALID' || control.status == 'DISABLED') {
			this.stepControl4 = false;
		}
	}

	validarStepperFifth(stepper: MatStepper) {
		const control = <FormArray>this.fifthFormGroup.controls['questions'];

		if (control.status == 'VALID' || control.status == 'DISABLED') {
			//stepper.next();
			this.stepControl6 = false;
		} else {
			return Swal.fire('Error', 'Debe responder todas las preguntas ', 'error');
		}
	}

	get getCategorySurveyArray() {
		let categorys = this.quarterFormGroupType2.get('categorys') as FormArray;
		return categorys;
	}

	getCategorySurveyArrayFind(Index: string, type: number) {
		let Question = '';

		if (Index != '0') {
			let array = this.quarterFormGroupType2.get('categorys') as FormArray;
			let item = array.value[Index];

			if (item.question != '') {
				if (type == 1) {
					Question = item.name;
				} else {
					Question = item.id;
				}
			}
		}
		return Question;
	}

	editCategorys(selector) {
		if (document.getElementById('divEditInput' + selector).style.display == 'block') {
			document.getElementById('divEditInput' + selector).style.display = 'none';
		} else {
			document.getElementById('divEditInput' + selector).style.display = 'block';
		}

		if (document.getElementById('divEditLabel' + selector).style.display == 'block') {
			document.getElementById('divEditLabel' + selector).style.display = 'none';
		} else {
			document.getElementById('divEditLabel' + selector).style.display = 'block';
		}
	}

	addCategory() {
		const control = this.quarterFormGroupType2.controls['newCategory'];
		if (control.status == 'VALID') {
			let array = this.quarterFormGroupType2.get('categorys') as FormArray;
			let id = array.value.length + 1;
			array.push(
				this._formBuilder.group({
					id: [id],
					name: [control.value],
					status: [0],
				})
			);
		}
	}

	removeCategory(index: number) {
		const control = <FormArray>this.quarterFormGroupType2.controls['categorys'];
		let item = control.value[index];

		if (item.id != '') {
			if (item.name == 'Engagement') {
				return Swal.fire('Error', 'No esta permitido borrar esta categoria', 'error');
			} else {
				control.removeAt(index);
			}
		}
	}

	get getSeriesSurveyArray() {
		let series = this.quarterFormGroupType2.get('series') as FormArray;
		return series;
	}

	getSeriesSurveyArrayFind(Index: string, type: number, init: number) {
		let Question = 'N_A';
		if (Index != '0') {
			if (init != 0) {
				let array = this.quarterFormGroupType2.get('series') as FormArray;
				let item = array.value[Index];

				if (item.question != '') {
					if (type == 1) {
						Question = item.name;
					} else if (type == 2) {
						Question = item.id;
					} else if (type == 3) {
						Question = item.idCategory;
					}
				}
			}
		}
		return Question;
	}

	editSeries(selector) {
		if (document.getElementById('divEditSeriesInput' + selector).style.display == 'block') {
			document.getElementById('divEditSeriesInput' + selector).style.display = 'none';
		} else {
			document.getElementById('divEditSeriesInput' + selector).style.display = 'block';
		}

		if (document.getElementById('divEditSeriesLabel' + selector).style.display == 'block') {
			document.getElementById('divEditSeriesLabel' + selector).style.display = 'none';
		} else {
			document.getElementById('divEditSeriesLabel' + selector).style.display = 'block';
		}
	}

	addSeries() {
		const control = this.quarterFormGroupType2.controls['newSerie']; //
		const newSerieCategory = this.quarterFormGroupType2.controls['newSerieCategory'];
		if (control.status == 'VALID') {
			let array = this.quarterFormGroupType2.get('series') as FormArray;
			let id = array.value.length + 1;
			array.push(
				this._formBuilder.group({
					id: [id],
					idCategory: [this.seriesCategory],
					name: [control.value],
					status: [0],
				})
			);
		}
	}

	removeSeries(index: number) {
		const control = <FormArray>this.quarterFormGroupType2.controls['series'];
		let itemSerie = control.value[index];

		const controlCategory = <FormArray>this.quarterFormGroupType2.controls['categorys'];
		let itemCategoryValues = controlCategory.value;

		var itemCategory = itemCategoryValues.filter((x) => x.id === itemSerie.idCategory);

		if (itemCategory[0].id != '') {
			if (itemCategory[0].name == 'Engagement') {
				return Swal.fire('Error', 'No esta permitido borrar esta Serie', 'error');
			} else {
				control.removeAt(index);
			}
		}
	}

	changeCategory(id: string) {
		this.seriesCategory = id;
	}

	// Preguntas

	changeQuestionsSeriesCategory(id: string) {
		this.questionsSeriesCategory = id;
	}

	get getQuestionsSurveyArray() {
		let questions = this.quarterFormGroupType2.get('questions') as FormArray;
		return questions;
	}

	getQuestionsSurveyArrayFind(Index: string, type: number) {
		let Question = 'N_A';
		if (Index != '0') {
			let array = this.quarterFormGroupType2.get('questions') as FormArray;
			let item = array.value[Index];

			if (item.question != '') {
				if (type == 1) {
					Question = item.name;
				} else if (type == 2) {
					Question = item.id;
				} else if (type == 3) {
					Question = item.idSeries;
				}
			}
		}
		return Question;
	}

	editQuestions(selector) {
		if (document.getElementById('divEditQuestionsInput' + selector).style.display == 'block') {
			document.getElementById('divEditQuestionsInput' + selector).style.display = 'none';
		} else {
			document.getElementById('divEditQuestionsInput' + selector).style.display = 'block';
		}

		if (document.getElementById('divEditQuestionsLabel' + selector).style.display == 'block') {
			document.getElementById('divEditQuestionsLabel' + selector).style.display = 'none';
		} else {
			document.getElementById('divEditQuestionsLabel' + selector).style.display = 'block';
		}
	}

	addQuestions() {
		const control = this.quarterFormGroupType2.controls['newNameQuestions']; //
		if (control.status == 'VALID') {
			let array = this.quarterFormGroupType2.get('questions') as FormArray;
			array.push(
				this._formBuilder.group({
					name: [control.value],
					status: '1',
					idSeries: [this.questionsSeries],
					idCatQuestions: [this.questionsSeriesCategory],
					idFactors: '0',
					GroupFactor: 0,
				})
			);
		}
	}

	removeQuestions(index: number) {
		const control = <FormArray>this.quarterFormGroupType2.controls['questions'];
		let itemQuestions = control.value[index];

		const controlSeries = <FormArray>this.quarterFormGroupType2.controls['series'];
		let itemSeriesValues = controlSeries.value;
		var itemSeries = itemSeriesValues.filter((x) => x.id === itemQuestions.idSeries);

		const controlCategory = <FormArray>this.quarterFormGroupType2.controls['categorys'];
		let itemCategoryValues = controlCategory.value;

		var itemCategory = itemCategoryValues.filter((x) => x.id === itemSeries[0].idCategory);

		if (itemCategory[0].id != '') {
			if (itemCategory[0].name == 'Engagement') {
				return Swal.fire('Error', 'No esta permitido borrar esta pregunta', 'error');
			} else {
				control.removeAt(index);
			}
		}

		control.removeAt(index);
	}

	changeSeries(id: string) {
		this.questionsSeries = id;
	}

	//questionsTypeGet
	questionsTypeGet() {
		const control = <FormArray>this.quarterFormGroupType2.controls['questionsTypes'];
		control.removeAt(0);

		control.push(
			this._formBuilder.group({
				id: ['4'],
				typeid: ['1'],
				section1: ['Totalmente de acuerdo'],
				section2: ['Totalmente de acuerdo'],
				section3: ['Totalmente de acuerdo'],
				description: ['Totalmente de acuerdo'],
				orderlist: ['1'],
				active: ['1'],
			})
		);
		control.push(
			this._formBuilder.group({
				id: ['3'],
				typeid: ['1'],
				section1: ['Parcialmente de acuerdo'],
				section2: ['Parcialmente de acuerdo'],
				section3: ['Parcialmente de acuerdo'],
				description: ['Parcialmente de acuerdo'],
				orderlist: ['2'],
				active: ['1'],
			})
		);

		control.push(
			this._formBuilder.group({
				id: ['2'],
				typeid: ['1'],
				section1: ['Parcialmente en desacuerdo'],
				section2: ['Parcialmente en desacuerdo'],
				section3: ['Parcialmente en desacuerdo'],
				description: ['Parcialmente en desacuerdo'],
				orderlist: ['3'],
				active: ['1'],
			})
		);
		control.push(
			this._formBuilder.group({
				id: ['1'],
				typeid: ['1'],
				section1: ['Totalmente en desacuerdo'],
				section2: ['Totalmente en desacuerdo'],
				section3: ['Totalmente en desacuerdo'],
				description: ['Totalmente en desacuerdo'],
				orderlist: ['4'],
				active: ['1'],
			})
		);
	}
	get getQuestionsTypeArray() {
		const array = <FormArray>this.quarterFormGroupType2.controls['questionsTypes'];

		return array;
	}

	getQuestionsTypeArrayFind(Index: string, type: number) {
		let Question = '';

		// if(Index != "0"){
		let array = this.quarterFormGroupType2.get('questionsTypes') as FormArray;
		let item = array.value[Index];

		if (item.question != '') {
			if (type == 1) {
				Question = item.section1;
			} else {
				Question = item.id;
			}
			//  }
		}
		return Question;
	}

	editQuestionsType(selector) {
		if (document.getElementById('divEditQuestionsTypeInput' + selector).style.display == 'block') {
			document.getElementById('divEditQuestionsTypeInput' + selector).style.display = 'none';
		} else {
			document.getElementById('divEditQuestionsTypeInput' + selector).style.display = 'block';
		}

		if (document.getElementById('divEditQuestionsTypeLabel' + selector).style.display == 'block') {
			document.getElementById('divEditQuestionsTypeLabel' + selector).style.display = 'none';
		} else {
			document.getElementById('divEditQuestionsTypeLabel' + selector).style.display = 'block';
		}
	}

	addQuestionsType() {
		const control = this.quarterFormGroupType2.controls['newQuestionsType'];
		if (control.status == 'VALID') {
			let array = this.quarterFormGroupType2.get('questionsTypes') as FormArray;
			array.push(
				this._formBuilder.group({
					id: ['0'],
					typeid: ['1'],
					section1: [control.value],
					section2: [control.value],
					section3: [control.value],
					description: [control.value],
					orderlist: ['1'],
					active: ['1'],
				})
			);
		}
	}

	get getQuestionsTypeSurveyArray() {
		let questionsType = this.quarterFormGroupType2.get('questionsType') as FormArray;
		return questionsType;
	}

	get getQuestionsFifth() {
		let questions = this.fifthFormGroup.get('questions') as FormArray;
		return questions;
	}

	getQuestionsFifthForm(Index: string, type = 0) {
		let Question = '';

		if (Index != '0') {
			let array = this.fifthFormGroup.get('questions') as FormArray;
			let item = array.value[Index];

			if (item.question != '') {
				if (type > 0) {
					Question = item.answer;
				} else {
					Question = item.question;
				}
			}
		}
		return Question;
	}

	getUsersGroup(id: number, event: MatCheckboxChange) {
		const isChecked = event.checked;
		const user = this.dataSourceToPaginator.data.find((item) => item.id === id);

		if (!user) {
			return;
		}

		let userInList = this.listUsersForm.find((item) => item.id === id);
		if (userInList) {
			userInList.status = isChecked ? '1' : '0';
		} else if (isChecked) {
			this.listUsersForm.push({ ...user, status: '1' });
		}
	}

	stepperPrevious(stepper: MatStepper, idStepper) {
		const control = this.firstFormGroup.value;
		switch (idStepper) {
			case 2:
				this.stepControl1 = true;
				stepper.previous();
				break;

			case 3:
				this.stepControl2 = true;
				stepper.previous();
				break;

			case 4:
				this.stepControl3 = true;
				stepper.previous();
				break;

			case 5:
				if (control.stepSecondEngagement == '1') {
					// this.stepControl4 = true;
					// stepper.previous();
				} else if (control.stepSecondEngagement == '2') {
					this.stepControl4 = true;
					stepper.previous();
				} else if (control.stepSecondEngagement == '3') {
					this.stepControl3 = true;
					stepper.previous();
				}

				break;

			case 6:
				if (control.stepSecondEngagement == '1') {
					this.stepControl4 = true;
					stepper.previous();
				} else if (control.stepSecondEngagement == '2') {
					this.stepControl5 = true;
					stepper.previous();
				} else if (control.stepSecondEngagement == '3') {
				}

				break;

			case 7:
				if (control.stepFirstType == '1') {
					if (control.stepSecondEngagement == '1') {
						this.stepControl6 = true;
						stepper.previous();
					} else if (control.stepSecondEngagement == '2') {
						this.stepControl6 = true;
						stepper.previous();
					} else if (control.stepSecondEngagement == '3') {
						this.stepControl5 = true;
						stepper.previous();
					}
				} else {
					this.stepControl3 = true;
					stepper.previous();
				}

			default:
				break;
		}
	}

	stepperNext(stepper: MatStepper, idStepper) {
		switch (idStepper) {
			case 5:
				this.stepControl5 = false;
				break;

			default:
				break;
		}
	}

	public onGroupChange(groupId: number) {
		this.subgroupsByGroup = this.subgroups.filter((item) => item.parent === groupId);
		this.getUsersByGroup(groupId);
	}

	public onSubgroupChange(subgroupId: number) {
		this.getUsersByGroup(subgroupId);
	}

	private getUsersByGroup(groupId: number) {
		this.conector
			.getUsersByGroup(groupId)
			.pipe(
				takeUntil(this.destroy$),
				map((response) => response.data)
			)
			.subscribe((users) => {
				const usersWithCheck = [...this.listUsersForm];
				const newUserWithCheck = [];
				users.forEach((user) => {
					const userIndex = usersWithCheck.findIndex((item) => item.id === user.id);
					if (userIndex > -1) {
						newUserWithCheck.push({ ...user, checked: true });
						return;
					}
					newUserWithCheck.push({ ...user, checked: false });
				});

				this.dataSourceToPaginator = new MatTableDataSource(newUserWithCheck);
				this.dataSourceToPaginator.paginator = this.paginator;
			});
	}
}
