import Swal from 'sweetalert2';
import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css']
})
export class ModuleComponent implements OnInit {

  @ViewChild('container', { read: ViewContainerRef, static: false })

  container: ViewContainerRef;

  entryMenu: any;

  constructor(private socket: SharedCommunicationService, private route: Router) { }

  ngOnInit() {
    this.socket.enableNav.next(true);
  }

  ngAfterViewInit(): void {

  }

}
