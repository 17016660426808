import { ConnectorGroupService } from './../../../../services/Admin/conector-group.service';
import { Input, OnInit, Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConnectorJobProfileService } from '../../../../services/job-profile.service';
import { GenericCatalogsService } from '../../../../services/GenericCatalogs.service';
import Swal from 'sweetalert2';
import { ConectorJsonService } from '../../../../services/conector-json.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JobprofileSurveysComponent } from '../jobprofile-surveys/jobprofile-surveys.component';
import { ConfigJobProfilesComponent } from '../config-jobprofiles/config-jobprofiles.component';
import { MatCheckboxChange } from '@angular/material';
import { SharedCommunicationService } from '../../../../services/shared-communication.service';
import { TranslateService } from '@ngx-translate/core';
import { Options } from '@angular-slider/ngx-slider';
import { TrurthifyPipe } from 'ngx-pipes';
import Groups from '../../../../types/db-groups-response';
import { from } from 'rxjs';

@Component({
	selector: 'app-create-jobprofile',
	templateUrl: './create-jobprofile.component.html',
	styleUrls: ['./create-jobprofile.component.scss'],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false },
		},
	],
})
export class CreateJobProfileComponent implements OnInit {
	@Input() item: any = { jobIdMhc: 0 };
	@Input() evaluationConfig: any;
	@Input() evaluationConfigMsg: any;
	@Input() isEditing: boolean;

	@Output() gridView: EventEmitter<any> = new EventEmitter();

	@ViewChild('stepper', null) private myStepper: MatStepper;
	@ViewChild(ConfigJobProfilesComponent, { static: false })
	configProfiles: ConfigJobProfilesComponent;

	@ViewChild(JobprofileSurveysComponent, null)
	private JobprofileSurveys: JobprofileSurveysComponent;

	enabledRiskAnalysis = false;
	enabledProfile = false;

	inteligenciaMhc = false;
	enabledProfileMhc = false;
	enabledClassicalMethod = false;

	enabledClassicalMethodAndRiskAnalysisStep = true;
	isLinear = true;

	jobSelected = { jobIdMhc: 0, id: 0 };
	jobSubmodules = [];

	IdJob = '';
	msnOperativeLevel = false;

	jobProfileForm: FormGroup;
	jobProfileList = [];

	pipelines = [];
	newForm = false;

	puestoform = '';
	put_job_surveys = false;

	job_competencies = [];
	profileCompetencies: any = [];

	selectedCompetencies: any[] = [];
	openBar: boolean = false;

	hideNavigationBar: boolean = false;
	materialCompetencies: any = [];

	minMaxRequired = true;
	predesigned: any = [];

	predesignedOn = false;
	predesignedField = null;
	stepperIndex = 0;
	predesignedOption = 0;
	predesignedAction: any = null;
	hiringFilter = [];
	trueIdentity = false;
	true_Identity_requerido = 0;
	true_Identity = false;
	ti_Requerido = false;

	sharing: boolean = false;
	groups: any;
	showAdditionalSettings: boolean = false;
	showSettingsProfile: boolean = false;
	showSettingHonesty: boolean = false;
	jobSummary: any = {};
	jobCompetenciesSummary: any = {};
	jobDimentionHonestySummary: any = {};
	jobDimentionIntegritySummary: any = {};
	jobDimentionIncidenceSummary: any = {};
	jobDimentionsRiskSummary: any = {};
	isEditingValue: boolean;
	isMetodAnalisisRiesgo: boolean;
	isMetodClasico: boolean;
	groupsCategoryCompany: any = [];
	subgroupsOfSelectedGroup: any = [];
	isSelectedGroup: false;

	constructor(
		private conector: ConnectorJobProfileService,
		private form: FormBuilder,
		private generic: GenericCatalogsService,
		public translate: TranslateService,
		private route: Router,
		private groupService: ConnectorGroupService
	) {}

	ngOnInit(): void {
		this.IdJob = this.item.id + ''; // RiskAnalysis

		if (this.item.id > 0) {
			this.isMetodAnalisisRiesgo =
				this.item.analisisRiesgo == null || this.item.analisisRiesgo == false ? false : true;
			this.isMetodClasico = this.item.analisisRiesgo == null || this.item.analisisRiesgo == false ? true : false;
		} else {
			this.isMetodAnalisisRiesgo = false;
			this.isMetodClasico = false;
		}

		this.isEditingValue = this.isEditing;

		this.configJobSurveys(this.item); // evaluation

		if (this.item.groupId) {
			this.getSubgroupsOfSelectedGroup(this.item.groupId);
		}

		this.jobProfileForm = this.form.group({
			id: [this.item.id],
			puesto: [this.item.puesto, Validators.required],
			level: [this.item.level, Validators.required],
			descripcion: [this.item.descripcion],
			groupId: [this.item.groupId],
			subgroupId: [this.item.subgroupId],
			analisisriesgo: [this.item.analisisRiesgo],
			token: [this.item.token],
			hiringId: [this.item.hiringId],
			stage: [this.item.stage],
			true_Identity: [this.item.true_Identity == null || this.item.true_Identity == false ? false : true],
			ti_Requerido: [this.item.tI_Requerido],
			valueTIRequired: [this.item.tI_Requerido],
		});

		if (this.item.true_Identity == null) {
			this.trueIdentity = false;
		} else {
			this.trueIdentity = this.item.true_Identity;
		}

		this.jobProfileForm.get('valueTIRequired').setValue(this.item.tI_Requerido ? 1 : 2);

		if (this.item.tI_Requerido == true) {
			this.true_Identity_requerido = 1;
		} else {
			this.true_Identity_requerido = 2;
		}

		this.puestoform = this.jobProfileForm.get('puesto').value;

		this.newForm = this.item.id == null;

		this.generic.ct_NivelPuestos().subscribe((response: any) => {
			this.jobProfileList = response.data;
		});

		// test predeterminados
		this.conector.predetermnated_get().subscribe((response: any) => {
			if (response.successfully == false) {
				this.predesigned = response.data;
			}
		});

		this.conector.trueIdentityOptions().subscribe((response: any) => {
			this.groups = response.data;
		});

		this.groupService.getAllGroups().subscribe((groups) => (this.groupsCategoryCompany = groups));
	}

	// ------------------------------------ event back step
	stepBack() {
		this.myStepper.previous();
	}

	getSubgroupsOfSelectedGroup(seletedGroupId: number) {
		this.groupService
			.getAllSubGroupsByGroupId(seletedGroupId)
			.subscribe((subgroups) => (this.subgroupsOfSelectedGroup = subgroups));
	}

	submit(formdata, stepper: MatStepper) {
		this.sharing = true;

		let typeMetodo = this.enabledRiskAnalysis;

		formdata.analisisriesgo = typeMetodo;

		formdata.ti_Requerido = formdata.valueTIRequired == 1 ? true : false;

		if (this.item.id) {
			if (formdata.id == 0 || formdata.id == null) {
				formdata.id = this.item.id;
			}

			if (formdata.groupId != null || formdata.subgroupId != null) {
				formdata.groupId = formdata.subgroupId ? formdata.subgroupId : formdata.groupId;
			}

			this.conector.jobprofile_put(formdata).subscribe(async (d: any) => {
				const { ok, msg, data } = d;

				if (ok == true) {
					const stage: number = +formdata.stage;

					this.myStepper.next();
					this.sharing = false;
				} else {
					await Swal.fire('Error', msg, 'error');
				}
			});
		} else {
			formdata.id = 0;
			if (formdata.groupId != null || formdata.subgroupId != null) {
				formdata.groupId = formdata.subgroupId ? formdata.subgroupId : formdata.groupId;
			}

			this.conector.jobprofile_post(formdata).subscribe(async (d: any) => {
				const { ok, msg, data } = d;

				if (ok == false) {
					await Swal.fire('Error', d.msg, 'error');

					this.sharing = false;
				} else {
					this.IdJob = data.id + ''; // RiskAnalysis

					this.item.id = data.id;

					this.configJobSurveys(this.item); // evaluation

					this.myStepper.next();

					this.sharing = false;
				}
			});
		}
	}

	// -------------------------------------- evaluaciones

	async configJobSurveys(row) {
		console.info('configJobSurveys');

		this.jobSelected = row;

		this.conector
			.get_job_surveys({
				modulo: localStorage.getItem('module'),
				puesto: row.id,
			})
			.subscribe((e: any) => {
				this.jobSubmodules = e.data;
				this.jobSubmodules.forEach((x) => {
					if (this.evaluationConfig == 1 && this.evaluationConfigMsg == 1) {
						if (x.idSubmodulo == 5 || x.idSubmodulo == 2) {
							x.selected == false;
							x.active = false;
						}
					}
				});

				this.enabledViewstep(e.data);
			});
	}

	JobprofileSurveysSaveAction() {
		this.JobprofileSurveys.saveAction();
	}

	// Classical Method

	classicalMethodView() {
		this.enabledClassicalMethod = true;
		this.enabledRiskAnalysis = false;
	}

	enableNextClassicalMethodAndRiskAnalysis(formData: any) {
		this.enabledClassicalMethodAndRiskAnalysisStep = false;
		this.hideNavigationBar = false;
		this.getJobSummary();
		this.myStepper.next();
		if (this.enabledClassicalMethod) {
			this.getDimentionsClassicSummary(formData);
		} else if (this.enabledRiskAnalysis) {
			this.getDimentionsRiskSummary(formData);
		}
	}

	riskAnalysisView() {
		this.enabledRiskAnalysis = true;
		this.enabledClassicalMethod = false;
		this.hideNavigationBar = false;
	}

	checkEval() {
		this.enabledRiskAnalysis = false;
		this.enabledClassicalMethod = false;
		this.isMetodClasico = false;
		this.isMetodAnalisisRiesgo = false;
	}

	/* 
    #myhumancloud enviar si se selecciona una o más de las sig. evaluaciones
    3.- perfiles
    4.- habilidades cognitivas
    10.- dominancia cerebral
  */

	async updateEvaluations(formData: any) {
		const slash = this.jobSubmodules.filter((x) => x.selected == true).map((x) => x.idSubmodulo);

		// Valida que para honestidad seleccionen metodo
		if (slash.includes(1)) {
			if (this.enabledClassicalMethod == false && this.enabledRiskAnalysis == false) {
				return Swal.fire(
					'Advertencia',
					'Debe seleccionar al menos un método para Honestidad Selección',
					'warning'
				);
			}
		}

		// Valida que seleccionen perfiles & dominancia
		if (!slash.includes(3) && slash.includes(10)) {
			return Swal.fire(
				'Advertencia',
				'Para seleccionar Dominancia cerebral, necesario seleccionar Perfiles.',
				'warning'
			);
		}

		// Valida que seleccionen perfiles & dominancia
		if (!slash.includes(1) && slash.includes(13)) {
			return Swal.fire(
				'Advertencia',
				'Para seleccionar el Reporte de Inteligencia Artificial, es necesario seleccionar Honestidad.',
				'warning'
			);
		}

		//this.submit(formData, null);

		//-----------------------------------------------
		if (formData.id == 0) {
			formData.id = this.item.id;
		}

		var typeMetodo = this.enabledRiskAnalysis;

		formData.analisisriesgo = typeMetodo;

		formData.ti_Requerido = formData.valueTIRequired == 1 ? true : false;
		this.conector.jobprofile_put(formData).subscribe(async (d: any) => {
			const { ok, msg, data } = d;
			if (ok != true) {
				await Swal.fire('Error', msg, 'error');
			}
		});
		//-----------------------------------------------

		// reset selected
		this.selectedCompetencies = [];

		const e = this.jobSubmodules;

		e.forEach(async (x) => {
			x.puesto = this.jobSelected.id;

			// if ((x.idSubmodulo == 3 && x.selected) || (x.idSubmodulo == 4 && x.selected) ) {
			if (x.idSubmodulo == 3 && x.selected) {
				this.enabledProfileMhc = true;
			}
			if (x.idSubmodulo == 3 && !x.selected) {
				this.enabledProfileMhc = false;
			}

			if (x.idSubmodulo == 4 && x.selected) {
				this.inteligenciaMhc = true;
			}
			if (x.idSubmodulo == 4 && !x.selected) {
				this.inteligenciaMhc = false;
			}
		});

		this.hideNavigationBar = true;

		//Insertar siempre back amitai
		await this.conector.put_job_surveys(e).toPromise();

		if (this.enabledProfileMhc === true && slash.includes(3)) {
			this.conector.get_competencies(this.item.jobIdMhc).subscribe((e: any) => {
				if (e) {
					e.competencies.forEach((x) => {
						x.selected = x.assigned == true ? true : false;
					});

					this.profileCompetencies = e;

					this.selectedCompetencies = e.competencies.filter((x) => x.assigned == true);

					this.configProfiles.updateSources(this.profileCompetencies.competencies);
				}
			});
		}
		this.getJobSummary();
		this.myStepper.next();
	}

	// --------------------------------------  Selección / Análisis de Riesgo

	JobProfileAnalisisRiesgo(row) {
		this.route.navigateByUrl('/manager/config-profile-integrity-risk/' + row.id);
	}

	quitarAcentos(texto) {
		texto = texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		return texto.trim().toLowerCase();
	}

	transform(arg: any) {
		const result = [];
		const value = this.item.hiring;
		for (const post of value) {
			var puestoSA = this.quitarAcentos(post.puesto);
			if (puestoSA.indexOf(arg) > -1) {
				result.push(post);
			}
		}

		return result;
	}

	enabledViewstep(row) {
		row.forEach((x) => {
			switch (x.idSubmodulo) {
				case 1:
					if (x.selected == false) {
						this.enabledClassicalMethod = false;
						this.enabledRiskAnalysis = false;
						//this.enabledRiskAnalysis = true
					}
					break;
				case 3:
					if (x.selected != false) {
						this.enabledProfileMhc = true;
					} else {
						this.enabledProfileMhc = false;
					}
					break;
				default:
					break;
			}
		});
	}

	// ------------------------------------- Close Grig profile

	async closeGrid(id) {
		if (id == 1) {
			var idpuestoEnviar = this.IdJob;
			var listSubmodules = this.jobSubmodules;

			this.conector.put_job_terceros(idpuestoEnviar, listSubmodules).subscribe(async (d: any) => {
				const { ok, msg, data } = d;

				if (ok == false) {
					//await Swal.fire('Error', d.msg, 'error');

					Swal.fire({
						title: d.msg,
						icon: 'error',
						showCancelButton: false,
						confirmButtonText: ' Aceptar ',
					}).then(() => {
						this.gridView.emit();
					});
				} else {
					Swal.fire({
						title: 'Puesto Guardado.',
						icon: 'success',
						showCancelButton: false,
						confirmButtonText: ' Aceptar ',
					}).then(() => {
						this.gridView.emit();
					});
				}
			});
		} else {
			this.gridView.emit();
		}
	}

	// ------------------------------------ msnOperative
	msnOperative(event) {
		if (event === 1) {
			this.msnOperativeLevel = true;
		} else {
			this.msnOperativeLevel = false;
		}
	}

	competencies(event) {
		this.selectedCompetencies = event;
	}

	async sendCompetencies() {
		this.getJobSummary();
		let mapped = [];

		this.selectedCompetencies.forEach((x) => {
			mapped.push({ Id: x.id, magnitude: x.magnitude, visible: x.visible });
		});

		const reductor = mapped.filter((x) => x.magnitude <= 50);

		const limite = parseInt((this.selectedCompetencies.length / 2).toFixed(0));

		if (reductor.length > limite) {
			return Swal.fire(
				'Advertencia',
				'Solo pueden ser menores al 50%, (' + limite + ') de las competencias.',
				'warning'
			);
		}

		//===== Ajuste para cambio en MHC se mueve esta parte desde UpdateEvaluations ==================
		const e = this.jobSubmodules;
		// Insertar human cloud si cumple condiciones
		if ((this.enabledProfileMhc === true || this.inteligenciaMhc === true) && this.item.jobIdMhc == undefined) {
			console.info('CREATE HUMAN PROFILE');

			this.item.jobIdMhc = await this.conector
				.create_profileMHC(this.jobSelected.id, {
					modules: e,
					name: this.jobProfileForm.controls['puesto'].value,
					competencies: mapped,
				})
				.toPromise();
			this.myStepper.next();
		} else if (
			(this.enabledProfileMhc === true || this.inteligenciaMhc === true) &&
			this.item.jobIdMhc != undefined
		) {
			console.info('UPDATE HUMAN PROFILE');
			this.item.jobIdMhc = await this.conector
				.update_profileMHC(this.jobSelected.id, {
					modules: e,
					name: this.jobProfileForm.controls['puesto'].value,
					competencies: mapped,
				})
				.toPromise();
			this.myStepper.next();
		}

		//==================================================================================================

		// const model = {
		//   jobId: this.item.jobIdMhc,
		//   competencies: mapped
		// };

		// await this.conector.put_job_competencies(model).toPromise();
	}

	filterOptions() {
		this.configProfiles.filterOptions();
	}

	validateProStep() {
		this.minMaxRequired = this.selectedCompetencies.length == 0 ? true : false;

		this.myStepper.next();
	}

	createPredesigned() {
		console.log(1478, this.predesignedField);

		const mm = this.predesigned.findIndex((p) => p.id == this.predesignedField);

		const pd = this.predesigned[mm];

		this.jobProfileForm.controls.puesto.setValue(pd.name);

		this.myStepper.next();
	}

	getPdCompetences() {
		this.conector.get_predesigned_competencies(this.predesignedOption).subscribe((e: any) => {
			e.data.competencies.forEach((z: any) => {
				const idx = this.profileCompetencies.competencies.findIndex((x: any) => x.id === z.id);

				if (idx != -1) {
					const model = this.profileCompetencies.competencies[idx];

					model.selected = true;
				}
			});

			// Oculta campo de seleccion
			this.predesignedAction = false;

			// Oculta boton de continuar
			this.configProfiles.configurePredesign = false;

			// libera boton de check
			this.minMaxRequired = false;

			// updatea seleccion
			this.configProfiles.filterOptions();
		});
	}

	setPsOption(e) {
		console.log(1515, e);
		this.predesignedOption = e;
	}

	enableTrueIdentity($event: MatCheckboxChange) {
		this.trueIdentity = $event.checked;
		this.true_Identity = this.trueIdentity;
	}

	opcionalRequerido(action: any) {
		this.true_Identity_requerido = action.id;
		this.jobProfileForm.get('valueTIRequired').setValue(action.value);
	}

	opcionalRequeridoId(id) {
		this.true_Identity_requerido = id;
	}

	getJobSummary() {
		const jobName = this.jobProfileForm.get('puesto').value;
		const jobDescription = this.jobProfileForm.get('descripcion').value;
		const level = this.jobProfileForm.get('level').value;
		const jobLevel = level === 1 ? 'Operativo' : level === 2 ? 'Supervisorio' : level === 3 ? 'Gerencial' : '';
		const jobTrueIdentity = this.jobProfileForm.get('true_Identity').value ? 'Activo' : 'No activo';
		const jobEvaluations = this.jobSubmodules.filter((item) => item.selected === true);

		this.jobSummary = {
			jobName,
			jobDescription,
			jobLevel,
			jobTrueIdentity,
			jobEvaluations,
		};

		const profileEvaluation = jobEvaluations.some((evaluation) => evaluation.idSubmodulo === 3);

		if (profileEvaluation) {
			this.jobCompetenciesSummary = this.groupCompetenciesByCategory(this.selectedCompetencies);
		}
		const containHonesty = jobEvaluations.some((item) => item.idSubmodulo === 1);
		const containProfile = jobEvaluations.some((item) => item.idSubmodulo === 3);

		this.showAdditionalSettings = containHonesty || containProfile;
		this.showSettingHonesty = containHonesty;
		this.showSettingsProfile = containProfile;
	}
	groupCompetenciesByCategory(competencies: any[]): any[] {
		const groupedCompetencies: any[] = [];
		competencies.forEach((competency) => {
			const existingCategory = groupedCompetencies.find((item) => item.category === competency.category);

			if (existingCategory) {
				existingCategory.competencies.push(competency);
			} else {
				groupedCompetencies.push({
					category: competency.category,
					competencies: [competency],
				});
			}
		});

		return groupedCompetencies;
	}
	getDimentionsClassicSummary(formData: any) {
		this.jobDimentionHonestySummary = formData.dimentions.filter((item) => item.dimensionH === 1);

		this.jobDimentionIntegritySummary = formData.dimentions
			.filter((item) => item.dimensionH === 2)
			.map((item) => {
				const updatedItem = { ...item };
				updatedItem.options = {
					...updatedItem.options,
					disabled: true,
				};
				return updatedItem;
			});

		this.jobDimentionIncidenceSummary = formData.dimentions.filter((item) => item.dimensionH === 0);
	}
	getDimentionsRiskSummary(formData: any) {
		const result = formData.map((item) => ({
			dimension: item.dimension,
			probabilidad: this.getTextByProbabilityImpact(item.probabilidad, item.impacto).probability,
			impacto: this.getTextByProbabilityImpact(item.probabilidad, item.impacto).impact,
			peso: item.peso,
			linferior: item.linferior,
			dimensionH: item.dimensionH,
			lowRange: item.lowRange,
			midRangeMin: item.midRangeMin,
			midRangeMed: item.midRangeMed,
			midRangeMax: item.midRangeMax,
			highRange: item.highRange,
			lsuperior: item.lsuperior,
			options: {
				floor: 0,
				ceil: 100,
				disabled: true,
				showTicks: true,
				showSelectionBar: false,
			},
		}));
		this.jobDimentionsRiskSummary = result;
	}
	getTextByProbabilityImpact(probability, impact) {
		const probabilityText = {
			1: 'Muy bajo',
			2: 'Bajo',
			3: 'Medio',
			4: 'Alto',
			5: 'Muy alto',
		};

		const impactText = {
			1: 'Muy bajo',
			2: 'Bajo',
			3: 'Medio',
			4: 'Alto',
			5: 'Muy alto',
		};

		return {
			probability: probabilityText[probability],
			impact: impactText[impact],
		};
	}
}
