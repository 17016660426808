import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConnectorEthicsSurveyService } from '../../../../services/ethics-survey/conector-ethics-survey.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';
import { answerQuestionsSurveyConfig, answerUsers } from '../../ViewModel/config-model/config-model.component';
import { EvaluatedAnswerComponent } from '../evaluated-answer.component';
import {NavigationStart, Router, ActivatedRoute, Params} from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatPaginator, MatTableDataSource } from '@angular/material';


@Component({
  selector: 'app-ethics-survey-type-one',
  templateUrl: './ethics-survey-type-one.component.html',
  styleUrls: ['./ethics-survey-type-one.component.css']
})
export class EthicsSurveyTypeOneComponent implements OnInit {
  fifthFormGroup : FormGroup;
  isShownEngagement: boolean = true ; // var control view div hiden
  isShownidquestion: boolean = false ; // var control view div hiden
  tableShow: boolean = false ; // var control view div hiden
  indexQuestion: number =1;
  pageOfItems: Array<any>;
  items_ = [];
  items2 = [];
  numberQuestions: number = 0 ;


  // ================================

  columnas: string[] = ['id', 'idquestion', 'idFactors', 'name', 'answers'];
  datos: QuestionsArray[] = [];
  columnsToDisplay: string[] = this.columnas.slice();

  dataSource = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // ================================
  
  constructor( private rutaActiva: ActivatedRoute, private conector: ConnectorEthicsSurveyService, private _formBuilder: FormBuilder,private router:Router) {

    
    this.fifthFormGroup = this._formBuilder.group({
      questions: this._formBuilder.array(
        [this._formBuilder.group({id : [''], question : [''],type : [''], answer : ['',Validators.required]})]),
    });
  }

  ValidarPaso() {

    const Q0 = document.getElementById("questions0");
      Q0.style.display = "block";
    for (var i = 0; i < 141; i++) {
      if(i != 0){
        var x = document.getElementById("questions"+i);
        
        x.style.display = "none";
      }
      const Q141 = document.getElementById("questions141");
      Q141.style.display = "none";
    }
      // if(index == 1){
      // }else{
      //   return false;
      // }  
    
  }

  ngOnInit() {
    this. get_ethics_suvey();

    
  }

  
  get_ethics_suvey() {
    var idSurvey = this.rutaActiva.snapshot.params.idSurvey  ;
    var idUser = this.rutaActiva.snapshot.params.idUser  ;

    
    this.conector.evaluatedAnswerSurveyTypeOneGet(idSurvey,idUser).subscribe( (response: any) => {
      const {ok, data, msg} = response;

      if (ok === false) {
        return Swal.fire('Error', msg, 'error')

      } else {
        const control = <FormArray>this.fifthFormGroup.controls['questions'];
        let i = 0;

        for (var key in response.data) {
          i++;
          var QuestionKey = response.data[key];
          this.items2.push(
            { id : i, 
              idquestion : QuestionKey.idquestion, 
              idFactors  : QuestionKey.idFactors , 
              name : QuestionKey.name, 
              answers : '.' 
            });

            this.datos.push(new QuestionsArray(i, QuestionKey.idquestion, QuestionKey.idFactors,QuestionKey.name, '.' ));
            // this.dataSource = new MatTableDataSource<QuestionsArray>(this.datos);
            // this.dataSource.paginator = this.paginator;

          //control.push(this._formBuilder.group({id : [ QuestionKey.idquestion ], type : [ QuestionKey.idFactors ], question : [ QuestionKey.name ], answer : ['',Validators.required]}));
        }
        console.log(this.datos);
        this.dataSource = new MatTableDataSource<QuestionsArray>(this.datos);
        this.dataSource.paginator = this.paginator;


        // ============= hide columns
       
       
        
        this.columnsToDisplay.length = 2 ;
        this.columnsToDisplay.pop();

        // ==============================
        this.items_ = this.items2;
        console.log(this.items_);
        this.numberQuestions = this.items_.length;

      }
      this.isShownEngagement = false;
      this.tableShow = true;

    });

  }

  valida2 (){
    this.tableShow = true;
  }

  get getQuestionsFifth(){
    let questions   =  this.fifthFormGroup.get('questions') as FormArray;
    return questions;
  }

  getQuestionsFifthForm(Index : string){
    let Question = "";

    if(Index != "0"){
      let array   =  this.fifthFormGroup.get('questions') as FormArray;
      let item = array.value[Index];

      if(item.question != ""){
        Question = item.question;
      }
  }
    return Question;
  }

  submit(){

    const ValidAnwers = this.datos.find( item => item.answers === '.' );
    if(ValidAnwers != undefined ){
      
      Swal.fire('Error', 'Tiene Preguntas sin respuestas ', 'error')

    }else{
      
      const answersUsers = new answerUsers();

      let answerQuestionsModel   =  this.fifthFormGroup.get('questions') as FormArray;
      let answerQuestionsArrayModel =  [new answerQuestionsSurveyConfig()];

      for (var i = 0; i < this.datos.length; i++) {
        let item = this.datos[i];
        if(item.id != 0){
          const  answerQuestionsSurvey = new answerQuestionsSurveyConfig();
          answerQuestionsSurvey.id =  parseInt(item.idquestion) ;
          answerQuestionsSurvey.answer =  item.answers;
          answerQuestionsSurvey.type =  item.idFactors;
          answerQuestionsArrayModel.push(answerQuestionsSurvey);
        }
      }
      answerQuestionsArrayModel.splice(0,1);
      answersUsers.answer = answerQuestionsArrayModel;
      var f = new Date();
      answersUsers.dateAnswers =  new Date(f.getTime() - (f.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
      answersUsers.host = this.rutaActiva.snapshot.params.idSurvey+'-'+  this.rutaActiva.snapshot.params.idUser  ;
      answersUsers.typeSurvey = "1";
      debugger;
      this.conector.postAnswerUser(answersUsers).subscribe( 
        (response: any) => {
          const {ok, data, msg} = response;
          if (ok === false) {
            var MsgError  = "";
            for(var k in data) {
              MsgError = MsgError +  "Error en " +  data[k].cam + " ( " + data[k].msg + " ) \n";
            }
            return Swal.fire('Error', MsgError, 'error')
          } else {
            return Swal.fire('Buen trabajo!', 'Respuestas Guardadas', 'success')
          }
        },
        (err) => {
          return Swal.fire('Error', err.status + ' ' + err.statusText, 'error')
        }
      );
    
    }


  
  }
  
  getValueAnswers(id:number , answer:string){
    debugger;
    id = id -1 ;
    this.datos[id].answers = answer;
    this.paginator.nextPage();
  }

  
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems; 
  }

  selectedCheck(answers:string, value :string){
    if (answers == value) {
      return true;
    } else {
      return false;
    }
  }

  // isDisabled(){
  //   const resultado = this.datos.find( item => item.answers === '.' );
  //   if(resultado.answers == '.' || resultado == undefined ){
  //     return false;
  //   }else{
  //     return true;
  //   }
  // }


  
  

}

export class QuestionsArray {
  constructor(public id: number, public idquestion: string, public idFactors: string, public name: string, public answers: string) {
  }
}

