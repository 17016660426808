import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { AuthenticationService } from './../../../../services/authentication.service';
import { ConectorJsonService } from './../../../../services/conector-json.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import User from '../../../../types/db-user-profile-response';
import { PermissionComponent } from '../../permission/permission.component';
import { permissionForAccountComponent } from '../../permissionForAccount/permissionForAccount.component';

@Component({
	selector: 'app-create-user',
	templateUrl: './create-user.component.html',
	styleUrls: ['./create-user.component.css'],
})
export class CreateUserComponent implements OnInit, OnDestroy {
	@ViewChild(PermissionComponent, null)
	private permissionComponent: PermissionComponent;

	@ViewChild(permissionForAccountComponent, null)
	private PermissionForAccountComponent: permissionForAccountComponent;
	public destroy$: Subject<boolean> = new Subject<boolean>();
	kindOF = 'password';

	userForm: FormGroup;

	enableUserNew: boolean = true;

	secondFormGroup = this.fb.group({
		secondCtrl: ['', Validators.required],
	});
	isEditable = false;
	currentModule = null;
	public userIdToEdit: number = 0;
	translations: { [key: string]: string } = {
		"action-text-register-added": "",
		"button-text-accept": "",
		"action-text-date-expiration": "",
		"action-text-error-already-exists": "",
		"action-text-register-modified": "",
		"action-text-error-modifying": "",
		"action-texxt-email-already-exists": ""
	};
	constructor(
		private conector: ConectorJsonService,
		private fb: FormBuilder,
		private router: Router,
		public translate: TranslateService,
		private auth: AuthenticationService,
		private route: ActivatedRoute
	) {
		this.userForm = this.fb.group(
			{
				name: [null, Validators.required],
				firstname: [null, Validators.required],
				lastname: [null],
				phone: [null],
				require2AF: [false],
				email: [null, [Validators.required, Validators.email]],
				password: [null, Validators.required],
				repeat: [null, Validators.required],
				expiration: [null],
				reportEnd: [null],
			},
			{
				validators: this.passwordConfirming,
			}
		);

		this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
		this.setLanguageAndGetTranslation();
	}
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	ngOnInit() {
		this.currentModule = localStorage.getItem('module');

		const userCurrent = JSON.parse(localStorage.getItem('userPermission'));

		if (userCurrent.expiration) {
			userCurrent.expiration = userCurrent.expiration.replace('T00:00:00', '');
		}

		if (userCurrent.id != 0 && userCurrent.id != undefined) {
			this.userForm = this.fb.group(
				{
					id: userCurrent.id,
					name: userCurrent.name,
					firstname: userCurrent.firstname,
					lastname: userCurrent.lastname,
					phone: userCurrent.phone,
					require2AF: userCurrent.require2AF,
					email: userCurrent.email,
					password: userCurrent.password,
					repeat: userCurrent.repeat,
					expiration: userCurrent.expiration,
					reportEnd: userCurrent.report_end,
				},
				{
					validators: this.passwordConfirming,
				}
			);
			this.enableUserNew = false;
		} else {
			localStorage.setItem('userPermission', JSON.stringify({}));
		}
	}
	async setLanguageAndGetTranslation(): Promise<void> {
		const language = await this.auth.setLanguage();
		this.translate.setDefaultLang(language);
		const translationPromises = Object.keys(this.translations).map(key => {
			return new Promise<void>((resolve, reject) => {
				this.translate.get(key).subscribe((res: string) => {
					this.translations[key] = res;
					resolve();
				});
			});
		});

		await Promise.all(translationPromises);
	}
	toogleKind() {
		this.kindOF = this.kindOF == 'text' ? 'password' : 'text';
	}

	passwordConfirming(c: AbstractControl): { invalid: boolean } {
		if (c.get('password').value !== c.get('repeat').value) {
			return { invalid: true };
		}
	}

	public submit(userForm: User, stepper: MatStepper) {
		this.userIdToEdit = parseInt(atob(decodeURIComponent(this.route.snapshot.paramMap.get('id')))) || 0;

		const names = userForm.firstname.split(' ') || null;

		//userForm.name = names[0] || null;
		//userForm.firstname = names[1] || null;

		if (this.userIdToEdit === 0) {
			this.createUserProfile(userForm, stepper);
		}
		this.updateUserProfile(userForm, stepper);
	}
	private updateUserProfile(userForm: User, stepper: MatStepper) {
		if (this.userIdToEdit === 0) return;

		userForm.role = 3;
		userForm.require2AF = Boolean(userForm.require2AF);
		userForm.reportEnd = Boolean(userForm.reportEnd);

		userForm.expiration = userForm.expiration || '';
		if (userForm.expiration !== '' && !this.verifyExpirationDate(userForm.expiration)) {
			return Swal.fire({
				title: this.translations["action-text-date-expiration"],
				icon: 'error',
				confirmButtonText: this.translations["button-text-accept"],
				customClass: {
					actions: 'my-actions',
					confirmButton: 'order-2 general-styles-buttons my-confirm-button',
				},
			});
		}

		this.conector
			.userUpdate(userForm)
			.pipe(takeUntil(this.destroy$))
			.subscribe((response) => {
				const { ok, msg, data } = response;
				if (!ok) {
					Swal.fire({
						title: this.translations["action-text-error-modifying"],
						icon: 'error',
						confirmButtonText: this.translations["button-text-accept"],
						customClass: {
							actions: 'my-actions',
							confirmButton: 'order-2 general-styles-buttons my-confirm-button',
						},
					});
					return;
				}
				stepper.next();
			});
	}

	private createUserProfile(userForm: User, stepper: MatStepper) {
		userForm.role = 3;
		userForm.require2AF = Boolean(userForm.require2AF);
		userForm.reportEnd = Boolean(userForm.reportEnd);
		userForm.phone = userForm.phone;
		userForm.expiration = userForm.expiration || '';

		if (userForm.expiration !== '' && !this.verifyExpirationDate(userForm.expiration)) {
			return Swal.fire({
				title: this.translations["action-text-date-expiration"],
				icon: 'error',
				confirmButtonText: this.translations["button-text-accept"],
				customClass: {
					actions: 'my-actions',
					confirmButton: 'order-2 general-styles-buttons my-confirm-button',
				},
			});
		}

		this.conector
			.userPost(userForm)
			.pipe(takeUntil(this.destroy$))
			.subscribe(async (response) => {
				const { ok, msg, data } = response;

				if (!ok) {
					await Swal.fire({
						title: this.translations["action-text-error-already-exists"],
						text: this.translations["action-texxt-email-already-exists"],
						icon: 'error',
						confirmButtonText: this.translations["button-text-accept"],
						customClass: {
							actions: 'my-actions',
							confirmButton: 'order-2 general-styles-buttons my-confirm-button',
						},
					});
					return;
				}

				localStorage.setItem('userPermission', JSON.stringify(data));
				const typePermission = data.action === 'edit' ? 1 : 2;
				await this.permissionComponent.implementLoadPermissions(typePermission, data);

				if (this.currentModule != 'Integriline') {
					await this.PermissionForAccountComponent.implementLoadPermissionsForAccount();
				}

				stepper.next();
			});
	}
	async endFlow() {
		const title = this.userIdToEdit === 0
			? this.translations["action-text-register-added"]
			: this.translations["action-text-register-modified"];
		Swal.fire({
			title: title,
			icon: 'success',
			showCancelButton: false,
			confirmButtonText: this.translations["button-text-accept"],
			customClass: {
				actions: 'my-actions',
				confirmButton: 'order-2 general-styles-buttons my-confirm-button',
			},
		}).then((result) => {
			this.router.navigateByUrl('/manager/users');
		});
	}

	submitPermission() {
		this.permissionComponent.setPermision();
	}

	private verifyExpirationDate(expiration: string): boolean {
		const startExpiration = this.resetTime(new Date(expiration));
		startExpiration.setDate(startExpiration.getDate() + 1);
		const currentDayParse = this.resetTime(new Date());
		return startExpiration >= currentDayParse;
	}

	private resetTime(date: Date): Date {
		date.setHours(0, 0, 0, 0);
		return date;
	}
}
