import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RankingService } from "../../../../services/ranking.service";

@Component({
  selector: "app-ranking-content",
  templateUrl: "./ranking-content.component.html",
  styleUrls: ["./ranking-content.component.css"],
})
export class RankingContentComponent implements OnInit {
  evaluatedsId: number[];
  evaluateds: any;
  showSliders = false;
  slidersEnabled = [false, false, false];
  slidersValue = [50, 50, 50];
  allSelected = false;
  disabledComparison: boolean = true;
  evaluations: number[];

  constructor(private rankingService: RankingService, private router: Router) {
    if (!!!this.router.getCurrentNavigation().extras.state) {
      this.router.navigateByUrl("/manager/Ranking");
    }
    this.evaluatedsId =
      this.router.getCurrentNavigation().extras.state.evaluateds;
  }

  ngOnInit() {
    this.rankingService
      .getEvaluatedsRankingInformation(this.evaluatedsId)
      .subscribe((response) => {
        this.evaluateds = response["evaluateds"];
        this.evaluations = response["evaluations"];
        this.enableSliders();
        this.orderRanking();
      });
  }
  orderRanking() {
    for (const evaluated of this.evaluateds) {
      evaluated.grade = this.getEvaluationsGrade(
        evaluated.profiles,
        evaluated.cognitiveSkills,
        evaluated.integrity
      );
    }
    this.evaluateds.sort((e1, e2) => {
      return e2.grade - e1.grade;
    });
  }

  getEvaluationsGrade(profiles, cognitiveSkills, integrity) {
    if (profiles == "NA") {
      profiles = 0;
    }
    if (cognitiveSkills == "NA") {
      cognitiveSkills = 0;
    }
    if (integrity == "NA") {
      integrity = 0;
    }

    const dividend =
      (profiles * this.slidersValue[0]) / 100 +
      (cognitiveSkills * this.slidersValue[1]) / 100 +
      (integrity * this.slidersValue[2]) / 100;

    const divisor =
      (this.slidersValue[0] * Number(this.slidersEnabled[0])) / 100 +
      (this.slidersValue[1] * Number(this.slidersEnabled[1])) / 100 +
      (this.slidersValue[2] * Number(this.slidersEnabled[2])) / 100;

    return dividend / divisor;
  }

  enableSliders() {
    this.slidersEnabled[0] = this.evaluations.includes(3);
    this.slidersEnabled[1] = this.evaluations.includes(4);
    this.slidersEnabled[2] = this.evaluations.includes(1);
    this.showSliders = this.slidersEnabled.filter((s) => s).length >= 2;
  }

  onSliderChange() {
    this.orderRanking();
  }

  someSelected(): boolean {
    if (!!!this.evaluateds) {
      return false;
    }
    return (
      this.evaluateds.filter((t) => t.selected).length > 0 && !this.allSelected
    );
  }

  selectAll(universalCheckState: boolean) {
    this.allSelected = true;
    this.evaluateds.forEach((e) => (e.selected = universalCheckState));
    this.disabledComparison =
      this.evaluateds.filter((item) => item.selected).length >= 2
        ? false
        : true;
  }

  updateUniversalCheckbox() {
    this.allSelected =
      this.evaluateds != null && this.evaluateds.every((t) => t.selected);
    this.disabledComparison =
      this.evaluateds.filter((item) => item.selected).length >= 2
        ? false
        : true;
  }

  openComparative() {
    if (this.evaluateds.filter((t) => t.selected).length < 2) {
      return;
    }

    const evaluatedsId = this.evaluateds
      .filter((t) => t.selected)
      .map((e) => e.userId);

    this.router.navigateByUrl(`/manager/Ranking/Comparative`, {
      state: { evaluateds: evaluatedsId },
    });
  }
}
