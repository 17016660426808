// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   base: 'https://localhost:5001',
//   server: 'https://localhost:5001/api/v1',
//   public: 'https://localhost:5001/public',
//   blobCompany:'https://amitaicompanypru.blob.core.windows.net',
//   // server: 'http://52.161.23.142:5000/api/v1',
//   // public: 'http://52.161.23.142:5000/public',
//   tiny: '5iboyhuj1h86j1nz1avwhfiknu3qs31rwditmn4x2bnd4nb8',

//   know_server: 'http://apicmsknowapp.cinnco.co/api',
//   know_email: 'rduran@amitai.com',
//   know_password: 'Amitai2020',

//   hiringroom_server: 'http://api.hiringroom.com/v0',
//   hiringroom_name: 'amitaidev',
//   hiringroom_redirect_uri: 'http://localhost:4200/oauth/hiringroom',
//   hiringroom_client_id: 'amitai_dev',
//   hiringroom_client_secret: 'eUlMzwQ80DeTYrp5G48aA7Gwbr8n7HXPn4jjp3q4yPw'
// };

// export const environment = {
//   production: true,
//   base: 'https://test-api.amitaisystem.com',
//   server: 'https://test-api.amitaisystem.com/api/v1',

//   public: 'https://test-api.amitaisystem.com/public',
//   blobCompany:'https://amitaicompanypru.blob.core.windows.net',
//   private: 'https://test-api.amitaisystem.com/private',
//   tiny: '5iboyhuj1h86j1nz1avwhfiknu3qs31rwditmn4x2bnd4nb8',

//   know_server: 'http://apicmsknowapp.cinnco.co/api',
//   know_email: 'rduran@amitai.com',
//   know_password: 'Amitai2020',

//   hiringroom_server: 'http://api.hiringroom.com/v0',
//   hiringroom_name: 'amitaistaging',
//   hiringroom_redirect_uri: 'https://api.amitaisystem.com/oauth/hiringroom',
//   hiringroom_client_id: 'amitai_stg',
//   hiringroom_client_secret: 'Ry1S0mzQfsVicfq8algCIdkjlmqszPZshTUAeCKPEGg'
// };

export const environment = {

  production: true,
  base: 'https://api.amitaisystem.com',
  server: 'https://api.amitaisystem.com/api/v1',

  public: 'https://api.amitaisystem.com/public',
  blobCompany:'https://amitaicompany.blob.core.windows.net',
  private: 'https://api.amitaisystem.com/private',
  tiny: '5iboyhuj1h86j1nz1avwhfiknu3qs31rwditmn4x2bnd4nb8',

  know_server: 'http://apicmsknowapp.cinnco.co/api',
  know_email: 'rduran@amitai.com',
  know_password: 'Amitai2020',

  hiringroom_server: 'http://api.hiringroom.com/v0',
  hiringroom_name: 'amitaistaging',
  hiringroom_redirect_uri: 'https://api.amitaisystem.com/oauth/hiringroom',
  hiringroom_client_id: 'amitai_stg',
  hiringroom_client_secret: 'Ry1S0mzQfsVicfq8algCIdkjlmqszPZshTUAeCKPEGg'

};
