import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { MatSort } from "@angular/material/sort";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { EventEmitter } from "@angular/core";
import { ITableDataCompetency } from "../../interfaces/table-data-competency";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";
@Component({
  selector: "config-jobprofile-table",
  templateUrl: "./config-jobprofile-table.component.html",
  styleUrls: ["./config-jobprofile-table.component.css"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed, void",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
      transition(
        "expanded <=> void",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ConfigJobprofileTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() data: ITableDataCompetency[];
  @Output() updateGraphEvent = new EventEmitter<ITableDataCompetency>();
  @Output() showBehavioralMapEvent = new EventEmitter<ITableDataCompetency>();
  @Input() humanId: number;
  @Input() isEditing: boolean;
  dataSource: MatTableDataSource<ITableDataCompetency> =
    new MatTableDataSource<ITableDataCompetency>([]);
  columnsToDisplay = [
    "name",
    "category",
    "levelOfRequirement",
    "conductualMap",
  ];
  constructor(private conector: ConnectorJobProfileService) {}
  ngOnInit() {
    this.getCompetenciesByJob();
  }

  getCompetenciesByJob() {
    if (this.isEditing && this.data.length <= 0) {
      this.conector.get_competencies(this.humanId).subscribe((e: any) => {
        if (e && e.competencies) {
          e.competencies.forEach((x: any) => {
            x.selected = x.assigned;
          });

          const competencies: any = e.competencies.filter(
            (x: any) => x.assigned === true
          );
          this.data = competencies.map((item: any) => ({
            ...item,
            expanded: false,
          }));
          this.dataSource = new MatTableDataSource<ITableDataCompetency>(
            this.data
          );
        }
      });
    } else {
      this.data = this.data.map((item: any) => ({
        ...item,
        expanded: false,
      }));
      this.dataSource = new MatTableDataSource<ITableDataCompetency>(this.data);
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  toggleExpand(element: ITableDataCompetency) {
    element.expanded = !element.expanded;
  }

  updateGraph(element: ITableDataCompetency) {
    this.updateGraphEvent.emit(element);
  }
  showBehavioralMap(element: ITableDataCompetency) {
    this.showBehavioralMapEvent.emit(element);
  }
}
